import React, { useState } from 'react';
import { CancelButton, SubmitButton } from '../../../Components/Utils/Buttons';
import { NuInput } from '../../../Components/Utils/Input';
import { TextMediumXL } from '../../../Components/Text/MediumText';
import axiosInstance from '../../../Services/axiosCommon';
import { useFormik } from 'formik';
import { useToast } from '../../../Components/Others/ToastServices';
import * as yup from 'yup';
import DecryptData from '../../../Components/Utils/Decrypt';
import { useNavigate } from 'react-router-dom';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'; // Import eye icons

const ChangeUserPassword = ({ onClose }) => {
  const userDetails = DecryptData("sLiAtEdReSuEdArT") || "";
  const navigate = useNavigate();
  const toast = useToast();

  const [createNew] = useState({
    email: userDetails?.email || '',
    type_password: '',
    retype_password: '',
  });

  const [showPassword, setShowPassword] = useState({
    type_password: false,
    retype_password: false,
  });

  const validationSchema = yup.object({
    type_password: yup.string().required("Password is required"),
    retype_password: yup
      .string()
      .oneOf([yup.ref('type_password')], "Passwords must match")
      .required("Re-type password is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const payload = {
        email: values.email,
        type_password: values.type_password,
        retype_password: values.retype_password,
      };

      axiosInstance.put(`/auth/reset-password`, payload)
        .then((res) => {
          if (res.data.status) {
            resetForm();
            toast.open({
              type: "success",
              message: "Password reset successfully",
              description: res.data?.msg || "Password has been updated.",
            });
            navigate(-1);
          }
        })
        .catch((err) => {
          toast.open({
            type: "error",
            message: "Password reset failed",
            description: err.response?.data?.msg || "Network Error",
          });
        });
    },
  });

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
      <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
        <form onSubmit={formik.handleSubmit}>
          <div>
            <div className="w-full flex flex-wrap">
              <TextMediumXL content="Enter New Password" otherStyles="mb-2" align="start" />

             
              <div className="relative w-full">
                <NuInput
                  type={showPassword.type_password ? "text" : "password"}
                  label="New Password"
                  placeholder="******"
                  formik={formik}
                  name="type_password"
                  width="w-full"
                />
                <div
  className="absolute inset-y-0 right-0 mt-6 mr-5 flex items-center pr-3 cursor-pointer"
  onClick={() => setShowPassword(prev => ({ ...prev, type_password: !prev.type_password }))}
>
  {showPassword.type_password 
    ? <AiFillEyeInvisible style={{ color: '#048178' }} /> 
    : <AiFillEye style={{ color: '#048178' }} />
  }
</div>

              </div>

             
              <div className="relative w-full">
                <NuInput
                  type={showPassword.retype_password ? "text" : "password"}
                  label="Re-type Password"
                  placeholder="******"
                  formik={formik}
                  name="retype_password"
                  width="w-full"
                />
                <div
                  className="absolute inset-y-0 right-0 flex mt-6 mr-5  items-center pr-3 cursor-pointer"
                  onClick={() => setShowPassword(prev => ({ ...prev, retype_password: !prev.retype_password }))}
                >
                  {showPassword.retype_password ? <AiFillEyeInvisible style={{ color: '#048178' }} /> : <AiFillEye  style={{ color: '#048178' }}/>}
                </div>
              </div>
            </div>

            <div className="w-full flex justify-end items-center gap-2">
              <CancelButton handleClick={() => navigate(-1)} />
              <SubmitButton />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangeUserPassword;
