import { Route, useLocation, useNavigate } from "react-router-dom";
import MainStore from "../Views/Web/Store/MainStore";
import RequestMain from "../Views/Web/Store/Request/MainRequest";
import RequestIndex from "../Views/Web/Store/Request";
import CreateRequest from "../Views/Web/Store/Request/CreateRequest";
import EditRequest from "../Views/Web/Store/Request/EditRequest";
import ViewRequest from "../Views/Web/Store/Request/ViewRequest";
import EditGRN from "../Views/Web/Store/GRN/EditGRN";
import ViewGRN from "../Views/Web/Store/GRN/ViewGRN";
import CreateGRN from "../Views/Web/Store/GRN/CreateGRN";
import GRNIndex from "../Views/Web/Store/GRN";
import MainGRN from "../Views/Web/Store/GRN/MainGRN";
import MainDeliveryNotes from "../Views/Web/Store/DeliveryNotes/MainDeliveryNotes";
import DeliveryNotesIndex from "../Views/Web/Store/DeliveryNotes";
import CreateDeliveryNotes from "../Views/Web/Store/DeliveryNotes/CreateDeliveryNotes";
import EditDeliveryNotes from "../Views/Web/Store/DeliveryNotes/EditDeliveryNotes";
import ViewDeliveryNotes from "../Views/Web/Store/DeliveryNotes/ViewDeliveryNotes";
import { AssetsIndex, AssetsMain, CreateAssets, EditAssets, ViewAssets } from "../Views/Web/Store/Assets";
import { CreateInventory, EditInventory, InventoryIndex, InventoryMain, ViewInventory } from "../Views/Web/Store/Inventory";
import { CreateReturns, EditReturns, ReturnsIndex, ReturnsMain, ViewReturns } from "../Views/Web/Store/Returns";
import { CreatePackingList, EditPackingList, PackingListIndex, PackingListMain, ViewPackingList } from "../Views/Web/Store/PackingList";
import { useEffect } from "react";
import { Wrapper } from "../Components/Utils/roles";
import ViewOfRelatableGrn from "../Views/Web/Store/GRN/ViewOfRelatableGrn";
import RelatedGRNIndex from "../Views/Web/Store/GRN/RelatedGRNIndex";



function StoreRoutes(){
    const location = useLocation();
    const navigate = useNavigate();
  
    useEffect(() => {
      if (
        location.pathname === "/store" ||
        location.pathname === "/store/") {
        navigate("/page-not-found")
      }
    },[location])
    return(

        <Route path="store" element={<MainStore/>}>
            <Route path="requests" element={<RequestMain/>}>
                <Route index element={<Wrapper accessKey={"requests_view"}  Component={RequestIndex} />}/>
                <Route path="create" element={<Wrapper accessKey={"requests_create"}  Component={CreateRequest} />}/>
                <Route path="edit/:requestEditId" element={<Wrapper accessKey={"requests_edit"}  Component={EditRequest} />}/>
                <Route path="view/:requestViewId" element={<Wrapper accessKey={"requests_view"}  Component={ViewRequest} />}/>
            </Route>
            <Route path="grn" element={<MainGRN/>}>
                <Route index element={<Wrapper accessKey={"grn_view"}  Component={GRNIndex} />}/>
                <Route path="create" element={<Wrapper accessKey={"grn_create"}  Component={CreateGRN} />}/>
                <Route path="edit/:grnEditId" element={<Wrapper accessKey={"grn_edit"}  Component={EditGRN} />}/>
                <Route path="view/:grnViewId" element={<Wrapper accessKey={"grn_view"}  Component={ViewGRN} />}/>
                <Route path="viewIndex/:grnIndexViewId" element={<Wrapper accessKey={"grn_view"}  Component={RelatedGRNIndex} />}/>
                <Route path="viewProduct/:grnProductViewId" element={<Wrapper accessKey={"grn_view"}  Component={ViewOfRelatableGrn} />}/>
            </Route>
            <Route path="delivery-note" element={<MainDeliveryNotes/>}>
                <Route index element={<Wrapper accessKey={"delivery_notes_view"}  Component={DeliveryNotesIndex} />}/>
                <Route path="create" element={<Wrapper accessKey={"delivery_notes_create"}  Component={CreateDeliveryNotes} />}/>
                <Route path="edit/:deliveryNoteEditId" element={<Wrapper accessKey={"delivery_notes_edit"}  Component={EditDeliveryNotes} />}/>
                <Route path="view/:deliveryNoteViewId" element={<Wrapper accessKey={"delivery_notes_view"}  Component={ViewDeliveryNotes} />}/>
            </Route>
            <Route path="assets" element={<AssetsMain/>}>
                <Route index element={<Wrapper accessKey={"assets_view"}  Component={AssetsIndex} />}/>
                <Route path="create" element={<Wrapper accessKey={"assets_create"}  Component={CreateAssets} />}/>
                <Route path="edit/:assetsEditId" element={<Wrapper accessKey={"assets_edit"}  Component={EditAssets} />}/>
                <Route path="view/:assetsViewId" element={<Wrapper accessKey={"assets_view"}  Component={ViewAssets} />}/>
            </Route>
            <Route path="inventory" element={<InventoryMain/>}>
                <Route index element={<Wrapper accessKey={"inventory_view"}  Component={InventoryIndex} />}/>
                <Route path="create" element={<Wrapper accessKey={"inventory_create"}  Component={CreateInventory} />}/>
                <Route path="edit/:inventoryEditId" element={<Wrapper accessKey={"inventory_edit"}  Component={EditInventory} />}/>
                <Route path="view/:inventoryViewId" element={<Wrapper accessKey={"inventory_view"}  Component={ViewInventory} />}/>
            </Route>
            <Route path="returns" element={<ReturnsMain/>}>
                <Route index element={<Wrapper accessKey={"returns_view"}  Component={ReturnsIndex} />}/>
                <Route path="create" element={<Wrapper accessKey={"returns_create"}  Component={CreateReturns} />}/>
                <Route path="edit/:returnsEditId" element={<Wrapper accessKey={"returns_edit"}  Component={EditReturns} />}/>
                <Route path="view/:returnsViewId" element={<Wrapper accessKey={"returns_view"}  Component={ViewReturns} />}/>
            </Route>
            <Route path="packing-list" element={<PackingListMain/>}>
                <Route index element={<Wrapper accessKey={"packing_list_view"}  Component={PackingListIndex} />}/>
                <Route path="create" element={<Wrapper accessKey={"packing_list_create"}  Component={CreatePackingList} />}/>
                <Route path="edit/:packingListEditId" element={<Wrapper accessKey={"packing_list_edit"}  Component={EditPackingList} />}/>
                <Route path="view/:packingListViewId" element={<Wrapper accessKey={"packing_list_view"}  Component={ViewPackingList} />}/>
            </Route>
        </Route>
    )
}

export default StoreRoutes;