import React, { useState } from 'react'
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import { useFormik } from 'formik';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import useAddFormData from '../../../../Components/Hooks/useAddFormData';
import * as yup from 'yup';
import { NuDate, NuInput, NuTextArea } from '../../../../Components/Utils/Input';
import DropDownAddMetaData from '../../../../Components/Utils/selectAddMetaData';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import { useParams } from 'react-router-dom';

function CreateVehicleMaintence() {
  const currentDate = new Date().toISOString().split('T')[0];
  const {value:mainType ,getData:refmainType} = useFetchMataData(`/dropdown/dropdown-details/`,'vehicle_mainType')
const {vehicleId}=useParams()
console.log(vehicleId,"oo")
    const { Oncancel, addFormData,isLoading } = useAddFormData();
    const [createNew, setCreateNew] = useState({
        mainDate: currentDate || "",
        mainBy : "",
        vehicleId:vehicleId||"",
        mainType : "",
        nextMain : "",
        comments : ""
      });
      // const [loading, setLoading] = useState(false);

    const formik = useFormik({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
        // email: yup.string().required('Email is required!'),
      }),
      onSubmit: async (value, { resetForm }) => {
        // setLoading(true); 
        addFormData(`/vehicles/new-maintenance`, value, resetForm);
        // setLoading(false)
      },
    });
    return (
      <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
       <Breadcrumb
            items={[
              {
                name: "Vehicle",
                link: "/maintenance/vehicle",
              },
              {
                name: "Maintenance Vehicle",
              },
            ]}
          />
          <BackButton />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden mt-4">
            <div className=" w-full h-full flex py-2 justify-start content-start flex-wrap  overflow-y-auto">
            <div className="w-full">
  <p className='pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4'>
  Maintenance Details:
  </p></div>
            <NuDate
                label="Maintenance Date"
                placeholder="Maintenance Date"
                formik={formik}
                name="mainDate"
                width="w-full md:w-1/2 lg:w-1/3"
              />
              <NuInput
                type="test"
                label="Maintenance By"
                placeholder="Maintenance By"
                formik={formik}
                name="mainBy"
                width="w-full md:w-1/2 lg:w-1/3"
              />
               <DropDownAddMetaData
                  label="Maintenance Type"
                  placeholder="Choose"
                  Options={mainType}
                  formik={formik}
                  name="mainType"
                  width="w-full md:w-1/2 lg:w-1/3"
                  getFunc={refmainType}
                  displayProperty={"Maintenance Type"}
                  propertyName={"vehicle_mainType"}
                />           
               <NuDate
                label="Next Maintenance"
                placeholder="Next Maintenance"
                formik={formik}
                name="nextMain"
                width="w-full md:w-1/2 lg:w-1/3"
              />          
              <NuTextArea
                label="Comments"
                placeholder="comments"
                formik={formik}
                name="comments"
                width="w-full md:w-1/2 lg:w-1/3"
              />
              <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
                <div className="md:flex justify-end gap-4">
                  <CancelButton handleClick={() => { Oncancel(-1);}}/>
                  <SubmitButton isLoading={isLoading}/>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }  

export default CreateVehicleMaintence