import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';
import { NormalHeading, SmallHeading } from '../../../../Components/Utils/Heading';
import capitalizeFirstLetter from '../../../../Components/Utils/Funcs';

function ViewUser() {
  const location = useLocation();
    const data = location.state.data;
    const from = location.state.from;
  return (
    <div className='w-full h-full px-4 py-2'>
        <div className="w-full h-[46px]  flex justify-between ">
        <Breadcrumb
         items={
          [
            {
              name: "Settings",
              link: "/settings/user"
            },  
          {
            name: "User",
            link: "/settings/user"
          },
          {
            name: "View User"
          }]
         }
        />
        <div className=" flex gap-2">
          <Link to={'/settings/user'}><BackButton link={true}/></Link>
          <Link to={`/settings/user/edit/${data?.userId}`} state={{data: data, from:`/settings/user/view/${data.userId}`}}><EditButton height='8'/></Link>
        </div>
      </div>
      <div className="w-full mx-auto h-[calc(100vh-145px)]  rounded-md overflow-hidden overflow-y-auto p-3">
      <div className="flex gap-4 w-full h-full">
         
              <div className="w-[60%]   bg-white rounded-lg drop-shadow-lg  overflow-y-auto  px-4  flex  flex-col">
              <div className="py-4">
              <NormalHeading title= {data?.name || '-'}/>
              </div>
                     <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>User ID</p>
                    <p className='w-1/2 py-2 pl-5  text-xs  text-[#606279]'>{data?.userId || '-'}</p>
                  </div>
                  {/* <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Name</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.name || '-'}</p>
                  </div> */}
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Email</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.email || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Phone</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.phone?.number || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Division</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.division || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
  <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Designation</p>
  <p className='w-1/2 py-2 pl-5 text-xs text-[#606279]'>
    {data?.designation ? capitalizeFirstLetter
    (data.designation) : '-'}
  </p>
</div>

                  {data?.designation === "salesman" && ( <>
                                                                               <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Monthly Target</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.monthlyTar || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Quarterly Target</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.quarterlyTar || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>HalfYear Target</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.halfYearTar || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Yearly Target</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.yearlyTar || '-'}</p>
                  </div>                            
                  </>)}
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Status</p>
                    <p className='w-1/2 py-2 pl-5  text-xs  text-[#606279]'>{data?.status == "" ? "In-Active" : 
                                                                              data?.status == 1 ? "Active" :
                                                                                "-"}
                                                                               </p>
                                                                               </div>  
                                                                               </div>
                 
           <div className="py-4 bg-white rounded-lg drop-shadow-lg w-[40%] px-4">
  <SmallHeading title={"User Image"} />
  <div className="w-full flex items-center justify-center mt-8">
    {data?.userImage?.file ? (
      <div className="w-[80%] h-[80%] rounded-[5%] overflow-hidden border border-gray-300">
        <img
          src={data.userImage.file}
          alt={`${data?.name}'s image`}
          className="object-cover w-full h-full"
        />
      </div>
    ) : (
      <div className="flex items-center justify-center w-full h-40 rounded bg-gray-100 text-gray-500">
        <p>No User Image available</p>
      </div>
    )}
  </div>
</div>


                 
        
    </div>

</div>
           
      </div>
              
        
  
  )
}

export default ViewUser;