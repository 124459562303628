import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import socket from '../../../Components/Utils/socket';
import socketAxiosInstance from '../../../Services/socketAxiosCommon';
import { CancelButton, SearchButton, SubmitButton } from '../../../Components/Utils/Buttons';
import Send from '../../../Components/Icons/send';
import ViewMore from '../../../Components/Icons/ViewMore';
import axiosInstance from '../../../Services/axiosCommon';
import { useToast } from '../../../Components/Others/ToastServices';
import { useFormik } from 'formik';
import { NuInput } from '../../../Components/Utils/Input';
import CustomImgUpload from '../../../Components/Utils/CustomImgUpload';
import * as yup from 'yup'
import DateFormat from "../../../Components/Utils/NuDate";


function ChatProfile() {
  const { id } = useParams();
  const location = useLocation();
  const data = location.state?.data;
  const [items, setItems] = useState([]);
  const item = location.state?.details
  // const refresh = location?.state?.rrefresh;
  const [message, setMessage] = useState("")
  const [messages, setMessages] = useState([])
  const messageContainerRef = useRef(null);
  const toast = useToast();
  const navigate = useNavigate();
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [createNew, setCreateNew] = useState({
    name: "",
    members: "",
    groupLogo: "",
    oldImage: ""
  });

  useEffect(() => {
    setCreateNew({
      name: item?.name,
      members: "",
      groupLogo: item?.image,
      oldImage: item?.image
    })
    getMembers();
    getData();
    if (item?.type == 'group') {
      getGroupMessages()
    } else {
      getMessages();
    }
  }, [item])

  const handleSendMessage = (e) => {
    e.preventDefault()
    if (message === "" || message === null) {
      return
    }
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('en-US', {
      year: '2-digit',
      month: 'short', // Use 'short' for abbreviated month names
      day: '2-digit',
    });

    // Format time
    const formattedTime = currentDate.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
    if (item.type!=='group'){
      const newMsg = {
        sender_id: data.id,
        receiver_id: id,
        username: data.email,
        message: message,
        time: formattedTime,
        date: formattedDate
      }

      socket.emit("send_message", newMsg)
      setMessages([...messages, newMsg])
      setMessage("")
    }else{
      const newMsg = {
        userId: data.id,
        group_id: item?.id,
        username: data.email,
        message: message,
        createdAt: formattedTime,
        date: formattedDate
      }

      socket.emit("send_group_message", newMsg)
      setMessages([...messages, newMsg])
      setMessage("")
    }
  }

  const connectSocket = () => {
    socket.connect();

    const userId = data?.id; // Adjust this based on your actual structure
    const userEmail = data?.email;

    const joinData = {
      userId: userId,
      username: userEmail,
    };
    socket.emit('join_room', joinData);
  }
  const disConnectSocket = () => {
    const userId = data?.id; // Adjust this based on your actual structure
    const userEmail = data?.email;

    const disconnectData = {
      userId: userId,
      username: userEmail,
    };
    socket.emit('leave_room', disconnectData);
    socket.disconnect();
  }

  async function getMessages() {
    await socketAxiosInstance
      .get(`/chat`, {
        params: {
          sender_id: data?.id || 1,
          receiver_id: id || 2,
        },
      })
      .then(async (res) => {
        (await res?.data?.data) &&
          setMessages(res?.data?.data);
        // setTotalProject(res.data?.data?.totalRecords);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getGroupMessages() {
    await axiosInstance
      .get(`/groups/messages/${item?.id}`)
      .then(async (res) => {
        (await res?.data?.data) &&
          setMessages(res?.data?.data);
        // setTotalProject(res.data?.data?.totalRecords);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const [members, setMembers] = useState([]);
  async function getMembers() {
    await axiosInstance
      .get(`/groups/drop-down/${id}`)
      .then(async (res) => {
        (await res?.data) && setMembers(res?.data);
        // setTotalProject(res.data?.data?.totalRecords);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const [existingMembers, setExistingMembers] = useState([])
  const [deletedMemberIds, setDeletedMemberIds] = useState([]);
  async function getData() {
    await axiosInstance
      .get(`/groups/${id}`)
      .then(async (res) => {
        const data = res?.data?.data;
        if (data) {
          setItems(data);
          const groupMemberIds = data.groupMembers?.map(member => member.userId) || [];
          setExistingMembers(groupMemberIds);
        }
        // setTotalProject(res.data?.data?.totalRecords);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    connectSocket();
    return () => {
      disConnectSocket();
    }
  }, []);

  useEffect(() => {
      const handleMessageReceive = (data) => {
        if (data.sender_id === item.id) {
          setMessages((prevMessages) => [...prevMessages, data]);
          socket.emit("read_message", data);
        }
      };
      const handleGroupMessageReceive = (data) => {
        if (data.group_id === item.id) {
          setMessages((prevMessages) => [...prevMessages, data]);
          socket.emit("read_group_message", data);
        }
      };

    const handleError = (error) => {
      console.error("Socket error:", error);
    };

    if (item.type !== 'group') {
      socket.on("receive_message", handleMessageReceive);
    } else {
      socket.on("receive_group_message", handleGroupMessageReceive);
    }
    socket.on("error", handleError);

    return () => {
      socket.off("receive_message", handleMessageReceive);
      socket.off("error", handleError);
    };
  }, [socket, item]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage(e);
    }
  };

  useEffect(() => {
    // Scroll down when messages change
    messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
  }, [messages]);

  const DeleteGroup = (id) => {
    axiosInstance.delete(`groups/${id}`)
      .then((res) => {
        if (res.data?.status === true) {
          toast.open({
            type: "success",
            message: "Success",
            description: res.data?.msg || "deleted Successfully."
          })
          setMessages([]);
          navigate('/chat');
          window.location.reload();
        }
      })
  }

  const closeFilterDialog = () => {
    setShowFilterDialog(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      let formData = new FormData();
      const valueData = {
        ...value,
        members: [
          ...existingMembers.filter(id => !deletedMemberIds.includes(id)),
          ...selectedUserIds
        ],
      }
      formData.append("json_data", JSON.stringify(valueData));
      formData.append('groupLogo', image.raw);
      await axiosInstance.put(`/groups/edit-group/${item?.id}`, formData)
        .then((res) => {
          if (res.data?.status === true) {
            toast.open({
              type: "success",
              message: "Success",
              description: res.data?.msg || "Created Successfully."
            })
            closeFilterDialog();
            // window.location.reload();/
            navigate("/chat")
          }
          else if (res.data?.status === false && res.data?.error) {
            toast.open({
              type: "error",
              message: "Error",
              description: res.data.error || "Network error."
            })
          }
          else {
            toast.open({
              type: "warning",
              message: "Warning",
              description: res.data?.msg || "Warning."
            })
          }
        }).catch((err) => {
          console.log(err);
        })
    },
  });

  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  const handleDeleteImg = () => {
    setImage({
      preview: "",
      raw: "",
    });
    setCreateNew(prevState => ({ ...createNew, groupLogo: "" }))
    formik.values.groupLogo = ''
    formik.values.oldImage = ''
  }


  const handleUserSelect = (user) => {
    if (!selectedUsers.some(selectedUser => selectedUser.id === user.id)) {
      setSelectedUsers((prevSelectedUsers) => [...prevSelectedUsers, user]);
      setSelectedUserIds((prevSelectedUserIds) => [...prevSelectedUserIds, user.id]);
    }
  };

  const handleUserDelete = (id) => {
    setSelectedUsers((prevSelectedUsers) =>
      prevSelectedUsers.filter(user => user.id !== id)
    );
  };

  const [searchQueryFront, setSearchQueryFront] = useState("");
  const filteredMembers = members.filter((member) =>
    member.name.toLowerCase().includes(searchQueryFront.toLowerCase())
  );

  const handleUserDeleteExisting = (userId) => {
    setDeletedMemberIds(prevDeletedMemberIds => [...prevDeletedMemberIds, userId]);
    setItems(prevItems => ({
      ...prevItems,
      groupMembers: prevItems.groupMembers.filter(member => member.userId !== userId)
    }));
  };

  return (

    <div className="w-full h-full rounded-r-md overflow-hidden">
      <div className='w-full h-[90%] overflow-hidden'>
        <div className='w-full h-14 bg-baseColor-secondary pt-2 px-2 mb-3'>
          <div className='flex justify-between items-center'>
            <div className='flex gap-2 item-center'>
              <div className="w-9 h-9 bg-teal-100 rounded-full overflow-hidden mt-0.5 flex items-center justify-center border border-white border-solid">
                {item?.image ? (
                  <img
                    src={item.image}
                    width="50px"
                    alt={`${item.image}`}
                    className="max-w-full max-h-full"
                  />
                ) :
                  <h1 className='font-semibold text-textColor-main text-xl'>
                    {item?.name ? item?.name?.[0] : null}
                  </h1>}
              </div>
              <div className="text-base font-medium text-[#ffffff] mt-2">
                {item?.name}
              </div>
            </div>
            {item?.type == "group" &&
              <div className='text-[#344767]  relative dropdown inline-block z-50'>
                <div className=' cursor-pointer underline-offset-8 text-xs mx-auto font-semibold flex items-center'>
                  <span className=' w-7 h-7 bg-gray-50 flex flex-col items-center justify-center rounded-full' style={{ border: '1px solid #C0C0C0' }}>
                    <ViewMore />
                  </span>
                </div>
                <ul className='dropdown-menuOne text-[#344767] text-left  rounded-lg absolute -left-[81px] -top-4 overflow-hidden hidden  z-50 shadow-lg'>
                  <li className=' w-[100px] bg-black block m-0'>
                    <span
                      className='bg-white hover:bg-gray-100 py-2 px-2 border-none block whitespace-no-wrap text-xs font-medium cursor-pointer'
                      onClick={() => setShowFilterDialog(true)}
                    >
                      Edit
                    </span>
                  </li>
                  <li className=''>
                    <a
                      className='bg-white hover:bg-gray-100 text-black hover:text-black py-2 px-2 block whitespace-no-wrap text-xs font-medium'
                      // to='/members'
                      href="#"
                      onClick={() => DeleteGroup(item?.id)}
                    >
                      Delete
                    </a>
                  </li>
                </ul>
              </div>
            }
          </div>
        </div>
        <div ref={messageContainerRef} className='w-full max-h-[calc(97%-3.5rem)] overflow-y-auto p-2'>
          {
            messages.map((iteem, idx) => {              
              return (
                (item.type!=='group' ? (
                <div className='w-full my-2' key={idx}>
                  {
                    data.id !== iteem.sender_id ?
                      <div className='flex justify-start'>
                        <div className='rounded-md min-w-[120px] px-2 py-0.5 bg-teal-200  border border-teal-400 text-gray-500 rounded-e-xl rounded-es-xl'>
                          <pre>{iteem.message}</pre>
                          <p className='text-[9px] text-gray-400 text-end'>{iteem?.date == DateFormat({value:Date(),format:"DD-MMM-YY"})?"Today ":iteem?.date} {iteem.time}</p>
                        </div>
                      </div>
                      :
                      <div className='flex justify-end '>
                        <div className=' px-2 py-0.5 min-w-[120px] border border-teal-400 text-gray-600 rounded-s-xl rounded-se-xl'>
                          <pre>{iteem.message}</pre>
                          <p className='text-[9px] text-gray-400 text-end'>{iteem?.date == DateFormat({value:Date(),format:"DD-MMM-YY"})?"Today ":iteem?.date} {iteem.time}</p>
                        </div>
                      </div>
                  }
                </div>
                ):
              (
                <div className='w-full my-1' key={idx}>
                  {
                    data.id !== iteem.userId ?
                      <div className='flex justify-start'>
                        <div className='rounded-md min-w-[120px] px-2 py-0.5 bg-teal-200  border border-teal-400 text-gray-500 rounded-e-xl rounded-es-xl'>
                          <p className='text-[12px] font-normal'>{iteem.memberName}</p>
                          <pre>{iteem.message}</pre>
                          <p className='text-[9px] text-gray-400 text-end'>{iteem?.date == DateFormat({value:Date(),format:"DD-MMM-YY"})?"Today ":iteem?.date} {iteem.time}</p>
                        </div>
                      </div>
                      :
                      <div className='flex justify-end '>
                        <div className=' px-2 py-0.5 min-w-[120px] border border-teal-400 text-gray-600 rounded-s-xl rounded-se-xl'>
                          <pre>{iteem.message}</pre>
                          <p className='text-[9px] text-gray-400 text-end'>{iteem?.date == DateFormat({value:Date(),format:"DD-MMM-YY"})?"Today ":iteem?.date} {iteem.time}</p>
                        </div>
                      </div>
                  }
                </div>
              )
              )
              )
            })
          }
        </div>
      </div>
      <div className='w-full h-[10%] px-1'>
        <form className='form text-left flex' onSubmit={handleSendMessage}>
          <textarea
            type="text"
            placeholder='Write message'
            className='w-full bg-transparent border-[1px] outline-none border-slate-300 resize-none text-xs text-gray-800 placeholder-gray-500 h-10  p-2 rounded-l-md'
            style={{ resize: 'none' }}
            value={message}
            onChange={e => setMessage(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <button className={`bg-baseColor-secondary ${message && "cursor-pointer"} p-2 border-none rounded-r-lg`}>
            <Send />
          </button>
        </form>
      </div>
      {showFilterDialog && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center " >
          <div className="animate-scale-in w-[70%] h-[82vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
            <h2 className="text-xl mb-4">Edit Group</h2>
            <form onSubmit={formik.handleSubmit}>
              <div className="flex w-full">
                <div className="w-1/2 p-4 ">
                  <NuInput
                    type="text"
                    label="Group Name"
                    placeholder="Group Name"
                    formik={formik}
                    name="name"
                    width="w-full"
                    px="0"
                  />
                  <p className={`text-xs 2xl:text-sm text-black pb-2`}>
                    Select Users
                  </p>
                  <div className=" w-full h-[34px] relative rounded-md p-1 flex justify-between bg-white items-center" style={{ border: '0.5px solid #EAEBED' }}>
                    <input type="text" onChange={(e) => setSearchQueryFront(e.target.value)} placeholder="Search here" className="placeholder-gray-400 w-[90%] text-sm px-2 outline-none border-none" />
                    <SearchButton />
                  </div>
                  <div className="w-full h-52 rounded-md mt-2 bg-[#f5f3ff] border border-slate-200 overflow-y-scroll">
                    {filteredMembers?.map((user, index) => {
                      return (
                        <span
                          key={index}
                          onClick={() => handleUserSelect(user)}
                          className={` ${selectedUsers.some(selectedUser => selectedUser.id === user.id)
                            ? 'bg-gray-300 cursor-not-allowed'
                            : 'bg-white cursor-pointer hover:bg-[#B3EBD7]  hover:bg-opacity-50'
                            }  m-1  flex justify-between items-center text-xs border border-gray-300 rounded text-main-title py-2 px-2`}>
                          {user?.name}
                        </span>
                      )
                    })}
                  </div>
                </div>
                <div className="w-1/2 p-4">
                  <CustomImgUpload
                    borderStyle='dashed'
                    handleChange={handleChangeImg}
                    label={'Group Logo'}
                    preview={image.preview || createNew.groupLogo || ""}
                    removeFunction={handleDeleteImg}
                  />
                  <p className={`text-sm 2xl:base text-black mt-5`}>
                    Group Created By: {items?.admin}
                  </p>
                  <p className={`text-xs 2xl:text-sm text-black mt-3`}>
                    Users In Group
                  </p>
                  <div className="w-full h-[200px] rounded-md bg-[#f5f3ff] border border-slate-200 mt-1 overflow-y-scroll">
                    <>
                      {(
                        selectedUsers.map((user, index) => (
                          <div
                            key={index}
                            className="m-1 flex justify-between items-center text-xs bg-[#B3EBD7] rounded text-main-title py-2 px-2 hover:bg-baseColor-secondary hover:bg-opacity-50"
                          >
                            {user?.name}
                            <span
                              onClick={() => {
                                handleUserDelete(user?.id);
                              }}
                              className="text-blue-900 cursor-pointer"
                            >
                              x
                            </span>
                          </div>
                        ))
                      )}
                      {(
                        items?.groupMembers?.map((user, index) => (
                          <div
                            key={index}
                            className=" m-1  flex justify-between items-center text-xs bg-white border border-gray-300 rounded text-main-title py-2 px-2 hover:bg-[#B3EBD7] hover:bg-opacity-50"
                          >
                            {user?.memberName}
                            <span
                              onClick={() => {
                                handleUserDeleteExisting(user?.userId);
                              }}
                              className="text-blue-900 cursor-pointer"
                            >
                              x
                            </span>
                          </div>
                        ))
                      )}
                    </>
                  </div>

                </div>
              </div>
              <div className="flex justify-end gap-2">
                <CancelButton handleClick={closeFilterDialog} />
                <SubmitButton name="Save" />
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default ChatProfile;