import AccessDenied from "./AccessDenied";
import DecryptData from "./Decrypt";

export function GetAccess(accessText){
    if(GetRole()){
        return true
    }else{
        const userAccess = localStorage.getItem("sSeCcArEsUeDaRt")?DecryptData("sSeCcArEsUeDaRt"):[]
        const access = userAccess?userAccess.some((permission)=>permission.permissionName == accessText ):false;
        return access;
    }
}


export function GetMultipleAccess(accessTextArr = []) {
    if(GetRole()){
        return true
    }else{
    const userAccess = localStorage.getItem("sSeCcArEsUeDaRt")?DecryptData("sSeCcArEsUeDaRt"):[];
    const access = accessTextArr.some(element => userAccess.some(userObj => userObj.permissionName === element));
    return access;
    }
}




export function GetRole(){
    const userData = localStorage.getItem("sLiAtEdReSuEdArT")?DecryptData("sLiAtEdReSuEdArT"):[];
    const role = userData?.role;
    if(role ==="super admin"||role==="admin"){
        return true
    }else{
        return false
    }
}

export function GetDashboardRole(giveRole="") {
    // const encryptedUserData = localStorage.getItem("sLiAtEdReSuEdArT");
    const userData = localStorage.getItem("sLiAtEdReSuEdArT")?DecryptData("sLiAtEdReSuEdArT"):[];
    const role = userData?.role;
    if(role ==="super admin"||role==="admin"){
        return true
    }else if(role === giveRole){
        return true
    }else{
        return false
    }

}


export function GetNotUseRole(giveRole="") {
    // const encryptedUserData = localStorage.getItem("sLiAtEdReSuEdArT");
    const userData = localStorage.getItem("sLiAtEdReSuEdArT")?DecryptData("sLiAtEdReSuEdArT"):[];
    const role = userData?.role;
    if(role ==="super admin"||role==="admin"){
        return true
    }else if(role === giveRole){
        return false
    }else{
        return false
    }

}


export function RendorPage(accessKey,Component){
if( (GetRole()||GetAccess( accessKey)) == true){
    return <Component/>
}else{
    return <AccessDenied/>
}
}


export function Wrapper({accessKey,Component}){
    return RendorPage(accessKey, Component);
  }