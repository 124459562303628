import React from 'react'
import { TextMediumdS, TextMediumSM, TextMediumXS } from '../../../../../Components/Text/MediumText';
import UseGetDashBoardData from '../../../../../Components/Hooks/useDashboardData';

const VehicleStatus = () => {
    const { data, loading } = UseGetDashBoardData(`/new-dashboard/vehicle-details`);
  
    return (
      <div className="col-span-2 bg-white h-[40vh] p-2  pb-10  rounded-lg shadow-lg overflow-hidden ">
        <div className="w-full  px-1 py-2 rounded-md ">
          <TextMediumSM content="Vehicle Status" color="text-[#4F4768]" />
        <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mt-2"></div>
  
        </div>
        {loading ? (
          <div className="flex justify-center items-center h-full">
            <div className="animate-pulse space-y-4 w-full">
              <div className="h-4 bg-slate-200 rounded"></div>
              <div className="h-4 bg-slate-200 rounded"></div>
              <div className="h-4 bg-slate-200 rounded"></div>
              <div className="h-4 bg-slate-200 rounded"></div>
            </div>
          </div>
        ) : (
          <div className="w-full h-[96%] overflow-y-scroll pb-4">
            <table className="w-full">
            <thead className="sticky top-0 bg-white shadow-sm">
  
                <tr>
                  <th className="text-left px-2 py-1"><TextMediumXS content="Vehicle" /></th>
                  <th className="text-left px-2 py-1"><TextMediumXS content="Plate No." /></th>
                  <th className="text-left px-2 py-1"><TextMediumXS content="Condition" /></th>
                  <th className="text-left px-2 py-1"><TextMediumXS content="Assignee" /></th>

                  {/* hover:shadow-md hover:scale-y-105 */}
                </tr>
              </thead>
              <tbody>
                              {data && data.map((item, index) => (
                  <tr className="odd:bg-white even:bg-slate-50 " key={index}>
                    <td className="px-2 py-2"><TextMediumdS
                     content={item?.name || "N/A"} /></td>
                    <td className="px-2 py-2"><TextMediumdS content={item?.plateNo || "N/A"} /></td>
                    <td className="px-2 py-2"><TextMediumdS content={item?.condition || "N/A"} /></td>
                    <td className="px-2 py-2"><TextMediumdS content={item?.assignedTo || "N/A"} /></td>

                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }
  

export default VehicleStatus