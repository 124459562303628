


const NuPills = ({width="w-16", bgColor="bg-slate-300",textColor="text-white",fontWight="text-normal",fontSize="text-xs" ,children}) =>{

    return(
        <div className={`${width} px-3 py-1 rounded-xl flex justify-around items-center ${bgColor} ${textColor} ${fontWight} ${fontSize}`}>
            {children}
        </div>
    )
}

export default NuPills;