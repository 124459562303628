import React, { useEffect, useState } from 'react'
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuDate, NuInput, NuTextArea } from '../../../../Components/Utils/Input';
import { useFormik } from 'formik';
import * as yup from 'yup';
import useAddFormData from '../../../../Components/Hooks/useAddFormData';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import DropDownAddMetaData from '../../../../Components/Utils/selectAddMetaData';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import { useParams } from 'react-router-dom';

function CreateEquipmentMaintenance() {
  const currentDate = new Date().toISOString().split('T')[0];
  const {value:mainType ,getData:refmainType} = useFetchMataData(`/dropdown/dropdown-details/`,'equipments_mainType')

    const { Oncancel, addFormData, contextHolder, isLoading } = useAddFormData();
  const {equipmentId}=useParams()
    const [createNew, setCreateNew] = useState({
        mainDate: currentDate || "",
        mainBy : "",
        equipmentId: equipmentId|| "",
        mainType : "",
        nextMain : "",
        comments : ""
       
    });
    // const [loading, setLoading] = useState(false);

    const formik = useFormik({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
    //     mainDate: yup.string().required('Maintenance date is required'),
    // mainBy: yup.string().required('Maintenance by is required'),
    // equipmentId: yup.string().required('Please select an Equipment'),
    // mainType: yup.string().required('Maintenance type is required'),
    // nextMain: yup.string().required('Next maintenance date is required'),
    // comments: yup.string(),
      }),
      onSubmit: async (value, { resetForm }) => {
        // setLoading(true); 
        addFormData("/equipments/new-maintenance", value, resetForm);
        // setLoading(false)
      

      },
    });
    
    return (
      <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
      <Breadcrumb
            items={[
              {
                name: "Equipments",
                link: "/maintenance/equipment",
              },
              {
                name: "Maintenance Equipment",
              },
            ]}
          />
          <BackButton />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden mt-4">
            <div className=" w-full h-full flex py-2 justify-start content-start flex-wrap  overflow-y-auto">
            <NuDate
                label="Maintenance Date"
                placeholder="Maintenance Date"
                formik={formik}
                name="mainDate"
                width="w-full md:w-1/2 lg:w-1/3"
              />
              
             
              <NuInput
                type="test"
                label="Maintenance By"
                placeholder="Maintenance By"
                formik={formik}
                name="mainBy"
                width="w-full md:w-1/2 lg:w-1/3"
              />
               <DropDownAddMetaData
                  label="Maintenance Type"
                  placeholder="Choose"
                  Options={mainType}
                  formik={formik}
                  name="mainType"
                  width="w-full md:w-1/2 lg:w-1/3"
                  getFunc={refmainType}
                  displayProperty={"Maintenance Type"}
                  propertyName={"equipments_mainType"}
                />
            

               <NuDate
                label="Next Maintenance"
                placeholder="Next Maintenance"
                formik={formik}
                name="nextMain"
                width="w-full md:w-1/2 lg:w-1/3"
              />
           
           
              <NuTextArea
                label="Comments"
                placeholder="comments"
                formik={formik}
                name="comments"
                
                width="w-full md:w-1/2 lg:w-1/3"
              />
       <div className="w-full my-4 mt-8 ">
              <div className="flex justify-end gap-4">
                       <CancelButton
                    handleClick={() => {
                      Oncancel(-1);
                    }}
                  />
                  <SubmitButton isLoading={isLoading}/>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
  

export default CreateEquipmentMaintenance