const NotificationIcon = () => {
    return (
      <div className="relative cursor-pointer">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="#ffff" viewBox="0 0 24 24">
          <path d="M12 24c1.104 0 2-.896 2-2h-4c0 1.104.896 2 2 2zm10-6v-5c0-3.514-2.613-6.432-6-6.92v-.58c0-1.657-1.343-3-3-3s-3 1.343-3 3v.58c-3.387.488-6 3.406-6 6.92v5l-2 2v1h20v-1l-2-2zm-8-14c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z"/>
        </svg>
      </div>
    );
  };
  
  export default NotificationIcon;
  