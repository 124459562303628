import React from "react";
import Breadcrumb from "../../Components/Utils/Breadcrumb";
import { Link, Outlet, useLocation } from "react-router-dom";
import { GetDashboardRole } from "../../Components/Utils/roles";

function DashboardLayout() {
    const location = useLocation();
    let pathFile = location.pathname;
    console.log("testing", pathFile);
    
    let LinkItem = [
        {
            label:"Overall",
            link:"/dashboard",
            access:GetDashboardRole(""),
        },
        {
            label:"Sales",
            link:"/dashboard/sales",
            access:GetDashboardRole("sales"),
        },
        {
            label:"Procurement",
            link:"/dashboard/procurement",
            access:GetDashboardRole("procurement"),

        },
        {
            label:"Stores",
            link:"/dashboard/stores",
            access:GetDashboardRole("store"),

        },
        {
            label:"Maintenance",
            link:"/dashboard/maintenance",
            access:GetDashboardRole(""),

        },
    ]
  return (
    <div className=" w-full h-full  px-4 py-2 overflow-hidden">
      <Breadcrumb items={[]} />

      <div className="w-[100%] h-[calc(100vh-90px)] mt-2">
        <div className=" w-full h-8 flex items-center gap-x-1 ">
            {
                LinkItem && LinkItem.map((item, i) => {
                    return(
                        <>
                         {
                            item.access && (
                                <Link to={item?.link} className={`mx-3 pb-1 text-xs 2xl:text-sm font-medium text-baseColor-primary ${item?.link == pathFile ?"border-b-2 border-baseColor-primary":"border-b-2 border-transparent"}   `}  key={item?.link} >{item?.label||""}</Link>
                            )
                         }
                        </>
                    )
                })
            }
        </div>
        <div className="w-[100%] h-[calc(100vh-141px)] overflow-y-auto">
            <Outlet/>
        </div>
      </div>
    </div>
  );
}

export default DashboardLayout;


// import React from "react";
// import Breadcrumb from "../../Components/Utils/Breadcrumb";
// import { Link, Outlet, useLocation } from "react-router-dom";
// import { GetRole } from "../../Components/Utils/roles";

// function DashboardLayout() {
//     const location = useLocation();
//     const pathFile = location.pathname;
    
  
//     const role = GetRole(); 
//     console.log("Restore",role);
    

//     let LinkItem = [
//       {
//         label: "Overall",
//         link: "/dashboard",
//         access: true, 
//       },
//       {
//         label: "Sales",
//         link: "/dashboard/sales",
//         access: (role === "super admin" || role === "admin") || role ==="sales", 
//       },
//       {
//         label: "Procurement",
//         link: "/dashboard/procurement",
//         access: (role === "super admin" || role === "admin") || role === "procurement", 
//       },
//       {
//         label: "Stores",
//         link: "/dashboard/stores",
//         access: (role === "super admin" || role === "admin") || role === "store", 
//       },
//       {
//         label: "Maintenance",
//         link: "/dashboard/maintenance",
//         access: (role === "super admin" || role === "admin") || role === "maintenance", 
//       },
//     ];

//     const accessibleLinks = LinkItem.filter((item) => item.access);
    
//     return (
//       <div className="w-full h-full px-4 py-2 overflow-hidden">
//         <Breadcrumb items={[]} />

//         <div className="w-[100%] h-[calc(100vh-90px)] mt-2">
//           <div className="w-full h-8 flex items-center gap-x-1">
//             {
//               accessibleLinks && accessibleLinks.map((item, i) => (
//                 <Link
//                   to={item.link}
//                   className={`mx-3 pb-1 text-xs 2xl:text-sm font-medium text-baseColor-primary ${item.link === pathFile ? "border-b-2 border-baseColor-primary" : "border-b-2 border-transparent"}`}
//                   key={i}
//                 >
//                   {item.label || ""}
//                 </Link>
//               ))
//             }
//           </div>
//           <div className="w-[100%] h-[calc(100vh-141px)] overflow-y-auto">
//             <Outlet />
//           </div>
//         </div>
//       </div>
//     );
// }

// export default DashboardLayout;