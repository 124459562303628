import React from 'react'
import Vehicle from './Components/Vehicle'
import Equipments from './Components/Equipments'
import Fahas from './Components/Fahas'
import Insurance from './Components/Insurance'
import VehicleStatus from './Components/VehicleStatus'
import EquipmentStatus from './Components/EquipmentStatus'

function MaintanaceIndex() {

    return (
      <div className="w-[100%] h-[calc(100vh-145px)] overflow-y-scroll py-2 ">
 

    
<div className="grid grid-cols-5 grid-rows-2 gap-4">
    <div ><Vehicle/></div>
    <div className=" row-start-2"><Equipments/></div>
     <div className="col-span-2 row-span-2 col-start-2 row-start-1"><Insurance/></div>
    <div className="col-span-2 row-span-2 col-start-4 row-start-1"><Fahas/></div>
</div> 


    
<div className='grid grid-cols-5 grid-rows-1 gap-4 mt-4'>
<div className="col-span-3 row-span-1"><VehicleStatus/></div>
    <div className="col-span-2 row-span-1 col-start-4"><EquipmentStatus/></div>
    </div>
      </div>
  )
}

export default MaintanaceIndex