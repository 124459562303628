import React, { useCallback, useState } from "react";
import Select from "react-select";

function MultiSelect({
  classname,
  labelclassname,
  name,
  placeholder,
  onChange,
  formik,
  label,
  Options,
  customClass,
  isRequired,
  width,
  FixPosition=false,
  PortalTarget= false
}) {
  const options = Options;

  const handleChange = (selectedOptions) => {
    // Extracting values from the selected options array
    const selectedValues = selectedOptions.map(option => option.value);
    formik.handleChange({
      target: {
        value: selectedValues, // Setting selected values as an array
        name: name,
      },
    });
  };

  const styles = {
    control: (provided) => ({
      ...provided,

      border: "1px solid #e5e5e5",
      outline: "none",
      borderRadius: "10px",
      padding: "1px 0",
      color: "#000",
      textAlign: "left",
      // fontSize:'13px',
      fontWeight: "500",
      width: "100%",
      zIndex: 0
    }),
  };
  return (
    <>
      <div className={`${width} relative px-6 py-2 `}>
        <label className={`${labelclassname} text-xs 2xl:text-sm`}>
          {label}
          {isRequired ? <span className="text-red-500"> *</span> : ""}
        </label>
        <div className="mt-2">
          <Select
            options={options}
            isMulti={true}
            value={options.filter(option => formik.values[name].includes(option.value))} // Filter options based on selected values
            styles={styles}
            className={`${classname} text-xs capitalize 2xl:text-sm mt-2 font-medium z-[1]`}
            name={name}
            onChange={onChange || handleChange}
            placeholder={placeholder}
            closeMenuOnSelect={false}
            menuPosition={FixPosition==true?"fixed":"absolute"}
            menuPortalTarget={PortalTarget==true?document.body:""}
          />
        </div>
        {formik.errors[name] ? (
          <span className="text-xs font-semibold text-red-500 ">
            {formik.errors[name]}
          </span>
        ) : null}
      </div>
    </>
  );
}

export default MultiSelect;


export function MultiSelectTwo({
  classname,
  labelclassname,
  name,
  placeholder,
  onChange,
  formik,
  label,
  Options,
  customClass,
  isRequired,
  width,
  handleClick=()=>{},
  debounceTime = 900,
  setSearch
}) {
  const options = Options;

  const [loading, setLoading] = useState(false);

  // Debounce function to limit API calls
  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };

  // Handle dynamic search
  const handleInputChange = useCallback(
    debounce(async (inputValue) => {
      if(inputValue) {
        if (inputValue.length>=3) {
          setLoading(true);
          // const data = UsePeoductDropDown('/items/new-drop-down',inputValue)||[]; // Call API
          // setOptions(data);
          setSearch(inputValue);
          setLoading(false);
        }
      }
    }, debounceTime),
    []
  );

  const handleChange = (selectedOptions) => {
    // Extracting values from the selected options array
    const selectedValues = selectedOptions.map(option => option.value);
    formik.handleChange({
      target: {
        value: selectedValues, // Setting selected values as an array
        name: name,
      },
    });
  };

  const styles = {
    control: (provided) => ({
      ...provided,

      border: "1px solid #e5e5e5",
      outline: "none",
      borderRadius: "10px",
      padding: "1px 0",
      color: "#000",
      textAlign: "left",
      // fontSize:'13px',
      fontWeight: "500",
      width: "100%",
      zIndex: 0
    }),
  };
  return (
    <>
      <div className={`${width} relative px-6 py-2 `}>
      <div className=" w-full flex gap-2">
        <label className={`${labelclassname} text-xs 2xl:text-sm w-[90%]`}>
          {label}
          {isRequired ? <span className="text-red-500"> *</span> : ""}
        </label>
        <button onClick={handleClick} type="button" className="w-6  text-center h-6 cursor-pointer bg-[#048178] flex justify-center items-center border border-solid border-white  text-white font-semibold rounded-lg " > + </button>

        </div>
        <div className="mt-2">
          <Select
            options={options}
            isMulti={true}
            value={options.filter(option => formik.values[name].includes(option.value))} // Filter options based on selected values
            styles={styles}
            className={`${classname} text-xs capitalize 2xl:text-sm mt-2 font-medium z-[1]`}
            name={name}
            onChange={onChange || handleChange}
            placeholder={placeholder}
            closeMenuOnSelect={false}
            onInputChange={handleInputChange} // Handle input change
            isLoading={loading}
          />
        </div>
        {formik.errors[name] ? (
          <span className="text-xs font-semibold text-red-500 ">
            {formik.errors[name]}
          </span>
        ) : null}
      </div>
    </>
  );
}

 