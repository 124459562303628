import { useFormik } from "formik";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import * as yup from "yup";
import useAddFormData from "../../../../Components/Hooks/useAddFormData";
import { useState } from "react";
import Input, { NuInput, NuMobileNumber, NuSelect, NuTextArea, NuUpload, SupplierNuInput } from "../../../../Components/Utils/Input";
import UseEditData from "../../../../Components/Hooks/useEditData";
import { useLocation } from "react-router-dom";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import { useEffect } from "react";
import axiosInstance from "../../../../Services/axiosCommon";
import useFetchMetaStar from "../../../../Components/Hooks/useFetchMetaStar";
import DropDownAddMetaData from "../../../../Components/Utils/selectAddMetaData";

const EditAgents = () => {
  const {value:Type} = useFetchMataData(`/dropdown/dropdown-details/`,'AgentType')
  const {value:Designation} = useFetchMataData(`/dropdown/dropdown-details/`,'AgentDesignation')
  const { value:Rating } = useFetchMetaStar(`/dropdown/dropdown-details/`,'AgentRating')
  const {value:status,getData:refstatus} = useFetchMataData(`/dropdown/dropdown-details/`,'AgentStatus')

  const { Oncancel, editData, contextHolder, isLoading } = UseEditData();
  const location = useLocation();
  const data = location.state.data;
  const from = location.state.from;
  const [image, setImage] = useState({ preview: "", raw: "" });
  const initialServices = data?.services ? data.services.split(", ").map((p) => p.trim()) : [];
  const [servicesList, setServicesList] = useState(initialServices);
  const servicesString = servicesList.join(', ');
  // const [loading, setLoading] = useState(false);

  const [createNew, setCreateNew] = useState({
    type: data?.type || "",
    status: data?.status || "",
    companyName: data?.companyName || "",
    email: data?.email || "",
    phone: data?.phone?.number || "",
    countryCode: data?.phone?.countryCode || "",
    website: data?.website || "",
    billingAddress: data?.billingAddress || "",
    shippingAddress: data?.shippingAddress || "",
    crNum: data?.crNum || "",
    vatNum: data?.vatNum || "",
    ratings: data?.ratings?.toString() || "",
    comments: data?.comments || "",
    country: data?.country || "",
    city: data?.city || "",
    state: data?.state || "",
    district: data?.district || "",
    postalCode: data?.postalCode || "",
    cpName: data?.cpName || "",
    cpEmail: data?.cpEmail || "",
    cpDesignation: data?.cpDesignation || "",
    cpCountryCode: data?.cpPhone?.countryCode || "",
    cpPhone: data?.cpPhone?.number || "",
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      phone: yup.string()
      .matches(/^\d+$/, "Phone number must contain only digits")
      .min(9, "Phone number must be at least 9 digits")
      .max(10, "Phone number must be at most 10 digits")
      .required("Phone number is required"),
      email: yup.string().required('Email is required!'),
      companyName: yup.string().required('Company Name is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      // setLoading(true); 
      let formData = new FormData();
      const valueWithProducts = {
        ...value,
        services: servicesString
      };
      formData.append("agentDetails", JSON.stringify(valueWithProducts));
      formData.append('agentImage', image.raw);

      editData(`/agents/edit-agent/${data.agentId}`, formData, resetForm, from)
      // setLoading(false)

    },
  });

  const handleAddServices = () => {
    const newServices = formik.values.services.split(',').map((p) => p.trim()).filter(Boolean);
    setServicesList((prevList) => {
      const allServices = [...prevList, ...newServices];
      return Array.from(new Set(allServices)); // Ensure unique products
    });
    formik.setFieldValue('services', '');
  };

  const handleDeleteServices = (index) => {
    setServicesList((prevList) => prevList.filter((_, i) => i !== index));
  };

  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between mb-4">
        <Breadcrumb
          items={
            [
              {
                name: "Shipping",
                link: "/shipping/agents"
              },  
            {
              name: "Shipping Agent",
              link: "/shipping/agents"
            },
            {
              name: "Edit Agents"
            }]
          }
        />
        <BackButton />
      </div>
      <div className="w-full h-[calc(100vh-140px)] overflow-y-auto">

      <form onSubmit={formik.handleSubmit}>
      <div className="w-full mx-auto pb-4 bg-white rounded-md   ">
          <div className=" w-full h-full flex justify-start content-start flex-wrap  mb-4">
          
                   <div className="w-full">
  <p className='pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4'>
  Agent Details
  </p>
</div>
      
          <FormikSelectTwo
              type="text"
              label="Agent Type"
              placeholder="Choose"
              Options={Type}
              formik={formik}
              name="type"
                width="w-full md:w-1/2 lg:w-1/3"            />
          
            <NuInput
              type="text"
              label="Company Name"
              placeholder="Company Name"
              formik={formik}
              name="companyName"
              isRequired={true}
                width="w-full md:w-1/2 lg:w-1/3"            />
                 <NuMobileNumber
              nunCode="countryCode"
              label="phone"
              placeholder="Phone"
              formik={formik}
              name="phone"
              isRequired={true}
                width="w-full md:w-1/2 lg:w-1/3"            />
       
            <NuInput
              type="email"
              label="Email"
              placeholder="Email"
              formik={formik}
              name="email"
              isRequired={true}
                width="w-full md:w-1/2 lg:w-1/3"            />
                <NuInput
              type="text"
              label="Website"
              placeholder="Website"
              formik={formik}
              name="website"
                width="w-full md:w-1/2 lg:w-1/3"            />
                 <NuInput
              type="text"
              label="Contact Person Name"
              placeholder="CPName"
              formik={formik}
              name="cpName"
                width="w-full md:w-1/2 lg:w-1/3"           
                 />
                  <NuMobileNumber
              nunCode="cpCountryCode"
              label="phone"
              placeholder="phone"
              formik={formik}
              name="cpPhone"
                width="w-full md:w-1/2 lg:w-1/3"           
                 />
            <NuInput
              type="email"
              label="Contact Person Email"
              placeholder="CPEmail"
              formik={formik}
              name="cpEmail"
                width="w-full md:w-1/2 lg:w-1/3"            
                />
            <FormikSelectTwo
              type="text"
              label="Contact Person Desigation"
              placeholder="Choose"
              Options={Designation}
              formik={formik}
              name="cpDesignation"
                width="w-full md:w-1/2 lg:w-1/3"            
                />
           
                   <NuInput
              type="number"
              label="crNum"
              placeholder="CR Num"
              formik={formik}
              name="crNum"
                width="w-full md:w-1/2 lg:w-1/3"   
            />
            <NuInput
              type="number"
              label="Vat Num"
              placeholder="Vat Num"
              formik={formik}
              name="vatNum"
                width="w-full md:w-1/2 lg:w-1/3"           
                 />
             <FormikSelectTwo
              type="text"
              label="Ratings"
              placeholder="Choose"
              Options={Rating}
              formik={formik}
              name="ratings"
               width="w-full md:w-1/2 lg:w-1/3"
            />
                 </div>
           </div>
           <div className="w-full mx-auto  bg-white rounded-md pb-4 mt-4 ">
          <div className=" w-full h-full flex justify-start content-start flex-wrap   mb-4">
       
          <div className="w-full">
  <p className='pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4'>
    Address
  </p></div>
            <NuInput
              type="text"
              label="Billing Address"
              placeholder="Billing Address"
              formik={formik}
              name="billingAddress"
                width="w-full md:w-1/2 lg:w-1/3"            />
            <NuInput
              type="text"
              label="Shipping Address"
              placeholder="Shipping Address"
              formik={formik}
              name="shippingAddress"
                width="w-full md:w-1/2 lg:w-1/3"            />
          
            <NuInput
              type="text"
              label="Country"
              placeholder="Country"
              formik={formik}
              name="country"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="text"
              label="State"
              placeholder="State"
              formik={formik}
              name="state"
                width="w-full md:w-1/2 lg:w-1/3"           
                 />
            <NuInput
              type="text"
              label="District"
              placeholder="District"
              formik={formik}
              name="district"
                width="w-full md:w-1/2 lg:w-1/3"           
                 />
            <NuInput
              type="text"
              label="City"
              placeholder="City"
              formik={formik}
              name="city"
                width="w-full md:w-1/2 lg:w-1/3"           
                 />
            <NuInput
              type="number"
              label="Postal Code"
              placeholder="Postal Code"
              formik={formik}
              name="postalCode"
                width="w-full md:w-1/2 lg:w-1/3"            
                />
            <DropDownAddMetaData
                label="Status"
                placeholder="Choose"
                Options={status}
                formik={formik}
                name="status"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refstatus}
                displayProperty={"Status"}
                propertyName={"AgentStatus"}
              />
                  {/* <FormikSelectTwo
              type="text"
              label="Status"
              placeholder="Status"
              Options={status}
              formik={formik}
              name="status"
                width="w-full md:w-1/2 lg:w-1/3"            
                /> */}
            <NuTextArea
              label="Comments"
              placeholder="Comments"
              formik={formik}
              name="comments"
                width="w-full md:w-1/2 lg:w-1/3"           
                 />
            <NuUpload
              label="Attachment"
              width="w-full md:w-1/2 lg:w-1/3"              
              onChange={handleChangeImg}
            />
             </div>
           </div>
           <div className="w-full mx-auto  bg-white rounded-md pb-4  mt-4 ">
          <div className=" w-full h-full flex justify-start content-start flex-wrap   mb-4">
       
          <div className="w-full">
  <p className='pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4'>
  Services
  </p></div>
            <div className="w-1/4 flex flex-col ">
              <div className="flex justify-start">
              <SupplierNuInput
                type="text"
                // label="Services"
                placeholder="separate services with ','"
                formik={formik}
                name="services"
                width="w-11/12"
              />
              <button
                onClick={handleAddServices}
                  type="button"
                 className="mt-4 w-10 h-10 text-center cursor-pointer bg-[#048178] border border-solid border-white  text-white font-semibold rounded-md "
                   >
                +
               </button>
               </div>
              <div className="w-11/12 h-28 ml-[8%] rounded-md p-1 flex flex-col border border-solid overflow-y-scroll">
                {servicesList.map((word, index) => (
                  <span key={index} className="m-1  flex justify-between items-center text-xs bg-[#B3EBD7] rounded text-main-title py-2 px-2 hover:bg-baseColor-secondary hover:bg-opacity-50">
                    {word}
                    <span
                onClick={() => {
                  handleDeleteServices(index);
                }}
                className=" text-blue-900 cursor-pointer"
              >
                x
              </span>
                  </span>
                ))}
              </div>
            </div>
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={() => { Oncancel(-1) }} />
                <SubmitButton isLoading={isLoading}/>
              </div>
            </div>
          </div>
        </div>
      </form>
      </div>
    </div>
  );
};

export default EditAgents;
