
import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';
import UseGetDashBoardWithParams from '../../../../../Components/Hooks/useDashboardDataParams';
import NuAmount from '../../../../../Components/Utils/NuAmount';
import axiosInstance from '../../../../../Services/axiosCommon';

const MonthWiseSales = () => {
  const currentYear = new Date().getFullYear();
  const [params, setParams] = useState({
    year: currentYear,
  });
  const [selectedYear, setSelectedYear] = useState(currentYear);
  // const { data, loading } = UseGetDashBoardWithParams(`/new-dashboard/sales-order/chart`, params);
  const [data, setData] = useState([]);
  const [loading,setLoading] = useState(false);  async function getData() {
    setLoading(true);
    try {
      await axiosInstance.get(`/new-dashboard/sales-order/chart`,params).then(res=>{
        setLoading(false);
        setData(res?.data || []) ;
      });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }
  useEffect(() => {
    getData();
  }, [params]);
  const [salesData, setSalesData] = useState([]);

 
  const years = Array.from(new Array(10), (val, index) => currentYear - 5 + index);

  
  useEffect(() => {
    if (data && data.data) {

      const formattedData = data.data.map((item) => ({
        month: new Date(0, item.month - 1).toLocaleString('default', { month: 'short' }), 
        amount: item.price, 
      }));
      setSalesData(formattedData);
    }
  }, [data]);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white rounded-md p-2 text-xs">
        <p>
          <span className="text-gray-900">{`Month : `}</span>
          <span className="text-gray-500">{payload[0].payload.month}</span>
        </p>
        <p>
          <span className="text-gray-900">Sales : </span>
          <span className="text-gray-500">
            <NuAmount value={payload[0].value} />
          </span>
        </p>
      </div>
      
      );
    }
    return null;
  };

  const handleYearChange = (e) => {
    const newYear = e.target.value;
    setParams({ ...params, year: newYear });
    setSelectedYear(newYear);
  };

  const renderCustomizedLabel = (props) => {
    const { x, y, width, value } = props;
    const radius = 10;

    return (
      <g>
        <text x={x + width / 2} y={y - radius} fill="#000" fontSize={10} textAnchor="middle" dominantBaseline="middle">
        {(value).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}
        </text>
      </g>
    );
  };

  return (
    <div className="col-span-2 bg-white h-[55vh] w-full p-4 rounded-lg drop-shadow-lg mb-4  pb-4">
      <div className="flex justify-between items-center ">
        <p className="text-xs 2xl:text-sm text-[#4F4768] font-medium">Monthly Sales Orders</p>
        <div className="flex items-center">
          <select
            value={selectedYear}
            onChange={handleYearChange}
            className="border rounded-md p-1 text-xs mb-1 text-[#ACACAC] font-normal"
          >
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="border-t border-[1px] border-slate-200/60 border-solid w-full "></div>

      {loading ? (
        <div className="p-4 max-w-sm w-full flex flex-col justify-center items-center h-full">
          <div className="animate-pulse flex space-x-4 w-full">
            <div className="flex-1 space-y-3 py-1">
              <div className="h-3 bg-slate-200 rounded"></div>
              <div className="space-y-3">
                <div className="grid grid-cols-3 gap-4">
                  <div className="h-3 bg-slate-200 rounded col-span-2"></div>
                  <div className="h-3 bg-slate-200 rounded col-span-1"></div>
                </div>
                <div className="h-3 bg-slate-200 rounded"></div>
                <div className="grid grid-cols-3 gap-4">
                  <div className="h-3 bg-slate-200 rounded col-span-1"></div>
                  <div className="h-3 bg-slate-200 rounded col-span-2"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <ResponsiveContainer width="100%" height="90%">
          <BarChart data={salesData}>
            <XAxis dataKey="month" fontSize={12} fontWeight={500} />
            <YAxis fontSize={12} fontWeight={500} />
            <Tooltip content={<CustomTooltip />} cursor={{ fill: "none" }} />
            <Legend />
            <Bar dataKey="amount" fill="#3A6D8C" barSize={15}>
              <LabelList dataKey="amount" content={renderCustomizedLabel} />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default MonthWiseSales;
