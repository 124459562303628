import React from 'react';

const Reference = () => {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 64 64" 
      width="15" 
      height="15" 
      fill="gray">
      <g fill="none" stroke="gray" strokeWidth="2">
        <rect x="10" y="10" width="40" height="44" rx="2" stroke="gray" fill="#d3d3d3"/>
        <path d="M12 12h36v40H12V12z" fill="none"/>
        <path d="M18 18h24v2H18zM18 22h24v2H18zM18 26h24v2H18zM18 30h24v2H18z"/>
        <circle cx="48" cy="36" r="6" fill="none" stroke="gray"/>
        <path d="M51.5 36.5l5 5" stroke="gray"/>
      </g>
    </svg>
  );
}

export default Reference;
