import React from 'react'
import Breadcrumb from '../../../../Components/Utils/Breadcrumb'
import { BackButton } from '../../../../Components/Utils/Buttons'
import PdfTemplate from './PdfTemplate'
import TempletePdf from './TempletePdf'

const MainPdfTemplate = () => {
    return (
        <div className="w-full h-full px-4 py-2">
          <div className="w-full flex justify-between items-center">
            <Breadcrumb
              items={[
                {
                  name: "Settings",
                },
                {
                  name: "PDF Template",
                },
              ]}
            />
            <BackButton />
          </div>
          <div className="  flex mt-3 
h-[calc(100vh-145px)] p-2 rounded-md overflow-hidden gap-4">
            
            <PdfTemplate/>
              
              </div>
              </div>
       )
    }

export default MainPdfTemplate