import React, { useEffect, useMemo, useState } from "react";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import FormikSelectTwo, { SelectProductWithError } from "../../../../Components/Utils/Select";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import { useFormik } from "formik";
import UseAddData from "../../../../Components/Hooks/useAddData";
import * as yup from "yup";
import {
  NuDate,
  NuInput,
  NuTextArea,
} from "../../../../Components/Utils/Input";
import Delete from "../../../../Components/Icons/Delete";
import UseDropDown from "../../../../Components/Hooks/useDropDown";
// import usePrProducts from "./Hooks/UsePrProducts";
import { TextMediumBase } from "../../../../Components/Text/MediumText";
import axiosInstance from "../../../../Services/axiosCommon";
// import useRfqSuppliers from "./Hooks/UseRFQSuppliers copy";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import DropDownAddMetaData, { DropDownAddUnitWithError } from "../../../../Components/Utils/selectAddMetaData";
import Select from "react-select";
import EditIcon from "../../../../Components/Icons/Edit";
import UseDropDownNew from "../../../../Components/Hooks/useDDNew";
import DropDownAddCurrency from "../../../../Components/Utils/selectAddCurrencyDropDown";
import UseFetchCurrency from "../../../../Components/Hooks/useFetchCurrencyData";
import NuAmount from "../../../../Components/Utils/NuAmount";
import { useToast } from "../../../../Components/Others/ToastServices";
import DropDownAddSubCategory from "../../../../Components/Utils/selectAddSubCategory";
import DropDownAdd from "../../../../Components/Utils/selectAdd";
import UseCatSubCatDropDown from "../../../../Components/Hooks/useCategoryDropDown";
import Attachement from "../../../../Components/Utils/CustomAttrachmentupload";
import useAddFormData from "../../../../Components/Hooks/useAddFormData";
import { useLocation } from "react-router-dom";
import { useCurrency } from "../../../../Context/CurrencyContext";
import UseProductDropDownNew from "../../../../Components/Hooks/useProductSearch";
const filetype = ["docx", "doc", "xlsx", "xls", "pdf", "txt", "pptx","mp4", "mov", "wmv", "avi", "flv", "mkv"];

function RfqCreateSupplierQuotes() {
  const toast = useToast();
  const { currencySymbol } = useCurrency();
  const location = useLocation();
  const data = location.state.data;
  console.log("suqu",data?.id);
  const { value: Validity, getData: refValidity } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "Validity_Supplierquotes"
  );
  const { value: PaymentTerms, getData: refPaymentTerms } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "PaymentTerms_Supplierqoutes"
  );
  const { value: leadTime, getData: refLeadTime } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "LeadTime_Supplierqoutes"
  );
  const { value: DeliveryTerms, getData: refDeliveryTerms } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "DeliveryTerms_Supplierqoutes"
  );
  const { value: Availability, getData: refAvailability } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "Availability_Supplierqoutes"
  );
  const { value: paymentMethod, getData: refpaymentMethod } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "paymentMethod_supplierquotes"
  );
  const [isEditing, setIsEditing] = useState(false);
  // const { value: Unit } = useFetchMataData(
  //   `/dropdown/dropdown-details/`,
  //   "Unit_item"
  // );
  const { value: brand } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "brand_item"
  );
  const { value: Manufacture } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "Manufacture_item"
  );

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };
  const [search, setSearch] = useState("")
  const { data: Item, setData } = UseProductDropDownNew('/items/new-drop-down',search);
  let { data: suppliers } = UseDropDown("/vendors/drop-down");
  let { value: currency, getData: refCurrency } = UseFetchCurrency("/currency/drop-down");
  const { value:Unit, getData:refUnit } = useFetchMataData(`/dropdown/dropdown-details/`,'Unit_item')

  const { data: PR } = UseDropDown("/PR/drop-down");
  const [rfqs, setRFQ] = useState([]);

  // const { data: user } = UseDropDown("/user/drop-down");
   const { Oncancel, addFormData, contextHolder, isLoading } = useAddFormData();
  // const [items, setItems] = useState([]);
  const [newItemData, setNewItemData] = useState({
    productId: "",
    quantity: "",
    price: "",
    landedCost:""
  });
  const [createNew, setCreateNew] = useState({
    dateReceived: "",
    deliverTerms: "",
    paymentTerms: "",
    paymentMethod: "",
    totalPrice: 0.0,
    vendorId: data?.vendor || "",
    isDirectQuote: false,
    prId: data?.prId || "",
    rfqId: data?.id || "",
    ref: "",
    availability: "",
    validity: "",
    specialNotes: "",
    leadTime: "",
    // landingCost:"",
    preparedBy: "",
    reviewedBy: "",
    approvedBy: "",
    remarks: "",
    comments: "",
    products: "",
    productId: "",
    quoteDate: "",
    purchaserChecked:"",
    exchangeRate: ""
  });

  const [error, setError] = useState('');

  useEffect(() => {
    // Set the initial quoteDate and quotationReceivedDate to the current date on component mount
    setCreateNew((prevCreateNew) => ({
      ...prevCreateNew,
      quoteDate: new Date().toISOString().slice(0, 10), // Format to YYYY-MM-DD
      dateReceived: new Date().toISOString().slice(0, 10),
    }));
  }, []);
  const [selectedFile, setSelectedFile] = useState(null);
  const [image, setImage] = useState([]);

  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = []
    files.map(item=>{
      if (item.size < maxFileSize ){
        fileArr.push(item)
      }else{
        toast.open({
          type:"warning",
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          // placement: "bottomRight",
        });
      }
    })    
    let tempArr = []
    setSelectedFile(fileArr);
    files.map(item=>{
      if (item.size < maxFileSize ){
      let fileName = item?.name
      console.log('fname',fileName);
      let fileExtention =  fileName.split(".").reverse()[0]
      if(filetype?.includes(fileExtention)){
        tempArr.push(fileName)
      }else{
        let x = URL.createObjectURL(item)
        tempArr.push(x)
      }
    }
    })
    console.log(tempArr,'temp');
    setImage(tempArr)
  }
  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  const handleImageRemove = () => {
    setImage({
      preview: "",
      raw: "",
    });
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      ref: yup.string().required('Quotation reffrence is required!') 
    }),
    onSubmit: async (value, { resetForm }) => {
      console.log("bbb",value);
      let newItems = [];

if (prlist.length > 0) {
  newItems = prlist.map((i) => ({ ...i, leadTime: i.leadTime.value }));
} else if (firstProduct.length > 0) {
  newItems = firstProduct;
}

if (prlist.length === 0 && firstProduct.length === 0) {
  setError('Product table is empty');
  return;
}
let formData = new FormData();
      if (data?.vendor){
        var valueWithItems = {
          ...value,
          rfqId:data?.id||"",
          vendorId: data?.vendor || "",
          isDirectQuote:false,
          products: newItems,
        };
      }
      else{
        valueWithItems = {
          ...value,
          rfqId:data?.id||"",
          isDirectQuote:false,
          products: newItems,
        };
      }
      formData.append('json_data', JSON.stringify(valueWithItems));
      // formData.append('attachments', image.raw);
      if(selectedFile !== null){
        selectedFile.forEach((file) => {
          formData.append("attachments", file);
        });
      }else{
        formData.append("attachments",[])
      } 
      addFormData("/supplier-quotations/new-quotation", formData, resetForm);
    },
  });
  const { data: Purchaser } = UseDropDown('/user/purchaser/drop-down');

  const [prods, setProds] = useState({
    sno: "",
    productId: "",
    isAlternate: "",
    product_unit: "",
    leadTime: "",
    landedCost:"",
    quantity: "",
    price: "",
    notes:"",
  });

  const productForm = useFormik({
    enableReinitialize: true,
    initialValues: prods,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({}),
    onSubmit: (value, { resetForm }) => {
      // const updatedPrlist = prlist;
      // updatedPrlist.push(value);
      // setPrlist(updatedPrlist);
      // resetForm();
    },
  });

  // const suppliers = useRfqSuppliers(formik.values.prId);
  // const products = usePrProducts(formik.values.prId);
  const [prlist, setPrlist] = useState([]);

  // const [sup, setSup] = useState([]);
  

  const getRfq = (rfqId) => {
    if (rfqId !== "") {
      axiosInstance
        .get(`/supplier-quotations/RFQ-products/drop-down/${rfqId}`)
        .then((res) => {
          let data = res.data;
          // formik.values.vendorName = data?.vendorName;
          // formik.values.vendorId = data?.vendorId;
          data && setPrlist(data?.products);
          // setPrlist(data);
          // setSup( data?.vendorId)
        })
        .catch((err) => {
          console.log(err, "err");
        });
    }
  };


  useEffect(() => {
    // console.log("frrr", formik.values.rfqId);
    if (formik.values.rfqId) {
      getRfq(formik.values.rfqId);
    }
  }, [formik.values.rfqId]);

  const [deletedItem,setDeletedItem] = useState([]);
  const [deletedItemDropDown,setDeletedItemDropDown] = useState([]);
  console.log("qqqq",deletedItem);
  const handleDeleteItem = (indexToDelete) => {
    // 1. Update the prlist state using filter:
    const deletedItem = prlist.find((_, i) => i == indexToDelete);
    let newDD = {
      value: deletedItem.productId,
      label: deletedItem.product_name,
      unit: deletedItem.product_unit,
    };
    setData([...Item, newDD]);

    const updatedPrlist = prlist.filter((_, index) => index !== indexToDelete);
    setPrlist(updatedPrlist);

    const deletedItems = prlist.filter((_, index) => index == indexToDelete);
    setDeletedItem((prevDeletedItems) => [...prevDeletedItems, ...deletedItems]);

    // 3. Update the totalPrice state based on removed item's price and quantity:
    const removedItemPrice = parseFloat(prlist[indexToDelete].price || 0);
    const removedLandedCost = parseFloat(prlist[indexToDelete].landedCost || 0);
    
    const removedItemQuantity = parseFloat(prlist[indexToDelete].quantity || 0);
    const newTotalPrice =
      formik.values.totalPrice - (removedItemPrice + removedLandedCost * removedItemQuantity);

    // 4. Update the formik state for totalPrice using setFieldValue:
    formik.setFieldValue("totalPrice", newTotalPrice);
  };
  useEffect(() => {
    let tempData = [];
    deletedItem.forEach((item) => {
      let tempObject = { value: item.productId, label: item.product_name };
      tempData.push(tempObject);
    });
    setDeletedItemDropDown(tempData);
  }, [deletedItem]);

  const handleAddDelPro = () => {
    const selectedProductId = formik.values.productId;
  
    // Find the selected product's details from the deletedItemDropDown (or deletedItem array)
    const selectedProduct = deletedItem.find(
      (item) => item.productId === selectedProductId
    );
  
    if (selectedProduct) {
      // Add the selected product to prList
      setPrlist((prevPrList) => [...prevPrList, selectedProduct]);
  
      // Optionally, remove the selected product from the dropdown after adding it to prList
      setDeletedItem((prevDeletedItems) =>
        prevDeletedItems.filter((item) => item.productId !== selectedProductId)
      );
    }
  };

  const handlePriceChange = (e, index) => {
    const updatedItems = [...prlist];
    updatedItems[index].price = e.target.value;
    updatedItems[index].convPrice = parseFloat(e.target.value*parseFloat(formik.values.exchangeRate>0?formik.values.exchangeRate:1)).toFixed(2);
    setPrlist(updatedItems);
  };
  // const handleConvPriceChange = (e, index) => {
  //   const updatedItems = [...prlist];
  //   updatedItems[index].convPrice = e.target.value*parseFloat(formik.values.exchangeRate>0?formik.values.exchangeRate:1);
  //   setPrlist(updatedItems);
  // };
  
  const handleCostChange = (e, index) => {
    const updatedItems = [...prlist];
    updatedItems[index].landedCost = e.target.value || 0;
    setPrlist(updatedItems);
  };

  useEffect(() => {
    const totalPrice = prlist?.reduce((total, item) => {
      return (total||0.00) + (((parseFloat(item.quantity)*(item.convPrice||0.00)))+parseFloat(item.landedCost||0.00));
    }, 0);

    formik.setFieldValue("totalPrice", totalPrice);
  }, [prlist]);

  const handleQuantityChange = (index, newQuantity) => {
    const updatedItems = [...prlist];
    updatedItems[index].quantity = newQuantity;
    setPrlist(updatedItems);
  };

  const handleIncrement = (index) => {
    handleQuantityChange(index, prlist[index].quantity + 1);
  };

  const handleDecrement = (index) => {
    handleQuantityChange(index, Math.max(1, prlist[index].quantity - 1));
  };

  const handleInputChange = (event, index) => {
    const value = Math.max(Number(event.target.value),0);
    handleQuantityChange(index, value);
  };
  const handleLeadTimeChange = (value, index) => {
    const updatedItems = [...prlist];
    updatedItems[index].leadTime = value;
    setPrlist(updatedItems);
  };

  const handleProductUnitChange = (value, index) => {
    const updatedItems = [...prlist];
    updatedItems[index].product_unit = value?.value;
    setPrlist(updatedItems);
  };

  const handleAdd = (index) => {
    
    if (
      productForm.values.productId &&
      productForm.values.quantity &&
      productForm.values.price
    ) {
      const selectedProduct = Item.find(
        (i) => i.value === productForm.values.productId
      );
      let lead_time = {
        label: productForm.values.leadTime,
        value: productForm.values.leadTime,
      };
  
      // Calculate new sno based on the existing item
      let newSno;
      if (formik.values.isDirectQuote===false) {
        const baseSno = prlist[index].sno;
        const subIndex = prlist.filter(item => String(item.sno).startsWith(baseSno)).length;
        newSno = `${baseSno}${String.fromCharCode(64 + subIndex)}`; // 97 is ASCII code for 'a'
      }
  
      const newItem = {
        sno: newSno,
        productId: productForm.values.productId,
        leadTime: lead_time,
        price: parseFloat(productForm.values.price).toFixed(2),
        isAlternate: formik.values.isDirectQuote == false ?true:false,
        product_name: selectedProduct.label,
        product_unit: productForm.values.product_unit,
        quantity: productForm.values.quantity,
        notes:productForm.values.notes,
        landedCost: parseFloat(productForm.values.landedCost).toFixed(2),
        convPrice: parseFloat(parseFloat(productForm.values.price).toFixed(2) * (formik.values.exchangeRate>0?formik.values.exchangeRate:1)).toFixed(2)
      };


      console.log("list",newItem);
      console.log("index",index+1);
      
      let NewValue = prlist[index].sno;

      console.log("get value",NewValue);
      

      let preFilter = prlist.map(item => {
        let tempvalue = {...item}
        tempvalue.sno = `${item.sno}`
        return tempvalue;
      })
      console.log("pre filter",preFilter);
      let Filter = preFilter.filter(item => item.sno.includes(`${NewValue}`)).length||0;

      console.log("filter",Filter);
      

      let newPrList = [...prlist];
      console.log("before", newPrList);
      newPrList.splice(index+Filter, 0, newItem);
      setPrlist(newPrList);
      console.log("after", newPrList);
  
      // Insert new item at the correct position
      // let newPrList = [...prlist];
      // newPrList.splice(index + 1, 0, newItem);
      // setPrlist(newPrList);
  
      // Update the remaining part
      let updatedItems = Item.filter(
        (it) => it.value !== productForm.values.productId
      );
      setData(updatedItems);
  
      // Clear the form fields
      productForm.values.productId = "";
      productForm.values.quantity = "";
      productForm.values.isAlternate = "";
      productForm.values.notes="";
      productForm.values.leadTime = "";
      productForm.values.landedCost = "";
      productForm.values.product_unit = "";

      productForm.values.price = "";
      if(formik.values.isDirectQuote===false){setShowForm(!showForm)};
    }
  };

  const [showPopup, setShowPopup] = useState(false);
  const [newProduct, setNewProduct] = useState({
    productName: "",
    unit: "",
    category: "",
    subCategory: "",
  });

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const resetForm = () => {
    formik.values.productName = "";
    formik.values.unit = "";
    formik.values.category = "";
    formik.values.subCategory = "";
    setShowPopup(false);
  };

  const handlePopupSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append(
      "itemDetails",
      JSON.stringify({
        productName: formik.values.productName,
        unit: formik.values.unit,
        category: formik.values.category,
        subCategory: formik.values.subCategory,
      })
    );

    try {
      const response = await axiosInstance.post("/items/new-item", formData);
      const createdProduct = response.data?.data;

      setData((prevProducts) => [
        {
          label: formik.values.productName,
          value: createdProduct.id,
          unit: createdProduct.unit,
        }, // Add the new product at the start
        ...prevProducts,
      ]);

      resetForm();
    } catch (error) {
      console.error("Error creating new product:", error);
    }
  };
  const [showForm, setShowForm] = useState(false);
  const [selectedProductIndex, setSelectedProductIndex] = useState(null);

  const toggleForm = (idx) => {
    setShowForm(!showForm);
    setSelectedProductIndex(idx);
  };

  const [firstProds, setFirstProds] = useState({
    sno: "",
    productId: "",
    leadTime: "",
    landedCost:"",
    quantity: "",
    price: "",
  });
  const[firstProduct,setFirstProduct] =useState([])
  function handleAddPro() {
    const selectedProduct = Item.find(
      (i) => i.value === productForm.values.productId
    );
    const newItem = {    
      sno: firstProduct.length + 1,   
      productId: productForm?.values?.productId,
      leadTime: productForm?.values?.leadTime,
      landedCost: productForm?.values?.landedCost||0,

      quantity: productForm?.values?.quantity||1,
      price: productForm?.values?.price,
      product_name: selectedProduct?.label,
      product_unit: selectedProduct?.unit
    };

    if(firstProduct.some(item=>item.productId ==productForm.values.productId)){
      let tempValue = [...firstProduct]
      let filterValue = tempValue.find(item=>item.productId == productForm.values.productId)
      filterValue.quantity =filterValue.quantity + newItem?.quantity
      filterValue.price = filterValue.price + Number(newItem?.price||0)
      filterValue.landedCost = filterValue.landedCost + newItem?.landedCost 
      setFirstProduct(tempValue)
      productForm.values.productId = "";
      productForm.values.quantity = "";
      productForm.values.leadTime = "";
      productForm.values.price = "";
      productForm.values.landedCost = "";
    }else{
      if(productForm?.values?.productId !="" ){
        if(productForm?.values?.price !="" ){
  
          setFirstProduct([...firstProduct,newItem])
          productForm.values.productId = "";
          productForm.values.quantity = "";
          productForm.values.leadTime = "";
          productForm.values.price = "";
          productForm.values.landedCost = "";
        }else{
          toast.open({
            type:"warning",
            message:"Warning",
            description:"Please Enter all mandatory fields to add product"
          })
        }
      }else{
        toast.open({
          type:"warning",
          message:"Warning",
          description:"Please choose Product to  the list"
        })
      }
    }

  }  


  
  function handleDeletePro(indexToDelete) {
    const updatedProducts = firstProduct.filter((_, i) => i !== indexToDelete);
    setFirstProduct(updatedProducts);
  }
  

  useEffect(() => {
    if (prlist?.length > 0) {
      setError('');
    }
  }, [prlist]);


  // useMemo(()=>{
  //   if(formik.values.isDirectQuote == true){
  //     setPrlist([])
  //     formik.values.totalPrice = 0
  //   }else if(formik.values.isDirectQuote == false){
  //     // setFirstProduct([]);
  //     formik.values.prId ="";
  //     formik.values.rfqId ="";
  //     formik.values.vendorId ="";
  //     formik.values.totalPrice = 0
  //   }
  // },[formik.values.isDirectQuote])

  const formatNumberWithCommas = (number) => {
    return number.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };
  

  useEffect(() => {
    const totalPrice = firstProduct.reduce((total, item) => {
      return total + (parseFloat(item.price)+parseFloat(item.landedCost) )* parseFloat(item.quantity || 0);
    }, 0);

    formik.setFieldValue("totalPrice", totalPrice);
  }, [firstProduct]);
 
  
  const { category: itemsCategory, subCategory: itemsSubCategory, getCategory, fetchSubCategory } = UseCatSubCatDropDown('/category/ItemCategory', "ItemCategory", formik?.values?.category);

  const [prRequiredError, setPrRequiredError] = useState(false);

const handleCheckboxChange = (e) => {
  if (!formik.values.prId) {
    // Set the error state if prId is empty
    setPrRequiredError(true);
    return;
  }
  // If prId is not empty, reset the error and update the checkbox value
  setPrRequiredError(false);
  formik.setFieldValue('purchaserChecked', e.target.checked);
};

  

  const [vendorlist, setVendorlist] = useState([]);
 
function getVendor(prId){
  axiosInstance.get(`/supplier-quotations/vendor/drop-down/${prId}`)
  .then((response) => {
    let tempData =[]
    if(response.data){
      let x = response.data

      x.map((item) => {
      let y = {
        value: item.id,
        label: item.name,
      }
      tempData.push(y)
      })

      setVendorlist(tempData)
    }
  }).catch((error) => {})
 }
 const [purchaserlist, setPurchaserlist] = useState([]);
 const [supplierCurrency,setSupplierCurrency] = useState([]);

//  function getSupplierCurrency(supId){
//   axiosInstance.get(`/vendors/${supId}/currency`)
//    .then((res)=>{
//     console.log("bbb",res?.data?.data?.currencyLabel);
//     setSupplierCurrency(res?.data?.data?.currencyLabel)
//    }).catch((err)=>{
//     console.log(err);
//    })
//  }
 
 function getPurchaser(prId){
   axiosInstance.get(`/supplier-quotations/purchaser/drop-down/${prId}`)
   .then((response) => {
     let tempData =[]
     if(response.data){
       let x = response.data
 
       x.map((item) => {
       let y = {
         value: item.id,
         label: item.name,
       }
       tempData.push(y)
       })
 
       setPurchaserlist(tempData)
     }
   }).catch((error) => {})
  }
  useEffect(() => {
    if (formik.values.prId && formik.values.purchaserChecked===true) {
      getPurchaser(formik.values.prId);
    }
    else if(formik.values.prId && (!formik.values.purchaserChecked)){
      getVendor(formik.values.prId);
    }
  }, [formik.values.prId,formik.values.purchaserChecked]);
  // useEffect(()=>{
  //   getSupplierCurrency(formik?.values?.vendorId)
  // },[formik?.values?.vendorId])

  const [rfq, setRFQs] = useState([]); // State for storing RFQs

  const getPr = (prId, vendorId) => {
    if (prId !== "" && vendorId !== "") {
      axiosInstance
        .get(`/rfq/drop-down/by-supplier/${prId}/${vendorId}`)
        .then((res) => {
          const data = res.data;
          const rfqOptions = data.map((item) => ({
            value: item.id,
            label: item.name,
          }));
          setRFQs(rfqOptions);
        })
        .catch((err) => {
          console.log(err, "err");
        });
    }
  };
  
  useEffect(() => {
    
    if (!formik.values.purchaserChecked){
      if (formik.values.prId && formik.values.vendorId) {
        getPr(formik.values.prId, formik.values.vendorId);
      }
    }
    
  }, [formik.values.prId, formik.values.vendorId]);
 
  useEffect(() => {
    if (rfq?.length==1) {
      formik.setFieldValue('rfqId', rfq[0].value);
    }
  }, [rfq]);

  const [selectedCurrency,setSelectedCurrency] = useState('')
 function getCurrenctFind(id){
    const label = currency.find(currency => currency.value === id);
    setSelectedCurrency(label?.label);
 }
 console.log("sele",selectedCurrency);

 const[exChange,setExchage] = useState("")
 function exchangeRate(id){
  axiosInstance
  .get(`/currency/${id}`)
  .then((res) => {
      // console.log("currency",res?.data?.data?.exchangeRate);
      setExchage(res?.data?.data?.exchangeRate)
      formik.setFieldValue("exchangeRate",res?.data?.data?.exchangeRate>0? res?.data?.data?.exchangeRate : "")
  }).catch((err)=>{
    console.log(err);
  })
 }

  useEffect(()=>{
    getCurrenctFind(formik?.values?.currencyId)
    formik?.values?.currencyId && exchangeRate(formik?.values?.currencyId);
  },[formik?.values?.currencyId])
  // console.log("cur",currencySelect);
  // const [rfqPurchaser, setRFQPurchaser] = useState([]); // State for storing RFQs

  const getRFQPo = (prId, purchaser) => {
    if (prId !== "" && purchaser !== "") {
      axiosInstance
        .get(`/rfq/drop-down/by-purchaser/${prId}/${purchaser}`)
        .then((res) => {
          const data = res.data;
          const rfqOptions = data.map((item) => ({
            value: item.id,
            label: item.name,
          }));
          setRFQs(rfqOptions);
        })
        .catch((err) => {
          console.log(err, "err");
        });
    }
  };

  useEffect(() => {
    if (formik.values.exchangeRate > 0) {
      const updatedList = prlist?.map((item) => ({
        ...item,
        convPrice: parseFloat(item.price * formik.values.exchangeRate).toFixed(2),
      }));
      
      // Assuming you want to set this updated list somewhere
      setPrlist(updatedList); // If you have a state to update prlist
    }
    }, [formik.values.exchangeRate]);
  
  useEffect(() => {
    
    if (formik.values.prId && formik.values.purchaser) {
      getRFQPo(formik.values.prId, formik.values.purchaser);
    }
    
  }, [formik.values.prId, formik.values.purchaser]);

  useEffect(() => {
    
    formik.setFieldValue('purchaser','');
    formik.setFieldValue('rfqId','');

    formik.setFieldValue('vendorId','');

    
  }, [formik.values.purchaserChecked]);

  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = [];
    files.map((item) => {
      if (item.size < maxFileSize) {
        fileArr.push(item);
      } else {
        toast.open({
          type: "warning",
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          // placement: "bottomRight",
        });
      }
    });
    let tempArr = [];
    setSelectedFile(fileArr);
    files.map((item) => {
      if (item.size < maxFileSize) {
        let fileName = item?.name;
        console.log("fname", fileName);
        // let fileExtention = fileName.split(".").reverse()[0];
        // if (filetype?.includes(fileExtention)) {
          tempArr.push(fileName);
        // } else {
        //   let x = URL.createObjectURL(item);
        //   tempArr.push(x);
        // }
      }
    });
    console.log(tempArr, "temp");
    setImage(tempArr);
  }

  const removeImages = (value) => {
    console.log("value", value);
    let List = selectedFile.filter((item) => item?.name !== value);
    console.log("LIst", List);
    setSelectedFile([...List]);
    let tempArr = []
    List.map((item) =>{
      let fileName = item?.name
      // console.log('fname',fileName);
      // let fileExtention =  fileName.split(".").reverse()[0]
      // if(filetype?.includes(fileExtention)){
        tempArr.push(fileName)
      // }else{
      //   let x = URL.createObjectURL(item)
      //   tempArr.push(x)
      // }
    })
    setImage(tempArr)
  };

 
  const handleNoteChange = (e, index) => {
    const updatedItems = [...prlist];
    updatedItems[index].notes = e.target.value;
    setPrlist(updatedItems);
  };

  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
      <Breadcrumb items={[
          { name: "RFQ", link: "/procurement/rfq/" },
          { name: "Create Quote"},
        ]} />
        <BackButton />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden mt-4">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
            <NuInput
              label="Quotation Reference"
              type="text"
              formik={formik}
              placeholder="Reference"
              name="ref"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />
            {!data?.vendor &&(
              <FormikSelectTwo
    type="text"
    label="Supplier"
    placeholder="Choose"
    Options={suppliers}
    formik={formik}
    name="vendorId"
    width="w-full md:w-1/2 lg:w-1/3"
    isRequired={true}
  />)}
            {/* <NuDate
              label="Quote Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="quoteDate"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            <NuDate
              label="Quotation Received Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="dateReceived"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            {/* <NuInput
                  label="Landing Cost"
                  type="number"
                  formik={formik}
                  placeholder="0.00"
                  name="landingCost"
                  
                  width="w-full md:w-1/2 lg:w-1/3"
                /> */}
            <DropDownAddMetaData
              label="Delivery Terms"
              placeholder="Choose"
              Options={DeliveryTerms}
              formik={formik}
              name="deliverTerms"
              width="w-full md:w-1/2 lg:w-1/3"
              getFunc={refDeliveryTerms}
              displayProperty={"Delivery Terms"}
              propertyName={"DeliveryTerms_Supplierqoutes"}
            />

            <DropDownAddMetaData
              label="Payment Terms"
              placeholder="Choose"
              Options={PaymentTerms}
              formik={formik}
              name="paymentTerms"
              width="w-full md:w-1/2 lg:w-1/3"
              getFunc={refPaymentTerms}
              displayProperty={"Payment Terms"}
              propertyName={"PaymentTerms_Supplierqoutes"}
            />

            <DropDownAddMetaData
              label="Payment Method"
              placeholder="Choose"
              Options={paymentMethod}
              formik={formik}
              name="paymentMethod"
              width="w-full md:w-1/2 lg:w-1/3"
              getFunc={refpaymentMethod}
              displayProperty={"Payment Method"}
              propertyName={"paymentMethod_supplierquotes"}
            />

            {/* <DropDownAddMetaData
              label="Product Availability"
              placeholder="Choose"
              Options={Availability}
              formik={formik}
              name="availability"
              width="w-full md:w-1/2 lg:w-1/3"
              getFunc={refAvailability}
              displayProperty={"Product Availability"}
              propertyName={"Availability_Supplierqoutes"}
            /> */}

            <DropDownAddMetaData
              label="Quotation Validity"
              placeholder="Choose"
              Options={Validity}
              formik={formik}
              name="validity"
              width="w-full md:w-1/2 lg:w-1/3"
              getFunc={refValidity}
              displayProperty={"Quotation Validity"}
              propertyName={"Validity_Supplierquotes"}
            />

            {/* <DropDownAddMetaData
              label="Lead Time"
              placeholder="Choose"
              Options={leadTime}
              formik={formik}
              name="leadTime"
              width="w-full md:w-1/2 lg:w-1/3"
              getFunc={refLeadTime}
              displayProperty={"Lead Time"}
              propertyName={"LeadTime_Supplierqoutes"}
            /> */}

            <DropDownAddCurrency
              label="Currency"
              placeholder="Choose"
              Options={currency}
              formik={formik}
              name="currencyId"
              width="w-full md:w-1/2 lg:w-1/3"
              getFunc={refCurrency}
              // displayProperty={"Currency"}
              // propertyName={"Validity_Supplierquotes"}
            />

            <NuInput
              label="Exchange Rate"
              type="text"
              formik={formik}
              placeholder="Exchange Rate"
              name="exchangeRate"
              width="w-full md:w-1/2 lg:w-1/3"
              disabled={(exChange === "" || !exChange) ? false : true}
            />

            {/* <FormikSelectTwo
              type="text"
              label="Currency"
              placeholder="Choose"
              Options={currency}
              formik={formik}
              name="currencyId"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}

            <NuTextArea
              label="Special Notes From Supplier"
              type="text"
              formik={formik}
              placeholder="Special Notes From Supplier"
              name="specialNotes"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuTextArea
              label="Comments"
              type="text"
              formik={formik}
              placeholder="Comments"
              name="comments"
              width="w-full md:w-1/2 lg:w-1/3"
            />
                  <div className="w-full">
  <p className='pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4'>
    SQ Attachement :
  </p></div>
           <Attachement
                  handleChange={handleImageChange}
                  preview={image||''}
                  width="w-full"
                  label="Attach"
                  multiple={true}
                  removeImages={removeImages}
                />
           {/* </div> */}
            <div className="w-full h-[1px] opacity-30 bg-[#C4C2C2] my-6 mx-auto "></div>
            {deletedItemDropDown.length > 0 ?
              <div className='w-full flex justify-start gap-2'>
                 <FormikSelectTwo
                   type="text"
                   label="Product"
                   placeholder="Product"
                   Options={deletedItemDropDown}
                   formik={formik}
                   name="productId"
                   width="w-full md:w-1/2 lg:w-[67%]"
                 />
                 <button
                onClick={handleAddDelPro}
                  type="button"
                 className=" p-1 mt-[44px] m-1 text-center h-8 cursor-pointer bg-[#048178] border border-solid border-white  text-xs font-medium  text-white  rounded-lg "
                   >
                Add
               </button>
              </div>
              :
              null
            }
            {(showForm || formik.values.isDirectQuote) && (
              <div className="w-full rounded-md flex flex-col px-1 md:flex-row gap-y-2 md:gap-y-0 justify-start items-end gap-1 border border-solid">
               

               <SelectProductWithError
                   type="text"
                   label="Product "
                   placeholder="Product "
                   Options={Item}
                   formik={productForm}
                   name="productId"
                   width="w-full md:w-1/2 lg:w-[40%]"
                   ErrMessage={""}
                   px='0'
                  //  handleClick={()=>{}}
                   hasAddButton={false}
                   setSearch={setSearch}
                  //  handleClick={togglePopup}
                 />
                 <button
                  onClick={togglePopup}
                  type="button"
                  className="px-3 mb-2 ml-2 text-center py-1.5 cursor-pointer bg-[#048178] border border-solid border-white  text-white font-semibold rounded-lg"
                >
                  
                  +
                </button>
                <DropDownAddMetaData
                label="Unit"
                placeholder="Choose"
                Options={Unit}
                formik={productForm}
                name="product_unit"
                width="w-full md:w-1/2 lg:w-1/4"
                getFunc={refUnit}
                displayProperty={"Add Unit"}
                propertyName={"Unit_item"}
              />
                <NuInput
                  label="Quantity"
                  type="number"
                  formik={productForm}
                  placeholder="Quantity"
                  name="quantity"
                  px="2"
                  width="w-full md:w-1/2 lg:w-1/4 "
                  isRequired={true}
                />
                {/* <FormikSelectTwo
                  type="text"
                  label="Lead time"
                  placeholder="Choose "
                  Options={leadTime}
                  formik={productForm}
                  name="leadTime"
                  width="w-full md:w-1/2 lg:w-1/4"
                  px="2"
                /> */}
                <DropDownAddMetaData
              label="Lead Time"
              placeholder="Choose"
              Options={leadTime}
              formik={productForm}
              name="leadTime"
              width="w-full md:w-1/2 lg:w-1/4"
              getFunc={refLeadTime}
              displayProperty={"Lead Time"}
              propertyName={"LeadTime_Supplierqoutes"}
            />
                <NuInput
                  label="Price"
                  type="number"
                  formik={productForm}
                  placeholder="0.00"
                  name="price"
                  px="2"
                  width="w-full md:w-1/2 lg:w-1/4"
                  isRequired={true}
                />
                <NuInput
                  label="Landing Cost"
                  type="number"
                  formik={productForm}
                  placeholder="0.00"
                  name="landedCost"
                  px="2"
                  width="w-full md:w-1/2 lg:w-1/4"
                  // isRequired={true}
                />
                <button
                  type="button"
                  onClick={()=> formik?.values?.isDirectQuote === false ?handleAdd(selectedProductIndex): handleAddPro() }
                  className="px-2 mb-3 text-center py-2 cursor-pointer bg-[#048178] border border-solid border-white  text-white text-xs font-semibold rounded-lg "
                >
                  Add
                </button>
              </div>
            )}
            <div className="w-full bg-white overflow-hidden mt-5 ">
            {error && <p className="text-xs font-semibold text-red-500">{error}</p>}
            <div className='flex py-2 items-center'><p className='text-xs pr-1 text-red-500'>Note :</p><p className='text-[10px] text-slate-600'>CLick the edit icon near "Action" to edit Notes, quantity, lead time and price.</p></div>

              <div className="w-full py-3  bg-[#048178] flex justify-between rounded-lg px-2">
                <p className=" w-[5%] text-left pl-1 text-white text-xs font-medium">
                  S.N.
                </p>
                <p className=" w-[35%] text-left text-white text-xs font-medium">
                  Product
                </p>
                <p className=" w-[10%] text-center text-white text-xs font-medium">
                  Unit
                </p>
                <p className=" w-[15%] text-center text-white text-xs font-medium">
                  Qty
                </p>
                <p className="w-[13%] text-center text-white text-xs font-medium">
                  Lead Time
                </p>
                <p className=" w-[15%] text-center text-white text-xs font-medium">
                  Price
                </p>
                <p className=" w-[15%] text-center text-white text-xs font-medium">
                  Conv. Price
                </p>
                <p className="w-[13%] text-center text-white text-xs font-medium">
                Landing Cost
                </p>
                <p className="w-[13%] text-center text-white text-xs font-medium">
                Total
                </p>
                <p className=" w-[7%] text-center text-white text-xs font-medium">
                  Action
                </p>
                <button
                  type="button"
                  onClick={toggleEdit}
                  className="text-center text-white text-xs font-medium"
                >
                  <EditIcon color="white" width={"12"} height={"12"} />
                </button>
              </div>
              {prlist?.length > 0 || firstProduct.length > 0 ? (
                <div className=" h-[calc(100vh-300px)] 2xl:h-[calc(100vh-500px)] overflow-y-scroll">
                  {prlist.map((list, index) => (
                    <div
                      key={index}
                      className="w-full  py-2 px-2 flex justify-between items-center odd:bg-white even:bg-slate-50"
                    >
                      <p className="w-[5%] text-left px-2 text-[#606279] text-xs font-light">
                        {list?.isAlternate == false &&
                          formik.values.isDirectQuote == false && (
                            <button
                              type="button"
                              onClick={()=>toggleForm(index)}
                              className="font-semibold px-1.5 py-0.5 bg-blue-600 text-white mr-2 rounded-lg ml-[-5px]"
                            >
                              <p>+</p>
                            </button>
                          )}
                        {list?.sno}
                      </p>
                      <div className="w-[35%]">
  <p className="text-left text-[#606279] text-xs font-light">
    {list?.product_name}
    
  </p>

     <p className="flex">
         <p className="text-[12px] text-[#606279] font-thin mt-1"> Notes :</p>
         {isEditing && !list?.isAlternate ? (
 
  <input
                          type="text"
                          className="w-[65%] py-1 text-left px-1 text-[#606279] border border-gray-100 text-xs font-light "
                          name="reason"
                          placeholder="Enter here..."
                          value={list.notes || ""}
                          onChange={(e) => handleNoteChange(e, index)}
                        />
                      
  ):(
    <p>{list.notes}</p>
  )}
    </p>
  {list?.isAlternate ? (
    <p className="text-left text-[#65667b] text-[10px] md:text-[10px] lg:text-[8px] xl:text-[10px] font-light">
      (Alternate)
    </p>
  ) : (
    <>
      
    </>
  )}
</div>

                      <p className="w-[10%] text-center text-[#606279] text-xs font-light">
                        {/* {list?.product_unit} */}
                        <Select
                          value={Unit.find(item=> item?.value == list.product_unit)||list.product_unit}
                          options={isEditing ? Unit : []}
                          className="w-full"
                          onChange={(value) =>
                            handleProductUnitChange(value, index)
                          }
                          isDisabled={!isEditing}
                        />
                      </p>
                      <div className="w-[15%] text-center text-[#606279] text-xs font-light">
                        {isEditing ? (
                          <>
                            <button
                              type="button"
                              className="border rounded-full p-1"
                              onClick={() => handleDecrement(index)}
                            >
                              -
                            </button>
                            <input
                              type="number"
                              className="w-[50%] text-center text-[#606279] text-xs font-light"
                              name="quantity"
                              value={list.quantity}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                            <button
                              type="button"
                              className="border rounded-full p-1"
                              onClick={() => handleIncrement(index)}
                            >
                              +
                            </button>
                          </>
                        ) : (
                          <p>{list.quantity}</p>
                        )}
                      </div>
                      <div className="w-[13%] text-center text-[#606279] text-xs font-light">
                        {/* {isEditing ? ( */}
                        <Select
                          value={list.leadTime}
                          options={isEditing ? leadTime : []}
                          className="w-full"
                          isDisabled={!isEditing}
                          onChange={(value) =>
                            handleLeadTimeChange(value, index)
                          }
                        />
                      </div>
                      {isEditing ? (
                        <div className="w-[15%] px-1">
                          <input
                            type="number"
                            className="w-[90%] py-1 text-center border text-[#606279] text-xs font-light"
                            name="price"
                            formik={formik}
                            placeholder="Enter the price"
                            value={list.price || ""}
                            onChange={(e) => handlePriceChange(e, index)}
                          />
                        </div>
                      ) : (
                        <p className="text-center w-[15%] text-[#606279] text-xs font-light">
                          {list.price}
                        </p>
                      )}
                        <p className="text-center w-[15%] text-[#606279] text-xs font-light">
                          {list.convPrice}
                          <p className='text-[8px]'>Total:<span className='text-red-900'>{(list?.quantity * list?.convPrice).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></p>
                        </p>
                       {isEditing ? (
                        <div className="w-[13%] px-1">
                          <input
                            type="number"
                            className="w-[90%] py-1 text-center border text-[#606279] text-xs font-light"
                            name="landedCost"
                            formik={formik}
                            placeholder="Enter the Cost"
                            value={list.landedCost || ""}
                            onChange={(e) => handleCostChange(e, index)}
                          />
                        </div>
                      ) : (
                        <p className="text-center w-[13%] text-[#606279] text-xs font-light">
                          {list.landedCost}
                        </p>
                      )}
                    <p className="text-center w-[13%] text-[#606279] text-xs font-light">
                    {((list.convPrice * list.quantity) + parseFloat(list.landedCost)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}

  {/* {supplierCurrency == "" ? 
                 <NuAmount value={Number((list.price * list.quantity + parseFloat(list.landedCost)) || 0)} />
                 :
                 <NuAmount currency={supplierCurrency} value={Number((list.price * list.quantity + parseFloat(list.landedCost)) || 0)} />
              } */}
</p>

                      <div className="w-[7%] text-center flex items-center justify-center">
                        <button
                          onClick={() =>
                            isEditing ? handleDeleteItem(index) : {}
                          }
                          type="button"
                          className={`text-red-500 ${
                            isEditing ? "cursor-pointer" : "cursor-default"
                          } bg-white ml-4 border-none`}
                        >
                          <Delete color="red" height={4} />
                        </button>
                      </div>
                    </div>
                  ))}
                  {firstProduct.map((list, index) => (
                    <div
                      key={index}
                      className="w-full  py-2 px-2 flex justify-between items-center odd:bg-white even:bg-slate-50"
                    >
                      <p className="w-[5%] text-left px-2 text-[#606279] text-xs font-light">
                        {list?.sno}
                      </p>
                      <div className="w-[35%]">
                        <p className="text-left text-[#606279] text-xs font-light">
                          {list?.product_name}
                        </p>
                      </div>
                      <p className="w-[10%] text-center text-[#606279] text-xs font-light">
                        {list?.product_unit}
                      </p>
                      <div className="w-[15%] text-center text-[#606279] text-xs font-light">                       
                          <p>
                            {list.quantity}
                            </p>

                      </div>
                      <div className="w-[13%] text-center text-[#606279] text-xs font-light">
                        {/* {isEditing ? ( */}
                        {/* <Select
                          value={list.leadTime}
                          options={isEditing ? leadTime : []}
                          className="w-full"
                          onChange={(value) =>
                            handleLeadTimeChange(value, index)
                          }
                        /> */}
                          <p>
                            {list.leadTime}
                            </p>
                      </div>
                      <p className="text-center w-[15%] text-[#606279] text-xs font-light">
                          {list.price}
                        </p>              
                       <p className="text-center w-[15%] text-[#606279] text-xs font-light">
                          {list.convPrice}
                         <p className='text-[8px]'>Total:<span className='text-red-900'>{(list?.quantity * list?.convPrice).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></p>
                        </p>
                        <p className="text-center w-[13%] text-[#606279] text-xs font-light">
                          {list.landedCost}
                        </p>
                        <p className="text-center w-[13%] text-[#606279] text-xs font-light">
                        {((list.convPrice * list.quantity) + parseFloat(list.landedCost)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
 
  {/* {supplierCurrency == "" ? 
                 <NuAmount value={Number(((list.price * list.quantity) + list.landedCost) || 0)} />
                 :
                 <NuAmount currency={supplierCurrency} value={Number(((list.price * list.quantity) + list.landedCost) || 0)} />
              } */}
</p>

                      <div className="w-[7%] text-center flex items-center justify-center">
                        <button
                          onClick={() =>
                            handleDeletePro(index)
                          }
                          type="button"
                          className={`text-red-500 ${
                            "cursor-pointer"
                          } bg-white ml-4 border-none`}
                        >
                          <Delete color="red" height={4} />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="w-full text-center text-[#93949f]">
                  No Items here
                </div>
              )}
            </div>
            <div className="w-full my-6 flex justify-end items-center gap-4">
              <TextMediumBase content={"Total Price : "} />
              <p className="text-sm"> {currencySymbol} </p>
              <p className="w-64 h-8 border rounded-md text-xs pl-2 py-2">
                 <NuAmount defaultCurrency={false} value={Number(formik?.values?.totalPrice || 0)} />
</p>
            </div>
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton
                  handleClick={() => {
                    Oncancel(-1);
                  }}
                />
                <SubmitButton isLoading={isLoading} />
              </div>
            </div>
          </div>
        </div>
      </form>
      {showPopup && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
          <div className="animate-scale-in w-[60%] max-h-[80vh] bg-white py-2 px-4 rounded-md shadow-lg overflow-y-scroll ">
            <h2 className="text-lg font-medium text-[14px]">Add Product</h2>
            <form onSubmit={handlePopupSubmit}>
              <div className="mb-4 flex flex-wrap">
              <NuInput
                  label="Product Name"
                  type="text"
                  formik={formik}
                  placeholder="Product Name"
                  name="productName"
                  width="w-full"
                  isRequired={true}
                />

<DropDownAddMetaData
                label="Unit"
                placeholder="Choose"
                Options={Unit}
                formik={formik}
                name="unit"
                width="w-full md:w-1/2 lg:w-1/2"
                getFunc={refUnit}
                displayProperty={"Add Unit"}
                propertyName={"Unit_item"}
                isRequired={true}
              />
             <DropDownAdd
              type="text"
              label="Category"
              placeholder="Category"
              Options={itemsCategory}
              formik={formik}
              name="category"
              width="w-full md:w-1/2 lg:w-1/2"
              getFunc={getCategory}
              categoryName={"ItemCategory"}
              displayProperty={"Add Category"}
              isRequired={true}
            />
            <DropDownAddSubCategory
              type="text"
              label="Sub-Category"
              placeholder="Sub-Category"
              Options={itemsSubCategory}
              formik={formik}
              name="subCategory"
              width="w-full md:w-1/2 lg:w-1/2"
              getFunc={fetchSubCategory}
              category={formik?.values?.category}
              module={"ItemCategory"}
              displayProperty={"Add Sub-Category"}
            />
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={togglePopup}
                  className="px-5 py-[9px] cursor-pointer text-xs 2xl:text-sm bg-[#E6F2F2]  hover:bg-gray-300
                  rounded-md flex items-center mr-2 text-textColor-main"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-[#048178] px-5 py-[9px] cursor-pointer outline-none border-none text-white rounded-md text-xs 2xl:text-sm flex items-center"
                >
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}

export default RfqCreateSupplierQuotes