import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import logo from '../../../../assets/images/logo.png';
import { NuInput, NuInputToggle } from '../../../../Components/Utils/Input';
import { useToast } from '../../../../Components/Others/ToastServices';
import CryptoJS from "crypto-js";
import ChangePassword from './changePassword';
import '../../../../App.css';



axios.defaults.baseURL = window.WEB_APP_API_URL;
function Login() {
  const toast = useToast()
  const [showForgetPassword, setShowForgetPassword] = useState(false);
  const handleForgetPasswordClick = () => {
    setShowForgetPassword(true);
  };
  const handleCloseModal = () => {
    setShowForgetPassword(false)
  };
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
  });


  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (localStorage.getItem("nEkOtReSuEdArT") && localStorage.getItem("sLiAtEdReSuEdArT") && localStorage.getItem("sSeCcArEsUeDaRt") ) {
      window.location.replace("/dashboard");
    }
  }, []);


  const encryptData = async (data,location) =>{
    let Test = JSON.stringify(data)
    const Value = CryptoJS.AES.encrypt(Test,process.env.REACT_APP_ACCESS_KEY).toString();
    localStorage.setItem(location,Value);
    // console.log('EnC',Value)
  }

  const [createNew, setCreateNew] = useState({
    email: '',
    password: '',
  });
  const formik = useFormik({
    initialValues: createNew,
    validateOnChange: false,
    validationSchema: yup.object({
      email: yup.string().required('Email is Required'),
      password: yup.string().required('Password is Required'),
    }),
    onSubmit: async (value) => {
      await axios
        .post('/auth/login', value)
        .then( async(res) => {
          if (res.data.status) {
            let userToken = res?.data?.token;
            await encryptData(userToken,'nEkOtReSuEdArT');
            let decodeToken = jwt_decode(res?.data?.token);
            await encryptData(decodeToken,'sLiAtEdReSuEdArT');
            let permissions = res?.data?.permissions
            await encryptData(permissions,'sSeCcArEsUeDaRt');
            // localStorage.setItem('sLiAtEdReSuEdArT', JSON.stringify(decodeToken));
            // localStorage.setItem('nEkOtReSuEdArT', JSON.stringify(res?.data?.token));
            // localStorage.setItem('sSeCcArEsUeDaRt', JSON.stringify(res?.data?.permissions));
            toast.open(
              {
                message:"Login successfully",
                description: res.data.msg || 'Successfully logged in  ',
                type:"success",
              }
            )
            window.location.replace("/");
          } else {
            toast.open(
              {
                message:"Login unsuccessfully",
                description: res.data.msg || 'Login failed ',
                type:"error",
              }
            )
          }
        })
        .catch((err) => {
          toast.open(
            {
              message:"Login unsuccessfully",
              description: err?.response.data.msg || 'Login failed or network error  ',
              type:"error",
            }
          )
        });
    },
  });

  return (
    <div className='w-full h-[100vh] login-image'>
      <div className='w-full h-[calc(100vh-30px)] flex items-center justify-center'>
        {/* {contextHolder} */}
        <div className='w-full mx-5 md:mx-0 md:w-3/5 h-2/3 rounded-2xl flex  shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)]'>
          <div className='hidden md:flex w-1/2 bg-gradient-to-t to-teal-200 from-green-700  rounded-l-2xl flex-col items-center justify-center gap-6'>
            <h1 className='text-center text-[#231942] capitalize font-bold text-[20px] lg:text-[23px] xl:text-[26px]'>Login</h1>
            <div className='w-[65%] rounded-[1.5rem] content-center h-[65%] bg-[#e6f3f2] flex items-center justify-center drop-shadow-lg'>
              <img className='w-1/2' src={logo} alt="logo"/>
            </div>
          </div>
          <div className='w-full md:w-1/2 h-full rounded-2xl md:rounded-l-none md:rounded-r-2xl bg-[#048178]/20 backdrop-blur-sm flex justify-center items-center'>
            <div className='w-full px-3 flex flex-col '>
              <form onSubmit={formik.handleSubmit} className=''>
                <div className=' justify-center '>
                  <NuInput
                    type="text"
                    label='Email *'
                    placeholder='Enter Your Mail'
                    formik={formik}
                    name='email'
                    width='w-full'
                    padding='py-2'
                  />

                  <NuInputToggle
                    type="password"
                    label='Password *'
                    placeholder='************'
                    formik={formik}
                    name='password'
                    width='w-full'
                    padding='py-2'
                  />
                </div>

                {/* <Link to="/auth/forget-password"> */}
                  <p onClick={handleForgetPasswordClick} className='text-[#048178] px-3 font-sans not-italic text-sm medium text-right cursor-pointer'>Forgot Password?</p>
                {/* </Link> */}
                <button type='submit' className='border-none cursor-pointer text-white text-xs bg-[#048178] h-10  mx-3 mt-6 rounded-md w-[95%]'>
                  Login
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <p className=' mt- font-semibold text-center text-s text-slate-200'>&#169;  numota</p>
      {showForgetPassword && <ChangePassword onClose={handleCloseModal} />}
    </div>
  );
}
export default Login;