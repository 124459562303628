import React, { useEffect, useState } from 'react'
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuDate, NuInput, NuMobileNumber, NuTextArea } from '../../../../Components/Utils/Input';
import FormikSelectTwo from '../../../../Components/Utils/Select';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import DropDownAddMetaData from '../../../../Components/Utils/selectAddMetaData';
import useAddFormData from '../../../../Components/Hooks/useAddFormData';
import axiosInstance from '../../../../Services/axiosCommon';
import { useToast } from '../../../../Components/Others/ToastServices';
import DeleteIcon from '../../../../Components/Icons/Delete';
import Attachement from '../../../../Components/Utils/CustomAttrachmentupload';
const filetype = ["docx", "doc", "xlsx", "xls", "pdf", "txt", "pptx","mp4", "mov", "wmv", "avi", "flv", "mkv"];

function CreateDeliveryNotes() {
  const currentDate = new Date().toISOString().split('T')[0];
  const {value:DeliveryVehicle ,getData:refDeliveryVehicle} = useFetchMataData(`/dropdown/dropdown-details/`,'DeliveryVehicle')
  const {value:DeliveryNoteIdType ,getData:refDeliveryNoteIdType} = useFetchMataData(`/dropdown/dropdown-details/`,'DeliveryNoteIdType')
  const { data: SO } = UseDropDown('/sales-order/drop-down');
  const [image, setImage] = useState([]);
  const toast = useToast();
  const { Oncancel, addFormData, isLoading } = useAddFormData();
  const [createNew, setCreateNew] = useState({
    idNumber: "",
    countryCode: "",
    mobile: "",
    deliveryVechicleType: "",
    soId: "",
    daysElapsed: "",
    date: currentDate || "",
    idType: "",
    deliveryPersonName: "",
    wayBillNo: "",
    deliveredOn: currentDate || "",
    comments: "",  
    products:"",
  })

  const [error,setError] = useState('')
  const formik = useFormik
    ({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
        soId: yup.string().required('Sales Order is required!'),
      }),
      onSubmit: async (value, { resetForm }) => {
        // setLoading(true); 
        if (solist.length === 0) {
          setError('Product table is empty');
          return;
        }
        value.products=solist
        value = {...value, mobile:String(value.mobile)}

        let formData = new FormData();
        formData.append('json_data', JSON.stringify(value));
        // formData.append('itemImage', image.raw);
        if(selectedFile !== null){
          selectedFile.forEach((file) => {
            formData.append("attachments", file);
          });
        }else{
          formData.append("attachments",[])
        } 
  
        addFormData('/delivery-notes/new-delivery-note', formData, resetForm);
        // setLoading(false)
      }       
    });


    const handleDeleteItem = (indexToDelete) => {
      const updatedItems = solist.filter((_, index) => index !== indexToDelete);
      setSolist(updatedItems);
    };
  
    const [selectedFile, setSelectedFile] = useState(null);

    function handleImageChange(e) {
      const maxFileSize = 200000000; // 200 MB
      const files = Array.from(e.target.files);
      let fileArr = []
      files.map(item=>{
        if (item.size < maxFileSize ){
          fileArr.push(item)
        }else{
          toast.open({
            type:"warning",
            message: `File Size to big`,
            description: `${item.name} is Removed and not Uploaded`,
            // placement: "bottomRight",
          });
        }
      })    
      let tempArr = []
      setSelectedFile(fileArr);
      files.map(item=>{
        if (item.size < maxFileSize ){
        let fileName = item?.name
        console.log('fname',fileName);
        let fileExtention =  fileName.split(".").reverse()[0]
        if(filetype?.includes(fileExtention)){
          tempArr.push(fileName)
        }else{
          let x = URL.createObjectURL(item)
          tempArr.push(x)
        }
      }
      })
      console.log(tempArr,'temp');
      setImage(tempArr)
    }
  
    const [solist, setSolist] = useState([]);
  
    const getPr = (soId) => {
      if (soId !== "") {
        axiosInstance
          .get(`/delivery-notes/so-products/${soId}`)
          .then(res => {
            let data = res?.data?.products;
            setSolist(data);
            formik.setFieldValue('vendorId', res?.data?.vendorId || '');
          })
          .catch(err => {
            console.log(err, "err");
          });
      }
    };
  
    useEffect(() => {
      if (formik.values.soId) {
        getPr(formik.values.soId);
      }
    }, [formik.values.soId]);
  
    const handleQuantityChange = (index, newpackedQty) => {
      const updatedItems = [...solist];
      if (newpackedQty <= updatedItems[index].orderedQty) {
        updatedItems[index].packedQty = newpackedQty;
        updatedItems[index].remainingQty = updatedItems[index].orderedQty - newpackedQty;
      }
      setSolist(updatedItems);
    };
  
    const handleIncrement = (index) => {
      const currentpackedQty = solist[index].packedQty;
      handleQuantityChange(index, Math.min(solist[index].orderedQty, currentpackedQty+ 1));
    };
  
    const handleDecrement = (index) => {
      const currentpackedQty = solist[index].packedQty;
      handleQuantityChange(index, Math.max(0, currentpackedQty - 1));
    };
  
    const handleInputChange = (event, index) => {
      const value = Math.max(0, Math.min(Number(event.target.value), solist[index].orderedQty));
      handleQuantityChange(index, value);
    };

    useEffect(() => {
      if (solist.length > 0) {
        setError('');
      }
    }, [solist]);

    const removeImages = (value) => {
      console.log("value", value);
      let List = selectedFile.filter((item) => item?.name !== value);
      console.log("LIst", List);
      setSelectedFile([...List]);
      let tempArr = []
      List.map((item) =>{
        let fileName = item?.name
          tempArr.push(fileName)
      })
      setImage(tempArr)
    };
    
  return (
    <div className="w-full h-full py-2 px-4 ">
    <div className="flex justify-between ">
       <Breadcrumb
          items={[
            {
              name: "Delivery Note",
              link: "/store/delivery-note"
            },
            { name: "Create" },
          ]} />
        <BackButton />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] mt-4 bg-white rounded-md overflow-hidden">
          <div className=" w-full h-full py-2 flex justify-start content-start flex-wrap  overflow-y-auto">
          <FormikSelectTwo
              type="text"
              label="Sales Order"
              placeholder="Sales Order"
              Options={SO}
              formik={formik}
              name="soId"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
              FixPosition={false}
              PortalTarget={false}
            />
            <NuInput
              label="Id Number"
              type="text"
              formik={formik}
              placeholder="Id Number"
              name="idNumber"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuMobileNumber
              nunCode="countryCode"
              label="Mobile"
              placeholder="Mobile"
              formik={formik}
              name="mobile"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <DropDownAddMetaData
                label="Delivery Vehicle Type"
                placeholder="Choose"
                Options={DeliveryVehicle}
                formik={formik}
                name="deliveryVehicleType"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refDeliveryVehicle}
                displayProperty={"Delivery Vehicle Type"}
                propertyName={"DeliveryVehicle"}
              />                     
            <NuInput
              label="Days Elapsed"
              type="number"
              formik={formik}
              placeholder="Days Elapsed"
              name="daysElapsed"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDate
              label="Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="date"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <DropDownAddMetaData
                label="Id Type"
                placeholder="Choose"
                Options={DeliveryNoteIdType}
                formik={formik}
                name="idType"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refDeliveryNoteIdType}
                displayProperty={"Id Type"}
                propertyName={"DeliveryNoteIdType"}
              />
             <NuInput
              label="Delivery Person Name"
              type="text"
              formik={formik}
              placeholder="Delivery Person Name"
              name="deliveryPersonName"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              label="WayBill No"
              type="text"
              formik={formik}
              placeholder="WayBill No"
              name="wayBillNo"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDate
              label="Delivered On"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="deliveredOn"
              width="w-full md:w-1/2 lg:w-1/3"
            />
              <NuTextArea
              label="Comments"
              type="text"
              formik={formik}
              placeholder="Comments"
              name="comments"
              new_width="w-full md:w-2/3"

              width="w-full md:w-1/2 lg:w-1/3"
            />
                <div className="w-full">
  <p className='pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4'>
  DN Attachement :
  </p></div>
                <Attachement
                  handleChange={handleImageChange}
                  preview={image||''}
                  width="w-full"
                  label="Attach"
                  multiple={true}
                removeImages={removeImages}

                />
               {/* </div> */}
            <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div>
            <div className="w-full bg-white overflow-y-scroll mt-5">
            {error && <p className="text-xs font-semibold text-red-500">{error}</p>}
              <div className="w-full py-3 bg-[#048178] flex justify-between rounded-lg px-6">
              <p className="w-[5%]  text-left  text-white text-xs font-medium">S.N. </p>

                <p className="w-[40%] text-left text-white text-xs font-medium">Product</p>
                <p className="w-[10%] text-center text-white text-xs font-medium">Unit</p>
                <p className="w-[15%] text-center text-white text-xs font-medium">Ordered Quantity</p>
                <p className="w-[20%] text-center text-white text-xs font-medium">Packed Quantity</p>
                <p className="w-[15%] text-center text-white text-xs font-medium">Remaining Quantity</p>
                <p className=" w-[5%] text-center text-white text-xs font-medium">
              Action
              </p>
              </div>
              {solist?.length>0?
              <div className="h-[calc(100vh-300px)] overflow-y-scroll">

              {solist.map((List, index) => (
                <div key={index} className="w-full py-2 flex justify-between px-6 odd:bg-white even:bg-slate-100">
                   <p className="w-[5%]  text-left  text-[#606279] text-xs font-light py-1">
                                    {index + 1}
                                </p>
                  <p className="w-[40%] text-left text-[#606279] text-xs font-light">{List?.product_name}</p>
                  <p className="w-[10%] text-center text-[#606279] text-xs font-light">{List?.product_unit}</p>
                  <p className="w-[15%] text-center text-[#606279] text-xs font-light">{List?.orderedQty}</p>
                  <div className="w-[20%] text-center text-[#606279] text-xs font-light">
                    <button
                      type="button"
                      className="border rounded-full p-1 "
                      onClick={() => handleDecrement(index)}
                    >
                      -
                    </button>
                    <input
                      type="number"
                      className="w-[20%] text-center border text-[#606279] text-xs font-light"
                      name="packedQty"
                      value={List.packedQty}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                    <button
                      type="button"
                      className="border rounded-full p-1 "
                      onClick={() => handleIncrement(index)}
                    >
                      +
                    </button>
                  </div>
                  <p className="w-[15%] text-center text-[#606279] text-xs font-light">{List?.remainingQty}</p>
                  <div className='w-[5%] text-center'> 
                 <button
                    onClick={() => handleDeleteItem(index)}
                    type="button"
                    className="text-red-500 cursor-pointer  ml-4 border-none"
                  >
                    <DeleteIcon
                     color='red'
                     height={4}
                    />
                  </button>
                  </div>
                </div>
                
              ))}
            </div>:<div className="w-full text-center text-[#93949f]">No Items here</div>}

            </div>
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={() => { Oncancel(-1) }} />
                <SubmitButton isLoading={isLoading}/>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
export default CreateDeliveryNotes