import React, { useRef } from 'react'

function CustomImgUpload({preview,borderStyle='solid',justify='',handleChange,width='w-full',label,isRemove=true,removeFunction=()=>{}}) {
    const fileInputRef=useRef();
  return (
    <div className={`${width}`}>
            <div className={`mt-2 px-3 flex ${justify} items-center`}>
            <div className=''>
      {preview ? (
        <div className={`w-20 h-20 flex justify-center items-center rounded-full border-2 border-${borderStyle} border-baseColor-secondary bg-basColor-quartary border-solid overflow-hidden`}>
          <img src={preview} alt="dummy"  width="120%" />
        </div>
        ) : (
          <>
            <div className= {`w-20 h-20 text-xs bg-basColor-quartary text-[#777779] rounded-full flex justify-center items-center overflow-hidden border-2 border-baseColor-secondary border-${borderStyle}`}>
            No Image
            </div>
            
          </>
        )}
      </div>
     {
      !preview && <div className=" border-none outline-none px-2 mx-3 py-1 text-white font-semibold text-xs  cursor-pointer rounded-md bg-inputColor-main" onClick={()=>fileInputRef.current.click()}>
        {label?label:`Choose`}
      </div>
      }
      {
        isRemove && preview && <div className=" border-none outline-none px-2 mx-3 py-1 text-white  cursor-pointer rounded-[4px] bg-red-600 " onClick={removeFunction}>Remove</div>
      }
      <input onChange={handleChange} multiple={false} ref={fileInputRef} type='file' accept='image/*' hidden/>
    </div>
    </div>
  )
}

export default CustomImgUpload