import React from 'react'
// import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import UseGetDashBoardData from '../../../../../Components/Hooks/useDashboardData';
import GRN from './GRN';
import DeliveryNote from './DeliveryNote';
import RequestApproval from './RequestApproval';

function Approvals() {
    
    return (
      <div className="col-span-4 bg-white h-[18vh] p-2   rounded-lg shadow-lg overflow-hidden ">
          {/* <div className='flex'>
      <div className="flex flex-col  mx-4">
                    {Array.from("APPROVAL").map((letter, index) => (
                        <p 
                          key={index} 
                          className="text-xs font-extrabold text-[#2D9596] transform transition-transform duration-300 hover:scale-125" 
                          style={{ fontFamily: 'Arial, sans-serif' }} 
                        >
                            {letter}
                        </p>
                    ))}
                </div>    */}

       <p className="text-sm font-semibold text-[#2D9596] pl-2">Approvals</p>
       <div className='flex'>
  
        <GRN/>
        <div className='border-r border-[1px] border-slate-200/60 border-solid'/>
  
      <DeliveryNote/>
        <div className='border-r border-[1px] border-slate-200/60 border-solid'/>
  
        <RequestApproval/>
        {/* </div> */}
        </div>
      </div>
    );
  }
  

export default Approvals