import { useState } from 'react';
import axiosInstance from '../../Services/axiosCommon';
// import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../Others/ToastServices';
import useNotification from './useNotification';

const useAddFormData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {handleSendNotification} = useNotification();
  const navigate = useNavigate()
  const toast = useToast();

  function Oncancel() {
      navigate(-1);
  }

  const addFormData = async (url, formData, resetForm,hasUrl=false,backUrl="") => {
    setIsLoading(true);

    try {
      const res = await axiosInstance.post(url, formData);
      if (res.data?.status === true) {
        if (res.data.notification!==null || res.data.notification!==undefined || res.data.notification!==""){
          handleSendNotification(res.data.notification);
        }
        toast.open({
          type:"success",
          message:"Success",
          description: res.data?.msg || "Created Successfully."
        })

        resetForm();
        if(hasUrl == true){
          navigate(backUrl)
        }else{
        Oncancel();          
        }
      } 
      else if(res.data?.status === false && res.data?.error){
        toast.open({
          type:"error",
          message:"Error",
          description: res.data.error || "Network error."
        })
      } 
      else {
        toast.open({
          type:"warning",
          message:"Warning",
          description: res.data?.msg || "Warning."
        })
      }
    } catch (err) {
    //   setError(err.message);
      console.log(err);
      toast.open({
        type:"error",
        message:"Error",
        description:  "Network error."
      })
    } finally {
      setIsLoading(false);
    }
  };

  return { addFormData, isLoading, Oncancel };
};

export default useAddFormData;