import React, { useEffect, useState } from 'react';
import { Cell, Label, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import axiosInstance from '../../../../../Services/axiosCommon';

export default function POToSuppliersProDash() {
  const [params, setParams] = useState({ period: 'monthly' });
  const [selectedPeriod, setSelectedPeriod] = useState('monthly');
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  // Define a color for each status in the correct order
  const COLORS = ['#FFBB28', '#FF8042', '#0088FE', '#00712D'];

  async function getData() {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/dashboard/PO-pending`, { params });
      setData(res?.data || {});
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, [params]);

  const status = [
    { name: 'Pending', value: data.pending || 0 },
    { name: 'Reviewed', value: data.reviewed || 0 },
    { name: 'Verified', value: data.verified || 0 },
    { name: 'Approved', value: data.approved || 0 },
  ];

  const handleChange = (e) => {
    const value = e.target.value;
    setParams({ period: value });
    setSelectedPeriod(value);
  };

  const CustomTooltip = ({ active, payload, }) => {
    
    if (active && payload && payload.length) {
      return (
        <div className=" max-w-[250px] bg-white shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px] rounded-lg p-[5px]">
          <p className=" text-xs font-medium">{`${payload[0].name} : ${payload[0].value}`}</p>
        </div>
      );
    }
  
    return null;
  };


  let SumValue = status.reduce((sum, item) => (sum + item?.value), 0);

  return (
    <div className="col-span-2 bg-white h-[40vh] w-full p-4 rounded-lg drop-shadow-lg mb-4">
      <div className="flex justify-between items-center mb-3">
        <p className="text-xs 2xl:text-sm text-[#4F4768] font-medium">Purchase Order Approval</p>
      </div>
      <div className="border-t border-[1px] border-slate-200/60 border-solid w-full "></div>

      {loading ? (
        <LoadingSkeleton/>
      ) : (
        <div className="w-full flex">
          {SumValue > 0 ?(
          <div className="w-1/2 mt-4 flex justify-center items-center">
          {
            SumValue > 0 ? (
              <ResponsiveContainer width="100%" height={150}>
              <PieChart>
                <Pie
                  data={status}
                  cx="50%"
                  cy="50%"
                  innerRadius={35}
                  outerRadius={60}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {status.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                   <Label
                    value={data?.total || '0'}
                    position="center"
                    className="text-center"
                    style={{ fontSize: '12px', fontWeight: 'bold' }}
                  />
                </Pie>
                <Tooltip content={<CustomTooltip/>} />
              </PieChart>
            </ResponsiveContainer>
            ) : (
              <ResponsiveContainer width="100%" height={150}>
              <PieChart>
                <Pie
                  data={[{
                    name:"No Data",
                    value:100
                  }]}
                  cx="50%"
                  cy="50%"
                  innerRadius={35}
                  outerRadius={60}
                  fill="#E8E8E8"
                  dataKey="value"
                >
                </Pie>
              </PieChart>
            </ResponsiveContainer>
            )
          }
          </div>
          ): (
            <NODataChart />
          )}
          <div className="w-1/2 mt-5">
            {status.map((item, idx) => (
              <div key={idx} className="w-full flex justify-start gap-2 p-1 ml-2 text-xs">
                <div
                  className="w-2 h-2 rounded-full mt-1"
                  style={{ backgroundColor: COLORS[idx % COLORS.length] }}
                ></div>
                <p className="w-1/3">{item.name}</p>
                <p className="w-1/2">{item.value}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

function LoadingSkeleton() {
  return (
    <div className="flex  h-full space-y-4 animate-pulse">
      <div className="w-1/2 flex justify-center">
      <NODataChart/>
      </div>
      <div className="w-1/2 flex flex-col items-centermt-4 space-y-2 ">
        <div className="w-1/3 h-4 bg-slate-200 rounded"></div>
        <div className="w-1/4 h-4 bg-slate-200 rounded"></div>
        <div className="w-1/2 h-4 bg-slate-200 rounded"></div>
        <div className="w-1/3 h-4 bg-slate-200 rounded"></div>
      </div>
    </div>
  );
}

export function NODataChart() {
  return (
    <div className=" w-[40%]">
      {/* <Doughnut data={chartData} /> */}
      <div className="w-full flex justify-center">
        <ResponsiveContainer width="100%" height={150}>
          <PieChart>
            <Pie
              data={[
                {
                  name: "No Data",
                  value: 100,
                },
              ]}
              cx="50%"
              cy="50%"
              innerRadius={35}
              outerRadius={60}
              fill="#E8E8E8"
              dataKey="value"
              // paddingAngle={0}
            >
              <Label
                value={0}
                position="center"
                className="text-center"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              />
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
