import React from "react";
import UseGetDashBoardData from "../../../../../Components/Hooks/useDashboardData";

function SalesOrderSales() {
  const { data, loading } = UseGetDashBoardData(`/dashboard/salesOrderStats`);
  const percentage = parseFloat(data?.total_sales_orders) || 0;
  const isNegative = percentage < 0;

  return (
    <div className=" bg-white p-4 rounded-lg drop-shadow-lg max-h-[25vh] w-full">
      <div className="flex ">
        <div className="bg-baseColor-teritary rounded-full p-2 2xl:p-3 h-[18%]  mt-4 ml-2">
          <svg
            width="20"
            height="20"
            fill="#048178"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
          >
            <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM80 64h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm16 96H288c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V256c0-17.7 14.3-32 32-32zm0 32v64H288V256H96zM240 416h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H240c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
          </svg>
        </div>
        <div className="pl-8 h-[96%] flex flex-col gap-1">
          <p className="text-[#4F4768] text-xs 2xl:text-sm font-semibold ">
            Orders from Customer
          </p>
          <p className="text-[#333333] text-[22px]  font-semibold ">
            {data?.total_sales_orders || 0}
          </p>
          <div className="flex flex-wrap">
            <p className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="18"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d={`M10 ${isNegative ? "17L10 5" : "5L10 17"}`}
                  stroke={isNegative ? "#FF0000" : "#048178"}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d={`M${
                    isNegative
                      ? "15.8337 10.0001L10.0003 15.8334L4.16699 10.0001"
                      : "4.16699 10.0001L10.0003 4.16675L15.8337 10.0001"
                  }`}
                  stroke={isNegative ? "#FF0000" : "#048178"}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p
                className={`text-${
                  isNegative ? "red-500" : "[#048178]"
                } text-[10px] 2xl:text-xs`}
              >
                {isNegative ? percentage * -1 : percentage}{" "}
                <span className="pl-[2px] text-[10px] text-[#4F4768]">
                  this month
                </span>
              </p>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SalesOrderSales;
