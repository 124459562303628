import React from "react";

export default function FileIcon({ width = 50, height = 50 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="15.599000778198242 14.828712310791015 32.802439041137696 34.343284912109375"
      enable-background="new 0 0 64 64"
      height={height}
      width={width}
    >
      <g>
        <path
          fill="#FFFFFF"
          stroke="#D7E0FFFF"
          stroke-width="1.3199999999999998"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="   M24.799,20.793v17.556c0,2.921,2.364,5.285,5.285,5.285H42.42c2.921,0,5.285-2.364,5.285-5.285V23.337   c0-0.597-0.213-1.17-0.614-1.62l-4.745-5.383c-0.466-0.524-1.137-0.826-1.832-0.826h-10.43   C27.163,15.508,24.799,17.873,24.799,20.793z"
        />

        <path
          fill="#FFFFFF"
          stroke="#D7E0FFFF"
          stroke-width="1.3199999999999998"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="   M40.121,15.508v6.127c0,1.219,0.99,2.209,2.209,2.209h5.375c0,0,0.261-1.213-0.614-2.127l-4.745-5.383   C41.459,15.288,40.121,15.508,40.121,15.508z"
        />
      </g>
      <g>
        <g></g>

        <path
          fill="#FFFFFF"
          stroke="#D7E0FFFF"
          stroke-width="1.3199999999999998"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="   M16.259,25.671v17.556c0,2.921,2.364,5.285,5.285,5.285H33.88c2.921,0,5.285-2.364,5.285-5.285V28.215   c0-0.597-0.213-1.17-0.614-1.62l-4.745-5.383c-0.466-0.524-1.137-0.826-1.832-0.826h-10.43   C18.623,20.386,16.259,22.75,16.259,25.671z"
        />

        <path
          fill="#FFFFFF"
          stroke="#D7E0FFFF"
          stroke-width="1.3199999999999998"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="   M31.581,20.386v6.127c0,1.219,0.99,2.209,2.209,2.209h5.375c0,0,0.261-1.213-0.614-2.127l-4.745-5.383   C32.919,20.165,31.581,20.386,31.581,20.386z"
        />
      </g>
      <g>
        <g>
          <line
            fill="none"
            stroke="#D7E0FFFF"
            stroke-width="1.3199999999999998"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            x1="21.365"
            y1="31.311"
            x2="28.653"
            y2="31.311"
          />

          <line
            fill="none"
            stroke="#D7E0FFFF"
            stroke-width="1.3199999999999998"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            x1="21.365"
            y1="36.561"
            x2="28.653"
            y2="36.561"
          />

          <line
            fill="none"
            stroke="#D7E0FFFF"
            stroke-width="1.3199999999999998"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            x1="21.365"
            y1="41.811"
            x2="25.009"
            y2="41.811"
          />
        </g>
      </g>
    </svg>
  );
}
