import React from 'react'
import { BackButton } from '../../../../Components/Utils/Buttons';
import { Link, useLocation } from 'react-router-dom';
import { NormalHeading } from '../../../../Components/Utils/Heading';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import NuDate from '../../../../Components/Utils/NuDate';

function ViewPromotion() {
    const location = useLocation();
    const data = location.state.data;
  return (
    <div className="w-full h-full py-2 px-4 ">
    <div className="flex justify-between ">
      <Breadcrumb
         items={
          [
            {
              name: "Sales",
            //   link: "/marketing/appointments",
            },  
          {
            name: "Promotion",
            link: "/sales/promotions"
          },
          {
            name: "View Promotion"
          }]
         }
        />
        <div className=" flex gap-2">
          <Link to={'/sales/promotions'}><BackButton link={true}/></Link>
          {/* <Link to={`/sales/promotions/edit/${data?.appointmentId}`} state={{data: data, from:`/marketing/appointments/view/${data.appointmentId}`}}><EditButton height='8'/></Link> */}
        </div>
      </div>
      <div className='w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-y-scroll mt-4 p-3'>
    {/* <h2 className='text-base font-normal text-[#111537] p-3'>Customers</h2> */}
    <NormalHeading title={data?.productName} />
    <div className=" w-full flex"> 
              <div className="w-full rounded-[20px]  bg-white py-4  flex  flex-col">
              {/* <Carousel images={filePathList} onClick={handleImageClick} /> */}
              {/* <div className="odd:bg-white even:bg-slate-50 w-full flex ">
                <p className="w-1/4 py-2 text-xs 2xl:text-sm font-medium text-[#111537]">
                  Product Name
                </p>
                <p className="w-1/4 py-2 pl-5 text-xs 2xl:text-sm  text-[#606279]">
                  {data?.productName || "-"}
                </p>
              </div> */}
              <div className="odd:bg-white even:bg-slate-50 w-full flex ">
                <p className="w-1/4 py-2 text-xs 2xl:text-sm font-medium text-[#111537]">
                  Item Code{" "}
                </p>
                <p className="w-1/4 py-2 pl-5 text-xs 2xl:text-sm  text-[#606279]">
                  {data?.itemCode || "-"}
                </p>
              </div>
              <div className="odd:bg-white even:bg-slate-50 w-full flex ">
                <p className="w-1/4 py-2 text-xs 2xl:text-sm font-medium text-[#111537]">
                  Category
                </p>
                <p className="w-1/4 py-2 pl-5 text-xs 2xl:text-sm  text-[#606279]">
                  {data?.category || "-"}
                </p>
              </div>
              <div className="odd:bg-white even:bg-slate-50 w-full flex ">
                <p className="w-1/4 py-2 text-xs 2xl:text-sm font-medium text-[#111537]">
                  Sub-Category
                </p>
                <p className="w-1/4 py-2 pl-5 text-xs 2xl:text-sm  text-[#606279]">
                  {data?.subCategory || "-"}
                </p>
              </div>
              <div className="odd:bg-white even:bg-slate-50 w-full flex ">
                <p className="w-1/4 py-2 text-xs 2xl:text-sm font-medium text-[#111537]">
                  Unit
                </p>
                <p className="w-1/4 py-2 pl-5 text-xs 2xl:text-sm  text-[#606279]">
                  {data?.unit || "-"}
                </p>
              </div>
              <div className="odd:bg-white even:bg-slate-50 w-full flex ">
                <p className="w-1/4 py-2 text-xs 2xl:text-sm font-medium text-[#111537]">
                  Under Promotion
                </p>
                <p className="w-1/4 py-2 pl-5 text-xs 2xl:text-sm  text-[#606279]">
                  {data.isPromotable ? "Yes" : "No" || "-"}
                </p>
              </div>
              <div className="odd:bg-white even:bg-slate-50 w-full flex ">
                <p className="w-1/4 py-2 text-xs 2xl:text-sm font-medium text-[#111537]">
                  Supplier
                </p>
                <p className="w-1/4 py-2 pl-5 text-xs 2xl:text-sm  text-[#606279]">
                  {data?.supplier || "-"}
                </p>
              </div>
              <div className="odd:bg-white even:bg-slate-50 w-full flex ">
                <p className="w-1/4 py-2 text-xs 2xl:text-sm font-medium text-[#111537]">
                  Price
                </p>
                <p className="w-1/4 py-2 pl-5 text-xs 2xl:text-sm  text-[#606279]">
                  {data?.price || "-"}
                </p>
              </div>
             
              <div className="odd:bg-white even:bg-slate-50 w-full flex ">
                <p className="w-1/4 py-2 text-xs 2xl:text-sm font-medium text-[#111537]">
                  Manufacturer
                </p>
                <p className="w-1/4 py-2 pl-5 text-xs 2xl:text-sm  text-[#606279]">
                  {data?.manufaturer || "-"}
                </p>
              </div>
              <div className="odd:bg-white even:bg-slate-50 w-full flex ">
                <p className="w-1/4 py-2 text-xs 2xl:text-sm font-medium text-[#111537]">
                  Brand
                </p>
                <p className="w-1/4 py-2 pl-5 text-xs 2xl:text-sm  text-[#606279]">
                  {data?.brand || "-"}
                </p>
              </div>
              <div className="odd:bg-white even:bg-slate-50 w-full flex ">
                <p className="w-1/4 py-2 text-xs 2xl:text-sm font-medium text-[#111537]">
                  Comments
                </p>
                <p className="w-1/4 py-2 pl-5 text-xs 2xl:text-sm  text-[#606279]">
                  {data?.comments || "-"}
                </p>
              </div>
            </div>

           
              </div>
          </div>
          
      </div>
  
  )
}

export default ViewPromotion