
import React, { useState, useEffect } from 'react';
import { useToast } from '../../../../Components/Others/ToastServices';
import axiosInstance from '../../../../Services/axiosCommon';
import TempletePdf from './TempletePdf';

const PdfTemplate = () => {
  const [selectedPdf, setSelectedPdf] = useState(null); 
  const [pdfData, setPdfData] = useState([]);
  const [showTemplate, setShowTemplate] = useState(false); 
  const [previewPdfId, setPreviewPdfId] = useState(null); 
  const toast = useToast();

  const getData = () => {
    axiosInstance.get(`/pdf-template/`)
      .then((res) => {
        if (res.data?.data) {
          setPdfData(res.data.data);
          const selectedTemplate = res?.data?.data?.find(template => template.isSelected);
          if (selectedTemplate) {
            setSelectedPdf(selectedTemplate.id.toString());
          }
        }
      })
      .catch(() => {
        toast.open({
          type: 'error',
          message: 'Error',
          description: 'Failed to fetch data.',
        });
      });
  };

  const handleResponse = (res, action) => {
    if (res.data?.status === true) {
      toast.open({
        type: 'success',
        message: 'Success',
        description: res.data?.msg || `${action} Successfully.`,
      });
    } else if (res.data?.status === false && res.data?.error) {
      toast.open({
        type: 'error',
        message: 'Error',
        description: res.data.error || 'Network error.',
      });
    } else {
      toast.open({
        type: 'warning',
        message: 'Warning',
        description: res.data?.msg || 'Warning.',
      });
    }
  };

 
  const handleRadioChange = (event) => {
    const pdfId = event.target.value;
    setSelectedPdf(pdfId);

   
    axiosInstance.put(`/pdf-template/select/${pdfId}`)
      .then((res) => {
        handleResponse(res, 'Updated');
        getData();
      })
      .catch(() => {
        toast.open({
          type: 'error',
          message: 'Error',
          description: 'Failed to update PDF selection.',
        });
      });
  };

 
  const handleBoxClick = (pdfId) => {
    setPreviewPdfId(pdfId); 
    setShowTemplate(true); 
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className='w-full h-[clac(100vh-180px)] overflow-y-auto flex justify-center items-center gap-4'>
      <div className='w-[40%] h-full bg-white rounded-md'>
        <div className="p-9 border rounded-lg w-[96%] mx-auto h-auto my-7">
          <h2 className="text-xl font-semibold text-center mb-4">Select a PDF Template</h2>
          <div className="flex justify-center items-center gap-8">
          
            <div className='grid items-center gap-2'>
              <input
                type="radio"
                value="1"
                checked={selectedPdf === '1'}
                onChange={handleRadioChange}
                className="mr-2"
              />
              <div
                className={`border-2 rounded-lg p-4 cursor-pointer ${previewPdfId === '1' ? 'border-blue-500' : 'border-gray-300'}`}
                onClick={() => handleBoxClick('1')} 
              >
                <label className="font-semibold text-sm">PDF Preview 1</label>
              </div>
            </div>

           
            <div className='grid items-start gap-2'>
              <input
                type="radio"
                value="2"
                checked={selectedPdf === '2'}
                onChange={handleRadioChange} 
                className="mr-2"
              />
              <div
                className={`border-2 rounded-lg p-4 cursor-pointer ${previewPdfId === '2' ? 'border-blue-500' : 'border-gray-300'}`}
                onClick={() => handleBoxClick('2')} 
              >
                <label className="font-semibold text-sm">PDF Preview 2</label>
              </div>
            </div>
          </div>
        </div>
      </div>

    
      <div className='w-[59%] h-full bg-white rounded-md'>
        {showTemplate && <TempletePdf selectedPdf={previewPdfId} />} 
      </div>
    </div>
  );
};

export default PdfTemplate;


