import React, { useState } from 'react'
import UseGetDataSearch from '../../../../Components/Hooks/useFetchDataBySearch';
import { CancelButton, FilterButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import SearchBar from '../../../../Components/Utils/SearchBar';
import { useFormik } from 'formik';
import * as yup from 'yup'
import CustomDataTable from '../../../../Components/Others/CustomDataTable';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

function RelatedGRNIndex() {
    const { grnIndexViewId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const dataFromView = location.state.data;
    const [page,setPage]=useState(1)
    const [pageCount,setPageCount]=useState(20)
    const [filter, setFilter] = useState(false);
    const [showFilterDialog, setShowFilterDialog] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [params, setParams] = useState({
        page: page,
        per_page: pageCount,
        search:"",
      });
      const [filterProps, setFilterProps] = useState({
        status:"",
  purchaseType:"",
  receivedDateFrom:"",
  receivedDateTo:"",
      });
      const closeFilterDialog = () => {
        setShowFilterDialog(false);
      };
    const { data, totalCount, getData} = UseGetDataSearch(`/grn/${dataFromView?.poId}/history`, params);
    console.log("hh",data);
    const clearFilter = ()=> {
        setParams({...params,status:"",
          purchaseType:"",
          receivedDateFrom:"",
          receivedDateTo:"",})
          setFilter(false)
          formik.values.status=""
          formik.values.purchaseType=""
          formik.values.receivedDateFrom=""
          formik.values.receivedDateTo=""
  
      }
      const defaultHeader = [
        {
          title: "YPR",
          width: "10%",
          field: "prRef",
          textAlign: "center",
          rowAlign: "center",
        },
        {
          title: "GRN No.",
          width: "10%",
          isLink: true,
          id: "id",
          link: '/store/grn/viewProduct/',
          field: "ref",
          textAlign: "center",
          rowAlign: "center",
        },
        
        {
          title: "PO No.",
          width: "10%",
          field: "poRef",
          textAlign: "center",
          rowAlign: "center",
        },
        {
          title: "Supplier",
          width: "10%",
          field: "vendorName",
          textAlign: "center",
          rowAlign: "center",
        },
        {
          title: "DN No.",
          width: "10%",
          field: "dnRef",
          textAlign: "center",
          rowAlign: "center",
        },
        {
          title: "Busy GRN No.",
          width: "10%",
          field: "busyGrnNo",
          textAlign: "center",
          rowAlign: "center",
        },
        {
          title: "Recvd Date",
          width: "10%",
          field: "receivedDate",
          type:"date",
          textAlign: "center",
          rowAlign: "center",
        },
        {
          title: "Status",
          width: "10%",
          field: "status",
          textAlign: "center",
          rowAlign: "center",
        },
      ];
      const availableHeaders = [
        {
          title: "YPR",
          width: "10%",
          field: "prRef",
          textAlign: "center",
          rowAlign: "center",
        },
        {
          title: "GRN No.",
          width: "10%",
          isLink: true,
          id: "id",
          link: '/store/grn/viewProduct/',
          field: "ref",
          textAlign: "center",
          rowAlign: "center",
        },
        
        {
          title: "PO No.",
          width: "10%",
          field: "poRef",
          textAlign: "center",
          rowAlign: "center",
        },
        {
          title: "Supplier",
          width: "10%",
          field: "vendorName",
          textAlign: "center",
          rowAlign: "center",
        },
        {
          title: "DN No.",
          width: "10%",
          field: "dnRef",
          textAlign: "center",
          rowAlign: "center",
        },
        {
          title: "Busy GRN No.",
          width: "10%",
          field: "busyGrnNo",
          textAlign: "center",
          rowAlign: "center",
        },
        {
          title: "Recvd Date",
          width: "10%",
          field: "receivedDate",
          type:"date",
          textAlign: "center",
          rowAlign: "center",
        },
        {
          title: "Status",
          width: "10%",
          field: "status",
          textAlign: "center",
          rowAlign: "center",
        },
      ];
      const formik = useFormik({
        enableReinitialize: true,
        initialValues: filterProps,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
          // email: yup.string().required('Email is required!'),
        }),
        onSubmit: async (value, { resetForm }) => {
          console.log("ffff",value);
          setParams({...params,status:value.status, purchaseType:value.purchaseType,receivedDateFrom:value.receivedDateFrom,receivedDateTo:value.receivedDateTo})
          closeFilterDialog();
          setFilter(true)
          setFilterProps({
            status:"",
  purchaseType:"",
  receivedDateFrom:"",
  receivedDateTo:"",
          });
        }
      })
    //   useEffect(() => {
    //     const result = data.filter(item => item.id !== poId);
    //     setFilteredData(result);
    //   }, [poId]);
    const onClose = () =>{
        navigate(-1);
    }
    const result = data.filter(item => item.id != grnIndexViewId);
    console.log("bbb",result);

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
        <div className="animate-scale-in w-[90%] h-[90vh] bg-slate-50 py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
            <div className='w-full flex justify-end mb-2'>
              <div className='w-5 h-5 rounded-sm flex justify-center items-center bg-red-400 text-white text-sm cursor-pointer' onClick={onClose}>X</div>
            </div>
            <div className='flex justify-end gap-2'>
      <SearchBar
              handleChange={(e) =>{
                setParams({ ...params, search: e.target.value, page:1 });
                setPage(1)
              }}
            />     
            {/* <FilterButton  handleClick={()=>setShowFilterDialog(true)} />
          {filter && <FilterButton  handleClick={()=>clearFilter()} label='Clear'/>} */}
        
      </div>
      <CustomDataTable 
        fetcher={getData}
        data={result} 
        availableHeaders={availableHeaders} 
        defaultHeader={defaultHeader}
        bodyHeight='max-h-[calc(100vh-260px)]'
        height='h-[calc(100vh-175px)]'
        total={totalCount}
        pageIndex={page}
        setPageIndex={setPage}
        dataCount={pageCount}
        setDataCount={setPageCount}
        title='Relatable GRN List'
        storageName="GrnRelatableTable"
        showFieldFalse={false}
        />
          </div>
          {showFilterDialog && (            
             <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
               <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
                 <h2 className="text-xl mb-4">Filter GRN</h2>
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="flex flex-col mb-4">
              
                    </div>
                   <div className="flex justify-end gap-2">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               </div>
             </div>
           )}
        </div>
  )
}

export default RelatedGRNIndex