import React from 'react'
import { Route } from 'react-router-dom'
import PrivateRoute from '../Services/PrivateRouter'
import MainSettings from '../Views/Main/MainSettings'
import ProductSettings from '../Views/Web/Settings/Products'
import { CompanyInfo } from '../Views/Web/Settings/CompanyInfo'
import MainMarketingSettings from '../Views/Web/Settings/Marketing/MainMarketing'
import MainProcurementSettings from '../Views/Web/Settings/Procurement/MainProcurement'
import MainSalesSettings from '../Views/Web/Settings/Sales/MainSales'
import MainPaymentsSettings from '../Views/Web/Settings/Payments/MainPayments'
import MainTasksSettings from '../Views/Web/Settings/Tasks/MainTasks'
import MainStoreSettings from '../Views/Web/Settings/Store/MainStore'
import MainShippingSettings from '../Views/Web/Settings/Shipping/MainShipping'
import MainMaintenanceSettings from '../Views/Web/Settings/Maintenance/MainMaintenance'
import MainUserRoleSettings from '../Views/Web/Settings/UserRole/MainUserRole'
import MainJobRoleSettings from '../Views/Web/Settings/JobRole/MainJobRole'
import { CreateUser, EditUser, MainUserSettings, MainUserSettingsIndex, ViewUser } from '../Views/Web/Settings/User'
import MainNotification from '../Views/Web/Settings/Notification/MainNotification'
import ReffrenceSettings from '../Views/Web/Settings/Reffrence'
import { CreateRole, EditRole, MainRole, MainRolesSettings, ViewRole } from '../Views/Web/Settings/Role'
import MainCurrencyAndVat from '../Views/Web/Settings/CurrencyAndVat/MainCurrencyAndVat'
import EditCompanyInfo from '../Views/Web/Settings/CompanyInfo/EditCompanyInfo'
// import SealAndSignature from '../Views/Web/Settings/SealAndSignature'
import MainSealPage from '../Views/Web/Settings/SealAndSignature/MainSealPage'
import MainStatusPermission from '../Views/Web/Settings/StatusPermission/MainStatusPermission'
import { Wrapper } from '../Components/Utils/roles'
import MainEmailServerdetails from '../Views/Web/Settings/EmailServerDetails/MainEmailServerdetails'
import MainTemplate from '../Views/Web/Settings/Template/MainTemplate'
import MainPdfTemplate from '../Views/Web/Settings/PDFTemplate/MainPdfTemplate'

function SettingsRoutes() {
  return (
    <Route path="/settings" element={
        <PrivateRoute>
          <MainSettings/>
        </PrivateRoute>
      }>
        <Route index element={ <Wrapper accessKey={"company_info_view"}  Component={CompanyInfo} />} />
        <Route path='company/edit' element={ <Wrapper accessKey={"company_info_edit"}  Component={EditCompanyInfo} /> } />
        <Route path='products' element={ <Wrapper accessKey={"product_view"}  Component={ProductSettings} />}/>
        <Route path='marketing' element={ <Wrapper accessKey={"marketing_view"}  Component={MainMarketingSettings} /> }/>
        <Route path='procurement' element={ <Wrapper accessKey={"procurement_view"}  Component={MainProcurementSettings} /> }/>
        <Route path='sales' element={ <Wrapper accessKey={"sales_view"}  Component={MainSalesSettings} />}/>
        <Route path='payments' element={ <Wrapper accessKey={"payment_view"}  Component={MainPaymentsSettings} /> }/>
        <Route path='tasks' element={ <Wrapper accessKey={"task_settings_view"}  Component={MainTasksSettings} />}/>
        <Route path='store' element={ <Wrapper accessKey={"store_view"}  Component={MainStoreSettings} /> }/>
        <Route path='shipping' element={ <Wrapper accessKey={"shipping_view"}  Component={MainShippingSettings} /> }/>
        <Route path='maintenance' element={ <Wrapper accessKey={"maintenance_view"}  Component={MainMaintenanceSettings} />}/>
        <Route path='user' element={ <MainUserSettings/>}>
          <Route index element={ <Wrapper accessKey={"user_view"}  Component={MainUserSettingsIndex} /> }/>
          <Route path='create' element={ <Wrapper accessKey={"user_create"}  Component={CreateUser} />} />
          <Route path='edit/:editUserId' element={ <Wrapper accessKey={"user_edit"}  Component={EditUser} />} />
          <Route path='view/:viewUserId' element={ <Wrapper accessKey={"user_view"}  Component={ViewUser} />} />
        </Route>
        <Route path='currency-vat' element={ <Wrapper accessKey={true}  Component={MainCurrencyAndVat} />}/>
        <Route path='emailserverdetails' element={ <Wrapper accessKey={true}  Component={MainEmailServerdetails} />}/>
        <Route path='emailserverdetails' element={ <Wrapper accessKey={true}  Component={MainEmailServerdetails} />}/>
        <Route path='emailserverdetails' element={ <Wrapper accessKey={true}  Component={MainEmailServerdetails} />}/>
        <Route path='template' element={ <Wrapper accessKey={true}  Component={MainTemplate} />}/>
        <Route path='pdfTemplate' element={ <Wrapper accessKey={true}  Component={MainPdfTemplate} />}/>


        <Route path='role' element={<MainRole/>}>
          <Route index element={ <Wrapper accessKey={"role_view"}  Component={MainRolesSettings} /> } />
          <Route path='create' element={ <Wrapper accessKey={"role_create"}  Component={CreateRole} /> } />
          <Route path='edit/:editRoleId' element={ <Wrapper accessKey={"role_edit"}  Component={EditRole} /> } />
          <Route path='view/:viewRoleId' element={ <Wrapper accessKey={"role_view"}  Component={ViewRole} /> } />
        </Route>
        <Route path='user-role' element={ <Wrapper accessKey={"user_role_view"}  Component={MainUserRoleSettings} /> }/>
        <Route path='job-role' element={ <Wrapper accessKey={true}  Component={MainJobRoleSettings} /> }/>
        <Route path='sealandsignature' element={ <Wrapper accessKey={true}  Component={MainSealPage} /> }/>
        <Route path='statuspermission' element={ <Wrapper accessKey={true}  Component={MainStatusPermission} />}/>
        <Route path='notifications' element={ <Wrapper accessKey={"notification_view"}  Component={MainNotification} /> }/>
        <Route path='reffrence' element={ <Wrapper accessKey={"reference_view"}  Component={ReffrenceSettings} /> }/>
      </Route>
  )
}

export default SettingsRoutes