import React, { useMemo, useRef, useState, forwardRef } from "react";
import { ErrorMessage } from "formik";
import ReactSelect from 'react-select';
import { mobileCode } from "../../assets/CountryCode";
import axiosInstance from "../../Services/axiosCommon";
import Select from "react-select";

export function NuInput(props) {
  let {
    name,
    placeholder,
    label,
    handleBlur,
    handleChange,
    formik,
    max,
    min,
    value,
    isRequired,
    type,
    onChange,disabled,
    width,
    color= "black",
    px= "6"
  } = props;
  const formikValues = formik ? formik.values[name] : "";
  const handleChangeFunc = formik ? formik.handleChange : handleChange;
  const formikErrors = formik && formik.errors ? formik.errors[name] : null;
  // console.log(max, "date");
  return (
    <div className={`px-${px} py-2 ${width}`}>
      <label htmlFor={name} className={`text-xs 2xl:text-sm text-${color}`}>
        {label}
        {isRequired ? <span className="text-red-500"> *</span> : ""}
      </label>
      <input disabled={disabled ? true : false}
          // id={name} 
        placeholder={placeholder}
        type={type}
        name={name}
        max={max || ""}
        min={min || ""}
        value={value || formikValues}
        onChange={onChange || handleChangeFunc}
        // onBlur={handleBlur}
        className={
          `text-xs 2xl:text-sm font-medium border w-full h-10 px-3 mt-2 rounded-[10px] outline-none ${disabled === true? "bg-slate-100 cursor-not-allowed":""}`
        }
        multiple
      />
      {formikErrors ? (
        <span className="text-xs font-semibold text-red-500">
          {formikErrors}
        </span>
      ) : null}
    </div>
  );
}


export function NuInputToggle(props) {
  let {
    name,
    placeholder,
    label,
    handleBlur,
    handleChange,
    formik,
    max,
    min,
    value,
    isRequired,
    type,
    onChange,disabled,
    width,
    color= "black",
    px= "6",
  } = props;
  const formikValues = formik ? formik.values[name] : "";
  const handleChangeFunc = formik ? formik.handleChange : handleChange;
  const formikErrors = formik && formik.errors ? formik.errors[name] : null;
  const [toggleEye,setToggleEye] = useState(false);
  // console.log(max, "date");
  return (
    <div className={`px-${px} py-2 ${width}`}>
      <label htmlFor={name} className={`text-xs 2xl:text-sm text-${color}`}>
        {label}
        {isRequired ? <span className="text-red-500"> *</span> : ""}
      </label>
      <div
        className={`${
          disabled === true ? "bg-slate-100 cursor-not-allowed" : ""
        } text-xs bg-white 2xl:text-sm font-medium border w-full h-10 px-3  mt-2 rounded-[10px] overflow-hidden flex justify-between items-center`}
      >
        <input
          disabled={disabled ? true : false}
          // id={name}
          placeholder={placeholder}
          type={toggleEye == true ? "text" : "password"}
          name={name}
          max={max || ""}
          min={min || ""}
          value={value || formikValues}
          onChange={onChange || handleChangeFunc}
          // onBlur={handleBlur}
          className={` outline-none flex-1 h-full ${
            disabled === true ? "bg-slate-100 cursor-not-allowed" : ""
          }`}
        />
        {toggleEye == true ? (
          <div
            className=" w-5 h-5   cursor-pointer "
            onClick={() => setToggleEye(!toggleEye)}
          >
            <svg
              width="20px"
              height="20px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0" />

              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              />

              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M9.60997 9.60714C8.05503 10.4549 7 12.1043 7 14C7 16.7614 9.23858 19 12 19C13.8966 19 15.5466 17.944 16.3941 16.3878M21 14C21 9.02944 16.9706 5 12 5C11.5582 5 11.1238 5.03184 10.699 5.09334M3 14C3 11.0069 4.46104 8.35513 6.70883 6.71886M3 3L21 21"
                  stroke="#048178"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />{" "}
              </g>
            </svg>
          </div>
        ) : (
          <div
            className=" w-5 h-5   cursor-pointer "
            onClick={() => setToggleEye(!toggleEye)}
          >
            <svg
              width="20px"
              height="20px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0" />

              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              />

              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M3 14C3 9.02944 7.02944 5 12 5C16.9706 5 21 9.02944 21 14M17 14C17 16.7614 14.7614 19 12 19C9.23858 19 7 16.7614 7 14C7 11.2386 9.23858 9 12 9C14.7614 9 17 11.2386 17 14Z"
                  stroke="#048178"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />{" "}
              </g>
            </svg>
          </div>
        )}
      </div>
      {formikErrors ? (
        <span className="text-xs font-semibold text-red-500">
          {formikErrors}
        </span>
      ) : null}
    </div>
  );
}

export function NuInputWithError(props) {
  let {
    name,
    placeholder,
    label,
    handleBlur,
    handleChange,
    formik,
    max,
    min,
    value,
    isRequired,
    type,
    onChange,disabled,
    width,
    color= "black",
    px= "6",
    ErrMessage
  } = props;
  const formikValues = formik ? formik.values[name] : "";
  const handleChangeFunc = formik ? formik.handleChange : handleChange;
  const formikErrors = formik && formik.errors ? formik.errors[name] : null;
  // console.log(max, "date");
  return (
    <div className={`px-${px} py-2 ${width}`}>
      <div className="flex">
      <label htmlFor={name} className={`text-xs 2xl:text-sm text-${color}`}>
        {label}
        {isRequired ? <span className="text-red-500"> *</span> : ""}
      </label>
      <p className="pl-2 text-xs font-semibold text-red-500 ">{ErrMessage}</p>
      </div>
      <input disabled={disabled ? true : false}
          // id={name} 
        placeholder={placeholder}
        type={type}
        name={name}
        max={max || ""}
        min={min || ""}
        value={value || formikValues}
        onChange={onChange || handleChangeFunc}
        // onBlur={handleBlur}
        className={
          `text-xs 2xl:text-sm font-medium border w-full h-10 px-3 mt-2 rounded-[10px] outline-none ${disabled === true? "bg-slate-100 cursor-not-allowed":""}`
        }
        multiple
      />
      {formikErrors ? (
        <span className="text-xs font-semibold text-red-500">
          {formikErrors}
        </span>
      ) : null}
      {/* <ErrorMessage name={name}> 
        {(errorMsg) => (
          <div className="pt-2 text-xs font-semibold text-red-500">
            {errorMsg}
          </div>
        )}
       </ErrorMessage> */}
    </div>
  );
}

export function NuInputCurrency(props) {
  let {
    name,
    placeholder,
    label,
    handleBlur,
    handleChange,
    formik,
    max,
    min,
    value,
    isRequired,
    type,
    onChange,disabled,
    width,
  } = props;
  const formikValues = formik ? formik.values[name] : "";
  const handleChangeFunc = formik ? formik.handleChange : handleChange;
  const formikErrors = formik && formik.errors ? formik.errors[name] : null;
  // console.log(max, "date");
  return (
    <div className={`py-2 ${width}`}>
      <label htmlFor={name} className="text-xs 2xl:text-sm">
        {label}
        {isRequired ? <span className="text-red-500"> *</span> : ""}
      </label>
      <input disabled={disabled ? true : false}
          // id={name} 
        placeholder={placeholder}
        type={type}
        name={name}
        max={max || ""}
        min={min || ""}
        value={value || formikValues}
        onChange={onChange || handleChangeFunc}
        // onBlur={handleBlur}
        className={
          `text-xs 2xl:text-sm font-medium border w-full h-10 px-3 mt-2 rounded-[10px] outline-none ${disabled == true? "bg-slate-100 cursor-not-allowed":""}`
        }
        multiple
      />
      {formikErrors ? (
        <span className="text-xs font-semibold text-red-500">
          {formikErrors}
        </span>
      ) : null}
      {/* <ErrorMessage name={name}> 
        {(errorMsg) => (
          <div className="pt-2 text-xs font-semibold text-red-500">
            {errorMsg}
          </div>
        )}
       </ErrorMessage> */}
    </div>
  );
}

export function SupplierNuInput(props) {
  let {
    name,
    placeholder,
    label,
    handleBlur,
    handleChange,
    formik,
    max,
    min,
    value,
    isRequired,
    type,
    onChange,disabled,
    width,
  } = props;
  const formikValues = formik ? formik.values[name] : "";
  const handleChangeFunc = formik ? formik.handleChange : handleChange;
  const formikErrors = formik && formik.errors ? formik.errors[name] : null;
  // console.log(max, "date");
  return (
    <div className={`pl-6 pr-3 py-2 ${width}`}>
      <label htmlFor={name} className="text-xs 2xl:text-sm">
        {label}
        {isRequired ? <span className="text-red-500"> *</span> : ""}
      </label>
      <input disabled={disabled ? true : false}
          // id={name} 
        placeholder={placeholder}
        type={type}
        name={name}
        max={max || ""}
        min={min || ""}
        value={value || formikValues}
        onChange={onChange || handleChangeFunc}
        // onBlur={handleBlur}
        className={
          `text-xs 2xl:text-sm font-medium border w-full h-10 px-3 mt-2 rounded-[10px] outline-none ${disabled == true? "bg-slate-100 cursor-not-allowed":""}`
        }
        multiple
      />
      {formikErrors ? (
        <span className="text-xs font-semibold text-red-500">
          {formikErrors}
        </span>
      ) : null}
      {/* <ErrorMessage name={name}> 
        {(errorMsg) => (
          <div className="pt-2 text-xs font-semibold text-red-500">
            {errorMsg}
          </div>
        )}
       </ErrorMessage> */}
    </div>
  );
}

export function NuInputSetting(props) {
  let {
    ref,
    name,
    placeholder,
    label,
    handleBlur,
    handleChange,
    formik,
    max,
    min,
    value,
    isRequired,
    type,
    onChange,disabled,
    width,
  } = props;
  const formikValues = formik ? formik.values[name] : "";
  const handleChangeFunc = formik ? formik.handleChange : handleChange;
  const formikErrors = formik && formik.errors ? formik.errors[name] : null;
  const focus = useRef('');
    const onFocus = () =>{
      focus?.current?.focus();
    }
  // console.log(max, "date");
  return (
    <div className={`flex items-center bg-white border border-gray-300 rounded-md shadow-sm mt-2`}>
      {/* <label htmlFor={name} className="text-xs 2xl:text-sm">
        {label}
        {isRequired ? <span className="text-red-500"> *</span> : ""}
      </label> */}
      <input
          // id={name}
        ref={focus} 
        placeholder={placeholder}
        type={type}
        name={name}
        max={max || ""}
        min={min || ""}
        value={value || formikValues}
        onChange={onChange || handleChangeFunc}
        // onBlur={handleBlur}
        className={
          `flex-grow outline-none bg-transparent text-gray-700 px-3 py-2`
        }
        multiple
      />
      {formikErrors ? (
        <span className="text-xs font-semibold text-red-500">
          {formikErrors}
        </span>
      ) : null}
      {/* <ErrorMessage name={name}> 
        {(errorMsg) => (
          <div className="pt-2 text-xs font-semibold text-red-500">
            {errorMsg}
          </div>
        )}
       </ErrorMessage> */}
             <button
                onClick={onFocus}
                class="bg-baseColor-primary text-white rounded-md px-3 py-2 hover:bg-green-800 transition-colors focus:outline-none"
                type="submit"
              >
                Add
              </button>
    </div>
  );
}

export function NuInputReffrence(props) {
  let {
    name,
    placeholder,
    label,
    handleBlur,
    handleChange,
    formik,
    max,
    min,
    value,
    isRequired,
    type,
    onChange,disabled,
    width,
  } = props;
  const formikValues = formik ? formik.values[name] : "";
  const handleChangeFunc = formik ? formik.handleChange : handleChange;
  const formikErrors = formik && formik.errors ? formik.errors[name] : null;
  // console.log(max, "date");
  return (
    <div className={`bg-white border border-gray-300 rounded-md shadow-sm mt-2`}>
      {/* <label htmlFor={name} className="text-xs 2xl:text-sm">
        {label}
        {isRequired ? <span className="text-red-500"> *</span> : ""}
      </label> */}
      <input
          // id={name} 
        placeholder={placeholder}
        type={type}
        name={name}
        max={max || ""}
        min={min || ""}
        value={value || formikValues}
        onChange={onChange || handleChangeFunc}
        // onBlur={handleBlur}
        className={
          `w-full h-8 outline-none bg-transparent text-gray-700 px-2`
        }
        multiple
      />
      {formikErrors ? (
        <span className="text-xs font-semibold text-red-500">
          {formikErrors}
        </span>
      ) : null}
      {/* <ErrorMessage name={name}> 
        {(errorMsg) => (
          <div className="pt-2 text-xs font-semibold text-red-500">
            {errorMsg}
          </div>
        )}
       </ErrorMessage> */}
             {/* <button
                class="bg-baseColor-primary text-white rounded-full px-3 py-2 hover:bg-green-600 transition-colors focus:outline-none"
                type="submit"
              >
                Add
              </button> */}
    </div>
  );
}

export function NuTextArea(props) {
  let {
    name,
    placeholder,
    label,
    formik,
    handleBlur,
    handleChange,
    isRequired,
    value,
    new_width="lg:w-[50%]",
  } = props;
  return (
    <div className={`relative px-6 py-2 ${new_width?new_width:"lg:w-[67%]"}`}>
      <label htmlFor={name} className="text-xs 2xl:text-sm">
        {label} {isRequired ? <span className="text-red-500"> *</span> : ""}
      </label>
      <textarea
        id={name}
        name={name}
        placeholder={placeholder}
        type="text"
        value={value || formik.values[name]}
        onChange={handleChange||formik.handleChange}
        onBlur={handleBlur}
        className={
          "text-input error text-xs 2xl:text-sm font-medium border w-full h-20 px-3 py-2 mt-2 rounded-[10px] outline-none"
        }
      ></textarea>
      {/* <ErrorMessage name={name}> */}
        {(errorMsg) => (
          <div className="pt-2 text-xs font-semibold text-red-500">
            {errorMsg}
          </div>
        )}
      {/* </ErrorMessage> */}
    </div>
  );
}

export function OnlyInput(props) {
  let { inputType, name, ClassName, placeholder, onChange } = props;
  return (
    <input type={inputType} className={ClassName} placeholder={placeholder} />
  );
}

export function NuDate(props) {
  const {
    name,
    placeholder,
    label,
    handleChange,
    formik,
    max,
    min,
    value,
    isRequired,
    onChange,disabled,
    width
  } = props;
  const formikValues = formik ? formik.values[name] : "";
  const handleChangeFunc = formik ? formik.handleChange : handleChange;
  const formikErrors = formik && formik.errors ? formik.errors[name] : null;
  return (
    <div className={`px-6  py-2 ${width}`}>
      <label htmlFor={name} className="text-xs 2xl:text-sm">
        {label}
        {isRequired ? <span className="text-red-500"> *</span> : ""}
      </label>
      <input disabled={disabled ? true : false}
          // id={name} 
        placeholder={placeholder}
        type="date"
        name={name}
        max={max || ""}
        min={min || ""}
        value={value || formikValues}
        onChange={onChange || handleChangeFunc}
        // onBlur={handleBlur}
        className={
          "text-xs 2xl:text-sm font-medium border w-full h-10 px-3 mt-2 rounded-[10px] outline-none"
        }
        // multiple
      />
      {formikErrors ? (
        <span className="text-xs font-semibold text-red-500">
          {formikErrors}
        </span>
      ) : null}
    </div>
  );
}



export function NuTime(props) {
  const {
    name,
    placeholder,
    label,
    handleChange,
    formik,
    max,
    min,
    value,
    isRequired,
    onChange,disabled,
    width
  } = props;
  const formikValues = formik ? formik.values[name] : "";
  const handleChangeFunc = formik ? formik.handleChange : handleChange;
  const formikErrors = formik && formik.errors ? formik.errors[name] : null;
  return (
    <div className={`px-6 py-2 ${width}`}>
      <label htmlFor={name} className="text-xs 2xl:text-sm">
        {label}
        {isRequired ? <span className="text-red-500"> *</span> : ""}
      </label>
      <input disabled={disabled ? true : false}
          // id={name} 
        placeholder={placeholder}
        type="time"
        name={name}
        max={max || ""}
        min={min || ""}
        value={value || formikValues}
        onChange={onChange || handleChangeFunc}
        // onBlur={handleBlur}
        className={
          "text-xs 2xl:text-sm font-medium border w-full h-10 px-3 mt-2 rounded-[10px] outline-none"
        }
        // multiple
      />
      {formikErrors ? (
        <span className="text-xs font-semibold text-red-500">
          {formikErrors}
        </span>
      ) : null}
    </div>
  );
}


export function NuDataTime(props) {
  const {
    name,
    placeholder,
    label,
    handleChange,
    formik,
    max,
    min,
    value,
    isRequired,
    onChange,disabled,
    width,
    defaultValues
  } = props;
  const formikValues = formik ? formik.values[name] : "";
  const handleChangeFunc = formik ? formik.handleChange : handleChange;
  const formikErrors = formik && formik.errors ? formik.errors[name] : null;
  return (
    <div className={`px-6 py-2 ${width}`}>
      <label htmlFor={name} className="text-xs 2xl:text-sm">
        {label}
        {isRequired ? <span className="text-red-500"> *</span> : ""}
      </label>
      <input disabled={disabled ? true : false}
          // id={name} 
        placeholder={placeholder}
        type="datetime-local"
        name={name}
        max={max || ""}
        min={min || ""}
        value={value || formikValues || defaultValues}
        onChange={onChange || handleChangeFunc}
        // onBlur={handleBlur}
        className={
          "text-xs 2xl:text-sm font-medium border w-full h-10 px-3 mt-2 rounded-[10px] outline-none"
        }
        // multiple
      />
      {formikErrors ? (
        <span className="text-xs font-semibold text-red-500">
          {formikErrors}
        </span>
      ) : null}
    </div>
  );
}



export function InputCheck({
  type,
  classname,
  placeholder,
  value,
  name,
  id,
  onchange,
  onclick,
}) {
  return (
    <div>
      <input
        type={type}
        className={classname}
        placeholder={placeholder}
        value={value}
        name={name}
        id={id}
        onChange={onchange}
        onClick={onclick}
      />
    </div>
  );
}

function Input({
  type,
  classname,
  placeholder,
  name,
  id,
  onchange,
  onclick,
  label,
  labelclassname,
  handlechange,
  customClass,
  formik,
}) {
  return (
    <div className={`${customClass}`}>
      <label className={`${labelclassname} text-xs 2xl:text-sm`}>{label}</label>
      <input
        type={type}
        className={`${classname} text-xs 2xl:text-sm font-medium`}
        placeholder={placeholder}
        value={formik.values[name] || ""}
        name={name}
        id={id}
        onChange={onchange}
        onClick={onclick}
        handleChange={handlechange}
      />
      {formik.errors[name] ? (
        <span className="text-xs font-semibold text-red-500">
          {formik.errors[name]}
        </span>
      ) : null}
    </div>
  );
}

export default Input;

export function NuSelect(props) {
  let { name, value, label, isRequired, handleChange, options, optionName } =
    props;
  return (
    <div className="relative px-6 py-2 lg:py-0 lg:w-6/12">
      <label htmlFor={name} className=" text-xs 2xl:text-sm">
        {label} {isRequired ? <span className="text-red-500"> *</span> : ""}
      </label>
      <select
        value={value}
        className={
          "text-xs 2xl:text-sm font-medium border w-full h-10 px-3 mt-2 rounded-3xl capitalize outline-none"
        }
        name={name}
        id={name}
        onChange={handleChange}
      >
        <option key={""}>Select {optionName}</option>
        {options}
      </select>
      <ErrorMessage name={name}>
        {(errorMsg) => (
          <div className="pt-2 text-xs font-semibold text-red-500">
            {errorMsg}
          </div>
        )}
      </ErrorMessage>
    </div>
  );
}

export const NuUpload = forwardRef ((props) => {
  let { 
    name, 
    label, 
    isRequired, 
    onChange, 
    // value, 
    width,
    accept="audio/*,video/*,image/*,.pdf,.doc,.docx,.ppt,.xlsx, .pptx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,text/comma-separated-values, text/csv, application/csv",
    multiple=false,
    ref
  } = props;
  return (
    <div className={`px-6 py-2 ${width}`}>
      <label htmlFor={name} className="text-xs 2xl:text-sm">
        {label} {isRequired ? <span className="text-red-500"> *</span> : ""}
      </label>

      <input
        ref={ref}
        id={name}
        name={name}
        onChange={onChange}
        type="file"
        accept={accept}
        multiple={multiple}
        className="mt-2 cursor-pointer block w-full text-sm text-slate-500
        file:mr-4 file:py-[9px] file:px-4 file:rounded-l-md
        file:border-0 file:text-sm file:font-semibold
        file:bg-gray-200 file:text-gray-700
        hover:file:bg-gray-400 file:cursor-pointer border rounded-[10px] h-10"
      />

    </div>
  );
})






export const NuMobileNumber = ({ label, name, nunCode, formik, onChange, placeholder, width, isRequired, disable, min, max,  FixPosition=false,
  PortalTarget= false }) => {
  const handleChange = (selectedOption) => {
    formik.handleChange({
      target: {
        value: selectedOption.value,
        name: nunCode,
      },
    });
  };

  const styles = {
    control: (provided) => ({
      ...provided,

      border: "1px solid #e5e5e5",
      outline: "none",
      borderRadius: "10px",
      padding: "1px 0",
      color: "#000",
      textAlign: "left",
      marginTop:"8px",
      // fontSize:'13px',
      // fontWeight: "500",
      // width: "250px",
      
    }),
  };

  return (
    <div className={`px-6 py-2 ${width}`}>
      <div className=''>
        <label className='text-xs 2xl:text-sm'>
          {label}
          {isRequired ? <span className="text-red-500">*</span> : ""}
        </label>
        <div className='flex items-center gap-1'>
          <ReactSelect
            className='w-28'
            styles={styles}
            options={mobileCode}
            value={mobileCode.find((i) => i.value == formik.values[nunCode]) || ""}
            onChange={handleChange}
            isDisabled={disable}
            placeholder={"00"}
            menuPosition={FixPosition==true?"fixed":"absolute"}
            menuPortalTarget={PortalTarget==true?document.body:""}
          />
          <input
            className='text-xs 2xl:text-sm font-medium border w-full h-10 px-3 mt-2 rounded-[10px] outline-none'
            name={name}
            placeholder={placeholder}
            value={formik.values[name] || ""}
            type='number'
            onChange={formik.handleChange||onChange}
            max={max}
            min={min}
            disabled={disable ? true : false}
          />
        </div>
        {(formik.errors[name] && <span className='text-xs font-semibold text-red-500'>{formik.errors[name]}</span>) ||
          (formik.values[name] && formik.values[nunCode] === "" && <span className='text-xs font-semibold text-red-500'>Select Country Code</span>)}
      </div>
    </div>
  );
};



export function NuInputCheck(props) {
  let {
    name,
    placeholder,
    label,
    handleBlur,
    handleChange,
    formik,
    max,
    min,
    value,
    isRequired,
    type,
    onChange,
    disabled,
    width,
    url=""
  } = props;

  const [hasError, setHasError] = useState(false);
  const [wasSuccess, setWasSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [timeoutId, setTimeoutId] = useState(null); // State to hold timeout ID

  const formikValues = formik ? formik.values[name] : "";
  const handleChangeFunc = formik ? formik.handleChange : handleChange;
  const formikErrors = formik && formik.errors ? formik.errors[name] : null;

  useMemo(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    if (formikValues !== "") {
      const newTimeoutId = setTimeout(() => {
        checkRefNumber(formikValues);
      }, 1000);
      
      setTimeoutId(newTimeoutId);
    } else {
      setHasError(false);
      setWasSuccess(false);
      setMessage("");
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [formikValues]);

  function checkRefNumber(value) {
    axiosInstance.get(`${url}=${value}`).then(res => {
      if (res.data?.status) {
        setWasSuccess(true);
        setHasError(false);
        setMessage("");
      } else {
        setWasSuccess(false);
        setHasError(true);
        setMessage(res.data?.msg);
      }
    }).catch(err => {
      setHasError(true);
      setWasSuccess(false);
    });
  }

  return (
    <div className={`px-6 py-2 ${width}`}>
      <label htmlFor={name} className="text-xs 2xl:text-sm">
        {label}
        {isRequired ? <span className="text-red-500"> *</span> : ""}
        {wasSuccess ? <div className="w-3 h-3 inline-block ml-4 bg-green-400 rounded-full"></div> : null}
        {hasError ? <div className="w-3 h-3 inline-block ml-4 bg-red-400 rounded-full"></div> : null}
      </label>
      <input
        disabled={disabled ? true : false}
        // id={name} 
        placeholder={placeholder}
        type="text"
        name={name}
        max={max || ""}
        min={min || ""}
        value={value || formikValues}
        onChange={onChange || handleChangeFunc}
        // onBlur={handleBlur}
        className={
          `text-xs 2xl:text-sm font-medium border w-full h-10 px-3 mt-2 rounded-[10px] outline-none ${disabled == true ? "bg-slate-100 cursor-not-allowed" : ""}`
        }
      />
      {formikErrors ? (
        <span className="text-xs font-semibold text-red-500">
          {formikErrors}
        </span>
      ) : null}
      {
        hasError ? <span className="text-xs font-semibold text-red-500"> {message ? message : `${label} was already exist !`}</span> : null
      }
    </div>
  );
}



export function NuChatArea(props) {
  let {
    name,
    formik,
    handleBlur,
    handleChange,
    value,
    placeholder
  } = props;

  const adjustTextareaHeight = (e) => {
    const textarea = e.target;
    textarea.style.height = 'auto';
    const maxHeight = 65;
    const height = Math.min(textarea.scrollHeight, maxHeight);
    textarea.style.height = `${height}px`;
    textarea.style.overflowY = height >= maxHeight ? 'scroll' : 'hidden';
  };

  return (
    <div className="relative px-1 py-2 lg:w-full">
      <textarea
        id={name}
        name={name}
        placeholder={placeholder}
        type="text"
        value={value || formik.values[name]}
        onChange={handleChange || formik.handleChange}
        onBlur={handleBlur}
        className={
          " text-xs  border w-full px-3 py-1 mt-2 rounded-[10px] outline-none"
        }
        style={{ maxHeight: '65px' }}
        onInput={adjustTextareaHeight}
      ></textarea>
    </div>
  );
}



export function FormikSelect({
  classname,
  labelclassname,
  name,
  placeholder,
  onChange,
  formik,
  label,
  Options = [], // Default to empty array if not provided
  disabled = false,
  customClass,
  px = "6",
  isRequired,
  width,
}) {
  const options = Array.isArray(Options) ? Options : [];

  const selectedOption = options.find((i) => i.value === formik?.values?.[name]);

  const handleChange = (selectedOption) => {
    formik?.setFieldValue(name, selectedOption ? selectedOption.value : '');
  };

  const styles = {
    control: (provided) => ({
      ...provided,
      border: "1px solid #e5e5e5",
      outline: "none",
      borderRadius: "10px",
      padding: "1px 0",
      color: "#000",
      textAlign: "left",
      fontWeight: "500",
      width: "100%",
      zIndex: 0,
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "8px 12px",
      fontSize: "12px",
      [`@media (min-width: 640px)`]: {
        fontSize: "12px",
      },
      [`@media (min-width: 768px)`]: {
        fontSize: "12px",
      },
      [`@media (min-width: 1024px)`]: {
        fontSize: "12px",
      },
      [`@media (min-width: 1536px)`]: {
        fontSize: "14px",
      },
      color: state.isSelected ? '#fff' : '#000',
      backgroundColor: state.isSelected ? '#2563EB' : '#fff',
      ':hover': {
        color: '#fff',
        backgroundColor: '#60A5FA',
      },
    }),
  };

  return (
    <div className={`${width} relative pl-5 py-2`}>
      <label className={`${labelclassname} text-xs 2xl:text-sm`}>
        {label}
        {isRequired ? <span className="text-red-500"> *</span> : ""}
      </label>
      <div className="mt-2">
        <Select
          isDisabled={disabled}
          options={options}
          value={selectedOption || ""}
          styles={styles}
          menuPortalTarget={document.body}
          className={`${classname} text-xs 2xl:text-sm mt-2 font-medium ${disabled ? "bg-slate-100 cursor-not-allowed" : ""}`}
          name={name}
          onChange={onChange || handleChange}
          placeholder={placeholder}
        />
      </div>
      {formik?.errors?.[name] ? (
        <span className="text-xs font-semibold text-red-500">
          {formik.errors[name]}
        </span>
      ) : null}
    </div>
  );
}


