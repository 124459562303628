import React from 'react'
import { TextMediumdS, TextMediumSM, TextMediumXS } from '../../../../../Components/Text/MediumText';
import UseGetDashBoardData from '../../../../../Components/Hooks/useDashboardData';

function Returns() {
    const { data, loading } = UseGetDashBoardData(`/dashboard/returnDetails`);
  
   
 
    return (
     <div className="col-span-2 bg-white h-[40vh] p-4  pb-10  rounded-lg shadow-lg overflow-hidden mb-5">
     <div className="w-full  px-1 py-2 rounded-md ">
        <TextMediumSM content={"Returns"} color="text-[#4F4768]" />
        <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mt-2"></div>
         
        </div>
  
        {loading ? (
          <div className="p-4 max-w-sm w-full flex flex-col justify-center items-center h-full">
            <div className="animate-pulse flex space-x-4 w-full">
              <div className="flex-1 space-y-3 py-1">
                <div className="h-3 bg-slate-200 rounded"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-3 bg-slate-200 rounded col-span-2"></div>
                    <div className="h-3 bg-slate-200 rounded col-span-1"></div>
                  </div>
                  <div className="h-3 bg-slate-200 rounded"></div>
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-3 bg-slate-200 rounded col-span-1"></div>
                    <div className="h-3 bg-slate-200 rounded col-span-2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
         
  
      <div className="w-full h-[89%] overflow-y-scroll  pb-4">
        <table className="w-full">
        <thead className="sticky top-0 bg-white shadow-sm">
 
            <tr>
              <th className="text-left px-2 "><TextMediumSM content="Company" /></th>
              <th className="text-left px-2 "><TextMediumSM content="Type" /></th>
              <th className="text-left px-2 "><TextMediumSM content="Status" /></th>

              
            </tr>
          </thead>
          <tbody>
                            {data && data.map((item, index) => (
                <tr className="odd:bg-white even:bg-slate-50 " key={index}>
                  <td className="px-2 py-2"><TextMediumdS content={item?.companyName || "N/A"} /></td>
                  <td className="px-2 py-2"><TextMediumdS content={item?.type || "N/A"} /></td>
                  <td className="px-2 py-2"><TextMediumdS content={item?.status || "N/A"} /></td>
                
                </tr>
              ))}
            </tbody>
        </table>
      </div>
    )}
  </div>
 );
 }
 

export default Returns