import React, { useState, useEffect } from 'react';
import axiosInstance from '../../Services/axiosCommon';

const UseGetDataSearch = (url, params=null) => {
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [credit, setCredit] = useState(0);
  const [advance, setAdvance] = useState(0);
  const [dataLoading, setDataLoading] = useState(true);
  const [total, setTotal] = useState(0);


  async function getData() {
    setDataLoading(true);
    try {
      const res = await axiosInstance.get(url, { params });
      params?.page!=="" && params?.pageCount!==""? setData(res?.data?.data?.data || []) : setData(res?.data?.data || []) ;
      params?.page!=="" && params?.pageCount!=="" ? setTotalCount(res?.data?.data?.total || 0) : setTotalCount(0)
      res.data?.credit && setCredit(res.data?.credit)
      res.data?.advance && setAdvance(res.data?.advance)
      setTotal(res?.data?.data?.total || 0)
      setDataLoading(false);
      
    } catch (error) {
      console.log(error);
      setDataLoading(false);
    }
  }
  useEffect(() => {
    getData();
  }, [url, JSON.stringify(params)]);

  return { data, totalCount , credit, advance, getData , dataLoading,total };
};

export default UseGetDataSearch;