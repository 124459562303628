import React from 'react'
import { TextMediumdS, TextMediumSM, TextMediumXS } from '../../../../../Components/Text/MediumText';
import UseGetDashBoardData from '../../../../../Components/Hooks/useDashboardData';
import capitalizeFirstLetter from '../../../../../Components/Utils/Funcs';

function OrdersByValueProDash() {
  const { data, loading } = UseGetDashBoardData('/dashboard/top-orders-by-value');
  const dataDummy = data?.top_orders_by_value?.map(product => ({
    supplierName: product.supplier,
    orderQty: product.order_qty,
    value:product.price,
  })) || [];
 
  return (
    <div className="col-span-2 bg-white h-[40vh] w-full p-4  rounded-lg drop-shadow-lg mb-4">
      <TextMediumSM content={"Order by value"} color='text-[#4F4768]' />
      <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mt-2"></div>

         {loading ? (
        <div className="flex justify-center items-center h-full">
          <div className="animate-pulse space-y-4 w-full">
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
          </div>
        </div>
      ) : (
        <div className="w-full h-[89%] overflow-y-scroll  py-2">
        <table className="w-full">
          <thead className="sticky top-0 bg-white shadow-sm">
            <tr>
              <th className="text-left px-2 py-1"><TextMediumSM content="Supplier Name" /></th>
              <th className="text-center px-2 py-1"><TextMediumSM content="Order Qty" /></th>
              <th className="text-left px-2 py-1"><TextMediumSM content="Value" /></th>

            </tr>
          </thead>
          <tbody>
          {dataDummy.map((value, index) => {
                const [currency, numericValue] = (value.value || "N/A").split(" ");
                const formattedValue = numericValue
                  ? Number(numericValue).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                  : "N/A";

                return (
                  <tr className="odd:bg-white even:bg-slate-50" key={index}>
                    <td className="px-2 py-2"><TextMediumdS content={capitalizeFirstLetter(value.supplierName || "N/A")} /></td>
                    <td className="px-2 py-2 text-center"><TextMediumdS content={value.orderQty || "N/A"} /></td>
                    <td className="px-2 py-2 text-left">
                      <TextMediumdS 
                        content={`${currency || ""} ${formattedValue}`} 
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
        
      )}
    </div>
  )
}

export default OrdersByValueProDash;