import { useEffect, useState } from "react";
import UseGetData from "../../../../Components/Hooks/useFetchData";
import SearchBar from "../../../../Components/Utils/SearchBar";
import {
  CancelButton,
  CreateButton,
  FilterButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import DataTable from "../../../../Components/Others/DataTable";
import { Link } from "react-router-dom";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import MoreOptions from "../../../../Components/Utils/MoreOptions";
import CustomDataTable from "../../../../Components/Others/CustomDataTable";
import { useFormik } from "formik";
import * as yup from 'yup';
import UseGetDataSearch from "../../../../Components/Hooks/useFetchDataBySearch";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import UseCatSubCatDropDown from "../../../../Components/Hooks/useCategoryDropDown";
import ModalContainer from "../../../../Components/Others/ModalContainer";
import MultiEditRequest from "./MultiEditRequest";
import { GetAccess } from "../../../../Components/Utils/roles";
import useFetchMDIndex from "../../../../Components/Hooks/useFetchMDIndex";

function RequestIndex() {
 
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(20);
  const [filter, setFilter] = useState(false);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const {value:brand} = useFetchMDIndex(`/dropdown/dropdown-details/`,'brand_item',showFilterDialog)
  const {value:Manufacture} = useFetchMDIndex(`/dropdown/dropdown-details/`,'Manufacture_item',showFilterDialog)
  const {value:Unit} = useFetchMDIndex(`/dropdown/dropdown-details/`,'Unit_item',showFilterDialog)

  const [filterProps, setFilterProps] = useState({
    category:"",
    subCategory:"",
    brand:"",
    manufacturer:"",
    unit:""
  });
  const [params, setParams] = useState({
    page: page,
    per_page: pageCount,
    search:"",
    category:"",
    subCategory:"",
    brand:"",
    manufacturer:"",
    unit:""
  });

  const clearFilter = ()=> {
    setParams({...params, category:"",
      subCategory:"",
      brand:"",
      manufacturer:"",
      unit:""})
      setFilter(false)
      formik.values.category=""
      formik.values.brand=""
      formik.values.subCategory=""
      formik.values.manufacturer=""
      formik.values.unit=""
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterProps,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      console.log("ffff",value);
      setParams({...params,category:value.category, subCategory:value.subCategory,brand:value.brand,manufacturer:value.manufacturer,unit:value.unit})
      closeFilterDialog();
      setFilter(true)
      setFilterProps({
        category:"",
        subCategory:"",
        brand:"",
        manufacturer:"",
       
        unit:""
      });
    }
  })

  const { data, totalCount, getData ,dataLoading} = UseGetDataSearch("/requests/",params );

  const defaultHeaders = [
    // {
    //   title: "ID",
    //   width: "5%",
    //   field: "requestId",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "Item",
      width: "10%",
      isLink: true,
      id: "requestId",
      link: '/store/requests/view/',
   
      field: "itemName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Request For",
      width: "10%",
      isLink: true,
      id: "requestId",
      link: '/store/requests/view/',
      field: "requestFor",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Purpose",
      width: "10%",
      field: "purpose",
      textAlign: "center",
      rowAlign: "center",
    },
   
    {
      title: "Quantity",
      width: "10%",
      field: "quantity",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Status",
      width: "10%",
      field: "status",
      textAlign: "center",
      rowAlign: "center",
    },
    // {
    //   title: "Comments",
    //   width: "10%",
    //   field: "comments",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents:{
        // fetcher:getData,
        id:"requestId",
        removeValue:"requestFor",
        url:`/requests/`,
        moduleName:"Requests",
        from:`/store/requests`,
        editLink:"/store/requests/edit/",
        viewLink:"/store/requests/view/"
      }
    },
  ];
  const availableHeaders = [
    {
      title: "ID",
      width: "5%",
      field: "requestId",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Item",
      width: "10%",
      isLink: true,
      id: "requestId",
      link: '/store/requests/view/',
   
      field: "itemName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Request For",
      width: "10%",
      isLink: true,
      id: "requestId",
      link: '/store/requests/view/',
      field: "requestFor",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Purpose",
      width: "10%",
      field: "purpose",
      textAlign: "center",
      rowAlign: "center",
    },
   
    {
      title: "Quantity",
      width: "10%",
      field: "quantity",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Status",
      width: "10%",
      field: "status",
      textAlign: "center",
      rowAlign: "center",
    },
    // {
    //   title: "Comments",
    //   width: "10%",
    //   field: "comments",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "Is Returnable",
      width: "10%",
      field: "isReturnable",
      isCondition: true,
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Approved By",
      width: "10%",
      field: "approvedByName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Issueed By",
      width: "10%",
      field: "issueedByName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Prepared By",
      width: "10%",
      field: "preparedByName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Reviewed By",
      width: "10%",
      field: "reviewedByName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents:{
        // fetcher:getData,
        id:"requestId",
        removeValue:"requestFor",
        url:`/requests/`,
        moduleName:"Requests",
        from:`/store/requests`,
        editLink:"/store/requests/edit/",
        viewLink:"/store/requests/view/"
      }
    },
  ];
  useEffect(()=>{
    setParams({...params,page:page,per_page:pageCount})
   },[page,pageCount])
  const closeFilterDialog = () => {
    setShowFilterDialog(false);
  };
  const { category: itemsCategory, subCategory: itemsSubCategory } = UseCatSubCatDropDown('/category/ItemCategory', "ItemCategory", formik?.values?.category);

  const [showEdit, setShowEdit] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [listofNumbers, setListofNumbers] = useState([])
  const [fullList, setFullList] = useState([])

  const selectOneFunction = (value) =>{ 
    let NewId;
    if(listofNumbers && listofNumbers.some(Item=>Item ===value)){
      NewId = listofNumbers.filter(Temp=> Temp !== value);
      setListofNumbers(NewId)
    }else{
      setListofNumbers([...listofNumbers,value])
    }
  }

  const selectMultipleFunction = (e) =>{ 
console.log(e.target.checked);
if(e.target.checked){
  setListofNumbers(fullList)
}else{
  setListofNumbers([])
}

  }

  useEffect(()=>{
    let tempArr = []
    data && data.forEach(i=>{
      console.log("I",i?.requestId);
      tempArr.push(i?.requestId)
    });
    setFullList(tempArr)
  },[data])

  return (
    <div className="w-full h-full px-4 py-2">
      <div className="w-full flex justify-between items-center">
        <Breadcrumb
          items={[
            { name: "Store" },
            {
              name: "Request",
            },
          ]}
        />
        <div className=" flex gap-2">
        <SearchBar
              handleChange={(e) =>{
                setParams({ ...params, search: e.target.value, page:1 });
                setPage(1)
              }}
            />    <FilterButton  handleClick={()=>setShowFilterDialog(true)} />
          {filter && <FilterButton  handleClick={()=>clearFilter()} label='Clear'/>}
        
          {/* <CreateButton label="Import" /> */}
          {GetAccess("requests_create") &&  <Link to="/store/requests/create">
            <CreateButton label="Request" />
          </Link>}
        </div>
      </div>

      <CustomDataTable
      fetcher={getData}
        data={data} 
        availableHeaders={availableHeaders} 
        defaultHeader={defaultHeaders}
        bodyHeight='max-h-[calc(100vh-260px)]'
        height='h-[calc(100vh-145px)]'
        total={totalCount}
        pageIndex={page}
        setPageIndex={setPage}
        dataCount={pageCount}
        setDataCount={setPageCount}
        title='Requests List'
        storageName="RequestTable"
        selectOneFunction={selectOneFunction}
        setShowDelete={setShowDelete}
        showDelete={showDelete}
        setShowEdit={setShowEdit}
        showEdit={showEdit}
        listofNumbers={listofNumbers}
        hasMultiEdit={true}
        selectMultipleFunction={selectMultipleFunction}
        fullList={fullList}
        getById="requestId" 
         editAccess="requests_edit"      
        deteleAccess="requests_delete" 
        dataLoading={dataLoading}
        />
          {showFilterDialog && (
             
             
             <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
               <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
                 <h2 className="text-xl mb-4">Filter Request</h2>
                 <form onSubmit={formik.handleSubmit}> 
                 <div className="flex flex-col mb-4">
                      <FormikSelectTwo 
                        label="Category"
                        placeholder="Choose"
                        Options={itemsCategory}
                        formik={formik}
                        name="category"
                        width="w-full"
                      />
                       <FormikSelectTwo
              type="text"
              label="Sub-Category"
              placeholder="Sub-Category"
              Options={itemsSubCategory}
              formik={formik}
              name="subCategory"
              width="w-full "
            />
                      
              <FormikSelectTwo
                type="text"
                label="Unit"
                placeholder="Choose"
                Options={Unit}
                formik={formik}
                name="unit"
                width="w-full "
              />
             <FormikSelectTwo
                type="text"
                label="Manufaturer"
                placeholder="Choose"
                Options={Manufacture}
                formik={formik}
                name="manufaturer"
                width="w-full "
              />
               <FormikSelectTwo
                type="text"
                label="Brand"
                placeholder="Choose"
                Options={brand}
                formik={formik}
                name="brand"
                width="w-full "
              />
                    </div>
                   <div className="flex justify-end gap-2">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               </div>
             </div>
           )}
                              {showEdit && (<MultiEditRequest data={listofNumbers} getData={getData} onClose={()=>setShowEdit(false)}/>)}
        <ModalContainer
                visiable={showDelete}
                title="Warning"
                content={`Are you sure, All selected datas will be removed from Purchase Requests List!`}
                handleClick={() =>{}}
                closeModal={() => setShowDelete(false)}
            />
    </div>
  );
}

export default RequestIndex;
