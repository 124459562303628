import { TextMediumSM } from "../../../../../Components/Text/MediumText"
import ApprovalStatus from "./ApprovalStatus";
import CustomerDiscount from "./CustomerDiscount";
import PaymentStatus from "./PaymentStatus";
import ShippingMethod from "./ShippingMethod";
import StatusSalesorder from "./StatusSalesorder";
import TermsAndConditions from "./TermsAndConditions";

const SalesOrder = () =>{
    return(
        <>
        <TextMediumSM content="Sales Order" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
         {/* <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <ApprovalStatus/>
         </div> */}
         {/* <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <CustomerDiscount/>
         </div> */}
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <PaymentStatus/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <ShippingMethod/>
         </div>

         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <TermsAndConditions/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <StatusSalesorder/>
         </div>
         
         </div>
         </>
    )
}

export default SalesOrder;