import React, { useState } from "react";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import { Link } from "react-router-dom";
import Dashboard from "../../../../Components/Icons/Dasboard";
import { useFormik } from "formik";
import useAddFormData from "../../../../Components/Hooks/useAddFormData";
import * as yup from "yup";
import {
  NuDataTime,
  NuInput,
  NuTextArea,
  NuDate,
} from "../../../../Components/Utils/Input";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import UseDropDown from "../../../../Components/Hooks/useDropDown";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import DropDownAddMetaData from "../../../../Components/Utils/selectAddMetaData";

function CreateEquipments() {
  const currentDate = new Date().toISOString().split('T')[0];
  const {value:EquipmentType ,getData:refEquipmentType} = useFetchMataData(`/dropdown/dropdown-details/`,'EquipmentType')
  const {value:EquipmentBrand ,getData:refEquipmentBrand} = useFetchMataData(`/dropdown/dropdown-details/`,'EquipmentBrand')
  const {value:EquipmentManufacturer,getData:refEquipmentManufacturer} = useFetchMataData(`/dropdown/dropdown-details/`,'EquipmentManufacturer')
  const {value:EquipmentUnit,getData:refEquipmentUnit} = useFetchMataData(`/dropdown/dropdown-details/`,'EquipmentUnit')
  const {value:EquipmentWarrentyPeriod,getData:refEquipmentWarrentyPeriod} = useFetchMataData(`/dropdown/dropdown-details/`,'EquipmentWarrentyPeriod')

  const [image, setImage] = useState({ preview: "", raw: "" });
  // const [division, setDivision] = useState([]);
  const { Oncancel, addFormData, contextHolder, isLoading } = useAddFormData();
  const { data: users } = UseDropDown("/user/drop-down");
  // const [loading, setLoading] = useState(false);

  const [createNew, setCreateNew] = useState({
    equipmentType: "",
    serialNo: "",
    brand: "",
    manufacturer: "",
    description: "",
    unit: "",
    quantity: "",
    // "condition" : "test",
    warrentyExpDate: currentDate || "",
    warrentyPeriod: "",
    calibReq: "",
    calibExpDate: currentDate || "",
    comments: "",
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      serialNo: yup.string().required('Serial No is required!'),

      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      // setLoading(true); 
      addFormData("/equipments/new-equipment", value, resetForm);
      // setLoading(false)
    },
  });
  return (
    <div className="w-full h-full py-2 px-4 ">
    <div className="flex justify-between ">
    <Breadcrumb
          items={[
            {
              name: "Equipments",
              link: "/maintenance/equipment",
            },
            {
              name: "Create Equipment",
            },
          ]}
        />
        <BackButton />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden mt-4">
          <div className=" w-full h-full flex py-2 justify-start content-start flex-wrap  overflow-y-auto">
          <DropDownAddMetaData
                label="Equipment Type"
                placeholder="Choose"
                Options={EquipmentType}
                formik={formik}
                name="equipmentType"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refEquipmentType}
                displayProperty={"Equipment Type"}
                propertyName={"EquipmentType"}
              />
        
            <NuInput
              type="number"
              label="Serial No"
              placeholder="Serial No"
              formik={formik}
              name="serialNo"
              isRequired={true}

              width="w-full md:w-1/2 lg:w-1/3"
            />
             <DropDownAddMetaData
                label="Brand"
                placeholder="Choose"
                Options={EquipmentBrand}
                formik={formik}
                name="brand"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refEquipmentBrand}
                displayProperty={"Brand"}
                propertyName={"EquipmentBrand"}
              />
          
               <DropDownAddMetaData
                label="Manufacturer"
                placeholder="Choose"
                Options={EquipmentManufacturer}
                formik={formik}
                name="manufacturer"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refEquipmentManufacturer}
                displayProperty={"Manufacturer"}
                propertyName={"EquipmentManufacturer"}
              />
           
            {/* <NuInput
              type="text"
              label="Description"
              placeholder="Description"
              formik={formik}
              name="description"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
             <DropDownAddMetaData
                label="Unit"
                placeholder="Choose"
                Options={EquipmentUnit}
                formik={formik}
                name="unit"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refEquipmentUnit}
                displayProperty={"Unit"}
                propertyName={"EquipmentUnit"}
              />
            
           

            <NuInput
              type="number"
              label="Quantity"
              placeholder="Quantity"
              formik={formik}
              name="quantity"
              width="w-full md:w-1/2 lg:w-1/3"
            />

            <NuDate
              label="Warrenty Expiration Date"
              placeholder="Warrenty Expiration Date"
              formik={formik}
              name="warrentyExpDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <DropDownAddMetaData
                label="Warrenty Period"
                placeholder="Choose"
                Options={EquipmentWarrentyPeriod}
                formik={formik}
                name="warrentyPeriod"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refEquipmentWarrentyPeriod}
                displayProperty={"Warrenty Period"}
                propertyName={"EquipmentWarrentyPeriod"}
              />
            
          
            <FormikSelectTwo
              label="Calibration Req"
              placeholder="Calibration Req"
              formik={formik}
              Options={[
                {
                  value: true,
                  label: "yes",
                },
                {
                  value: false,
                  label: "no",
                },
              ]}
              name="calibReq"
              width="w-full md:w-1/2 lg:w-1/3"
            />

            <NuDate
              label="Calibration ExpDate"
              placeholder="Calibration ExpDate"
              formik={formik}
              name="calibExpDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuTextArea
              label="Description"
              placeholder="Description"
              formik={formik}
              name="description"
              new_width="w-full md:w-2/3"

              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuTextArea
              label="Comments"
              placeholder="comments"
              formik={formik}
              name="comments"
              width="w-full md:w-1/2 lg:w-1/3"
            />

<div className="w-full my-4 mt-8 ">
              <div className="flex justify-end gap-4">
               <CancelButton
                  handleClick={() => {
                    Oncancel(-1);
                  }}
                />
                <SubmitButton isLoading={isLoading}/>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default CreateEquipments;
