import React from 'react'
import { TextMediumdS, TextMediumSM, TextMediumXS } from '../../../../../Components/Text/MediumText';
import { useState } from 'react';
import UseGetDashBoardData from '../../../../../Components/Hooks/useDashboardData';
import UseGetDashBoardWithParams from '../../../../../Components/Hooks/useDashboardDataParams';
import capitalizeFirstLetter from '../../../../../Components/Utils/Funcs';

function CostSavingByProcurmentTeamProdash() {
    // const currentMonth = (new Date().getMonth() + 1).toString().padStart(2, '0');
    // const [selectedMonth, setSelectedMonth] = useState(currentMonth);     
    const [params, setParams] = useState({
      period:"monthly",
  
    });
    const [selectedPeriod, setSelectedPeriod] = useState( 'Monthly');
 
    const { data, loading } = UseGetDashBoardWithParams(`/dashboard/cost-save-by-procurement`,params);
    const hanChange=(e)=>{
      setParams({
        period:e.target.value
      })
      setSelectedPeriod(e.target.value)
    }
  
    const periods = [
      { value: 'monthly', option: 'Monthly' },
      { value: 'quarterly', option: 'Quarterly' },
      { value: 'halfyearly', option: 'Half Yearly' },
      { value: 'yearly', option: 'Yearly' },
    ];
    const dataDummy = data?.top_orders_by_value?.map(product => ({
    
      name: product.name,
      count:product.count,
      value: product.value,
      ordered_price: product.ordered_price,
      difference:product.difference
    })) || [];
     
  return (
    <div className="col-span-2 bg-white h-[40vh] w-full p-4  rounded-lg drop-shadow-lg mb-4">
     <div className="flex justify-between items-center mb-1">
      <p className="text-xs 2xl:text-sm text-[#4F4768] font-medium">Cost Saving by Procurment Team</p>
          <div className="flex items-center">
            <select
              value={selectedPeriod}
              onChange={hanChange}
              className="border rounded-md p-1 text-xs text-[#ACACAC]  font-normal"
            >
              {periods.map((period) => (
                <option key={period.value} value={period.value}>
                {period.option}
              </option>
              ))}
            </select>
          </div>
        </div>
        <div className="border-t border-[1px] border-slate-200/60 border-solid w-full"></div>

      {loading ? (
        <div className="flex justify-center items-center h-full">
          <div className="animate-pulse space-y-4 w-full">
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
          </div>
        </div>
      ) : (
        <div className="w-full h-[86%] overflow-y-scroll  pb-4">
        <table className="w-full">
          <thead className="sticky top-0 bg-white shadow-sm">
            <tr>
            <th className="text-left px-1 py-1"><TextMediumSM content="Name" /></th>
              <th className="text-center px-1 py-1"><TextMediumSM content="Count" /></th>
              <th className="text-left px-1 py-1"><TextMediumSM content="Value" /></th>
              <th className="text-left px-1 py-1"><TextMediumSM content="Ordered " /></th>
              <th className="text-left px-1 py-1"><TextMediumSM content="Difference" /></th>
          
            </tr>
          </thead>
          <tbody className=''>
          {dataDummy.map((value, index) => {
  // Split currency and numeric value for each relevant field
  const [valueCurrency, valueNumeric] = (value.value || "N/A").toString().split(" ");
  const [orderedCurrency, orderedNumeric] = (value.ordered_price || "N/A").toString().split(" ");
  const [differenceCurrency, differenceNumeric] = (value.difference || "N/A").toString().split(" ");

  // Format the numeric values, setting them to "N/A" if undefined
  const formattedValue = valueNumeric
    ? Number(valueNumeric).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    : "N/A";
  const formattedOrdered = orderedNumeric
    ? Number(orderedNumeric).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    : "N/A";
  const formattedDifference = differenceNumeric
    ? Number(differenceNumeric).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    : "N/A";

  return (
    <tr className="odd:bg-white even:bg-slate-50 " key={index}>
      <td className="px-1 py-2 "><TextMediumdS content={capitalizeFirstLetter(value?.name || "N/A")} /></td>
      <td className="px-1 py-2 text-center"><TextMediumdS content={value?.count || "N/A"} /></td>
      <td className="px-1 py-2"><TextMediumdS content={`${valueCurrency || ""} ${formattedValue}`} /></td>
      <td className="px-1 py-2"><TextMediumdS content={`${orderedCurrency || ""} ${formattedOrdered}`} /></td>
      <td className="px-1 py-2"><TextMediumdS content={`${differenceCurrency || ""} ${formattedDifference}`} /></td>
    </tr>
  );
})}

          </tbody>
        </table>
      </div>
      
      )}
    </div>
  )
}

export default CostSavingByProcurmentTeamProdash;