import React from 'react';

const CurrencyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      width="15"
      height="15"
      fill="gray"
    >
      <path d="M32 0C14.4 0 0 14.4 0 32c0 17.6 14.4 32 32 32s32-14.4 32-32S49.6 0 32 0zm0 60c-15.6 0-28-12.4-28-28S16.4 4 32 4s28 12.4 28 28-12.4 28-28 28z"/>
      <path d="M32 16c-8.8 0-16 7.2-16 16s7.2 16 16 16 16-7.2 16-16-7.2-16-16-16zm0 28c-6.6 0-12-5.4-12-12s5.4-12 12-12 12 5.4 12 12-5.4 12-12 12z"/>
      <path d="M27 22h-4v8h-4v4h4v8h4v-8h4v-4h-4v-8z"/>
    </svg>
  );
};

export default CurrencyIcon;
