import React, { useEffect, useState } from 'react';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import FormikSelectTwo from '../../../../Components/Utils/Select';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import { useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import UseEditData from '../../../../Components/Hooks/useEditData';
import * as yup from 'yup';
import { NuDate, NuInput, NuTextArea } from '../../../../Components/Utils/Input';
import Delete from '../../../../Components/Icons/Delete';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import useRfqSuppliers from './Hooks/UseRFQSuppliers copy';
import usePrProducts from './Hooks/UsePrProducts';
import axiosInstance from '../../../../Services/axiosCommon';
import { TextMediumBase } from '../../../../Components/Text/MediumText';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import UseDropDownNew from '../../../../Components/Hooks/useDDNew';
import Select from "react-select";
import NuAmount from '../../../../Components/Utils/NuAmount';
import useEditFormData from '../../../../Components/Hooks/useEditFormData';
import { useToast } from '../../../../Components/Others/ToastServices';
import Attachement from '../../../../Components/Utils/CustomAttrachmentupload';
const filetype = ["docx", "doc", "xlsx", "xls", "pdf", "txt", "pptx","mp4", "mov", "wmv", "avi", "flv", "mkv"];

function EditSupplierQuotations() {
  const {value:ApprovalStatus} = useFetchMataData(`/dropdown/dropdown-details/`,'ApprovalStatus_SQ')
  let { data: suppliers } = UseDropDown("/vendors/drop-down");

  const { value: Validity } = useFetchMataData(`/dropdown/dropdown-details/`, 'Validity_Supplierquotes');
  const { value: PaymentTerms } = useFetchMataData(`/dropdown/dropdown-details/`, 'PaymentTerms_Supplierqoutes');
  const { value: LeadTime } = useFetchMataData(`/dropdown/dropdown-details/`, 'LeadTime_Supplierqoutes');
  const { value: DeliveryTerms } = useFetchMataData(`/dropdown/dropdown-details/`, 'DeliveryTerms_Supplierqoutes');
  const { value: Availability } = useFetchMataData(`/dropdown/dropdown-details/`, 'Availability_Supplierqoutes');
  const { value: paymentMethod } = useFetchMataData(`/dropdown/dropdown-details/`, 'paymentMethod_supplierquotes');
  const { value: Status } = useFetchMataData(`/dropdown/dropdown-details/`, 'Status_Supplierqoutes');
  // const [loading, setLoading] = useState(false);

  let { data: Item, setData:NewSetData } = UseDropDownNew("/items/new-drop-down");
  const { data: PR } = UseDropDown('/PR/drop-down');
  // const { data: user } = UseDropDown('/user/drop-down');
  let { data: currency } = UseDropDown("/currency/drop-down");
  const [image, setImage] = useState([]);
  const [fileRemoveList, setFileRemoveList] = useState([]);

  const location = useLocation();
  const data = location.state.data;
  const from = location.state.from;
console.log("kkkkkk",data);

  const { Oncancel, editFormData, contextHolder, isLoading } =
  useEditFormData();
    const [dataa, setData] = useState(data);
  const [items, setItems] = useState([]);
  const [remove, setRemove] = useState([]);
  const [newItemData, setNewItemData] = useState({
    productId: "",
    quantity: "",
    price: "",
    landedCost:"",
    product_unit:""
  });
  const [missingProducts, setMissingProducts] = useState([]);
  const [prlist, setPrlist] = useState([]);

  const [createNew, setCreateNew] = useState({
    approvalStatus:data?.approvalStatus,
    dateReceived: data.dateReceived,
    deliverTerms: data.deliverTerms,
    paymentTerms: data.paymentTerms,
    paymentMethod: data.paymentMethod,
    totalPrice: data.totalPrice,
    vendorId: data.vendorId,
    prId: data.prId,
    rfqId: data.rfqId,
    ref: data.ref,
    availability: data.availability,
    validity: data.validity,
    specialNotes: data.specialNotes,
    leadTime: data.leadTime,
    comments: data.comments,
    removedProducts: [],
    price: data.price,
    landedCost: data.landedCost,
    filesToRemove: [],
    quantity: data.quantity,
    status:data.status,
    quoteDate:data.quoteDate,
    rfqRef: data.rfqRef,
    isDirectQuote:data?.isDirectQuote,
    currencyId: data?.currencyId,
    currencyName: data?.currencyName,
    purchaser:data?.purchaser,
  });

  const [createtNew, setCreatetNew] = useState({
   
    totalPrice: data.totalPrice || 0,
  });
 
  const handleDelete = (quotProductId, list) => {
  
    
    const updatedquoteProducts = prlist.filter(item => item.quotProductId !== quotProductId);
   console.log("testing", updatedquoteProducts);
   
   
    const deletedProduct = data?.quoteProducts.find(item => item.quotProductId == quotProductId);


    console.log("deleted pro", deletedProduct);
    
  
    if (deletedProduct) {
      const priceToRemove = deletedProduct.price || 0;
      const landedCostToRemove = deletedProduct.landedCost || 0;

      const quantityToRemove = deletedProduct.quantity || 0;
      const reduction = landedCostToRemove+priceToRemove * quantityToRemove;
  
      const newTotalPrice = formik.values.totalPrice - reduction;
  
      setPrlist(updatedquoteProducts)

      console.log("New List of Item",prlist);
      
  
      formik.setFieldValue('totalPrice', newTotalPrice);
  
      setRemove([...remove, quotProductId]);
    }
  }; 
   
  //    const handleDeleteItem = (indexToDelete, list) => {
  //   const updatedItems = items.filter((_, index) => index !== indexToDelete);
  //   setItems(updatedItems);
  //   formik.setFieldValue('totalPrice', formik.values.totalPrice - (list.landingCost+list.price * list.quantity));

  // };
  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  const handleImageRemove = () => {
    setImage({
      preview: "",
      raw: "",
    });
    setCreateNew((prevState) => ({ ...createNew, image: "" }));
    formik.values.image = "";
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
    //   supplierId: yup.string().required('Supplier is required'),
    //   validity: yup.string().required('Validity is required'),
    //   paymentTerm: yup.string().required('Payment Term is required'),
    //   leadTime: yup.string().required('Lead Time is required'),
    }),
    onSubmit: async (value, { resetForm }) => {
      // setLoading(true)
      delete value.price
      delete value.landedCost

      delete value.quantity
      console.log("values", prlist);

      let newItems = prlist.map((i) => ({ ...i, leadTime: i?.leadTime?.value||"" }))||[];
      console.log("submit value",newItems);
      let formData = new FormData();
      
      const valueWithItems = {
        ...value,
        products: newItems,
        removedProducts: remove,
        filesToRemove: fileRemoveList,
      };

      // console.log("formvalue",valueWithItems);
      formData.append("json_data", JSON.stringify(valueWithItems));
      if (selectedFile !== null) {
        selectedFile.forEach((file) => {
          formData.append("attachments", file);
        });
      } else {
        formData.append("attachments", []);
      }
      
      editFormData(`/supplier-quotations/edit-quotation/${data.id}`, formData, resetForm, from);
      // setLoading(false)
    }
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePathList, setFilePathList] = useState([]);
  const [removefilePathList, setRemoveFilePathList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const toast = useToast();

  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = [];
    files.map((item) => {
      if (item.size < maxFileSize) {
        fileArr.push(item);
      } else {
        toast.open({
          type: "warning",
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          // placement: "bottomRight",
        });
      }
    });
    let tempArr = [];
    setSelectedFile(fileArr);
    files.map((item) => {
      if (item.size < maxFileSize) {
        let fileName = item?.name;
        console.log("fname", fileName);
        let fileExtention = fileName.split(".").reverse()[0];
        if (filetype?.includes(fileExtention)) {
          tempArr.push(fileName);
        } else {
          let x = URL.createObjectURL(item);
          tempArr.push(x);
        }
      }
    });
    console.log(tempArr, "temp");
    setImage(tempArr);
  }

  useEffect(() => {
    if (
      data?.attachments !== null &&
      data?.attachments !== "None" &&
      data?.attachments !== "[]"
    ) {
      console.log(data.attachments, "ghjkllgd");
      setFilePathList(data?.attachments);
     
      setRemoveFilePathList(data?.attachments);
     
    }
  }, [data]);

  function UpdateAttachmentList(item) {
    let filterList = filePathList.filter((itemList) => itemList.file !== item);
    let removeFile = filePathList.find((itemList) => itemList.file === item);
    console.log("llll", filterList);
    setFileRemoveList([...fileRemoveList, removeFile.file]);
    setFilePathList(filterList);
  }

  function handleImageClick(img) {
    setSelectedImage(img);
    setIsModalOpen(true);
  }

  

  const handleAdd = (index) => {
    if (
      productForm.values.productId &&
      productForm.values.quantity &&
      productForm.values.price
    ) {
      const selectedProduct = Item.find(
        (i) => i.value === productForm.values.productId
      );
      let lead_time = {
        label: productForm.values.leadTime,
        value: productForm.values.leadTime,
      };
  
      // Calculate new sno based on the existing item
      let newSno;
      if (formik.values.isDirectQuote) {
        newSno = prlist.length + 1;
      } else {
        const baseSno = prlist[index].sno;
        const subIndex = prlist.filter(item => String(item.sno).startsWith(baseSno)).length;
        newSno = `${baseSno}${String.fromCharCode(64 + subIndex)}`; // 97 is ASCII code for 'a'
      }
  
      const newItem = {
        sno: newSno,
        productId: productForm.values.productId,
        leadTime: lead_time,
        price: parseFloat(productForm.values.price).toFixed(2),
        convPrice: parseFloat(productForm.values.price/(formik.values.exchangeRate>0?formik.values.exchangeRate:1)).toFixed(2),
        isAlternate: formik.values.isDirectQuote?false:true,
        product_name: selectedProduct.label,
        product_unit: selectedProduct.unit,
        landedCost: parseFloat(productForm.values.landedCost).toFixed(2),

        quantity: productForm.values.quantity,
      };


      if(formik.values.isDirectQuote){
        setPrlist([...prlist, newItem]);
      }else{
        let NewValue = prlist[index].sno||"";


        let preFilter = prlist.map(item => {
          let tempvalue = {...item}
          tempvalue.sno = `${item.sno}`
          return tempvalue;
        })
  
        let Filter = preFilter.filter(item => item.sno.includes(`${NewValue}`)).length||0;
        let newPrList = [...prlist];
        newPrList.splice(index+Filter, 0, newItem);
        setPrlist(newPrList);
        let updatedItems = Item.filter(
          (it) => it.value !== productForm.values.productId
        );
        setData(updatedItems);
      }

  
      // Clear the form fields
      productForm.values.productId = "";
      productForm.values.quantity = "";
      productForm.values.isAlternate = "";
      productForm.values.leadTime = "";
      productForm.values.price = "";
      productForm.values.convPrice = "";
      productForm.values.landedCost = "";

      if(formik.values.isDirectQuote===false){setShowForm(!showForm)};
    }
  };
  
  useEffect(() => {
    if (formik.values.exchangeRate > 0) {
      const updatedList = prlist.map((item) => ({
        ...item,
        convPrice: parseFloat(item.price / formik.values.exchangeRate).toFixed(2),
      }));
      
      // Assuming you want to set this updated list somewhere
      setPrlist(updatedList); // If you have a state to update prlist
    }
    }, [formik.values.exchangeRate]);

  // const getPr = (prId) => {
  //   if (prId !== "") {
  //     axiosInstance
  //       .get(`/PR/pr-products/${prId}`)
  //       .then(res => {
  //         let data = res.data.data;
  //         setPrlist(data);
  //       })
  //       .catch(err => {
  //         console.log(err, "err");
  //       });
  //   }
  // };

  // useEffect(() => {
  //   if (formik.values.prId) {
  //     getPr(formik.values.prId);
  //   }
  // }, [formik.values.prId]);

  // useEffect(() => {
  //   const quoteProductIds = dataa?.quoteProducts.map(item => item.productId);
  //   const missing = prlist
  //     .filter(prItem => !quoteProductIds.includes(prItem.productId))
  //     .map(item => ({
  //       value: item.productId,
  //       label: item.product_name,
  //       unit:item.product_unit
  //     }));
  //   setMissingProducts(missing);
  // }, [prlist, dataa?.quoteProducts]);

  
  const handlePriceChange = (e, index, isDataa = true) => {
    const value = e.target.value;
    if (isDataa) {
      const updatedDataa = { ...dataa };
      updatedDataa.quoteProducts[index].price = value;
      updatedDataa.quoteProducts[index].convPrice = value*parseFloat(formik.values.exchangeRate>0?formik.values.exchangeRate:1);
    setData(updatedDataa);
    } else {
      const updatedItems = [...items];
      updatedItems[index].price = value;
      updatedItems[index].convPrice = value/parseFloat(formik.values.exchangeRate>0?formik.values.exchangeRate:1);
    setItems(updatedItems);
    }
    updateTotalPrice();
  };
  const handleCostChange = (e, index, isDataa = true) => {
    const value = e.target.value;
    if (isDataa) {
      const updatedDataa = { ...dataa };
      updatedDataa.quoteProducts[index].landedCost = value;
      setData(updatedDataa);
    } else {
      const updatedItems = [...items];
      updatedItems[index].landedCost = value;
      setItems(updatedItems);
    }
    updateTotalPrice();
  };
  
  const handleQuantityChange = (index, newQuantity, isDataa = true) => {
    if (isDataa) {
      const updatedDataa = { ...dataa };
      updatedDataa.quoteProducts[index].quantity = newQuantity;
      setData(updatedDataa);
    } else {
      const updatedItems = [...items];
      updatedItems[index].quantity = newQuantity;
      setItems(updatedItems);
    }
    updateTotalPrice();
  };
  
  const updateTotalPrice = () => {
    const totalPrice = [...dataa.quoteProducts, ...items].reduce((total, item) => {
      return total + ((parseFloat(item.price)* parseFloat(item.quantity || 0)+parseFloat(item.landedCost)));
    }, 0);
    formik.setFieldValue('totalPrice', totalPrice);
  };
  

  
  const handleIncrement = (index, isDataa = true) => {
    const newQuantity = isDataa ? dataa.quoteProducts[index].quantity + 1 : items[index].quantity + 1;
    handleQuantityChange(index, newQuantity, isDataa);
  };

  const handleDecrement = (index, isDataa = true) => {
    const newQuantity = isDataa ? Math.max(1, dataa.quoteProducts[index].quantity - 1) : Math.max(1, items[index].quantity - 1);
    handleQuantityChange(index, newQuantity, isDataa);
  };

  const handleInputChange = (event, index, isDataa = true) => {
    const value = Math.max(Number(event.target.value), 0);
    handleQuantityChange(index, value, isDataa);
  };

  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };


  const [showForm, setShowForm] = useState(false);
  const [selectedProductIndex, setSelectedProductIndex] = useState(null);

  const toggleForm = (idx) => {
    setShowForm(!showForm);
    setSelectedProductIndex(idx);
  };
  const [prods, setProds] = useState({
    sno: "",
    productId: "",
    isAlternate: "",
    leadTime: "",
    quantity: "",
    price: "",
  });

  const productForm = useFormik({
    enableReinitialize: true,
    initialValues: prods,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({}),
    onSubmit: (value, { resetForm }) => {
      // const updatedPrlist = prlist;
      // updatedPrlist.push(value);
      // setPrlist(updatedPrlist);
      // resetForm();
    },
  });


  useEffect(() => {
    setPrlist(data?.quoteProducts)
  },[data])


  const handleLeadTimeChange = (value, index) => {
    const updatedItems = [...prlist];
    updatedItems[index].leadTime = value;
    setPrlist(updatedItems);
  };
  const [purchaserlist, setPurchaserlist] = useState([]);
 
 function getPurchaser(prId){
   axiosInstance.get(`/supplier-quotations/purchaser/drop-down/${prId}`)
   .then((response) => {
     let tempData =[]
     if(response.data){
       let x = response.data
 
       x.map((item) => {
       let y = {
         value: item.id,
         label: item.name,
       }
       tempData.push(y)
       })
 
       setPurchaserlist(tempData)
     }
   }).catch((error) => {})
  }
  useEffect(() => {
    if (formik.values.prId && formik.values.purchaserChecked===true) {
      getPurchaser(formik.values.prId);
    }
    // else if(formik.values.prId && (!formik.values.purchaserChecked)){
    //   getVendor(formik.values.prId);
    // }
  }, [formik.values.prId,formik.values.purchaserChecked]);
  const [supplierCurrency,setSupplierCurrency] = useState([]);

 function getSupplierCurrency(supId){
  axiosInstance.get(`/vendors/${supId}/currency`)
   .then((res)=>{
    console.log("bbb",res?.data?.data?.currencyLabel);
    setSupplierCurrency(res?.data?.data?.currencyLabel)
   }).catch((err)=>{
    console.log(err);
   })
 }
 useEffect(()=>{
  getSupplierCurrency(formik?.values?.vendorId)
},[formik?.values?.vendorId])

const[exChange,setExchage] = useState("")
function exchangeRate(id){
 axiosInstance
 .get(`/currency/${id}`)
 .then((res) => {
    //  console.log("currency",res?.data?.data?.exchangeRate);
     setExchage(res?.data?.data?.exchangeRate)  
     formik.setFieldValue("exchangeRate",res?.data?.data?.exchangeRate>0? res?.data?.data?.exchangeRate : 1)
 }).catch((err)=>{
   console.log(err);
 })
}

 useEffect(()=>{
  //  getCurrenctFind(formik?.values?.currencyId)
   formik?.values?.currencyId && exchangeRate(formik?.values?.currencyId);
 },[formik?.values?.currencyId])
 console.log("pathlist",filePathList);

  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
        <Breadcrumb
          items={[
            {
              name: "Supplier Quote",
              link: "/procurement/supplier-quotes",
            },
            { name: "Edit" },
          ]}
        />
        <div className="flex gap-2">
          <BackButton />
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden mt-4">
          <div className="w-full h-full py-4 px-3 flex justify-start content-start flex-wrap overflow-y-auto">
          {formik.values.prId &&(
            <FormikSelectTwo
              type="text"
              label="Purchase Requests"
              placeholder="Purchase Requests"
              Options={PR}
              formik={formik}
              name="prId"
              disabled={true}
              width="w-full md:w-1/2 lg:w-1/3"
            />)}
              {formik.values.purchaser &&(
            <FormikSelectTwo
    type="text"
    label="Local Purchaser"
    placeholder="Choose"
    Options={purchaserlist}
    formik={formik}
    disabled={true}
    name="Purchaser"
    width="w-full md:w-1/2 lg:w-1/3"
  />)}
             <FormikSelectTwo
              type="text"
              label="Supplier"
              disabled={true}
              placeholder="Supplier"
              Options={suppliers}
              formik={formik}
              name="vendorId"
              width="w-full md:w-1/2 lg:w-1/3"
            />
          
  {formik.values.rfqRef &&(
            <NuInput
              disabled={true}
              label="RFQ Reference"
              type="text"
              formik={formik}
              placeholder="RFQ Reference"
              name="rfqRef"
              width="w-full md:w-1/2 lg:w-1/3"
            />
  )}
            <NuInput
              disabled={true}
              label="Quotation Reference"
              type="text"
              formik={formik}
              placeholder="Enter Reference"
              name="ref"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            {/* <NuDate
              label="Quote Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="quoteDate"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            <NuDate
              label="Quotation Received Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="dateReceived"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             {/* <NuInput
                  label="Landing Cost"
                  type="number"
                  formik={formik}
                  placeholder="0.00"
                  name="landingCost"
                  
                  width="w-full md:w-1/2 lg:w-1/3"
                /> */}
            <FormikSelectTwo
              type="text"
              label="Quotation Validity"
              placeholder="Validity"
              Options={Validity}
              formik={formik}
              name="validity"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            {/* <FormikSelectTwo
              type="text"
              label="Product Availability"
              placeholder="Availability"
              Options={Availability}
              formik={formik}
              name="availability"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            <FormikSelectTwo
              type="text"
              label="Delivery Terms"
              placeholder="Delivery Terms"
              Options={DeliveryTerms}
              formik={formik}
              name="deliverTerms"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <FormikSelectTwo
              type="text"
              label="Payment Terms"
              placeholder="Payment Terms"
              Options={PaymentTerms}
              formik={formik}
              name="paymentTerms"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            {/* <FormikSelectTwo
              type="text"
              label="Lead Time"
              placeholder="Lead Time"
              Options={LeadTime}
              formik={formik}
              name="leadTime"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}

            <FormikSelectTwo
              type="text"
              label="Currency"
              placeholder="Choose"
              Options={currency}
              formik={formik}
              name="currencyId"
              width="w-full md:w-1/2 lg:w-1/3"
            />

            <NuInput
              label="Exchange Rate"
              type="text"
              formik={formik}
              placeholder="Reference"
              name="exchangeRate"
              width="w-full md:w-1/2 lg:w-1/3"
              disabled={exChange === "" ? false : true}
            />

            <FormikSelectTwo
              type="text"
              label="Payment Method"
              placeholder="Payment Method"
              Options={paymentMethod}
              formik={formik}
              name="paymentMethod"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            {/* <FormikSelectTwo
            type="text"
            label="Approval Status"
            placeholder="Approval Status"
            Options={ApprovalStatus}
           formik={formik}
           name="approvalStatus"
           width="w-full md:w-1/2 lg:w-1/3"
          /> */}
            <FormikSelectTwo
              type="text"
              label="Status"
              placeholder="Status"
              Options={Status}
              formik={formik}
              name="status"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuTextArea
              label="Special Notes From Supplier"
              placeholder="Special Notes From Supplier"
              formik={formik}
              name="specialNotes"
              width="w-full md:w-1/2 lg:w-1/3"
            />

            <NuTextArea
              label="Comments"
              placeholder="Comments"
              formik={formik}
              name="comments"
              width="w-full md:w-1/2 lg:w-1/3"
            />
              <div className='w-full'>
            <label className="text-xs 2xl:text-sm px-4">supplier Quote Attachment</label>
          
            <div className="md:flex justify-between mt-5 px-3 w-full">
              <div className="w-full flex gap-2 items-center flex-wrap">
                {data?.attachments &&
                  filePathList.map((imgs, idx) => {
                    console.log("img", imgs);
                    return (
                      <div
                        className=" w-28 h-11 relative flex justify-center items-center rounded-lg"
                        key={idx}
                        style={{
                          border: "1px solid gray",
                          borderColor:
                            "rgb(148 163 184 / var(--tw-bg-opacity))",
                        }}
                      >
                        <div
                          className=" w-4 h-4 rounded bg-slate-400 absolute text-white cursor-pointer font-medium flex justify-center items-center  -top-2 -right-2 z-10"
                          onClick={() => UpdateAttachmentList(imgs?.file)}
                        >
                          x
                        </div>
                        <div
                          className=" w-28 h-11 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer"
                          onClick={() => handleImageClick(imgs)}
                        >
                          {/* {imgs && <ViewFiles data={imgs} key={idx} />} */}
                          <img src={imgs?.file} alt={imgs?.file} />
                        </div>
                      </div>
                    );
                  })}

                <Attachement
                  handleChange={handleImageChange}
                  preview={image || ""}
                  width="w-full md:w-1/2"
                  label="Attach"
                  multiple={true}
                />
              </div>
            </div>
</div>
            <div className="w-full h-[1px] opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div>
            {missingProducts.length>0 && (
              <div className="w-full flex flex-col md:flex-row gap-y-3 md:gap-y-0 justify-between gap-3 border border-solid">
                <FormikSelectTwo
                  type="text"
                  label="Product"
                  placeholder="Product"
                  Options={missingProducts}
                  formik={formik}
                  name="productId"
                  width="w-[90%]"
                />
                <button
                  onClick={handleAdd}
                  type="button"
                  className=" p-1 mt-10 m-1 text-center h-10 cursor-pointer bg-[#048178] border border-solid border-white  text-white font-semibold rounded-lg "
                >
                  Add
                </button>
              </div>
            )}
            <div className="w-full bg-white overflow-hidden mt-5">
            {(showForm || formik.values.isDirectQuote) && (
              <div className="w-full rounded-md flex flex-col md:flex-row gap-y-3 md:gap-y-0 justify-start items-end gap-1 border border-solid">
                                <FormikSelectTwo
                  type="text"
                  label="Product "
                  placeholder="Product "
                  Options={Item}
                  formik={productForm}
                  name="productId"
                  width="w-full md:w-[70%] lg:w-[50%] "
                  px="3"
                />

                <button
                  onClick={togglePopup}
                  type="button"
                  className="px-3 mb-2 ml-2 text-center py-1.5 cursor-pointer bg-[#048178] border border-solid border-white  text-white font-semibold rounded-lg"
                >
                  {" "}
                  +{" "}
                </button>


                <NuInput
                  label="Quantity"
                  type="number"
                  formik={productForm}
                  placeholder="Quantity"
                  name="quantity"
                  px="2"
                  width="w-full md:w-1/2 lg:w-1/4 "
                />
                <FormikSelectTwo
                  type="text"
                  label="Lead time"
                  placeholder="Choose "
                  Options={LeadTime}
                  formik={productForm}
                  name="leadTime"
                  width="w-full md:w-1/2 lg:w-1/4"
                  px="2"
                />
                <NuInput
                  label="Price"
                  type="number"
                  formik={productForm}
                  placeholder="0.00"
                  name="price"
                  px="2"
                  width="w-full md:w-1/2 lg:w-1/4"
                />
                 <NuInput
                  label="Landing Cost"
                  type="number"
                  formik={productForm}
                  placeholder="0.00"
                  name="landedCost"
                  px="2"
                  width="w-full md:w-1/2 lg:w-1/4"
                  // isRequired={true}
                />
                <button
                  type="button"
                  onClick={()=>handleAdd(selectedProductIndex)}
                  className="px-2 mb-2 text-center py-1.5 cursor-pointer bg-[#048178] border border-solid border-white  text-white text-sm font-semibold rounded-lg "
                >
                  Add
                </button>
              </div>
            )}
              <div className="w-full py-3  bg-[#048178] flex justify-between rounded-lg px-6">

              <p className=" w-[5%] text-left pl-1 text-white text-xs font-medium">
                  S.N
                </p>
                <p className=" w-[35%] text-left text-white text-xs font-medium">
                  Product
                </p>
                <p className=" w-[10%] text-center text-white text-xs font-medium">
                  Unit
                </p>
                <p className=" w-[15%] text-center text-white text-xs font-medium">
                  Qty
                </p>
                <p className="w-[13%] text-center text-white text-xs font-medium">
                  Lead Time
                </p>
                <p className=" w-[15%] text-center text-white text-xs font-medium">
                  Price
                </p>
                <p className=" w-[15%] text-center text-white text-xs font-medium">
                  Conv. Price
                </p>
                <p className=" w-[15%] text-center text-white text-xs font-medium">
                  Landing Cost
                </p>
                <p className="w-[13%] text-center text-white text-xs font-medium">
                Total
                </p>
                <p className=" w-[7%] text-center text-white text-xs font-medium">
                  Action
                </p>
              </div>
              {prlist.length>0?

             
              <div className="h-[calc(100vh-300px)] overflow-y-scroll">
            
         
              {prlist && prlist.map((List, index) => {
                return (
                  <div
                    key={index}
                    className="w-full py-2 flex justify-between px-6 odd:bg-white even:bg-slate-50"
                  >
                    <p className=" w-[5%] text-left pl-1 text-[#606279]  text-xs ">
                    {List?.isAlternate == false &&
                          formik.values.isDirectQuote == false && (
                            <button
                              type="button"
                              onClick={()=>toggleForm(index)}
                              className="font-semibold px-1.5 py-0.5 bg-blue-600 text-white rounded-lg ml-[-5px] mr-1"
                            >
                              <p>+</p>
                            </button>
                          )}
                        {List?.sno||"-"}
                    </p>
                    <div className="w-[35%]">
                        <p className="text-left text-[#606279] text-xs font-light">
                          {List?.product_name}
                        </p>
                        {List?.isAlternate ? (
                          <p className="text-left text-[#65667b] text-[10px] md:text-[10px] lg:text-[8px] xl:text-[10px] font-light">
                            (Alternate)
                          </p>
                        ) : (
                          <></>
                        )}
                      </div>
                    <p className=" w-[10%] text-center text-[#606279] text-xs font-light">
                      {List?.product_unit}
                    </p>
                    {/* <p className=" w-[20%] text-center text-[#606279] text-xs font-light ">
                      {List?.quantity}
                    </p> */}
                    <div className="w-[15%] text-center text-[#606279] text-xs font-light">
                      <button
                        type="button"
                        className="border rounded-full p-1 m-1"
                        onClick={() => handleDecrement(index)}
                      >
                        -
                      </button>
                      <input
                        type="number"
                        className="w-[20%] text-center border text-[#606279] text-xs font-light"
                        name="quantity"
                        value={List.quantity}
                        onChange={(e) => handleInputChange(e, index)}
                      />
                      <button
                        type="button"
                        className="border rounded-full p-1 m-1"
                        onClick={() => handleIncrement(index)}
                      >
                        +
                      </button>
                    </div>
                    <div className="w-[13%] px-2 text-center text-[#606279] text-xs font-light">
                        {/* {isEditing ? ( */}
                        <Select
                          value={LeadTime.find(item=> item?.value == List.leadTime)||List.leadTime}
                          options={LeadTime }
                          className="w-full"
                          onChange={(value) =>
                            handleLeadTimeChange(value, index)
                          }
                        />
                      </div>
                      <div className='w-[15%] px-2 '>
                      <input
                      type="number"
                      className=" w-full py-2 rounded  text-center border text-[#606279] text-xs font-light"
                      name="price"
                      formik={formik}
                      placeholder="Enter the price"
                      value={List.price || ""}
                      onChange={(e) => handlePriceChange(e, index)}
                    />
                      </div>
                      <p className="text-center w-[15%] text-[#606279] text-xs py-1 font-light">
                          {List.convPrice}
                      </p>
                      <div className='w-[15%] px-2 '>
                      <input
                      type="number"
                      className=" w-full py-2 rounded  text-center border text-[#606279] text-xs font-light"
                      name="landedCost"
                      formik={formik}
                      placeholder="Enter the Cost"
                      value={List.landedCost || ""}
                      onChange={(e) => handleCostChange(e, index)}
                    />
                      </div>
                      <p className="text-center w-[13%] text-[#606279] text-xs font-light">
  {(List.convPrice * List.quantity + parseFloat(List.landedCost)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} 
  {/* <span>{supplierCurrency}</span> */}

  {/* {data?.label == "" ? 
                 <NuAmount value={Number((List.price * List.quantity + parseFloat(List.landedCost)) || 0)} />
                 :
                 <NuAmount currency={supplierCurrency} value={Number((List.price * List.quantity + parseFloat(List.landedCost)) || 0)} />
              } */}

</p>
                    {/* <p className=" w-[15%] text-center text-[#606279] text-xs font-light">
                      {List?.price}
                    </p> */}
                    <div className="w-[7%] text-center">
                      <button
                        onClick={() => handleDelete(List?.quotProductId)}
                        type="button"
                        className="text-red-500 cursor-pointer bg-white ml-4 border-none"
                      >
                        <Delete color="red" height={4} />
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>:<div className="w-full text-center text-[#93949f]">No Items here</div>}

              </div>
           
            {/* {items.map((list, index) => (
                 <div key={index} className="w-full py-1 flex justify-between px-6 odd:bg-white even:bg-slate-50">
                 <p className=" w-[30%] text-left text-[#606279] text-xs font-light ">
                {list?.productName}
                 </p>
                 <p className=" w-[15%] text-center text-[#606279] text-xs font-light">
                   {list?.unit}
                 </p>
                
                  <div className="w-[20%] text-center text-[#606279] text-xs font-light">
                 <button
                   type="button"
                   className="border rounded-full p-1 m-1"
                   onClick={() => handleDecrement(index)}
                 >
                   -
                 </button>
                 <input
                   type="number"
                   className="w-[10%] text-center border text-[#606279] text-xs font-light"
                   name="quantity"
                   value={list.quantity}
                   onChange={(e) => handleInputChange(e, index)}
                 />
                 <button
                   type="button"
                   className="border rounded-full p-1 m-1"
                   onClick={() => handleIncrement(index)}
                 >
                   +
                 </button>
               </div>
               <input
                 type="number"
                 className="w-[15%] py-1 text-center border text-[#606279] text-xs font-light"
                 name="price"
                 formik={formik}
                 placeholder="Enter the price"
                 value={list.price || ''}
                 onChange={(e) => handlePriceChange(e, index)}
               />
                
                 <div className='w-[5%] text-center'>
                   <button
                     onClick={() => handleDeleteItem(list?.quotProductId)}
                     type="button"
                     className="text-red-500 cursor-pointer bg-white ml-4 border-none"
                   >
                     <Delete
                       color='red'
                       height={4}
                     />
                   </button>
                 </div>
               </div>
                // <div className="border border-gray-200 bg-gray-50 px-2 rounded-md mb-1 flex justify-between content-start items-start flex-wrap w-full" key={list.productId}>
                //   <div className="text-xs md:text-xs py-1">
                //     {index + dataa?.quoteProducts.length + 1}. {list.productName}
                //   </div>
                //   <div className="flex justify-between content-start gap-4 items-start flex-wrap ">
                //     <div className="text-xs md:text-xs">
                //       <div className="text-[10px]">Price</div>
                //       <input type="number" className="border border-gray-300 w-[90px] rounded-md text-[10px]" placeholder="Price" value={list.price} readOnly />
                //     </div>
                //     <div className="text-xs md:text-xs">
                //       <div className="text-[10px]">Quantity</div>
                //       <input type="number" className="border border-gray-300 w-[90px] rounded-md text-[10px]" placeholder="Quantity" value={list.quantity} readOnly />
                //     </div>
                //     <div className="w-[40px] text-xs md:text-xs py-1 cursor-pointer" onClick={() => handleDeleteItem(index, list)}>
                //       <Delete />
                //     </div>
                //   </div>
                // </div>
              ))} */}
            <div className=" w-full my-6  flex justify-end items-center gap-4">
              <TextMediumBase content={"Total Price : "} />
              <p className="w-64 h-8 border rounded-md text-xs pl-2 py-2">
                 <NuAmount value={Number(formik?.values?.totalPrice || 0)} />
</p>
              {/* <NuInput
                label=""
                type="text"
                formik={formik}
                placeholder="Total Price"
                name="totalPrice"
                value={`${supplierCurrency} ${formik.values.totalPrice || 0}`} 

                width="w-full md:w-1/2 lg:w-1/3"
                disabled={true}
              /> */}
            </div>
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton
                  handleClick={() => {
                    Oncancel(-1);
                  }}
                />
                <SubmitButton isLoading={isLoading} />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}



export default EditSupplierQuotations;
