import { useFormik } from "formik";
import { NuInput, NuInputCurrency } from "../../../../Components/Utils/Input";
import { useToast } from "../../../../Components/Others/ToastServices";
import { SubmitButton } from "../../../../Components/Utils/Buttons";
import { useEffect, useState } from "react";
import axiosInstance from "../../../../Services/axiosCommon";
import { TextMediumBase, TextMediumSM, TextMediumXS } from "../../../../Components/Text/MediumText";

const CurrencyIndex = () => {
  const toast = useToast();
  const [createNew, setCreateNew] = useState({
    currency: "",
    exchangeRate: "",
  });
  const [data, setData] = useState([]);
  const GetData = async () => {
    await axiosInstance
      .get(`/currency/`)
      .then((res) => {
        console.log("data", res.data?.data);
        res.data?.data && setData(res.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    GetData();
  },[]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: yup.object({
    // category: yup.string().required('Status is Required'),
    // }),
    onSubmit: async (value, { resetForm }) => {
      axiosInstance.post(`/currency/new-currency`, value).then((res) => {
        if (res.data) {
          console.log("Created", resetForm);
          resetForm();
          GetData();
          toast.open({
            type: "success",
            message: `Currency  Added successfully`,
            // description:'',
            // placement: 'bottomRight',
          });
        } else {
          console.log("Failed");
          toast.open({
            type: "error",
            message: `Currency Added failed`,
            // description: response.data.msg || 'Network error',
            // placement: 'bottomRight',
          });
        }
      });
    },
  });
  const handleDelete = (value) => {
    axiosInstance.delete(`/currency/${value}`)
      .then(res => {
        if (res?.status == 200) {
          GetData();
          toast.open({
            type:"success",
            message: `Currency  Deleted successfully`,
            // placement: 'bottomRight',
          });
        }
        else {
          console.log("Failed")
          toast.open({
            type:"error",
            message: `Currency Deleted failed`,
            // placement: 'bottomRight',
          });
        }
      })
  }
  return (
    <div className=" w-full p-3">
      <TextMediumBase content={"Currency"} />
      <form onSubmit={formik.handleSubmit}>
        <div className={`w-full text-xs pt-2 flex justify-start gap-[5%]`}>
          <div className="w-[50%] mb-1">
            <label className="font-medium text-textColor-500">
              {"Currency"}
            </label>
            <NuInputCurrency
              type="text"
              placeholder=""
              formik={formik}
              name="currency"
              width="w-full md:-mt-2"
            />
          </div>
          <div className="w-[25%] mb-1">
            <label className="font-medium text-textColor-500">
              {"Exchange Rate"}
            </label>
            <NuInputCurrency
              type="number"
              placeholder=""
              formik={formik}
              name="exchangeRate"
              width="w-full md:-mt-2"
            />
          </div>
          <div className="w-[8%] h-20 mt-6">
            <SubmitButton name="Add" />
          </div>
        </div>
      </form>
      <div className="">
        <div className="w-full rounded-lg h-44 bg-[#f5f3ff] overflow-x-hidden overflow-y-auto">
          <ul className="">
            {data.map((List, idx) => {
              return (
                <li
                  className=" m-1  flex justify-between items-center text-xs bg-white border border-gray-300 rounded text-main-title py-2 px-2 hover:bg-[#B3EBD7] hover:bg-opacity-50"
                  key={idx}
                >
                  {/* {"Testing Data" || "-"} */}
                  <div className="flex gap-2">
                  <TextMediumSM
                    color={"text-[#009858]"}
                    content={List.currency || "-"}
                  />
                  <div className="flex gap-0.5">
                  <TextMediumSM
                    color={"text-[#009858]"}
                    content={List.exchangeRate || "-"}
                  />
                  <span className="text-[8px] text-slate-600">(exchange rate)</span>
                  </div>
                  </div>
                  {List?.id && (
                    <span
                      onClick={() => {
                        handleDelete(List?.id);
                      }}
                      className="text-sm text-blue-900 cursor-pointer"
                    >
                      x
                    </span>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CurrencyIndex;


