import { TextMediumSM } from "../../../../../Components/Text/MediumText"
import Consignee from "./Consignee";
import CustomerClearanceStatus from "./CustomesClearanceStatus";
import FinalDestination from "./FinalDestination";
import InsurancePremium from "./InsurancePremium";
import InsuranceProvider from "./InsuranceProvider";
import IssuedCarrier from "./IssuedCarrier";
import ModeOfShipment from "./ModeOfShipment";
import PortOfDischarge from "./PortOfDischarge";
import PortOfLoading from "./PortOfLoading";
import ShipIdentityType from "./ShipIdentityType";
import ShipVehicleType from "./ShipVehicleType";
import Shipper from "./Shipper";
import ShippingMethod from "./ShippingMethod";
import StatusShipping from "./StatusShipping";
import VesselName from "./VesselName";





const ShipmentTracking = () =>{
    return(
        <>
        <TextMediumSM content="Shipment Tracking" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
        
         {/* <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <IssuedCarrier/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Shipper/>
         </div> */}
         {/* <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <ModeOfShipment/>
         </div> */}
         {/* <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Consignee/>
         </div> */}
         {/* <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <VesselName/>
         </div> */}
         {/* <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <PortOfLoading/>
         </div> */}
         {/* <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <PortOfDischarge/>
         </div> */}
         {/* <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <FinalDestination/>
         </div> */}
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <ShippingMethod/>
         </div>
         {/* <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <CustomerClearanceStatus/>
         </div> */}
         {/* <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <InsuranceProvider/>
         </div> */}
         {/* <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <InsurancePremium/>
         </div> */}
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <StatusShipping/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <ShipIdentityType/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <ShipVehicleType/>
         </div>
        
         </div>
         </>
    )
}

export default ShipmentTracking;