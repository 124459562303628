import React, { useEffect, useState } from 'react'
import DataTable from '../../../../Components/Others/DataTable';
import { CancelButton, CreateButton, FilterButton, ImportButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { Link } from 'react-router-dom';
import SearchBar from '../../../../Components/Utils/SearchBar';
import UseGetData from '../../../../Components/Hooks/useFetchData';
import MoreOptions from '../../../../Components/Utils/MoreOptions';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import CustomDataTable from '../../../../Components/Others/CustomDataTable';
import UseGetDataSearch from '../../../../Components/Hooks/useFetchDataBySearch';
import { useFormik } from 'formik';
import FormikSelectTwo from '../../../../Components/Utils/Select';
import { NuDate } from '../../../../Components/Utils/Input';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import * as yup from 'yup';
import Importdoc from './Importdoc';
import MultiEditPr from './MultiEditPr';
import ModalContainer from '../../../../Components/Others/ModalContainer';
import MultiSelect from '../../../../Components/Utils/MultiSelect';
import { GetAccess } from '../../../../Components/Utils/roles';
import useFetchMDIndex from '../../../../Components/Hooks/useFetchMDIndex';

function PRIndex() {
 
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(20)
  const [filter, setFilter] = useState(false);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const { value: status } = useFetchMDIndex(
    `/dropdown/dropdown-details/`,
    "rfqStatus_pr",showFilterDialog
  );  
  const { data: RFQAssignee } = UseDropDown('/user/procurement/drop-down');
  const { data: Salesman } = UseDropDown('/user/salesman/drop-down');


  const [filterProps, setFilterProps] = useState({
  
    status:"",
    rfqAssignee:"",
    salesPerson:"",
    deadLineFrom:"",
    deadLineTo:""
  });
  const [params, setParams] = useState({
    page: page,
    per_page: pageCount,
    search:"",
    status:"",
    rfqAssignee:"",
    salesPerson:"",
    deadLineFrom:"",
    deadLineTo:""
  });

  const clearFilter = ()=> {
    setParams({...params,status:"", rfqAssignee:"", salesPerson:"",deadLineFrom:"", deadLineTo:""})
    formik.values.status=""
    formik.values.rfqAssignee=""
    formik.values.salesPerson=""
    formik.values.deadLineFrom=""
    formik.values.deadLineTo=""

      setFilter(false)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterProps,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      setParams({...params,status:value.status, rfqAssignee:value.rfqAssignee, salesPerson:value.salesPerson, deadLineFrom:value.deadLineFrom, deadLineTo:value.deadLineTo})
      closeFilterDialog();
      setFilter(true)
      setFilterProps({
        status:"",
        rfqAssignee:"",
        salesPerson:"",
        deadLineFrom:"",
        deadLineTo:""
      });
    }
  })
  const { data, totalCount, getData,dataLoading } = UseGetDataSearch( '/PR/',params);


  const defaultHeader = [
    // {
    //   title: "ID",
    //   width: "5%",
    //   field: "prId",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "YPR",
      width: "10%",
      isLink: true,
      id: "prId",
      link: '/procurement/purchase-requests/view/',
   
      field: "ref",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "RFQ Assignee",
      width: "10%",
         isLink: true,
      id: "prId",
      link: '/procurement/purchase-requests/view/',
      field: "rfqAssigneeNames",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "DeadLine",
      width: "10%",
      field: "deadLine",
      type:"date",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Days Elapsed",
      width: "10%",
      field: "daysElapsed",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Salesman",
      width: "10%",
      field: "salesPersonName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Status",
      width: "10%",
      field: "status",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents:{
        // fetcher:getData,
        id:"prId",
        removeValue:"ref",
        url:`/PR/`,
        moduleName:"Purchase Requests",
        from:`/procurement/purchase-requests`,
        editLink:"/procurement/purchase-requests/edit/",
        viewLink:"/procurement/purchase-requests/view/"

      }
    },
  ];
  const availableHeaders = [
    // {
    //   title: "ID",
    //   width: "5%",
    //   field: "prId",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "YPR",
      width: "10%",
      isLink: true,
      id: "prId",
      link: '/procurement/purchase-requests/view/',
   
      field: "ref",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "RFQ Assignee",
      width: "10%",
         isLink: true,
      id: "prId",
      link: '/procurement/purchase-requests/view/',
      field: "rfqAssigneeNames",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "DeadLine",
      width: "10%",
      field: "deadLine",
      type:"date",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Days Elapsed",
      width: "10%",
      field: "daysElapsed",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Salesman",
      width: "10%",
      field: "salesPersonName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Status",
      width: "10%",
      field: "status",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Customer Reference",
      width: "10%",
      isLink: true,
      id: "prId",
      link: '/procurement/purchase-requests/view/',
      field: "customerRfqRef",
      textAlign: "center",
      rowAlign: "center",
    },
   
    {
      title: "Customer ",
      width: "10%",
      field: "customerName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Description",
      width: "10%",
      field: "description",
      textAlign: "center",
      rowAlign: "center",
    },
   
    // {
    //   title: "Is Active",
    //   width: "10%",
    //   field: "isActive",
    //   isCondition: true,
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "Prepared By",
      width: "5%",
      field: "preparedByName",
      textAlign: "center",
      rowAlign: "center",
    },
    // {
    //   title: "Reviewed By",
    //   width: "5%",
    //   field: "reviewedByName",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    // {
    //   title: "Approved By",
    //   width: "5%",
    //   field: "approvedByName",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "Verified By",
      width: "5%",
      field: "verifiedByName",
      textAlign: "center",
      rowAlign: "center",
    },
   
    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents:{
        // fetcher:getData,
        id:"prId",
        removeValue:"ref",
        url:`/PR/`,
        moduleName:"Purchase Requests",
        from:`/procurement/purchase-requests`,
        editLink:"/procurement/purchase-requests/edit/",
        viewLink:"/procurement/purchase-requests/view/"

      }
    },
  ];
  useEffect(()=>{
    setParams({...params,page:page,per_page:pageCount})
   },[page,pageCount])

   const closeFilterDialog = () => {
    setShowFilterDialog(false);
  };

  const [showImportModal, setShowImportModal] = useState(false);


  const handleImportClick = () => {
    setShowImportModal(true);
  };

  const closeModal = () => {
    setShowImportModal(false);
  };

  // hasMultiEdit=true,
  // selectMultipleFunction=()=>{},
  // selectOneFunction=()=>{},
  // listofNumbers=[1,2],
  // setShowEdit=()=>{},
  // showEdit=false,
  // setShowDelete=()=>{},
  // showDelete=false,

  const [showEdit, setShowEdit] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [listofNumbers, setListofNumbers] = useState([])
  const [fullList, setFullList] = useState([])

  const selectOneFunction = (value) =>{ 
    let NewId;
    if(listofNumbers && listofNumbers.some(Item=>Item ===value)){
      NewId = listofNumbers.filter(Temp=> Temp !== value);
      setListofNumbers(NewId)
    }else{
      setListofNumbers([...listofNumbers,value])
    }
  }

  const selectMultipleFunction = (e) =>{ 
console.log(e.target.checked);
if(e.target.checked){
  setListofNumbers(fullList)
}else{
  setListofNumbers([])
}

  }



  useEffect(()=>{
    let tempArr = []
    data && data.forEach(i=>{
      console.log("I",i?.prId);
      tempArr.push(i?.prId)
    });
    setFullList(tempArr)
  },[data])
  

  

  return (
    <div className='w-full h-full px-4 py-2'>
      <div className='w-full flex justify-between items-center'>
        <Breadcrumb
          items={[
            { name: "Procurement" },

            { name: "Purchase Request" },
          ]} />
        <div className=' flex gap-2'>
        <SearchBar
              handleChange={(e) =>{
                setParams({ ...params, search: e.target.value, page:1 });
                setPage(1)
              }}
            />  <FilterButton  handleClick={()=>setShowFilterDialog(true)} />
          {filter && <FilterButton  handleClick={()=>clearFilter()} label='Clear'/>}
        
          {/* {GetAccess("purchase_request_create") && <ImportButton handleClick={handleImportClick} />} */}
          {GetAccess("purchase_request_create") && <Link to="/procurement/purchase-requests/create"><CreateButton label='PR' /></Link>}
        </div>
      </div>

      <CustomDataTable 
      fetcher={getData}
        data={data} 
        availableHeaders={availableHeaders} 
        defaultHeader={defaultHeader}
        bodyHeight='max-h-[calc(100vh-260px)]'
        height='h-[calc(100vh-145px)]'
        total={totalCount}
        pageIndex={page}
        setPageIndex={setPage}
        dataCount={pageCount}
        setDataCount={setPageCount}
        title='Purchase Requests List'
        storageName="PrTable"
        selectOneFunction={selectOneFunction}
        setShowDelete={setShowDelete}
        showDelete={showDelete}
        setShowEdit={setShowEdit}
        showEdit={showEdit}
        listofNumbers={listofNumbers}
        hasMultiEdit={true}
        selectMultipleFunction={selectMultipleFunction}
        fullList={fullList}
        getById="prId"
        editAccess="purchase_request_edit"      
        deteleAccess="purchase_request_delete" 
        dataLoading={dataLoading}
        />
         {showFilterDialog && (
             
             
             <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
               <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
                 <h2 className="text-xl mb-4">Filter Purchase Requests</h2>
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="flex flex-col mb-4">
                    <p className="py-2 px-6 text-m">Dead Line</p>
                    <div className="flex justify-between  w-full ">
                    <NuDate
              label=" From"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="deadLineFrom"
              width="w-full"
            />
              <NuDate
              label=" To"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="deadLineTo"
              width="w-full"
            />
            </div>
            <FormikSelectTwo
              // type="text"
              label="RFQ Assignee"
              placeholder="RFQ Assignee"
              Options={RFQAssignee}
              formik={formik}
              name="rfqAssignee"
              width="w-full "
            />
             <FormikSelectTwo
                type="text"
                label="Sales Person"
                placeholder="Choose"
                Options={Salesman}
                formik={formik}
                name="salesPerson"
                width="w-full"
              />
           
             <FormikSelectTwo
              type="text"
              label="Status"
              placeholder="Status"
              Options={status}
              formik={formik}
              name="status"
              width="w-full"
            />
                     
                    </div>
                   <div className="flex justify-end gap-2">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               </div>
             </div>
           )}
        {showImportModal && (<Importdoc show={showImportModal} onClose={closeModal} getData={getData} />)}
        {showEdit && (<MultiEditPr data={listofNumbers} getData={getData} onClose={()=>setShowEdit(false)}/>)}
        <ModalContainer
                visiable={showDelete}
                title="Warning"
                content={`Are you sure, All selected datas will be removed from Purchase Requests List!`}
                handleClick={() =>{}}
                closeModal={() => setShowDelete(false)}
            />
    </div>
  )
}

export default PRIndex