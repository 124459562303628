import React, { useEffect, useState } from "react";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import Dashboard from "../../../../Components/Icons/Dasboard";
import { Link, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import useEditFormData from "../../../../Components/Hooks/useEditFormData";
import * as yup from "yup";
import {
  NuInput,
  NuMobileNumber,
  NuTextArea,
  NuUpload,
} from "../../../../Components/Utils/Input";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import useFetchMetaStar from "../../../../Components/Hooks/useFetchMetaStar";
import { TextMediumBase } from "../../../../Components/Text/MediumText";
import { CountryMIN } from "../../../../assets/CountryMini";
import { useToast } from "../../../../Components/Others/ToastServices";
import Attachement, {
  videotype,
} from "../../../../Components/Utils/CustomAttrachmentupload";
import Modal from "../../../../Components/Utils/AttachmentView";
import CloseIcon from "../../../../Components/Icons/Close";
import Video from "../../../../Components/Icons/Video";
import Doc from "../../../../Components/Icons/Doc";
import Xls from "../../../../Components/Icons/Xls";
import Txt from "../../../../Components/Icons/Txt";
import Pdf from "../../../../Components/Icons/Pdf";
import DropDownAddMetaData from "../../../../Components/Utils/selectAddMetaData";
const filetype = [
  "docx",
  "doc",
  "xlsx",
  "xls",
  "pdf",
  "txt",
  "pptx",
  "mp4",
  "mov",
  "wmv",
  "avi",
  "flv",
  "mkv",
];

function EditLeads() {
  const { value: Source } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "lead_Source"
  );
  const { value: Industry } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "Industry_leads"
  );
  const { value: CoversionProbablity } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "Lead_CoversionProbablity"
  );
  const { value: CompanySize } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "CompanySize_leads"
  );
  const { value: LeadStatus, getData:refStatus } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "lead_Status"
  );
  const { value: LeadsRating } = useFetchMetaStar(
    `/dropdown/dropdown-details/`,
    "leadsRating"
  );
  // const [loading, setLoading] = useState(false);

  const [image, setImage] = useState([]);
  const location = useLocation();
  const data = location.state.data;
  const from = location.state.from;
  console.log(location.state, "state");
  // const [division, setDivision] = useState([]);
  const { Oncancel, editFormData, contextHolder, isLoading } =
    useEditFormData();
  const [createNew, setCreateNew] = useState({
    industry: data?.industry,
    companyAddress: data?.companyAddress,
    companySize: data?.companySize,
    contactInfo: data?.contactInfo,
    conversionProbabilities: data?.conversionProbabilities,
    description: data?.description,
    leadOwner: data?.leadOwner,
    leadSource: data?.leadSource,
    name: data?.name,
    ratings: data?.ratings.toString(),
    comments: data?.comments,
    country: data?.country,
    city: data?.city,
    postalCode: data?.postalCode,
    state: data?.state,
    address1: data?.address1,
    address2: data?.address2,
    email: data?.email,
    mobile: data?.mobile.number,
    countryCode: data?.mobile.countryCode,
    status: data?.status,
    contactPersonName: data?.contactPersonName,
  });
  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  const handleImageRemove = () => {
    setImage({
      preview: "",
      raw: "",
    });
    setCreateNew((prevState) => ({ ...createNew, image: "" }));
    formik.values.image = "";
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      name: yup.string().required("Name is required!"),
      industry: yup.string().required("Industry is required!"),
      email: yup.string().required("Email is required!"),
      mobile: yup.string().required("Mobile is required!"),
    }),
    onSubmit: async (value, { resetForm }) => {
      // setLoading(true); 
      let formData = new FormData();
      if (removefilePathList) {
        let tempArr = [];
        let finalArr = removefilePathList.filter(
          (items) => !filePathList.includes(items)
        );
        finalArr &&
          finalArr.map((fileName) => {
            tempArr.push(fileName.file);
          });
        // console.log(',..df.,..',tempArr);
        value.filesToRemove = tempArr;
      } else {
        value.filesToRemove = [];
      }
      formData.append("leadDetails", JSON.stringify(value));
      if (selectedFile !== null) {
        selectedFile.forEach((file) => {
          formData.append("attachments", file);
        });
      } else {
        formData.append("attachments", []);
      }
      // formData.append('attachmennts', image.raw);

      editFormData(
        `/leads/edit-lead/${data.leadId}`,
        formData,
        resetForm,
        from
      );
      // setLoading(false)
    },
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePathList, setFilePathList] = useState([]);
  const [removefilePathList, setRemoveFilePathList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const toast = useToast();

  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = [];
    files.map((item) => {
      if (item.size < maxFileSize) {
        fileArr.push(item);
      } else {
        toast.open({
          type: "warning",
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          // placement: "bottomRight",
        });
      }
    });
    let tempArr = [];
    setSelectedFile(fileArr);
    files.map((item) => {
      if (item.size < maxFileSize) {
        let fileName = item?.name;
        console.log("fname", fileName);
        // let fileExtention = fileName.split(".").reverse()[0];
        // if (filetype?.includes(fileExtention)) {
        tempArr.push(fileName);
        // } else {
        //   let x = URL.createObjectURL(item);
        //   tempArr.push(x);
        // }
      }
    });
    console.log(tempArr, "temp");
    setImage(tempArr);
  }

  useEffect(() => {
    if (
      data?.attachments !== null &&
      data?.attachments !== "None" &&
      data?.attachments !== "[]"
    ) {
      console.log(data.attachments, "ghjkllgd");
      setFilePathList(data?.attachments);
      // console.log('image',createNew?.attachments);
      setRemoveFilePathList(data?.attachments);
      // console.log('images',filePathList);
    }
  }, [data]);

  function UpdateAttachmentList(item) {
    let filterList = filePathList.filter((itemList) => itemList.file !== item);
    console.log("llll", filterList);
    setFilePathList(filterList);
  }

  function handleImageClick(img) {
    setSelectedImage(img);
    setIsModalOpen(true);
  }

  const removeImages = (value) => {
    console.log("value", value);
    let List = selectedFile.filter((item) => item?.name !== value);
    console.log("LIst", List);
    setSelectedFile([...List]);
    let tempArr = [];
    List.map((item) => {
      let fileName = item?.name;
      // console.log('fname',fileName);
      // let fileExtention =  fileName.split(".").reverse()[0]
      // if(filetype?.includes(fileExtention)){
      tempArr.push(fileName);
      // }else{
      //   let x = URL.createObjectURL(item)
      //   tempArr.push(x)
      // }
    });
    setImage(tempArr);
  };

  return (
    <div className="w-full h-full px-4 py-2 ">
      <div className="flex justify-between mb-4">
        <Breadcrumb
          items={[
            {
              name: "Lead",
              link: "/marketing/leads",
            },
            {
              name: "Edit Lead",
            },
          ]}
        />
        <BackButton />
      </div>
      <div className="w-full h-[calc(100vh-140px)] overflow-y-auto">
      <form onSubmit={formik.handleSubmit}>
      <div className="w-full mx-auto pb-4 bg-white rounded-md ">
          <div className=" w-full h-full flex justify-start content-start flex-wrap   mb-4">
          
                   <div className="w-full">
  <p className='py-2 px-4 text-xs 2xl:text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4'>
  Leads Detail :
  </p>
</div>       <NuInput
              type="text"
              label="Name"
              placeholder="Name"
              formik={formik}
              name="name"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />
            <FormikSelectTwo
              type="text"
              label="Industry"
              placeholder="Choose"
              Options={Industry}
              formik={formik}
              name="industry"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />

            <FormikSelectTwo
              type="text"
              label="Company Size"
              placeholder="Choose"
              Options={CompanySize}
              formik={formik}
              name="companySize"
              width="w-full md:w-1/2 lg:w-1/3"
            />
                        <NuInput
              type="text"
              label="Contact Person"
              placeholder="Contact Person"
              formik={formik}
              name="contactPersonName"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />
            <NuMobileNumber
              nunCode="countryCode"
              label="Mobile"
              placeholder="Mobile"
              formik={formik}
              name="mobile"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />
            <NuInput
              type="email"
              label="Email"
              placeholder="Email"
              formik={formik}
              name="email"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />

            {/* <FormikSelectTwo
              Options={LeadsRating}
              label="Performance Ratings"
              placeholder="Ratings"
              formik={formik}
              name="ratings"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            <FormikSelectTwo
              type="text"
              label="Conversion Probabilities"
              placeholder="Choose"
              Options={CoversionProbablity}
              formik={formik}
              name="conversionProbabilities"
              width="w-full md:w-1/2 lg:w-1/3"
            />

            {/* <NuInput
              type="text"
              label="Description"
              placeholder="Description"
              formik={formik}
              name="description"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="text"
              label="LeadOwner"
              placeholder="LeadOwner"
              formik={formik}
              name="leadOwner"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            <FormikSelectTwo
              type="text"
              label="Lead Source"
              placeholder="Choose"
              Options={Source}
              formik={formik}
              name="leadSource"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <DropDownAddMetaData
              label="Status"
              placeholder="Choose"
              Options={LeadStatus}
              formik={formik}
              name="status"
              width="w-full md:w-1/2 lg:w-1/3"
              getFunc={refStatus}
              displayProperty={"Status"}
              propertyName={"lead_Status"}
            />
            {/* <FormikSelectTwo
              type="text"
              label="Status"
              placeholder="Choose"
              Options={LeadStatus}
              formik={formik}
              name="status"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
             </div>
           </div>
           <div className="w-full mx-auto h-auto bg-white rounded-md overflow-hidden mt-4 ">
          <div className=" w-full h-full flex justify-start content-start flex-wrap  overflow-y-auto mb-4">
       
          <div className="w-full">
  <p className='py-2 px-4 text-xs 2xl:text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4'>
    Address :
  </p></div>
  <NuInput
              type="text"
              label="Street Address Line 1"
              placeholder="Street Address Line 1"
              formik={formik}
              name="address1"
              width="w-full md:w-1/2 lg:w-1/3"
            />

            <NuInput
              type="text"
              label="Street Address Line 2"
              placeholder="Street Address Line 2"
              formik={formik}
              name="address2"
              width="w-full md:w-1/2 lg:w-1/3"
            />

<NuInput
              type="text"
              label="City"
              placeholder="City"
              formik={formik}
              name="city"
              width="w-full md:w-1/2 lg:w-1/3"
            />

<NuInput
              type="text"
              label="State / Province"
              placeholder="State / Province"
              formik={formik}
              name="state"
              width="w-full md:w-1/2 lg:w-1/3"
            />

<NuInput
              type="number"
              label="Postal / Zip Code"
              placeholder="Postal / Zip Code"
              formik={formik}
              name="postalCode"
              width="w-full md:w-1/2 lg:w-1/3"
            />

            <FormikSelectTwo
              type="text"
              label="Country"
              placeholder="Country"
              Options={CountryMIN}
              formik={formik}
              name="country"
              width="w-full md:w-1/2 lg:w-1/3"
            />

         
            <NuTextArea
              label="Comments"
              placeholder="comments"
              formik={formik}
              name="comments"
              width="w-full md:w-1/2 "
              new_width="lg:w-2/3"
            />
              {/* </div>
           </div>
           <div className="w-full mx-auto h-auto bg-white rounded-md overflow-hidden mt-4 ">
          <div className=" w-full h-full flex justify-start content-start flex-wrap  overflow-y-auto mb-4"> */}
       
          <div className="w-full">
  <p className='py-2 px-4 text-xs 2xl:text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4'>
  Attachment :
  </p></div>
            <div className="md:flex justify-between mt-5 px-3 w-full">
              <div className="w-full flex gap-2 items-center flex-wrap">
                {data?.attachments &&
                  filePathList.map((imgs, idx) => {
                    console.log("img", imgs);
                    let Ext = imgs?.file_name.split(".").reverse()[0];

                    return (
                      <div
                        className=" w-20 h-9 my-2 relative flex justify-center items-center rounded-lg"
                        key={idx}
                        style={{
                          border: "1px solid gray",
                          borderColor:
                            "rgb(148 163 184 / var(--tw-bg-opacity))",
                        }}
                      >
                        <div
                          className=" w-4 h-4 rounded bg-slate-400 absolute text-white cursor-pointer font-medium flex justify-center items-center  -top-2 -right-2 z-10"
                          onClick={() => UpdateAttachmentList(imgs?.file)}
                        >
                          <CloseIcon color="white" height="8" width="8" />
                        </div>
                        <div
                          className=" w-20 h-9 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer"
                          onClick={() => handleImageClick(imgs)}
                        >
                          <div className=" w-8 h-8">
                            {videotype.includes(Ext) ? <Video /> : null}

                            {Ext === ("docx" || "doc" || "txt") ? (
                              <Doc />
                            ) : Ext === ("xlsx" || "xls") ? (
                              <Xls />
                            ) : Ext === "txt" ? (
                              <Txt />
                            ) : Ext === "pdf" ? (
                              <Pdf />
                            ) : Ext === "pptx" ? (
                              <>No file</>
                            ) : (
                              <img src={imgs?.file} alt="#" width="120%" />
                            )}
                          </div>
                          <p className=" w-[50px] text-[11px] truncate">
                            {imgs?.file_name}
                          </p>
                        </div>
                      </div>
                    );
                  })}

                <Attachement
                  handleChange={handleImageChange}
                  preview={image || ""}
                  width="w-full md:w-1/2"
                  label="Attach"
                  multiple={true}
                  removeImages={removeImages}
                />
              </div>
            </div>
            {/* <NuUpload
              label="Attachments"
              width="w-full md:w-1/2 lg:w-1/3"
              onChange={handleChangeImg}
            /> */}
            <div className="w-full my-4 mt-8 ">
              <div className="flex justify-end gap-4">
                <CancelButton
                  handleClick={() => {
                    Oncancel(-1);
                  }}
                />
                <SubmitButton isLoading={isLoading}/>
              </div>
            </div>
          </div>
        </div>
      </form>
      </div>
      <Modal show={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <img src={selectedImage?.file} alt={selectedImage?.file} />
      </Modal>
    </div>
  );
}

export default EditLeads;
