import React, { useEffect, useState } from "react";
import ViewIcon from "../../../../Components/Icons/ViewIcon";
import { GetAccess } from "../../../../Components/Utils/roles";
import EditIcon from "../../../../Components/Icons/Edit";
import DeleteIcon from "../../../../Components/Icons/Delete";
import ModalContainer from "../../../../Components/Others/ModalContainer";
import { useDeleteData } from "../../../../Components/Hooks/useDeleteData";
import { useFormik } from "formik";
import * as yup from "yup";
import { NuInput, NuTextArea } from "../../../../Components/Utils/Input";
import axiosInstance from "../../../../Services/axiosCommon";
import { useToast } from "../../../../Components/Others/ToastServices";
import CircleXIcon from "../../../../Components/Icons/CircleXIcon";
import CircleCheckIcon from "../../../../Components/Icons/CircleCheckIcon";


function ViewMore({ getData, data }) {
  const toast = useToast();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isEdit, setIsEdit] = useState(false)
  const [isEnable,setIsEnable] = useState(false);
  const { handle } = useDeleteData(getData);
  const handleDelete = async () =>
    handle(`/email_templates/${data?.id}`, setIsModalOpen);

  const [createNew, setCreateNew] = useState({
    subject:"",
    message:"",
    thanksMessage:"",
    enable:""
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({}),
    onSubmit: async (value, { resetForm }) => {
      await axiosInstance.put(`/email_templates/${data?.id}`,value).then(res=>{
        if(res?.data?.status==true){
          toast.open({
            type:"success",
            message:"Success",
            description: res.data?.msg || "Created Successfully."
          })
          resetForm();
          getData()
          setIsEdit(false);
        }else{
          toast.open({
            type:"warning",
            message:"Warning",
            description: res.data?.msg ||res.data.error ||"Warning."
          })
        }
      }).catch(err=>{
        toast.open({
          type:"error",
          message:"Error",
          description:  "Network error."
        })
      })
    },
  });

  useEffect(() => {
    setCreateNew({
      subject:data?.subject||"",
      message:data?.message||"",
      thanksMessage:data?.thanksMessage||"",
      enable:data?.enable||""
    })
  },[data])

  function CloseEdit(){
    formik.resetForm()
    setIsEdit(false)
  }


  async function EnableOrDisable(){
    let value = {...data}
    value.enable = data?.enable == true ? false : true
    await axiosInstance.put(`/email_templates/${data?.id}`,value).then(res=>{
      if(res?.data?.status==true){
        toast.open({
          type:"success",
          message:"Success",
          description: res.data?.msg || "Created Successfully."
        })
        getData()
        setIsEnable(false);
      }else{
        toast.open({
          type:"warning",
          message:"Warning",
          description: res.data?.msg ||res.data.error ||"Warning."
        })
      }
    }).catch(err=>{
      toast.open({
        type:"error",
        message:"Error",
        description:  "Network error."
      })
    })
  }

  return (
    <>
      <div className=" w-full flex  justify-start items-center">
        <div className=" w-full flex justify-center gap-2 ">
          <div className="  overflow-hidden flex justify-center items-center">
            <span className=" cursor-pointer" onClick={() => setIsView(true)}>
              <ViewIcon color="#048178" />
            </span>
          </div>
          {GetAccess("") && (
            <div className="  overflow-hidden flex justify-center items-center">
              <span className=" cursor-pointer" onClick={()=>setIsEdit(true)}>
                <EditIcon color="skyblue" width={"12"} height={"12"} />
              </span>
            </div>
          )}
          {GetAccess("") && (
            <div className="  overflow-hidden flex justify-center items-center">
              <span className=" cursor-pointer" onClick={()=>setIsEnable(true)}>
              {data?.enable === true ? (
              <CircleCheckIcon color="green" /> 
            ) : (
              <CircleXIcon color="red" /> 
            )}
              </span>
            </div>
          )}
          {/* {GetAccess("") && (
            <div className=" w-[25%] overflow-hidden flex justify-center items-center">
              <span
                className=" cursor-pointer"
                onClick={() => setIsModalOpen(true)}
              >
                <DeleteIcon height={"3"} width={"3"} color="lightcoral" />
              </span>
            </div>
          )} */}
        </div>
      </div>
      <ModalContainer
        visiable={isModalOpen}
        title="Warning"
        content={`Are you sure, This Email template will  removed from Template list!`}
        handleClick={() => handleDelete()}
        closeModal={() => setIsModalOpen(false)}
      />

<ModalContainer
        visiable={isEnable}
        title="Warning"
        content={`Are you sure, You want ${data?.enable?"disable":"enable"} This Email template!`}
        handleClick={() => EnableOrDisable()}
        closeModal={() => setIsEnable(false)}
      />

      <ModalContainer
        visiable={isView}
        title=""
        content={
          <>
            <div className=" w-full relative ">
              <div className="w-full -mt-3 mb-2 flex justify-between items-center">
                <p className=" font-semibold text-sm 2xl:text-base">View Email Template</p>
                <button
                  className="absolute top-0 right-0 text-white bg-red-600"
                  onClick={() => setIsView(false)}
                >
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className=" w-[60vw] 2xl:w-[50vw]">
                <table className=" w-full">
                  <tbody >
                    <tr className=" my-2">
                      <td className=" w-[20%] text-xs 2xl:text-sm font-medium py-2">Subject&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</td>
                      <td className=" w-[80%] text-xs 2xl:text-sm py-2"> <p>{data?.subject||""}</p></td>
                    </tr>
                    <tr className=" my-2">
                      <td className=" w-[20%] text-xs text-start 2xl:text-sm font-medium py-2">Message&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</td>
                      <td className=" w-[80%] text-xs 2xl:text-sm py-2 overflow-x-auto"><div className=" w-[42vw] overflow-auto">
                      <pre style={{fontFamily:"'Poppins'"}}>{data?.message||""}</pre>
                        </div></td>
                    </tr>
                    <tr className=" my-2">
                      <td className=" w-[20%] text-xs 2xl:text-sm font-medium py-2">Thank Message&nbsp;:</td>
                      <td className=" w-[80%] text-xs 2xl:text-sm py-2"><p>{data?.thanksMessage||""}</p></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </>
        }
        handleClick={() => {}}
        closeModal={() => setIsView(false)}
        hasButton={false}
        hasContent={true}
      />




<ModalContainer
        visiable={isEdit}
        title=""
        content={
          <>
            <div className=" w-full relative ">
              <div className="w-full -mt-3 mb-2 flex justify-between items-center">
                <p className=" font-semibold text-sm 2xl:text-base">Edit Email Template</p>
                {/* <button
                  className="absolute top-0 right-0 text-white bg-red-600"
                  onClick={() => setIsView(false)}
                >
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button> */}
              </div>
              <div className=" w-[60vw] 2xl:w-[50vw]">
                <form onSubmit={formik.handleSubmit}>
                  
                  <NuInput
              type="text"
              label="Subject"
              placeholder="Enter Subject"
              formik={formik}
              name="subject"
              width="w-full  md:-mt-2"
            />
            <NuTextArea
              label="Message"
              type="text"
              formik={formik}
              placeholder="Enter Message"
              name="message"
              new_width="w-full"
              row="6"
            />
                           <NuTextArea
              label="Thanks Message"
              type="text"
              formik={formik}
              placeholder="Enter Thanks Message"
              name="thanksMessage"
              new_width="w-full"
            />
                          <div className="flex justify-end">
                <button
                  type="button"
                  onClick={CloseEdit}
                  className="px-5 py-[9px] cursor-pointer text-xs 2xl:text-sm bg-[#E6F2F2]  hover:bg-gray-300
                  rounded-md flex items-center mr-2 text-textColor-main"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-[#048178] px-5 py-[9px] cursor-pointer outline-none border-none text-white rounded-md text-xs 2xl:text-sm flex items-center"
                >
                  Submit
                </button>
              </div>
                </form>
              </div>
            </div>
          </>
        }
        handleClick={() => {}}
        closeModal={() => setIsView(false)}
        hasButton={false}
        hasContent={true}
      />
    </>
  );
}

export default ViewMore;
