import React from "react";
import UseGetDashBoardData from "../../../../Components/Hooks/useDashboardData";
import NuAmount from "../../../../Components/Utils/NuAmount";

export default function PurchaseOrderstoSuppliers() {
  const { data, loading } = UseGetDashBoardData(
    `/dashboard/purchaseOrderStats`
  );
  const percentage = parseFloat(data?.percentage_last_month);
  const isNegative = percentage < 0;
  return (
    <div className="bg-white p-4 rounded-lg drop-shadow-lg max-h-[25vh] w-full">
      <div className="flex">
        <div className="bg-baseColor-teritary rounded-full p-2 2xl:p-3 h-[18%]  mt-4 ml-2">
          <svg
            width="22"
            height="22"
            xmlns="http://www.w3.org/2000/svg"
            fill="#048178"
            viewBox="0 0 576 512"
          >
            <path d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
          </svg>
        </div>
        <div className="pl-8 h-[96%] flex flex-col gap-1">
          <div className="flex ">
            <p className="text-[#4F4768] text-xs 2xl:text-sm font-semibold ">
              PO to Suppliers
            </p>
          </div>
          <div className="flex gap-2">
            <p className="text-[#333333] text-[22px]  font-semibold ">
              {data?.total_purchase_orders || 0}
            </p>
            <p className="text-[#ACACAC] text-[10px] font-light text-center mt-2">
              <NuAmount value={data?.total_price || 0} />
            </p>
          </div>
          <div className="flex flex-wrap">
            <p className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="18"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d={`M10 ${isNegative ? "17L10 5" : "5L10 17"}`}
                  stroke={isNegative ? "#FF0000" : "#048178"}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d={`M${
                    isNegative
                      ? "15.8337 10.0001L10.0003 15.8334L4.16699 10.0001"
                      : "4.16699 10.0001L10.0003 4.16675L15.8337 10.0001"
                  }`}
                  stroke={isNegative ? "#FF0000" : "#048178"}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p
                className={`text-${
                  isNegative ? "red-500" : "[#048178]"
                } text-[10px] 2xl:text-xs`}
              >
                {percentage}{" "}
                <span className="pl-[2px] text-[10px] text-[#4F4768]">
                  this month
                </span>
              </p>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
