import React, { useEffect, useState } from 'react'
import { TextMediumSM } from '../../../../Components/Text/MediumText'
import Breadcrumb from '../../../../Components/Utils/Breadcrumb'
import { BackButton, CancelButton, CreateButton, SubmitButton } from '../../../../Components/Utils/Buttons'
import axiosInstance from '../../../../Services/axiosCommon'
import { useFormik } from 'formik'
import UseEditData from '../../../../Components/Hooks/useEditData'

function MainNotification() {
  const [data,setData] = useState([])
  const {Oncancel,editData,isLoading} = UseEditData();



  function getData() {
    axiosInstance.get(`/alert-settings/action`).then(res=>{
      setData(res?.data?.data)
    }).catch(err=>{
      console.log(err);
    })
  }

  useEffect(()=>{
    getData()
  },[]) 

  function handleChange(event, name, id) {
    const updatedData = data.map((item) =>
      item.name === name && item.id ===id ? { ...item, isDefault: event.target.checked } : item
    );
    setData(updatedData);
    console.log("Updated Data:", updatedData);
  }
  
  function handleAlertChange(event, name, id) {
    const updatedData = data.map((item) =>
      item.name === name && item.id ===id ? { ...item, alertEnabled: event.target.checked } : item
    );
    setData(updatedData);
    console.log("Updated Data:", updatedData);
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: "",
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (value,{resetForm}) => {
      let noti={
        data:data
      }
      editData(`/alert-settings/edit-alerts`,noti,resetForm,`/settings/notifications`)
      getData()
    }
  })

  return (
    <div className="w-full h-full px-4 py-2 overflow-hidden">
    <div className="w-full flex justify-between items-center">
      <Breadcrumb
        items={[
          {
            name: "Settings",
          },
          {
            name: "Notifications",
          },
        ]}
      />
      <BackButton />
    </div>
    <div className="w-full h-[calc(100vh-145px)] bg-white mt-3 mx-auto  p-2 rounded-md overflow-y-scroll">
      <div className=" w-full flex justify-between items-center">
        <TextMediumSM content="Notifications List" />
      </div>
<form onSubmit={formik.handleSubmit}>
      <table className=' w-full max-h-[calc(100vh-200px)] overflow-y-auto mt-2 '>
       <thead>
       <tr className=''>
          <th className=' w-[33.3%] text-left text-sm font-medium py-2 px-4'>Module Name</th>
          <th className=' w-[33.3%] text-center text-sm font-medium py-2 px-4'>Alert</th>
          <th className=' w-[33.3%] text-center text-sm font-medium py-2 px-4'>Is Default</th>
        </tr>
       </thead>
       <tbody>
        {
          data && data.map((value, index) => {
            return (
              <tr className="even:bg-white odd:bg-slate-50 " key={index}>
                <td className=" w-[33.3%] py-2 text-left text-sm px-4">
                  {value?.name || "-"}
                </td>
                <td className=" w-[33.3%] py-2 text-center text-sm px-4">
                  <label className="inline-flex items-center cursor-pointer overflow-hidden">
                    <input type="checkbox" 
                      checked={value?.alertEnabled == true ? true : false}
                      onChange={(e) => handleAlertChange(e, value?.name,value?.id)}
                     className="sr-only peer" hidden />
                    <div className="relative w-9 h-5 bg-gray-200  rounded-full peer dark:bg-gray-300 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] peer-checked:after:bg-white after:bg-textColor-main after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all  peer-checked:bg-textColor-main"></div>
                  </label>
                </td>
                <td className=" w-[33.3%] py-2 text-center text-sm px-4">
                  <input
                    type="checkbox"
                    checked={value?.isDefault== true ? true : false}
                    onChange={(e) => handleChange(e, value?.name,value?.id)}
                  />
                </td>
              </tr>
            );
          })
        }
       </tbody>
      </table>
      <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                {/* <CancelButton handleClick={() => {  }} /> */}
                {/* <CreateButton svg={false} label='Submit' handleClick={()=>{}} /> */}
                <SubmitButton />
              </div>
            </div>
</form>
    </div>
  </div>
  )
}

export default MainNotification