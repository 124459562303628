import React, { useCallback, useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Navbar, SideBar } from "./Layouts/MainPage";
import { SidebarItem } from "./Layouts/MainPage/SideBar";
import { useTranslation } from "react-i18next";
import Dashboard from "./Components/Icons/Dasboard";
import Task from "./Components/Icons/Task";
import Maintanance from "./Components/Icons/Maintanance";
import Products from "./Components/Icons/Products";
import Marketing from "./Components/Icons/Marketing";
import Procurement from "./Components/Icons/Procurement";
import Team from "./Components/Icons/Team";
import Report from "./Components/Icons/Report";
import Chat from "./Components/Icons/Chat";
import Payments from "./Components/Icons/Payments";
import Sales from "./Components/Icons/Sales";
import Store from "./Components/Icons/Store";
import Shipping from "./Components/Icons/Shipping";
import { GetDashboardRole, GetMultipleAccess, GetRole } from "./Components/Utils/roles";

const App = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();


    // handle what happens on key press
    const handleKeyPress = useCallback((event) => {
      if(event.key === "Escape") {
        navigate(-1);
      }
    }, []);
  
    useEffect(() => {
      // attach the event listener
      document.addEventListener('keydown', handleKeyPress);
  
      // remove the event listener
      return () => {
        document.removeEventListener('keydown', handleKeyPress);
      };
    }, [handleKeyPress]);


  function isActiveFun(x){
    let actualPath = location.pathname.split("/")[1]
    let linkPath = ""
    if(x?.hasSubMenu){
      if(x?.subMenu){
         let y = x.subMenu[0].link;
         let z = y.split("/")[0]
         linkPath = z
      }
    }else{
      linkPath = x?.link;
    }
    if(actualPath == linkPath) {
      return true;
    }else{
      return false;
    }
  }


  const navItem = [
    {
      icon: <Dashboard/>,
      title: "Dashboard",
      hasSubMenu: false,
      hasAccess:true,
      link: GetDashboardRole("")?"dashboard":GetDashboardRole("sales")?"/dashboard/sales":GetDashboardRole("procurement")?"/dashboard/procurement":GetDashboardRole("store")?"/dashboard/stores":"/dashboard",
    },
    {
      icon: <Products/>,
      title: "Product",
      link: "products/items",
      hasSubMenu: false,
      hasAccess:["catalogue_view","items_view"]
      // subMenu: [
        // {
        //   name: "Catalogues",
        //   link: "products/catalogues",
        // },
      //   {
      //     name: "Items",
      //     link: "products/items",
      //   },
      // ],
    },
    {
      icon: <Marketing/>,
      title: "Marketing",
      hasSubMenu: true,
      hasAccess:["appointments_view","leads_view"],
      subMenu: [
        {
          name: "Appointment",
          link: "marketing/appointments",
          hasAccess:'appointments_view'
        },
        {
          name: "Lead",
          link: "marketing/Leads",
          hasAccess:'leads_view'
        },
      ],
    },
    {
      icon: <Procurement/>,
      title: "Procurement",
      hasAccess:["purchase_request_view","rfq_view","supplier_quote_view","quote_compare_view","purchase_order_view","suppliers_view"],
      hasSubMenu: true,
      subMenu: [
        {
          name: "Purchase Request",
          link: "procurement/purchase-requests",
          hasAccess:'purchase_request_view'
        },
        {
          name: "RFQ",
          link: "procurement/rfq",
          hasAccess:'rfq_view'

        },
        // {
        //   name: "Supplier Quote",
        //   link: "procurement/supplier-quotes",
        //   hasAccess:'supplier_quote_view'
        // },
        {
          name: "Quote Comparison",
          link: "procurement/quote-comparison",
          hasAccess:'quote_compare_view'

        },
        {
          name: "Purchase Order",
          link: "procurement/purchase-order",
          hasAccess:'purchase_order_view'

        },
        // {
        //   name: "Contracts",
        //   link: "procurement/contracts",
        // },
        {
          name: "Supplier",
          link: "procurement/suppliers",
          hasAccess:'suppliers_view'

        },
        {
          name: "Product Comparison",
          link: "procurement/product-comparison",
          hasAccess:'suppliers_view'

        },
        {
          name: "Team Performance",
          link: "procurement/team-performance",
          hasAccess: GetDashboardRole("management")

        }
      ],
    },
    {
      icon: <Sales/>,
      title: "Sales",
      hasAccess:["customers_view","customer_quote_view","customer_order_view","sales_order_view","invoice_in_view","invoice_out_view","promotions_view"],
      hasSubMenu: true,
      subMenu: [
        {
          name: "Customer",
          link: "sales/customers",
          hasAccess:'customers_view'

        },
        {
          name: "Quote",
          link: "sales/quotes",
          hasAccess:'customer_quote_view'

        },
        // {
        //   name: "Customer Order",
        //   link: "sales/customer-order",
        //   hasAccess:'customer_order_view'

        // },
        {
          name: "Sales Order",
          link: "sales/sales-orders",
          hasAccess:'sales_order_view'

        },
        {
          name: "Invoices Incoming",
          link: "sales/invoice_incoming",
          hasAccess:'invoice_in_view'

        },
        {
          name: "Invoices Outgoing",
          link: "sales/invoice_outgoing",
          hasAccess:'invoice_out_view'

        },
        {
          name: "Promotion",
          link: "sales/promotions",
          hasAccess:'promotions_view'

        },
      ],
    },
    {
      icon: <Payments/>,
      title: "Payment",
      hasAccess:["payables_view","receivables_view"],
      hasSubMenu: true,
      subMenu: [
        {
          name: "Payable",
          link: "payments/payables",
          hasAccess:'payables_view'

        },
        {
          name: "Receivable",
          link: "payments/receivables",
          hasAccess:'receivables_view'

        },
        // {
        //   name: "Refunds",
        //   link: "payments/refunds",
        // },
      ],
    },
    // {
    //   icon: <Task/>,
    //   title: "Tasks",
    //   hasSubMenu: true,
    //   subMenu: [
    //     {
    //       name: "My Tasks",
    //       link: "tasks/all-tasks",
    //     },
    //     {
    //       name: "All Tasks",
    //       link: "tasks/my-tasks",
    //     },
    //   ],
    // },
    {
      icon: <Store/>,
      title: "GRN",
      hasSubMenu: false,
      link: "store/grn",
      hasAccess: ["grn_view",]
    },
    // {
    //   icon: <Store/>,
    //   title: "Store",
    //   hasAccess:["grn_view","inventory_view","assets_view","delivery_notes_view","packing_list_view","returns_view","requests_view"],
    //   hasSubMenu: true,
    //   subMenu: [
    //     {
    //       name: "GRN",
    //       link: "store/grn",
    //       hasAccess:'grn_view'

    //     },
    //     {
    //       name: "Inventory",
    //       link: "store/inventory",
    //       hasAccess:'inventory_view'

    //     },
    //     {
    //       name: "Asset",
    //       link: "store/assets",
    //       hasAccess:'assets_view'

    //     },       
    //     {
    //       name: "Delivery Note",
    //       link: "store/delivery-note",
    //       hasAccess:'delivery_notes_view'

    //     },
    //     {
    //       name: "Packing List",
    //       link: "store/packing-list",
    //       hasAccess:'packing_list_view'

    //     },
    //     {
    //       name: "Return",
    //       link: "store/returns",
    //       hasAccess:'returns_view'

    //     },
    //     {
    //       name: "Request",
    //       link: "store/requests",
    //       hasAccess:'requests_view'

    //     },
    //   ],
    // },
    {
      icon: <Shipping/>,
      title: "Shipping",
      hasAccess:["agents_view","shipment_tracking_view"],
      hasSubMenu: true,
      subMenu: [
        {
          name: "Shipping Agent",
          link: "shipping/agents",
          hasAccess:'agents_view'

          
        },
        {
          name: "Shipment Tracking",
          link: "shipping/shipment-tracking",
          hasAccess:'shipment_tracking_view'

        },
      ],
    },
    {
      icon: <Maintanance/>,
      title: "Maintenance",
      hasAccess:["vehicle_view","equipment_view"],
      hasSubMenu: true,
      subMenu: [
        {
          name: "Vehicle",
          link: "maintenance/vehicle",
          hasAccess:'vehicle_view'
        },
        {
          name: "Equipment",
          link: "maintenance/equipment",
          hasAccess:'equipment_view'
        },
      ],
    },
    // {
    //   icon: <Chat/>,
    //   title: "Chat",
    //   hasSubMenu: false,
    //   link: "chat",
    //   hasAccess: true
    // },
    // {
    //   icon: <Team/>,
    //   title: "Team",
    //   hasSubMenu: false,
    //   link: "team",
    // },
    // {
    //   icon: <Report/>,
    //   title: "Report",
    //   hasAccess:["report_view"],
    //   hasSubMenu: false,
    //   link: "reports",
    // },
  ];

  return (
    <div className=" w-full h-screen bg-slate-100 flex justify-start items-center overflow-hidden">
      <SideBar>
        {navItem && 
          navItem.map((item,idx) => {
            
            return (
              <>
              {
                (item?.hasAccess == true||GetMultipleAccess(item?.hasAccess||[]) )&&(<SidebarItem
                    icon={item?.icon}
                    text={t(item?.title)}
                    hasSubMenu={item?.hasSubMenu}
                    subItem={item?.subMenu}
                    link={item?.link}
                    active={isActiveFun(item)}
                    key={idx}
                  />
                )
              }
              </>
            );
          })}
      </SideBar>

      <div className=" w-full h-[100vh]  ">
        <div className=" w-full h-[59px] bg-white z-50 shadow-lg">
          <Navbar />
        </div>
        <div className=" w-full h-[calc(100vh-87px)]  overflow-y-auto">
          <Outlet />
        </div>
        {/* <div className=" w-full h-7 ">
          <p className=' font-semibold text-center text-s text-slate-400'>
            <a
             href="https://numota.in"
             target="_blank"
            >
              &#169;  numota
            </a>
            </p>
        </div> */}
      </div>
    </div>
  );
};

export default App;
