import React, { useEffect, useState } from "react";
import axiosInstance from "../../Services/axiosCommon";
import ModalContainer from "../Others/ModalContainer";

function PDFViewWithAPI({ item }) {
  console.log("item", item);

  const [show, setShow] = useState(false);
  const [viewPDF, setViewPDF] = useState(null)
  const [loading,setLoading] = useState(true);


  useEffect(() => {
    if(show ==true){
        downloadPDF();
    }
  },[show])


  const downloadPDF = async () => {
    try {
        const response = await axiosInstance.get(`${item?.link}`, {
            responseType: 'blob' 
        });
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
        let PDFValue = window.URL.createObjectURL(pdfBlob);
        setViewPDF(PDFValue);
        setLoading(false);
    } catch (error) {
        console.error('Error downloading the PDF:', error);
        setLoading(false);
    }
};
  return (
    <>
      <div className="cursor-pointer m-1 border rounded p-[2px] flex justify-start items-center   w-auto h-[26px]"
       onClick={()=>setShow(true)}
      >
        <svg
          width="20"
          height="20"
          fill="#048178"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 144-208 0c-35.3 0-64 28.7-64 64l0 144-48 0c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z" />
        </svg>
        <p className=" w-auto text-[10px] ">{item?.name||""}</p>
      </div>

      <ModalContainer
        visiable={show}
        title=""
        hasButton={false}
        content={<>
        <div className=" w-[95vw] relative h-[92vh]">
        <div className="w-full -mt-3 mb-2 flex justify-between items-center">
            <p className=" font-semibold text-sm">PDF File View</p>
            <button
             className="absolute top-0 right-0 text-white bg-red-600"
             onClick={() => setShow(false)}
      >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
          </div>
          <div className=' w-full h-full'>
              {
                viewPDF ?
                <iframe
                          src={viewPDF} // PDF URL or Blob URL
                          style={{ width: '100%', height: '100%' }}
                          frameBorder="0"
                        />

                : loading == true?<div className=" w-full h-full flex justify-center items-center">
                <p>Loading ....</p>
            </div>:
                <div className=" w-full h-full flex justify-center items-center">
                    <p>No File to View</p>
                </div>
              }      
          </div>
        </div>
        </>}
        closeModal={()=>setShow(false)}
        />
    </>
  );
}

export default PDFViewWithAPI;
