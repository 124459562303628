import React from 'react';

const PDFTemplateIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      width="20"
      height="20"
      fill="none"
      stroke="gray"
      strokeWidth="2"
    >
      <rect x="10" y="2" width="40" height="60" rx="3" fill="lightgray" />
      <path d="M14 6h30v14h10v36H14V6z" fill="white" />
      <rect x="20" y="20" width="24" height="4" rx="2" fill="gray" />
      <rect x="20" y="28" width="16" height="4" rx="2" fill="gray" />
      <rect x="20" y="36" width="24" height="4" rx="2" fill="gray" />
      <rect x="20" y="44" width="16" height="4" rx="2" fill="gray" />
      <text x="32" y="54" font-size="10" fill="red" font-family="Arial" font-weight="bold">PDF</text>
    </svg>
  );
};

export default PDFTemplateIcon;
