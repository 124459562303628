import axios from "axios";
import { useEffect, useState } from "react";
import Breadcrumb from "../../../Components/Utils/Breadcrumb";
import { Link, useNavigate } from "react-router-dom";
import { BackButton, CancelButton, CreateButton, EditButton, NotificationButton, SubmitButton } from "../../../Components/Utils/Buttons";
import NuPills from "../../../Components/Utils/Nupills";
import DecryptData from "../../../Components/Utils/Decrypt";
import axiosInstance from "../../../Services/axiosCommon";
import { NuInput, NuInputSetting, SupplierNuInput } from "../../../Components/Utils/Input";
import CustomImgUpload from "../../../Components/Utils/CustomImgUpload";
import { useFormik } from "formik";
import socketAxiosInstance from "../../../Services/socketAxiosCommon";
import { useToast } from "../../../Components/Others/ToastServices";
import * as yup from 'yup';
import EditIcon from "../../../Components/Icons/Edit";
import CustomImgUploadSquare from "../../../Components/Utils/CustomImgUploadSquare";

function UserProfile() {
  const navigate = useNavigate();
  const toast = useToast();
  const userDetails = DecryptData("sLiAtEdReSuEdArT")||"";
console.log(userDetails,'khgh');
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [user, setUser] = useState({});
  const [userEmailDetails,setUserEmailDetails] = useState({});
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('image');
  const [openEdit,setOpenEdit] = useState(false);
  console.log("search",userEmailDetails?.senderEmail);
  const [createNew, setCreateNew] = useState({
    senderEmail: "",
    password: "",
    signature: "" 
  });

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  const handleDeleteImg = () => {
    setImage({
      preview: "",
      raw: "",
    });
    setCreateNew(prevState => ({ ...createNew, signature :"" }))
    formik.values.signature = ''
  }

  const getUserDetails = async () => {
    try {
      const res = await axiosInstance.get(`/user/user-details/${userDetails?.id}`); 
            setUser(res?.data?.data || {});
    } catch (error) {
      console.error(error);
    }
  };

  const getUserEmailDetails = async () => {
    try {
      const res = await axiosInstance.get(`/user/${userDetails?.id}/email-details`); 
      console.log("finding",res?.data?.data);
            setUserEmailDetails(res?.data?.data || {});
    } catch (error) {
      console.error(error);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validationSchema:yup.object({

    }),
    onSubmit: async (values, { resetForm }) => {
      let formData = new FormData();
      formData.append("json_data", JSON.stringify(values));
      formData.append("signature", image.raw);
      axiosInstance.put(`/user/edit-email-details/${userDetails?.id}`, formData)
        .then((res) => {
          if (res.data.status) {
            resetForm();
            toast.open({
              type: "success",
              message: "Sender Email reset successfully",
              description: res.data?.msg || "Password has been updated.",
            });
          }
          getUserEmailDetails();
          setOpenEdit(false);
        })
        .catch((err) => {
          toast.open({
            type: "error",
            message: "Sender Email reset failed",
            description: err.response?.data?.msg || "Network Error",
          });
        });
    },
  });

  useEffect(() => {
    getUserDetails();
    getUserEmailDetails();
  }, []);

  useEffect(()=>{
    setCreateNew({
      senderEmail: userEmailDetails?.senderEmail,
      password: userEmailDetails?.password,
      signature: userEmailDetails.signature?.file
    })
  },[userEmailDetails])

 
  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between">
        <Breadcrumb items={[{ name: "User Profile" }]} />
        <BackButton />
      </div>
      <div className="w-full h-[calc(100vh-150px)]">
        <h2 className="text-sm font-semibold text-[#4F4768] p-4 mb-4">
          User Profile View :
        </h2>
        <div className="flex gap-4">
          <div className="w-[30%] mx-auto py-7 h-96 lg:h-80 flex justify-center items-center rounded-md bg-white border-4 border-[#048178]/90 border-r-0 border-y-0 shadow-lg">
            <div className="px-4 flex flex-col justify-center items-center">
              <div className="w-36 h-36 rounded-full bg-[#048178] flex justify-center items-center">
                <div className="w-[124px] h-[124px] rounded-full bg-[#92bab9] flex justify-center items-center">
                  <div className="w-[110px] h-[110px] rounded-full bg-white border-blue-100 border font-medium text-black text-3xl capitalize flex justify-center items-center overflow-hidden">
                    {user.userImage?.file?.length > 0 ? (
                      <img
                        src={user.userImage?.file}
                        alt="user"
                        className="rounded-full w-full h-full"
                      />
                    ) : (
                      user?.name?.charAt(0)
                    )}
                  </div>
                </div>
              </div>
              <div className="text-center bg-white rounded-lg drop-shadow-lg w-full justify-center items-center mt-5">
                <ul className="list-none pt-5">
                  <li>
                    <p className="text-sm text-mainblack-500 font-semibold">
                      {user?.name || "-"}
                    </p>
                  </li>
                  <li>
                    <p className="font-medium text-xs text-mainblack-300">
                      {user?.designation || "-"}
                    </p>
                  </li>
                  <li>
                    <div className="w-3/12 my-3 mx-10 text-center">
                      <NuPills
                        width="w-16"
                        bgColor="bg-[#E7F9E7]"
                        textColor="text-[#39D42B]"
                        fontWight="font-semibold"
                        fontSize="text-[12px]"
                      >
                        {user?.status === 1 ? "Active" : "Inactive"}
                      </NuPills>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="w-[35%] h-96 lg:h-80 bg-white shadow-lg rounded-lg">
            <div className=" w-full h-full p-4 overflow-y-scroll">
              <div className=" w-full flex flex-wrap h-full p-2  rounded-lg">
                {/* <div className=" w-full grid grid-cols-2 gap-x-8 "> */}
               <p className="text-[14px] font-semibold">User Details</p>
                <div className="w-full flex px-2">
                  <p className="w-[40%] py-2 text-xs font-medium text-[#111537]">
                    Email
                  </p>
                  <p className="w-[60%] py-2 text-xs font-medium text-[#606279]">
                    : {user?.email || "-"}
                  </p>
                </div>
                {/* <div className="w-full flex px-2">
              <p className="w-[40%] py-2 text-xs font-medium text-[#111537]"> 
              Sender Email
              </p>
              <p className="w-[60%] py-2 text-xs font-medium text-[#606279]">
              : {user?.mailSenderEmail || '-'}
              </p>
            </div> */}
                <div className="w-full flex px-2">
                  <p className="w-[40%] py-2 text-xs font-medium text-[#111537]">
                    Phone
                  </p>
                  <p className="w-[60%] py-2 text-xs font-medium text-[#606279]">
                    : {user?.phone?.display || "-"}
                  </p>
                </div>
                <div className="w-full flex px-2">
                  <p className="w-[40%] py-2 text-xs font-medium text-[#111537]">
                    Division
                  </p>
                  <p className="w-[60%] py-2 text-xs font-medium text-[#606279]">
                    : {user?.division || "-"}
                  </p>
                </div>
                <div className="w-full flex px-2">
                  <p className="w-[40%] py-2 text-xs font-medium text-[#111537]">
                    Desigination
                  </p>
                  <p className="w-[60%] py-2 text-xs font-medium text-[#606279]">
                    : {user?.designation || "-"}
                  </p>
                </div>
                {/* <div className="w-full flex px-2">
              <p className="w-[40%] py-2 text-xs font-medium text-[#111537]"> 
              Country
              </p>
              <p className="w-[60%] py-2 text-xs font-medium text-[#606279]">
              : {user?.country || "-"}
              </p>
            </div> 
            <div className="w-full flex px-2">
              <p className="w-[40%] py-2 text-xs font-medium text-[#111537]"> 
              State
              </p>
              <p className="w-[60%] py-2 text-xs font-medium text-[#606279]">
              : {user?.state || "-"}
              </p>
            </div>  */}

                {/* <div className="w-full flex px-2">
              <p className="w-[40%] py-2 text-xs font-medium text-[#111537]"> 
              District
              </p>
              <p className="w-[60%] py-2 text-xs font-medium text-[#606279]">
              : {user?.district || "-"}
              </p>
              </div>
              <div className="w-full flex px-2">
              <p className="w-[40%] py-2 text-xs font-medium text-[#111537]"> 
              City
              </p>
              <p className="w-[60%] py-2 text-xs font-medium text-[#606279]">
              : {user?.city || "-"}
              </p>
              </div>
               <div className="w-full flex px-2">
              <p className="w-[40%] py-2 text-xs font-medium text-[#111537]"> 
              Street Address Line 1
              </p>
              <p className="w-[60%] py-2 text-xs font-medium text-[#606279]">
              : {user?.address1 || "-"}
              </p>
            </div>
            <div className="w-full flex px-2">
              <p className="w-[40%] py-2 text-xs font-medium text-[#111537]"> 
              Street Address Line 2
              </p>
              <p className="w-[60%] py-2 text-xs font-medium text-[#606279]">
              : {user?.address2 || "-"}
              </p>
            </div>
           
             <div className="w-full flex px-2">
              <p className="w-[40%] py-2 text-xs font-medium text-[#111537]"> 
              Postal Code
              </p>
              <p className="w-[60%] py-2 text-xs font-medium text-[#606279]">
              : {user?.postalCode || "-"}
              </p>
           
            
       </div> */}

                {/* </div> */}
              </div>
              {/* <div className="flex gap-4 mt-1">
          <div className="w-[50%] ">
        <p className="  text-xs font-semibold text-[#111537] p-1">
         Address 1
            </p>
        <div className="  border border-gary  h-28 overflow-y-scroll rounded-lg items-center">
           
            <p className="  text-xs p-2 break-words text-[#606279]">
              {user.address1 || "-"}
            </p>
          </div>
         </div>
          <div className="w-[50%] ">
          <p className=" p-1 text-xs font-semibold text-[#111537]">
           Address 2
            </p>
          <div className=" border border-gary  overflow-y-scroll h-28 rounded-lg p-2">
          <p className="  text-xs p-2 break-words text-[#606279]">
              {user.address2 || "-"}
            </p>
    </div>
    </div>
    </div> */}
            </div>
          </div>
          <div className="w-[35%] h-96 lg:h-80 bg-white shadow-lg rounded-lg  overflow-y-scroll ">
            <div className=" w-full p-4">
              <div className=" w-full flex flex-wrap h-full p-2 rounded-lg">
                <div className="w-full flex justify-between">
                  <p className="text-[14px] font-semibold">Email Settings</p>
                  <div
                    className="cursor-pointer"
                    onClick={() => setOpenEdit(true)}
                  >
                    <EditIcon color="skyblue" />
                  </div>
                </div>
                <div className="w-full flex flex-col">
                  <NuInput
                    label="Sender Email"
                    value={userEmailDetails?.senderEmail}
                    type="email"
                    formik={formik}
                    placeholder="Sender Email"
                    name="senderEmail"
                    width="w-full"
                    px="0"
                    disabled={true}
                  />
                  <NuInput
                    label="Email Password"
                    value={userEmailDetails?.password}
                    type="password"
                    formik={formik}
                    placeholder="Sender Email"
                    name="password"
                    width="w-full"
                    px="0"
                    disabled={true}
                  />
                </div>
                <div className="w-full flex flex-col px-2">
                  <p className="w-[40%] py-2 text-xs font-medium text-[#111537]">
                    Signature
                  </p>
                  <div className="w-[60%] py-2 text-xs font-medium text-[#606279] flex gap-3">
                    <div className="w-full h-20 border border-black rounded-md">
                      <img
                        src={userEmailDetails.signature?.file}
                        alt="alt"
                        className="w-full h-full object-cover rounded-lg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full p-4">
          <h2 className="text-sm text-[#4F4768] font-semibold mt-4">
            Settings :
          </h2>
          <div className="w-full flex items-center gap-6 p-4">
            <div className="flex justify-between gap-4 items-center my-2">
              <p className="font-normal text-xs text-[#4F4768]">Profile</p>
              <Link
                to={`/profile/editProfile/${userDetails?.id}`}
                state={{ data: user, from: "/profile" }}
              >
                <EditButton className="text-xs" height={8}>
                  Edit
                </EditButton>
              </Link>
            </div>
            <div className="flex justify-between gap-4 items-center my-2">
              <p className="font-normal text-[#4F4768] text-xs">
                Change Password
              </p>
              <Link to={`/profile/PasswordProfile/${userDetails?.id}`}>
                <CreateButton label="Change" />
              </Link>
            </div>
            <div className="flex justify-between gap-4 items-center my-2">
              <p className="font-normal text-[#4F4768] text-xs">
                Email & Notification
              </p>
              <Link to={`/profile/notificationProfile/${userDetails?.id}`}>
                <NotificationButton
                  className="text-xs"
                  onClick={() => setOpen(true)}
                  label="Change"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      {openEdit && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
          <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
            <h2 className="text-sm mb-1">Edit Email Settings</h2>
            <form onSubmit={formik.handleSubmit}>
              <div className=" w-full">
                <div className=" w-full flex flex-wrap h-full p-2 rounded-lg">
                  <div className="w-full flex flex-col">
                    <NuInput
                      label="Sender Email"
                      type="email"
                      formik={formik}
                      placeholder="Sender Email"
                      name="senderEmail"
                      width="w-full"
                      px="0"
                    />
                    <NuInput
                      label="Email Password"
                      type="password"
                      formik={formik}
                      placeholder="Sender Email"
                      name="password"
                      width="w-full"
                      px="0"
                    />
                  </div>
                  <div className="w-full flex px-2">
                    <p className="w-[40%] py-2 text-xs font-medium text-[#111537]">
                      Signature
                    </p>
                    <div className="w-[60%] py-2 text-xs font-medium text-[#606279] flex gap-3">
                      :
                      <label className="flex gap-0.5">
                        <input
                          type="radio"
                          value="text"
                          checked={selectedOption === "text"}
                          onChange={handleOptionChange}
                        />
                        Text
                      </label>
                      <label className="flex gap-0.5">
                        <input
                          type="radio"
                          value="image"
                          checked={selectedOption === "image"}
                          onChange={handleOptionChange}
                        />
                        Image
                      </label>
                    </div>
                  </div>
                  <div className="w-full">
                    {selectedOption === "text" && (
                      <div className="w-10/12 mx-auto">
                        <NuInput
                          label="Text Signature"
                          type="text"
                          formik={formik}
                          placeholder="Signature"
                          name=""
                          width="w-full"
                          px="0"
                        />
                      </div>
                    )}
                    {selectedOption === "image" && (
                      <div className="w-10/12 mx-auto">
                        <CustomImgUploadSquare
                          borderStyle="dashed"
                          handleChange={handleChangeImg}
                          label={"Signature"}
                          preview={
                            image.preview ||
                            createNew.signature ||
                            ""
                          }
                          removeFunction={handleDeleteImg}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex justify-end gap-2">
                  <CancelButton
                    handleClick={() => {
                      setOpenEdit(false);
                    }}
                  />
                  <SubmitButton name="Save" />
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserProfile;
