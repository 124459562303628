import React, { useEffect, useState } from 'react'
import UseGetDashBoardData from '../../../../../Components/Hooks/useDashboardData';
import axiosInstance from '../../../../../Services/axiosCommon';

const Equipments = () => {
    // const { data, loading } = UseGetDashBoardData(`/new-dashboard/vehicles-and-equipments`);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
  
    async function getData() {
      setLoading(true);
      try {
        await axiosInstance.get(`/new-dashboard/vehicles-and-equipments`).then((res) => {
          setLoading(false);
          setData(res?.data || []);
        });
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  
    useEffect(() => {
      getData();
    }, []);
  
  
    return (
      <div className=" bg-white p-4 rounded-lg drop-shadow-lg h-[16vh] w-full ">
   
      <div className="flex  scroll-px-36 mt-2 gap-2">
      <div className="bg-baseColor-teritary rounded-full p-2 2xl:p-3 h-[18%]">
         
      <svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
  width="24"
  height="24"
  fill="none"
  stroke="#048178"
  strokeWidth="2"
  strokeLinecap="round"
  strokeLinejoin="round"
>
  <circle cx="12" cy="12" r="3" />
  <path d="M19.4 15a1.8 1.8 0 0 1 0 2.5l-1.8 1.8a1.8 1.8 0 0 1-2.5 0l-2.3-2.3a1.8 1.8 0 0 1 0-2.5" />
  <path d="M4.6 9a1.8 1.8 0 0 1 0-2.5l1.8-1.8a1.8 1.8 0 0 1 2.5 0l2.3 2.3a1.8 1.8 0 0 1 0 2.5" />
  <path d="M19.4 9a1.8 1.8 0 0 1 0 2.5l-1.8 1.8a1.8 1.8 0 0 1-2.5 0l-2.3-2.3a1.8 1.8 0 0 1 0-2.5" />
  <path d="M4.6 15a1.8 1.8 0 0 1 0-2.5l1.8-1.8a1.8 1.8 0 0 1 2.5 0l2.3 2.3a1.8 1.8 0 0 1 0 2.5" />
</svg>



      </div>
          <div className="pl-4 h-[95%] flex flex-col ">
          <p className='text-[#4F4768] text-xs 2xl:text-sm font-normal '> Equipment</p>
          <p className='text-[#333333] text-[18px] font-semibold'>
  {data?.equipments}
</p>   
 
           
            </div>
            
  
            </div>
            </div>
    );
  }

export default Equipments