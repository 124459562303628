import { useEffect, useState } from "react";
import UseGetData from "../../../../Components/Hooks/useFetchData";
import SearchBar from "../../../../Components/Utils/SearchBar";
import {
  CancelButton,
  CreateButton,
  FilterButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import DataTable from "../../../../Components/Others/DataTable";
import { Link } from "react-router-dom";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import MoreOptions from "../../../../Components/Utils/MoreOptions";
import Pagination from "../../../../Components/Utils/Pagenation";
import CustomDataTable from "../../../../Components/Others/CustomDataTable";
import UseGetDataSearch from "../../../../Components/Hooks/useFetchDataBySearch";
import { useFormik } from "formik";
import * as yup from 'yup';
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import { GetAccess } from "../../../../Components/Utils/roles";
import useFetchMDIndex from "../../../../Components/Hooks/useFetchMDIndex";

function AppoinmentIndex() {
 
  const [page, setPage] = useState(1)
    const [pageCount, setPageCount] = useState(20)
    const [filter, setFilter] = useState(false);
    const [showFilterDialog, setShowFilterDialog] = useState(false);
    const {value:Status} = useFetchMDIndex(`/dropdown/dropdown-details/`,'Status_Appoinments',showFilterDialog)
    const {value:Industry} = useFetchMDIndex(`/dropdown/dropdown-details/`,'Industry_Appoinments',showFilterDialog)
  
    const [filterProps, setFilterProps] = useState({
     
      status:"",
      industry:"",
      holderType:"",
      // holderId:""
    });
    
    const [params, setParams] = useState({
      page:page,
      per_page:pageCount,
      search:"",
      status:"",
      industry:"",
      holderType:"",
      // holderId:"",
    });
    const clearFilter = ()=> {
      setParams({...params,status:"", industry:"", holderType:""})
      formik.values.status=""
      formik.values.industry=""
      formik.values.holderType=""

        setFilter(false)
    }
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: filterProps,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
        // email: yup.string().required('Email is required!'),
      }),
      onSubmit: async (value, { resetForm }) => {
        console.log("ffff",value);
        setParams({...params,status:value.status, industry:value.industry, holderType:value.holderType})
        closeFilterDialog();
        setFilter(true)
        setFilterProps({
          status:"",
          industry:"",
          holderType:"",
          // holderId:""
        });
      }
    })
    const { data, totalCount, getData,dataLoading } = UseGetDataSearch( '/appointments/',params);
    const defaultHeader = [
      {
        title: "ID",
        width: "5%",
        field: "appointmentId",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Title",
        width: "10%",
        isLink: true,
        id: "appointmentId",
        link: '/marketing/appointments/view/',
        field: "title",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Industry ",
        width: "10%",
        field: "industry",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Visit Purpose",
        width: "10%",
        field: "visitPurpose",
        textAlign: "center",
        rowAlign: "center",
      },
      
      {
        title: "Reminder",
        width: "10%",
        type: "date-time",
        field: "reminder",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Status ",
        width: "10%",
        field: "status",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Action",
        width: "5%",
        field: "action",
        textAlign: "center",
        rowAlign: "center",
        contents:{
          // fetcher:getData,
          id:"appointmentId",
          removeValue:"title",
          url:`/appointments/`,
          moduleName:"Appoinment",
          from:`/marketing/appointments`,
          editLink:"/marketing/appointments/edit/",
          viewLink:"/marketing/appointments/view/"
  
  
  
        }
      },
    ];
    const availableHeaders = [
      {
        title: "ID",
        width: "5%",
        field: "appointmentId",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Title",
        width: "10%",
        isLink: true,
        id: "appointmentId",
        link: '/marketing/appointments/view/',
        field: "title",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Industry ",
        width: "10%",
        field: "industry",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Visit Purpose",
        width: "10%",
        field: "visitPurpose",
        textAlign: "center",
        rowAlign: "center",
      },
      
      {
        title: "Reminder",
        width: "10%",
        field: "reminder",
        type: "date-time",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Appoinment Date ",
        width: "10%",
        field: "dateTime",
        type: "date-time",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Holder Type",
        width: "10%",
        field: "holderType",
        textAlign: "center",
        rowAlign: "center",
      },
      // {
      //   title: "Holder Id",
      //   width: "10%",
      //   field: "holderId",
      //   textAlign: "center",
      //   rowAlign: "center",
      // },
      {
        title: "Status ",
        width: "10%",
        field: "status",
        textAlign: "center",
        rowAlign: "center",
      },
      // {
      //   title: "Comments",
      //   width: "10%",
      //   field: "comments",
      //   textAlign: "center",
      //   rowAlign: "center",
      // },
     
      {
        title: "Action",
        width: "5%",
        field: "action",
        textAlign: "center",
        rowAlign: "center",
        contents:{
          // fetcher:getData,
          id:"appointmentId",
          removeValue:"title",
          url:`/appointments/`,
          moduleName:"Appoinment",
          from:`/marketing/appointments`,
          editLink:"/marketing/appointments/edit/",
          viewLink:"/marketing/appointments/view/"
  
        }
       
      },
    ];
   

    useEffect(()=>{
      setParams({...params,page:page,per_page:pageCount})
     },[page,pageCount])
     
     const closeFilterDialog = () => {
      setShowFilterDialog(false);
    };

    return (
      <div className='w-full h-full px-4 py-2'>
        <div className='w-full flex justify-between items-center'>
          <Breadcrumb items={[
            { name: "Marketing" },
  
            { name: "Appoinment" },
          ]} />
          <div className=' flex gap-2'>
          <SearchBar
              handleChange={(e) =>{
                setParams({ ...params, search: e.target.value, page:1 });
                setPage(1)
              }}
            />   <FilterButton  handleClick={()=>setShowFilterDialog(true)} />
          {filter && <FilterButton  handleClick={()=>clearFilter()} label='Clear'/>}
        
            {/* <CreateButton label='Import' /> */}
            {GetAccess("appointments_create") && <Link to="/marketing/appointments/create"><CreateButton label='Appoinment' /></Link>}
          </div>
        </div>
  
        <CustomDataTable 
        fetcher={getData}
        data={data} 
        availableHeaders={availableHeaders} 
        defaultHeader={defaultHeader}
        bodyHeight='max-h-[calc(100vh-260px)]'
        height='h-[calc(100vh-145px)]'
        total={totalCount}
        pageIndex={page}
        setPageIndex={setPage}
        dataCount={pageCount}
        setDataCount={setPageCount}
        title='Appoinment List'
        storageName="AppoinmentTable"
        editAccess="appointments_edit"      
        deteleAccess="appointments_delete" 
        dataLoading={dataLoading}
        />
      {showFilterDialog && (
             
             
             <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
               <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
                 <h2 className="text-xl mb-4">Filter Appoinment</h2>
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="flex flex-col mb-4">
                   
                       <FormikSelectTwo
                type="text"
                label="Industry"
                placeholder="Choose"
                Options={Industry}
                formik={formik}
                name="industry"
                        width="w-full"
                      />
                      <FormikSelectTwo
                type="text"
                label="Holder Type"
                placeholder="Choose"
                Options={[
                  { value :"Lead", label:"Lead"},
                  { value :"Customer", label:"Customer"},
                 
                ]}
                formik={formik}
                name="holderType"
                width="w-full"
                />
                       <FormikSelectTwo
                type="text"
                label="Status"
                placeholder="Choose"
                Options={Status}
                formik={formik}
                name="status"
                        width="w-full"
                      />
                    </div>
                   <div className="flex justify-end gap-2">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               </div>
             </div>
           )}
      </div>
    )
  }
  

export default AppoinmentIndex;
