import { useEffect, useState } from 'react'
import axiosInstance from '../../Services/axiosCommon';

function useFetchMataData(url,prop) {
    const [value,setValue] = useState([])
    function getData(){
        axiosInstance.get(url+prop).then(res=>{
            let data = res.data?.data[prop];
            let tempData = [];
            data.forEach((item) => {
              let tempObject = { value: item?.value, label: item?.value };
              tempData.push(tempObject);
            });
            res.data?.data[prop] && setValue(tempData);
        }).catch(err=>{
            console.log("err");
        })
    }
useEffect(()=>{
    getData()
},[])

return {value, getData}||[]
}

export default useFetchMataData