import React from "react";

const DownloadIcon = ({ width = 18, height = 18, color = "#fff" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />

      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          d="M12.5 4V17M12.5 17L7 12.2105M12.5 17L18 12.2105"
          stroke={color}
          stroke-width="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />{" "}
        <path
          d="M6 21H19"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />{" "}
      </g>
    </svg>
  );
};

export default DownloadIcon;
