
import React, { useState } from 'react';
import DesignOne from './DesignOne';
import DEsignTwo from './DEsignTwo';


const TempletePdf = ({ selectedPdf }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className=" p-6 z-10 w-full h-[98%] overflow-y-auto">
      {selectedPdf === '1' ? (
        <div onClick={openModal} className="cursor-pointer">
          <h3 className="text-lg font-semibold mb-4">PDF Template 1</h3>
          <DesignOne />
        </div>
      ) : (
        <div onClick={openModal} className="cursor-pointer">
          <h3 className="text-lg font-semibold mb-4">PDF Template 2</h3>
          <DEsignTwo />
        </div>
      )}

      
      {isModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
          <div className=" relative animate-scale-in w-[98%] h-[98%] bg-white py-4 px-6 rounded-md shadow-lg">
          <button
              className="absolute top-2 right-2 text-white bg-red-600"
              onClick={() => closeModal(false)}
      >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
            <div className="rounded-lg shadow-lg p-6 z-10 w-[50%] mx-auto">
              {selectedPdf === '1' ? (
                <div>
                  <h3 className="text-lg font-semibold mb-4">PDF Template 1</h3>
                  <DesignOne />
                </div>
              ) : (
                <div>
                  <h3 className="text-lg font-semibold mb-4">PDF Template  2</h3>
                  <DEsignTwo />
                </div>
              )}
              {/* <div className="flex justify-end mt-4">
                <button onClick={closeModal} className="bg-blue-500 text-white px-4 py-2 rounded">Close</button>
              </div> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TempletePdf;
