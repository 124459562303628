import { useEffect } from 'react';
import socket from '../Utils/socket';
import DecryptData from '../Utils/Decrypt';

const useNotification = () => {

  const userDetails = DecryptData("sLiAtEdReSuEdArT") || "";
  const connectSocket = () => {
    socket.connect();

    const userId = userDetails?.id; // Adjust this based on your actual structure
    const userEmail = userDetails?.email;

    const joinData = {
      userId: userId,
      username: userEmail,
    };
    socket.emit('join_notif_room', joinData);
  }
  const disConnectSocket = () => {
    const userId = userDetails?.id; // Adjust this based on your actual structure
    const userEmail = userDetails?.email;

    const disconnectData = {
      userId: userId,
      username: userEmail,
    };
    socket.emit('leave_notif_room', disconnectData);
    socket.disconnect();
  }

  // Connect the socket when the component using this hook mounts
  useEffect(() => {
    connectSocket();
    return () => {
      disConnectSocket();
    }
  }, []);

  const handleSendNotification = (notificationData) => {
    if (!socket) return;

    socket.emit("send_notification", notificationData);
  };

  return {handleSendNotification};
};

export default useNotification;