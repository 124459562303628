import React from 'react';
import CompanyLogo from "../../../../assets/images/companyLogo.png";
import CompanyName from "../../../../assets/images/companyName.png";

const DesignOne = ({ data, seal }) => {
  return (
    <div className="p-4 text-xs font-normal font-helvetica w-full h-auto border overflow-y-scroll rounded-md">
      
      {/* Header Section */}
      <div className="flex justify-between items-center mb-4">
        <img src={CompanyName} alt="Company Name" className="w-2/5" />
        <img src={CompanyLogo} alt="Company Logo" className="w-1/5" />
      </div>

      {/* Purchase Request Title */}
      <div className="text-center mb-4">
        <h1 className="text-lg font-bold">Title</h1>
      </div>

      {/* Ref and Date Section */}
      <div className="flex justify-between items-center mb-4 border p-2">
        <div className="w-1/3">
          <p>Your Ref. :</p>
        </div>
        <div className="w-1/3 text-center">
          <p>Date : </p>
        </div>
        <div className="w-1/3 text-right">
          <p>Ref# : </p>
        </div>
      </div>

      {/* Company Details */}
      <div className="flex justify-between px-3 py-2 border mb-4">
        <div className="w-1/2">
          <p>Name:</p>
          <p>Reference:</p>
          <p>Category:</p>
        </div>
      </div>

      {/* Product Table */}
      <h4 className="font-bold mb-2">ITEMS</h4>
      <table className="min-w-full border border-collapse mb-4">
        <thead className="bg-gray-200">
          <tr>
            {['S.NO.', 'Product ', 'Unit', 'Quantity','Total'].map((header, index) => (
              <th key={index} className="border border-black px-2 py-1">{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          
              <tr  className="border border-black">
                <td className="border border-black px-2 py-3 text-center"></td>
                <td className="border border-black px-2 py-3"></td>
                <td className="border border-black px-2 py-3 text-center"></td>
                <td className="border border-black px-2 py-3 text-center"></td>
                <td className="border border-black px-2 py-3 text-center"></td>

              </tr>
           
          
        </tbody>
      </table>

      {/* Footer Section */}
      <div className="flex justify-end mb-4">
        <div className="text-right w-1/3">
          <p className="font-semibold">TOTAL : </p>
          <p className="font-semibold">NET TOTAL : </p>
        </div>
      </div>

      {/* Terms & Conditions */}
      <div className="mt-4 text-xs">
        <h4 className="font-bold">Terms & Conditions</h4>
        <p>Price: </p>
        <p>Delivery Period: </p>
        <p>Delivery: </p>
        <p>Payment: </p>
      </div>

      {/* Notes Section */}
      <div className="mt-4 text-xs">
        <p>Note:</p>
      </div>
    </div>
  );
};

export default DesignOne;
