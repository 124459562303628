import React from 'react';

const PermissionIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      width="15"
      height="15"
      fill="gray"
    >
      <path d="M32 2C15.3 2 2 15.3 2 32s13.3 30 30 30 30-13.3 30-30S48.7 2 32 2zm0 56C16.5 58 6 47.5 6 32S16.5 6 32 6s26 10.5 26 26-10.5 26-26 26zm-5-38h-4v12h4V20zm0 14h-4v4h4v-4zm14-14h-4v12h4V20zm0 14h-4v4h4v-4z" />
    </svg>
  );
};

export default PermissionIcon;
