import React, { useEffect, useRef } from 'react'
import { NuSelect, NuUpload } from '../../../../../Components/Utils/Input'
import { useFormik } from 'formik';
import useAddFormData from '../../../../../Components/Hooks/useAddFormData';
import { useState } from 'react';
import * as yup from 'yup';
import FormikSelectTwo from '../../../../../Components/Utils/Select';
import UseDropDown from '../../../../../Components/Hooks/useDropDown';
import { SubmitButton } from '../../../../../Components/Utils/Buttons';
import { TextMediumSM } from '../../../../../Components/Text/MediumText';
import UseGetDataSearch from '../../../../../Components/Hooks/useFetchDataBySearch';
import axiosInstance from '../../../../../Services/axiosCommon';
import DeleteIcon from '../../../../../Components/Icons/Delete';
import { useToast } from '../../../../../Components/Others/ToastServices';

function UserSignature() {
  const toast = useToast();
  const fileInputRef = useRef(null);
  const { data: user } = UseDropDown('/user/drop-down');
  const [data,setData] = useState([]);
  const getData = () => {
    axiosInstance.get('/signature/')
      .then((res) => {
        setData(res?.data?.data || []);
      })
      .catch(() => {
        toast.open({
          type: 'error',
          message: 'Error',
          description: 'Failed to fetch data.',
        });
      });
  };

  useEffect(()=>{
     getData();
  },[])
  console.log("ccc",data);
  const [image, setImage] = useState({ preview: "", raw: "" });
  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  const [createNew, setCreateNew] = useState({
    userId: ""
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      let formData = new FormData();
      formData.append('json_data', JSON.stringify(value));
      formData.append("signature", image?.raw);
      axiosInstance.post('/signature/add-signature',formData)
      .then((res)=>{
        if (res.data?.status === true) {
          toast.open({
            type:"success",
            message:"Success",
            description: res.data?.msg || "Created Successfully."
          }) 
          resetForm();
          setImage({ preview: '', raw: '' });
            if (fileInputRef.current) {
              fileInputRef.current.value = '';
            }
          getData();
        } 
        else if(res.data?.status === false && res.data?.error){
          toast.open({
            type:"error",
            message:"Error",
            description: res.data.error || "Network error."
          })
        } 
        else {
          toast.open({
            type:"warning",
            message:"Warning",
            description: res.data?.msg || "Warning."
          })
        }
      })
    },
  });
  function deleteSig(id){
      axiosInstance.delete(`signature/${id}`)
      .then(res => {
        if (res?.data.status === true) {
            toast.open({
                type:"success",
                message:"Success",
                description: res.data?.msg || "Deleted Successfully."
              })
            getData();
        }
        else if(res.data?.status === false && res.data?.error){
            toast.open({
              type:"error",
              message:"Error",
              description: res.data.error || "Network error."
            })
          } 
        else {
            toast.open({
                type:"warning",
                message:"Warning",
                description: res.data?.msg || "Warning."
              })
        }

    }).catch(
        error => {
            console.log("error",error);
            toast.open({
                type:"error",
                message:"Error",
                description:  "Network error."
              })
        }
    );
  }
  return (
    <>
     <div className='ml-5'>
        <TextMediumSM content={"Signature"}/>
     </div>
     <form onSubmit={formik.handleSubmit}>
    <div className="flex justify-start">
      <FormikSelectTwo
        label="User"
        placeholder="Choose"
        Options={user}
        formik={formik}
        name="userId"
        width="w-full md:w-1/2 lg:w-1/3"
      />
      <NuUpload
          label="Signature Attachment"
          width="w-full md:w-1/2 lg:w-1/3"
          onChange={handleChangeImg}
          ref={fileInputRef}
        />
        <div className='w-10 h-12 mt-11'>
          <SubmitButton name='Add'/>
        </div>
    </div>
    </form>
    <div className='ml-5 mt-3 w-[70%] h-80 border border-slate-300 rounded-md overflow-y-scroll'>
       {data?.map((item,index)=>{
        return(
          <div className='w-full flex'>
               <p className='w-[45%] flex justify-center items-center text-xs'>{item?.userName}</p>
               <div className='w-[45%] h-20 rounded-md my-1 p-1 border border-slate-500'>
                   <div className='w-full h-full'>
                      <img src={item?.signature?.[0]?.file} alt={item?.signature?.[0]?.file} className='w-full h-full ' />
                   </div>
               </div>
               <div className='w-[10%] flex justify-center items-center'>
                  <span onClick={()=>{deleteSig(item?.id)}} className='cursor-pointer'>
                    <DeleteIcon/>
                  </span>
               </div>
          </div>
        )
       })}
    </div>
    </>
  );
}

export default UserSignature