import React, { useEffect, useState } from "react";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import { NuInput, NuTextArea } from "../../../../Components/Utils/Input";
import { useFormik } from "formik";
import UseEditData from "../../../../Components/Hooks/useEditData";
import { Link, useLocation } from "react-router-dom";
import Dashboard from "../../../../Components/Icons/Dasboard";
import * as yup from "yup";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import useEditFormData from "../../../../Components/Hooks/useEditFormData";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import UseCatSubCatDropDown from "../../../../Components/Hooks/useCategoryDropDown";
import { useToast } from "../../../../Components/Others/ToastServices";
import Attachement, {
  videotype,
} from "../../../../Components/Utils/CustomAttrachmentupload";
import Modal from "../../../../Components/Utils/AttachmentView";
import UseDropDown from "../../../../Components/Hooks/useDropDown";
import Doc from "../../../../Components/Icons/Doc";
import Xls from "../../../../Components/Icons/Xls";
import Txt from "../../../../Components/Icons/Txt";
import Pdf from "../../../../Components/Icons/Pdf";
import CloseIcon from "../../../../Components/Icons/Close";
import Video from "../../../../Components/Icons/Video";
const filetype = [
  "docx",
  "doc",
  "xlsx",
  "xls",
  "pdf",
  "txt",
  "pptx",
  "mp4",
  "mov",
  "wmv",
  "avi",
  "flv",
  "mkv",
];

function EditItems() {
  const { value: Unit } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "Unit_item"
  );
  const { value: brand } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "brand_item"
  );
  const { value: Manufacture } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "Manufacture_item"
  );
  const { data: vendor } = UseDropDown("/vendors/drop-down");
  // const [loading, setLoading] = useState(false);

  const toast = useToast();
  const [image, setImage] = useState([]);
  const location = useLocation();
  const data = location.state.data;
  const from = location.state.from;
  console.log(location.state, "state");
  // const [division, setDivision] = useState([]);
  const { Oncancel, editFormData, contextHolder, isLoading } =
    useEditFormData();
  const [createNew, setCreateNew] = useState({
    productName: data.productName,
    category: data.category,
    subCategory: data.subCategory,
    itemCode: data.itemCode,
    isPromotable: data.isPromotable,
    unit: data.unit,
    manufaturer: data.manufaturer,
    brand: data.brand,
    comments: data.comments,
    productDescription: data.productDescription,
    price: data.price,
    vendorId: data.vendorId,
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      productName: yup.string().required("Product is required!"),
      category: yup.string().required("Category is required!"),
      unit: yup.string().required("Unit is required!"),
    }),

    onSubmit: async (value, { resetForm }) => {
      // setLoading(true)
      let formData = new FormData();
      if (removefilePathList) {
        let tempArr = [];
        let finalArr = removefilePathList.filter(
          (items) => !filePathList.includes(items)
        );
        finalArr &&
          finalArr.map((fileName) => {
            tempArr.push(fileName.file);
          });
        // console.log(',..df.,..',tempArr);
        value.filesToRemove = tempArr;
      } else {
        value.filesToRemove = [];
      }
      formData.append("itemDetails", JSON.stringify(value));
      if (selectedFile !== null) {
        selectedFile.forEach((file) => {
          formData.append("itemImage", file);
        });
      } else {
        formData.append("itemImage", []);
      }
      editFormData(
        `/items/edit-item/${data.productId}`,
        formData,
        resetForm,
        from
      );
      // setLoading(false)
    },
  });
  const { category: itemsCategory, subCategory: itemsSubCategory } =
    UseCatSubCatDropDown(
      "/category/ItemCategory",
      "ItemCategory",
      formik?.values?.category
    );

  const [selectedFile, setSelectedFile] = useState(null);
  const [filePathList, setFilePathList] = useState([]);
  const [removefilePathList, setRemoveFilePathList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = [];
    files.map((item) => {
      if (item.size < maxFileSize) {
        fileArr.push(item);
      } else {
        toast.open({
          type: "warning",
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          // placement: "bottomRight",
        });
      }
    });
    let tempArr = [];
    setSelectedFile(fileArr);
    files.map((item) => {
      if (item.size < maxFileSize) {
        let fileName = item?.name;
        console.log("fname", fileName);
        // let fileExtention = fileName.split(".").reverse()[0];
        // if (filetype?.includes(fileExtention)) {
        tempArr.push(fileName);
        // } else {
        //   let x = URL.createObjectURL(item);
        //   tempArr.push(x);
        // }
      }
    });
    console.log(tempArr, "temp");
    setImage(tempArr);
  }

  useEffect(() => {
    if (
      data?.productImage !== null &&
      data?.productImage !== "None" &&
      data?.productImage !== "[]"
    ) {
      setFilePathList(data?.productImage);
      // console.log('image',createNew?.attachments);
      setRemoveFilePathList(data?.productImage);
      // console.log('images',filePathList);
    }
  }, [data]);

  function UpdateAttachmentList(item) {
    let filterList = filePathList.filter((itemList) => itemList.file !== item);
    console.log("llll", filterList);
    setFilePathList(filterList);
  }

  function handleImageClick(img) {
    setSelectedImage(img);
    setIsModalOpen(true);
  }

  console.log("data", data?.productImage);

  const removeImages = (value) => {
    console.log("value", value);
    let List = selectedFile.filter((item) => item?.name !== value);
    console.log("LIst", List);
    setSelectedFile([...List]);
    let tempArr = [];
    List.map((item) => {
      let fileName = item?.name;
      // console.log('fname',fileName);
      // let fileExtention =  fileName.split(".").reverse()[0]
      // if(filetype?.includes(fileExtention)){
      tempArr.push(fileName);
      // }else{
      //   let x = URL.createObjectURL(item)
      //   tempArr.push(x)
      // }
    });
    setImage(tempArr);
  };

  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
        <Breadcrumb
          items={[
            // { name: "Product" },

            {
              name: "Product",
              link: "/products/items",
            },

            { name: "Edit" },
          ]}
        />
        <div className=" flex gap-2">
          <BackButton />
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden mt-4">
          <div className=" w-full h-full flex py-2 justify-start content-start flex-wrap  overflow-y-auto">
            <div className="w-full">
              <p className="pb-1 px-4 text-xs 2xl:text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                Product Details :
              </p>
            </div>
            <NuInput
              label="Product"
              type="text"
              formik={formik}
              placeholder="Product"
              name="productName"
              width="w-full md:w-1/2 lg:w-2/3"
              isRequired={true}
            />
            <FormikSelectTwo
              type="text"
              label="Unit"
              placeholder="Choose"
              Options={Unit}
              formik={formik}
              name="unit"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />
            <FormikSelectTwo
              type="text"
              label="Category"
              placeholder="Category"
              Options={itemsCategory}
              formik={formik}
              name="category"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />
            <FormikSelectTwo
              type="text"
              label="Sub-Category"
              placeholder="Sub-Category"
              Options={itemsSubCategory}
              formik={formik}
              name="subCategory"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <FormikSelectTwo
              type="text"
              label="Brand"
              placeholder="Choose"
              Options={brand}
              formik={formik}
              name="brand"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            {/* <FormikSelectTwo
                type="text"
                label="Manufaturer"
                placeholder="Choose"
                Options={Manufacture}
                formik={formik}
                name="manufaturer"
                width="w-full md:w-1/2 lg:w-1/3"
              /> */}
            {/* <NuInput
              label="Item Code"
              type="text"
              formik={formik}
              placeholder="Item Code"
              name="itemCode"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            {/* <FormikSelectTwo
              type="text"
              label="Under Promotion?"
              placeholder="Under Promotion?"
              Options={[
                { value: false, label: "No" },
                { value: true, label: "Yes" }

              ]}
              formik={formik}
              name="isPromotable"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             {formik.values.isPromotable === true && (
              <FormikSelectTwo
                type="text"
                label="Supplier"
                placeholder="Choose"
                Options={vendor}
                formik={formik}
                name="vendorId"
                width="w-full md:w-1/2 lg:w-1/3"
              />
            )} */}
            {/* {formik.values.isPromotable === true && (
               <NuInput
               label="Price"
               type="text"
               formik={formik}
               placeholder="Price"
               name="price"
               width="w-full md:w-1/2 lg:w-1/3"
             />
            )} */}
            {/* <NuInput
              label="Product Description"
              type="text"
              formik={formik}
              placeholder="Product Description"
              name="productDescription"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            <NuTextArea
              label="Comments"
              type="text"
              formik={formik}
              placeholder="Comments"
              name="comments"
              width="w-full md:w-1/2 lg:w-1/3"
              new_width="lg:w-2/3"
            />
            <div className="w-full">
              <p className=" px-4 text-xs 2xl:text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                Product Attachement :
              </p>
            </div>{" "}
            {data?.productImage.length > 0 ? (
              <div className="md:flex justify-between my-5 px-3 w-full">
                <div className="w-full flex gap-2 items-center flex-wrap pl-3">
                  {data?.productImage &&
                    filePathList.map((imgs, idx) => {
                      console.log("img", imgs);
                      let Ext = imgs?.file_name.split(".").reverse()[0];
                      return (
                        <div
                          className=" w-20 h-9 my-2 relative flex justify-center items-center rounded-lg"
                          key={idx}
                          style={{
                            border: "1px solid gray",
                            borderColor:
                              "rgb(148 163 184 / var(--tw-bg-opacity))",
                          }}
                        >
                          <div
                            className=" w-4 h-4 rounded bg-slate-400 absolute text-white cursor-pointer font-medium flex justify-center items-center  -top-2 -right-2 z-10"
                            onClick={() => UpdateAttachmentList(imgs?.file)}
                          >
                            <CloseIcon color="white" height="8" width="8" />
                          </div>
                          <div
                            className=" w-20 h-9 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer"
                            onClick={() => handleImageClick(imgs)}
                          >
                            <div className=" w-8 h-8">
                              {videotype.includes(Ext) ? <Video /> : null}

                              {Ext === ("docx" || "doc" || "txt") ? (
                                <Doc />
                              ) : Ext === ("xlsx" || "xls") ? (
                                <Xls />
                              ) : Ext === "txt" ? (
                                <Txt />
                              ) : Ext === "pdf" ? (
                                <Pdf />
                              ) : Ext === "pptx" ? (
                                <>No file</>
                              ) : (
                                <img src={imgs?.file} alt="#" width="120%" />
                              )}
                            </div>
                            <p className=" w-[50px] text-[11px] truncate">
                              {imgs?.file_name}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            ) : null}
            <Attachement
              handleChange={handleImageChange}
              preview={image || ""}
              width="w-full md:w-1/2"
              label="Attach"
              multiple={true}
              removeImages={removeImages}
              accept="image/*"
            />
            <div className="w-full   md:block mx-a my-4 mr-6">
              <div className="flex  justify-end gap-4">
                <CancelButton
                  handleClick={() => {
                    Oncancel();
                  }}
                />
                <SubmitButton isLoading={isLoading} />
              </div>
            </div>
          </div>
        </div>
      </form>
      <Modal show={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <img src={selectedImage?.file} alt={selectedImage?.file} />
      </Modal>
    </div>
  );
}

export default EditItems;
