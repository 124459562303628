import { useCallback, useEffect, useMemo, useState } from "react";
import axiosInstance from "../../Services/axiosCommon";


const UseCatSubCatDropDown = (url,moduleName,name) =>{

 const [category,setCategory] = useState([]);
  async function getCategory(){
    axiosInstance.get(url)
    .then((res)=>{
      console.log("search",res?.data?.data);
      let data = res?.data?.data
      let tempData = [];
            data.forEach((item) => {
                let tempObject = { value: item.name, label: item.name };
                tempData.push(tempObject);
            });
            res.data && setCategory(tempData);
    }).catch(err => {
      console.log(err, "err");
  })
  }

  const [subCategory,setSubCategory] = useState([])
  
  const fetchSubCategory = useCallback(async () => {
    if (category.length !== 0 && name !== "") {
      try {
        const res = await axiosInstance.get(`/category/sub-categories/${moduleName}/${name}`);
        console.log("subsearch", res?.data?.data);
        const data = res?.data?.data;
        let tempData = [];
        data.forEach((item) => {
          let tempObject = { value: item.name, label: item.name };
          tempData.push(tempObject);
        });
        if (res.data) {
          setSubCategory(tempData);
        }
      } catch (err) {
        console.log(err, "err");
      }
    }
  }, [category, moduleName, name]);

  // useMemo to memoize subCategory fetching
  const memoizedSubCategory = useMemo(() => {
    fetchSubCategory();
  }, [fetchSubCategory]);

  useEffect(()=>{
    getCategory();
 },[])

    return { category, subCategory, getCategory, fetchSubCategory}
}

export default UseCatSubCatDropDown;