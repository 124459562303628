import { useEffect, useRef, useState } from "react";
import FormForComments from "../Hooks/formForComments";
import Send from "../Icons/send";
import { useToast } from "../Others/ToastServices";
import { useTranslation } from "react-i18next";
import DecryptData from "./Decrypt";
import { useFormik } from "formik";
import * as yup from 'yup';
import axiosInstance from "../../Services/axiosCommon";
import { TextMediumSM } from "../Text/MediumText";
import ViewCommentFiles from "./ViewCommentFiles";
import NuDate from "./NuDate";
import CloseIcon from "../Icons/Close";
import Video from "../Icons/Video";
import Doc from "../Icons/Doc";
import Xls from "../Icons/Xls";
import Txt from "../Icons/Txt";
import Pdf from "../Icons/Pdf";
import capitalizeFirstLetter from "./Funcs";

const filetype = [
    "docx",
    "doc",
    "xlsx",
    "xls",
    "pdf",
    "txt",
    "pptx",
    "mp4",
    "mov",
    "wmv",
    "avi",
    "flv",
    "mkv",
  ];
  const videotype = ["mp4", "mov", "wmv", "avi", "flv", "mkv"];
  
  const CommonComments = ({ title, mainId, addurl, geturl, userDetails, index }) => {
    const toast = useToast();
    const { addFormData } = FormForComments();
    const [attachments, setAttachments] = useState({ preview: "", raw: "" });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const { t, i18n } = useTranslation();
    const [mentionedUsers,setMentionedUsers] = useState([]);
    console.log("men",mentionedUsers);
    const decryptedUserDetails = DecryptData("sLiAtEdReSuEdArT") || userDetails;
    const [createNew, setCreateNew] = useState({
      mainId: "",
      comments: "",
    });
  
    const [userMessages, setUserMessages] = useState(() => {
      const savedMessages = localStorage.getItem(`messages_${mainId}`);
      return savedMessages ? JSON.parse(savedMessages) : [];
    });
  
    const formik = useFormik({
      initialValues: createNew,
      validationSchema: yup.object({
        comments: yup.string().required("Message is required"),
      }),
      onSubmit: async (values, { resetForm }) => {
        const currentDate = new Date();
        const formattedDate = currentDate.toLocaleDateString("en-US", {
          year: "2-digit",
          month: "short",
          day: "2-digit",
        });
        const formattedTime = currentDate.toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });
        const newMessage = {
          id:new Date(),
          userId: decryptedUserDetails?.id,
          comments: values.comments,
          createdAt: `${formattedDate} ${formattedTime}`,
          attachments: attachments.raw ? { file: attachments.preview } : null,
        };
        values = { ...values, mainId: mainId,users:mentionedUsers };
        const updatedMessages = [...userMessages, newMessage];
        setUserMessages(updatedMessages);
        localStorage.setItem(
          `messages_${mainId}`,
          JSON.stringify(updatedMessages)
        );
  
  
        const formData = new FormData();
        formData.append("json_data", JSON.stringify(values));
        if (selectedFile && selectedFile.length>0) {
          selectedFile.map(item=>{
          formData.append("attachments", item);
          })
        }
  
        await addFormData(addurl, formData);
        await getData();
        resetForm();
        setSelectedFile([]);
        setMentionedUsers([]);
      },
    });
  
    const getData = () => {
      axiosInstance.get(geturl).then((res) => {
        if (res?.data?.data) {
          setUserMessages(res.data.data);
        }
      }).catch((err)=>{
          console.log(err);
      })
    };
  
    useEffect(() => {
      getData();
    }, [geturl]);
  
    const containerRef = useRef(null);
  
    useEffect(() => {
      const container = containerRef.current;
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
    }, [userMessages]);
  
    const [selectedFile, setSelectedFile] = useState(null);
    const [image, setImage] = useState([]);
  
    function handleImageChange(e) {
      const maxFileSize = 200000000; // 200 MB
      const files = Array.from(e.target.files);
      let fileArr = [];
      files.map((item) => {
        if (item.size < maxFileSize) {
          fileArr.push(item);
        } else {
          toast.open({
            type: "warning",
            message: `File Size to big`,
            description: `${item.name} is Removed and not Uploaded`,
            // placement: "bottomRight",
          });
        }
      });
      let tempArr = [];
      setSelectedFile(fileArr);
      files.map((item) => {
        if (item.size < maxFileSize) {
          let fileName = item?.name;
          console.log("fname", fileName);
          let fileExtention = fileName.split(".").reverse()[0];
          if (filetype?.includes(fileExtention)) {
            tempArr.push(fileName);
          } else {
            let x = URL.createObjectURL(item);
            tempArr.push(x);
          }
        }
      });
  
      setImage(tempArr);
    }
  
    const handleImageClick = (image) => {
      setSelectedImage(image);
      setIsModalOpen(true);
    };
  
    const [isExpanded, setIsExpanded] = useState(false);
    const maxLength = 150; // Set the max length for the text before showing "Read More"
  
    const toggleReadMore = () => {
      setIsExpanded(!isExpanded);
    };
  
    const removeImages = (value) => {
      console.log("value", value);
      let List = selectedFile.filter((item) => item !== value);
      console.log("LIst", List);
      setSelectedFile([...List]);
    };

 // const usersList = [
    //   { id: 1, name: "John Doe" },
    //  { id: 2, name: "Jane Smith" },
    //   { id: 3, name: "Alice Johnson" },
    // ];

const [usersList,setUsersList]=useState([])
useEffect(() => {
axiosInstance.get(`/user/drop-down`).then(res => {
 
  let data = res.data;

  let tempData = [];
  data.forEach((item) => {
      let tempObject = { value: item.id, label: item.name };
      tempData.push(tempObject);
  });
  res.data && setUsersList(tempData);
}).catch(err => {
  console.log(err, "err");
})
}, []);
   

    const [filteredUsers, setFilteredUsers] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    
    const handleInputChange = (e) => {
      const value = e.target.value;
      const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
      formik.setFieldValue("comments", capitalizedValue);
    
      if (value.includes('@')) {
        const searchTerm = value.split('@').pop().toLowerCase();
        const filtered = usersList.filter(user =>
          user.label?.toLowerCase().includes(searchTerm)
        );
        setFilteredUsers(filtered);
        setShowDropdown(filtered.length > 0);
      } else {
        setShowDropdown(false);
      }
    };
    
    const handleKeyDown = (e) => {
      if (e.key === '@') {
        setShowDropdown(true);
      }
    
      if (e.key === 'Enter' || e.key === 'Escape') {
        setShowDropdown(false);
      }
    };
    

  
    return (
      <>
        <div className="w-full h-full overflow-hidden">
          {/* <div className="p-1 flex justify-between items-center bg-baseColor-primary rounded-md"> */}
          <div className="p-1 flex justify-between items-center bg-baseColor-primary rounded-t-lg">

            <span dir={i18n.language === "ar" ? "rtl" : ""}>
              <TextMediumSM
                color="text-white"
                content={t(title) || "Title"}
                truncate={true}
                otherStyles="my-1"
                align="center"
              />
            </span>
          </div>
          <div
            ref={containerRef}
            className="h-[60vh] overflow-y-auto flex flex-col"
          >
            {userMessages.map((comments, index) => {
              const displayText = comments?.comments
                ? isExpanded
                  ? comments.comments.charAt(0).toUpperCase() +
                    comments.comments.slice(1)
                  : comments.comments
                      .slice(0, maxLength)
                      .charAt(0)
                      .toUpperCase() + comments.comments.slice(1, maxLength)
                : "";
  
              return (
                <div
                  className={`min-w-[2rem] max-w-[15rem] my-1 ${
                    comments.userId === decryptedUserDetails?.id
                      ? "ml-auto"
                      : "mr-auto"
                  }`}
                  key={index}
                >
                  <div
                    className={`px-2 py-1 rounded-lg text-sm 
                      ${
                      comments.userId === decryptedUserDetails?.id
                        ? "border border-blue-200 text-[#111537] mx-1"
                        : "border border-blue-200 text-red"
                    }
                    `
                  }
                  >
                    {/* view attachments */}
                    {comments?.attachments && comments?.attachments.length > 0 && (
  
                      <ViewCommentFiles data={comments.attachments} />
                    )}
                    {/* {comments.attachments.length > 0 && comments.attachments.map((item,index)=>(
                                            <div onClick={() => handleImageClick(item.file)} key={index}>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    className="w-6 h-6 cursor-pointer"
                                                    fill="#fff"
                                                >
                                                    <path d="M448 80c8.8 0 16 7.2 16 16l0 319.8-5-6.5-136-176c-4.5-5.9-11.6-9.3-19-9.3s-14.4 3.4-19 9.3L202 340.7l-30.5-42.7C167 291.7 159.8 288 152 288s-15 3.7-19.5 10.1l-80 112L48 416.3l0-.3L48 96c0-8.8 7.2-16 16-16l384 0zM64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zm80 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" />
                                                </svg>
                                            </div>
                                        ))} */}
                    <p className="break-words text-xs">
                      {<HighlightText text={displayText}/>}
                      {comments?.comments &&
                        comments?.comments.length > maxLength && (
                          <span
                            className="text-blue-500 cursor-pointer"
                            onClick={toggleReadMore}
                          >
                            {isExpanded ? " Read Less" : "... Read More"}
                          </span>
                        )}
                    </p>
                    <div className="flex justify-end items-end text-[8px] text-gray-400">
                    <p className="pr-1 text-[8px] text-end">
  by {comments?.userName ? capitalizeFirstLetter(comments.userName) : capitalizeFirstLetter(decryptedUserDetails.name) || 'None'}
</p>

                      <NuDate
                        value={comments?.createdAt}
                        format="DD-MMM-YYYY hh:mm A"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {/* Modified file upload */}
          <div className="w-full h-[40px] border rounded-xl overflow-hidden">
            <form onSubmit={formik.handleSubmit}>
              <div className="flex items-center justify-between ">
                <div className=" w-full flex justify-between items-center gap-1  h-[40px]">
                  <div className="w-[70%]">
                    <div className="flex items-center px-1 py-2 border rounded-md">
                      <textarea
                        className="w-full bg-transparent outline-none resize-none text-xs text-gray-800 placeholder-gray-500 h-11  py-1 max-h-32 overflow-y-auto"
                        name="comments"
                        placeholder="Type a comment"
                        value={formik.values.comments}
                        // onChange={(e) => {
                        //   const value = e.target.value;
                        //   const capitalizedValue =
                        //     value.charAt(0).toUpperCase() + value.slice(1);
                        //   formik.setFieldValue("comments", capitalizedValue);
                        // }}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        rows={1}
                      />
                    </div>
                    {formik.touched.comments && formik.errors.comments ? (
                      <div className="text-red-500 text-xs">
                        {formik.errors.comments}
                      </div>
                    ) : null}
                  </div>
  {/* Preview Selecte Image */}
                  {selectedFile &&
                    selectedFile.map((item, index) => {
                      console.log("ddd", item);
  
                      let Ext = item?.name?.split(".").reverse()[0];
                      return (
                        <div className="relative w-7 h-7" key={index}>
                          <button
                            type="button"
                            className="absolute top-[-0.5rem] right-[-0.5rem] p-1 text-white  rounded-full"
                            onClick={() => removeImages(item)}
                          >
                            <CloseIcon />
                          </button>
                          {videotype.includes(Ext) ? <Video /> : null}
  
                          {Ext === ("docx" || "doc" || "txt") ? (
                            <Doc />
                          ) : Ext === ("xlsx" || "xls") ? (
                            <Xls />
                          ) : Ext === "txt" ? (
                            <Txt />
                          ) : Ext === "pdf" ? (
                            <Pdf />
                          ) : Ext === "pptx" ? (
                            <>No file</>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                              className="w-6 h-6 "
                              fill="#3B82F6"
                            >
                              <path d="M448 80c8.8 0 16 7.2 16 16l0 319.8-5-6.5-136-176c-4.5-5.9-11.6-9.3-19-9.3s-14.4 3.4-19 9.3L202 340.7l-30.5-42.7C167 291.7 159.8 288 152 288s-15 3.7-19.5 10.1l-80 112L48 416.3l0-.3L48 96c0-8.8 7.2-16 16-16l384 0zM64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zm80 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" />
                            </svg>
                          )}
                        </div>
                      );
                    })}
                  <div className="relative w-8 h-5">
                    <label
                      htmlFor={`file-upload-${mainId}`}
                      className="absolute inset-0 w-full h-full text-center  text-white rounded-lg cursor-pointer"
                    >
                      <svg
                        weight="15"
                        height="20"
                        viewBox="0 0 12 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        // className="mt-3 mb-2 mx-3"
                      >
                        <path
                          d="M8.92087 9.46421L8.84249 9.40212L8.78039 9.4805L6.5825 12.2548C5.67329 13.4025 4.00738 13.5956 2.85973 12.6864C1.71208 11.7772 1.51895 10.1113 2.42815 8.96363L7.76469 2.22752C8.03129 1.891 8.42065 1.67417 8.84711 1.62473C9.27358 1.57529 9.70222 1.69729 10.0387 1.96389C10.3753 2.23049 10.5921 2.61985 10.6415 3.04632C10.691 3.47278 10.569 3.90142 10.3024 4.23794L5.81968 9.89627C5.61916 10.1494 5.24744 10.1925 4.99434 9.99195C4.74124 9.79144 4.69815 9.41972 4.89866 9.16662L6.24271 7.47008L6.30481 7.3917L6.22642 7.3296L5.41809 6.68922L5.33971 6.62712L5.27761 6.7055L3.93356 8.40204C3.63403 8.78014 3.49696 9.26173 3.5525 9.74089C3.60805 10.22 3.85167 10.6575 4.22977 10.9571C4.60786 11.2566 5.08946 11.3937 5.56862 11.3381C6.04777 11.2826 6.48524 11.0389 6.78477 10.6609L11.2675 5.00252C12.2453 3.76828 12.0375 1.97659 10.8033 0.99879C9.56908 0.0209951 7.77738 0.228706 6.79959 1.46294L1.46305 8.19905C0.130911 9.88056 0.413639 12.3194 2.09515 13.6515C3.77666 14.9836 6.21545 14.7009 7.5476 13.0194L9.74549 10.2451L9.80759 10.1667L9.7292 10.1046L8.92087 9.46421Z"
                          fill="#9DA3AA"
                          stroke="#9DA3AA"
                          stroke-width="0.2"
                        />
                      </svg>
                    </label>
                    <input
                      type="file"
                      id={`file-upload-${mainId}`}
                      className="hidden"
                      onChange={handleImageChange}
                      multiple={true}
                      accept="audio/*,video/*,image/*,.pdf,.doc,.docx,.ppt, .pptx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,text/comma-separated-values, text/csv, application/csv"
                    />
                  </div>
                </div>
  
                <button
                  type="submit"
                  className="flex items-center justify-center w-14 h-10 bg-baseColor-primary text-white "
                >
                  <Send />
                </button>
              </div>
            </form>
          </div>
        </div>
       
        {showDropdown && (
      <ul className="absolute bottom-[70px] bg-white border border-gray-300 rounded-md shadow-lg z-10 overflow-y-scroll w-32 h-32 mx-2">
        {filteredUsers.map(user => (
          <li
            key={user.value}
            className="cursor-pointer hover:bg-gray-200 p-2 text-xs"
            onClick={() => {
              const currentText = formik.values.comments;
              const mention = `${user.label} `;
              setMentionedUsers([...mentionedUsers, user.label])
              formik.setFieldValue("comments", currentText + mention);
              setShowDropdown(false);
            }}
          >
            {user.label}
          </li>
        ))}
      </ul>
    )}
      </>
    );
  };
  
  export default CommonComments;
  


  const HighlightText = ({ text }) => {
    // Regular expression to match strings starting with '@' and ending with a space
    const regex = /(@\w+\s)/g;
  
    // Split the text by the matched pattern
    const parts = text.split(regex);
  
    return (
      <p>
        {parts.map((part, index) => 
          regex.test(part) ? (
            // Apply styles to matching text (bold and green)
            <span key={index} style={{ fontWeight: '600', color: 'green' }}>
              {part}
            </span>
          ) : (
            part
          )
        )}
      </p>
    );
  };