import { useEffect, useState } from "react";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import { BackButton, CancelButton, CreateButton, SubmitButton } from "../../../../Components/Utils/Buttons";
import axiosInstance from "../../../../Services/axiosCommon";
import { useFormik } from 'formik';
import * as yup from 'yup';
import FormikSelectTwo from "../../../../Components/Utils/Select";
import { useToast } from "../../../../Components/Others/ToastServices";
import ViewMore from "./ViewMore";



const MainUserRoleSettings = () =>{
  const toast = useToast();
    const [data, setData] = useState([])
    const [open, setOpen] = useState(false)
    const [creatNew,setCreateNew]= useState({
      userId:"",
      roleId:""
    })
    const [userList, setUserList] = useState([])
    const [roleList, setRoleList] = useState([])

    const formik = useFormik({
      initialValues:creatNew,
      enableReinitialize:true,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
        userId: yup.string().required('User is requred!'),
        roleId: yup.string().required('Role is requred!')
      }),
      onSubmit: async (value, { resetForm }) => {
        axiosInstance.post(`/user-role/`,value).then(res=>{
          if(res?.data?.status==true){
            // ActiveUser(value?.userId)
            resetForm()
            getData()
            toast.open({
              type:"success",
              message:"Success",
              description: res.data?.msg || "Created Successfully."
            })
            setOpen(false)
          }else{
            toast.open({
              type:"error",
              message:"Error",
              description: res.data.error || res.data?.msg || res.data?.message  || "Network error."
            })
          }
        }).catch(err=>{
          console.error(err);
          toast.open({
            type:"error",
            message:"Error",
            description: err.response.data.error || err.response.data.message || err.response.data.msg || "Network error."
          })
        })
   }
    })



    // async function ActiveUser(value) {
    //   await axiosInstance.put(`/user/update-status/${value}`,{
    //     "status":true
    // }).then(res=>{
    //      if (res?.data?.status == true){
    //       toast.open({
    //         type: "success",
    //         message: "Success",
    //         description: res.data?.msg || "Created Successfully.",
    //       });
    //      }else{
    //       toast.open({
    //         type: "error",
    //         message: "Error",
    //         description:
    //           res.data.error ||
    //           res.data?.msg ||
    //           res.data?.message ||
    //           "Network error.",
    //       });
    //      }
    //   }).catch(err=>{
    //     toast.open({
    //       type: "error",
    //       message: "Error",
    //       description:
    //         err.response.data.error ||
    //         err.response.data.message ||
    //         err.response.data.msg ||
    //         "Network error.",
    //     });
    //   })
    // }

    useEffect(() => {
      getData()
      GetUser()
      GetRoles()
    },[])

    function getData(){
      axiosInstance.get(`/user-role/`).then((res) => {
        // res?.data?.data
        setData(res?.data?.data)
      }).then(err=>{
        console.error(err);
      })
    }


    function GetUser(){
      axiosInstance.get("/user/drop-down").then(res => {
        let arr =[]
        res?.data && res?.data.map(item=>{
          let temp = {
            value: item?.id,
            label: item?.name,
          }
          arr.push(temp)
        })
        setUserList(arr)
      }).catch(err=>{
        console.error(err);
      })
    }


    function GetRoles(){
      axiosInstance.get("/role/").then(res => {
        let arr =[]
        res?.data?.data && res?.data?.data.map(item=>{
          let temp = {
            value: item?.roleId,
            label: item?.roleName,
          }
          arr.push(temp)
        })
        setRoleList(arr)
      }).catch(err=>{
        console.error(err);
      })
    }

 function OnClose(){
   formik.resetForm() 
   setOpen(false)
 }

    return(
      <>
        <div className='w-full h-full px-4 py-2'>
        <div className='w-full flex justify-between items-center'>
        <Breadcrumb
          items={[
            {
              name: "Settings",
            },
            {
              name: "User Role"
            }
          ]}
        />
        <div className=" flex gap-2 items-center">
          <CreateButton handleClick={()=>setOpen(true)} label="Create" />
          <BackButton/>
        </div>
        
        </div>
        <div className="w-full h-[calc(100vh-145px)] bg-white mt-3 mx-auto rounded-md overflow-y-hidden">
          <div className=" relative w-full h-[calc(100vh-176px)] overflow-y-auto mt-4">
            <table className=" text-xs w-full border-collapse relative">
             <thead className=" sticky top-0 bg-white">
               <tr className="text-xs   text-[#4F4768] border-b">
                <th className=" w-[20%] text-left py-2 pl-2">Sl.No</th>
                <th className=" w-[40%] text-left py-2">User</th>
                <th className=" w-[30%] text-left py-2">Role</th>
                <th className=" w-[10%] text-left py-2">Action</th>
                <th></th>
               </tr>
             </thead>
             <tbody >
              {
                data && data.map((item, index) => {
                  return (
                    <tr className="w-full" key={index}>
                      <td className=" w-[20%] text-left py-2 pl-2">{data.length-index||""}</td>
                      <td className=" w-[40%] text-left py-2">{item?.userName||"-"}</td>
                      <td className=" w-[30%] text-left py-2">{item?.roleName||"-"}</td>
                      <td className=" w-[10%] text-left py-2"><ViewMore roleList={roleList} getData={getData} userList={userList} data={item} id={item?.userRoleId}/></td>
                    </tr>
                  )
                })
              }
             </tbody>
            </table>
          </div>
          <div className=" w-full h-8 bg-white">

          </div>
        </div>
        </div>

        {open && (
  <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
  <div className="animate-scale-in relative w-[45%] min-h-[50%] max-h-[96%] bg-white py-2 px-4 rounded-md shadow-lg overflow-y-scroll ">
    <button
      className="absolute top-2 right-2 text-white bg-red-600"
      onClick={() => setOpen(false)}
    >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <p className=" text-sm font-semibold">Create User Roll</p>
      <form onSubmit={formik.handleSubmit}>
      <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap ">
      <FormikSelectTwo
              type="text"
              label="User"
              placeholder="Choose"
              Options={userList}
              formik={formik}
              name="userId"
              width="w-full"
              isRequired={true}
            />
            <FormikSelectTwo
              type="text"
              label="Role"
              placeholder="Choose"
              Options={roleList}
              formik={formik}
              name="roleId"
              width="w-full"
              isRequired={true}
            />
      </div>
      <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton
                  handleClick={OnClose}
                />
                <SubmitButton />
              </div>
            </div>
      </form>
    </div>
  </div>
)}

      </>
    )
  }

  export default MainUserRoleSettings;