import React, { useState } from "react";
import { Link } from "react-router-dom";
import ViewMore from "../Icons/ViewMore";
import { useDeleteData } from "../Hooks/useDeleteData";
import ModalContainer from "../Others/ModalContainer";
import EditIcon from "../Icons/Edit";
import DeleteIcon from "../Icons/Delete";
import CommentsIcon from "../Icons/comments";
import ViewIcon from "../Icons/ViewIcon";
import Chat from "../Icons/Chat";
import { GetAccess, GetRole } from "./roles";
import Email from "../Icons/Email";
import EmailModal from "./EmailModel";
import ModalEmail from "../Others/ModalForEmail";

const MoreOptions = ({
  data,
  fetcher,
  id,
  removevalue,
  url,
  moduleName,
  from,
  viewLink,
  editLink,
  EmailRequired,
  emailEnable,
  isEmailSent,
  EditAccess = "",
  DeteleAccess = "",
  emailType,
  adminPermmision,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const { handle } = useDeleteData(fetcher);
  const handleDelete = async () => handle(url + `${id}`, setIsModalOpen);
  let Edit = editLink ? true : null;
  let Comment = editLink ? false : null;

  let EditCondition = editLink?.includes("{id}") ? true : false;
  let editableLink = EditCondition ? editLink.replace("{id}", `${id}`) : "";

  let Delete = true;
  console.log(data?.status, "khugf");

  return (
    <>
      <div className=" w-full flex  justify-start items-center">
        <div className=" w-full flex justify-start ">
          {EmailRequired && !isEmailSent && !data?.purchaser && emailEnable && (
            <div
              className=" w-[25%] overflow-hidden flex justify-center items-center cursor-pointer "
              onClick={() => {
                setModalOpen(true);
              }}
            >
              <Email height={"3"} width={"3"} color="#BF2EF0" />
            </div>
          )}

          <div className=" w-[25%] overflow-hidden flex justify-center items-center">
            <Link
              className=" text-black hover:text-black border-none"
              to={viewLink + `${id}`}
              state={{ data: data }}
            >
              <ViewIcon color="#048178" />
            </Link>
          </div>
          {Edit && (
            <>
              {data?.approvalStatus?.toLowerCase() == "verified" ||
              data?.approvalStatus?.toLowerCase() == "approved" ? (
                <>
                  {GetRole() && (
                    <div className=" w-[25%] overflow-hidden flex justify-center items-center ">
                      <Link
                        className=" text-black hover:text-black border-none"
                        to={EditCondition ? editableLink : editLink + `${id}`}
                        state={{ data: data, from: from }}
                      >
                        <EditIcon color="skyblue" width={"12"} height={"12"} />
                      </Link>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {GetAccess(EditAccess) && (
                    <div className=" w-[25%] overflow-hidden flex justify-center items-center ">
                      <Link
                        className=" text-black hover:text-black border-none"
                        to={EditCondition ? editableLink : editLink + `${id}`}
                        state={{ data: data, from: from }}
                      >
                        <EditIcon color="skyblue" width={"12"} height={"12"} />
                      </Link>
                    </div>
                  )}
                </>
              )}
            </>
          )}

          {Delete && (
            <>
              {data?.approvalStatus?.toLowerCase() == "verified" ||
              data?.approvalStatus?.toLowerCase() == "approved" ? (
                <>
                  {GetRole() && (
                    <div
                      className=" w-[25%] overflow-hidden flex justify-center items-center cursor-pointer "
                      onClick={() => {
                        setIsModalOpen(true);
                      }}
                    >
                      <DeleteIcon height={"3"} width={"3"} color="lightcoral" />
                    </div>
                  )}
                </>
              ) : (
                <>
                  {GetAccess(DeteleAccess) && (
                    <div
                      className=" w-[25%] overflow-hidden flex justify-center items-center cursor-pointer "
                      onClick={() => {
                        setIsModalOpen(true);
                      }}
                    >
                      <DeleteIcon height={"3"} width={"3"} color="lightcoral" />
                    </div>
                  )}
                </>
              )}
            </>
          )}

          {Comment && (
            <div className=" w-[25%] overflow-hidden flex justify-center items-center  cursor-pointer ">
              <Chat width={"11"} height={"11"} />
            </div>
          )}
        </div>
      </div>
      <ModalContainer
        visiable={isModalOpen}
        title="Warning"
        content={`Are you sure, ${removevalue} will removed from ${moduleName}!`}
        handleClick={() => handleDelete()}
        closeModal={() => setIsModalOpen(false)}
      />
      <ModalEmail
        visiable={modalOpen}
        title=""
        hasContent={true}
        content={
          <>
            <EmailModal
              fetcher={fetcher}
              vendorId={data?.vendor || data?.vendorId}
              id={data?.id}
              url={url}
              idd={id}
              onClose={() => setModalOpen(false)}
              emailType={emailType}
            />
          </>
        }
        // handleClick={() => {}}
        hasButton={false}
        // outSideClick={false}
        closeModal={() => setModalOpen(false)}
      />
    </>
  );
};
export default MoreOptions;
