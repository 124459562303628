import React from 'react';

const CompanyInfoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      width="15"
      height="15"
      fill="gray"
    >
      <path d="M12 2c-1.1 0-2 .9-2 2v52c0 1.1.9 2 2 2h40c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2H12zm0 4h40v52H12V6zM32 10c-1.1 0-2 .9-2 2v4c0 1.1.9 2 2 2s2-.9 2-2v-4c0-1.1-.9-2-2-2zm0 8c-1.1 0-2 .9-2 2v4c0 1.1.9 2 2 2s2-.9 2-2v-4c0-1.1-.9-2-2-2zm0 8c-1.1 0-2 .9-2 2v4c0 1.1.9 2 2 2s2-.9 2-2v-4c0-1.1-.9-2-2-2zm-8-8c-1.1 0-2 .9-2 2v24c0 1.1.9 2 2 2s2-.9 2-2V18c0-1.1-.9-2-2-2zm16 0c-1.1 0-2 .9-2 2v24c0 1.1.9 2 2 2s2-.9 2-2V18c0-1.1-.9-2-2-2z"/>
    </svg>
  );
};

export default CompanyInfoIcon;
