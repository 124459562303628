import { useState } from 'react';
import axiosInstance from '../../Services/axiosCommon';
// import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../Others/ToastServices';
import useNotification from './useNotification';

const UseAddData = () => {
  const [isLoading, setIsLoading] = useState(false);
  // const [ api, contextHolder ] = Notification.useNotification();
  const {handleSendNotification} = useNotification();
  const navigate = useNavigate()
  const toast = useToast();

  function Oncancel() {
      navigate(-1);
  }

  const addData = async (url, value, resetForm) => {
    setIsLoading(true);

    try {
      const res = await axiosInstance.post(url, value);
      if (res.data?.status === true) {
        if (res.data.notification!==null || res.data.notification!==undefined || res.data.notification!==""){
          handleSendNotification(res.data.notification);
        }
        toast.open({
          type:"success",
          message:"Success",
          description: res.data?.msg || "Created Successfully."
        })

        resetForm();
        Oncancel();
      }
      else if(res.data?.status === false && res.data?.error){
        toast.open({
          type:"error",
          message:"Error",
          description: res.data.error || "Network error."
        })
      } 
      else {
        toast.open({
          type:"warning",
          message:"Warning",
          description: res.data?.msg || "Warning."
        })
      }
    } catch (err) {
    //   setError(err.message);
      console.log(err);
      toast.open({
        type:"error",
        message:"Error",
        description:  "Network error."
      })
    } finally {
      setIsLoading(false);
    }
  };

  return { addData, isLoading, Oncancel };
};

export default UseAddData;