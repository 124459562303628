import { ResponsiveContainer, PieChart, Pie, Cell, Label, Tooltip} from "recharts";

export default function PieCharts({height=120,COLORS = ["#0088FE", "#00C49F"],data=[] ,label=false ,labelValue=0}) {
  const CustomTooltip = ({ active, payload, }) => {
    
    if (active && payload && payload.length) {
      return (
        <div className=" max-w-[250px] bg-white shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px] rounded-lg p-[5px]">
          <p className=" text-xs font-medium">{`${payload[0].name} : ${payload[0].value}`}</p>
        </div>
      );
    }
  
    return null;
  };
  return (
    <div style={{ width: "100%", height: height }}>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            innerRadius={"50%"}
            outerRadius={"80%"}
            dataKey="value"
            data={data}
            // paddingAngle={1}
            fill="#8884d8"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
                
              />
            
            ))}
           { label && <Label
           value={labelValue}
            position="center"
            fill="gray"
            style={
              {
                fontSize:"16px"
              }
            }            
            />}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}
