import React, { useState } from 'react'
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';
import { Link,useLocation ,useParams} from 'react-router-dom';
import { NormalHeading } from '../../../../Components/Utils/Heading';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';

function ViewSuppliers() {
    const { suppliersViewId} = useParams();
  const location = useLocation();
  const data = location.state.data;
  const initialProducts = data?.products ? data.products.split(", ").map((p) => p.trim()) : [];
  const [productList, setProductList] = useState(initialProducts);
  const initialEmails = data?.emails ? data.emails.split(", ").map((p) => p.trim()) : [];
  const [emailsList, setEmailsList] = useState(initialEmails);
  const initialServices = data?.services ? data.services.split(", ").map((p) => p.trim()) : [];
  const [servicesList, setServicesList] = useState(initialServices);
return (
  <div className="w-full h-full py-2 px-4 ">
  <div className="flex justify-between ">
     <Breadcrumb 
        items={[
            {
                name:"Suppliers",
                link:"/procurement/suppliers"

            },
            {
                name:"View Suppliers"
            }
        ]}
        />
      <div className=" flex gap-2">
        <Link to={'/procurement/suppliers'}><BackButton link={true}/></Link>
        <Link to={`/procurement/suppliers/edit/${suppliersViewId}`} 
        state={{data: data, from:`/procurement/suppliers/view/${suppliersViewId}`}}
        ><EditButton height='8'/></Link>
      </div>
    </div> 
    <div className='w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md mt-4 overflow-y-scroll p-3'>
    {/* <h2 className='text-base font-normal text-[#111537] p-3'>Customers</h2> */}
    <NormalHeading title={data.companyName} />
    <div className='flex'> 
         <div className='w-full  flex  '>
            <div className='w-full rounded-[20px]  bg-white py-4  flex  flex-col  '>
 {/*                <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Customer Type</p>
                <p className='w-1/2 py-2 text-xs  text-[#606279]'>{data.customerType}</p>
              </div> */}
                              {/* <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Company Name</p>
                <p className='w-1/2 py-2 text-xs  text-[#606279]'>{data.companyName}</p>
              </div>  */}
                              <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Category</p>
                <p className='w-1/2 py-2 text-xs  text-[#606279]'>{data.category}</p>
              </div>

              <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Type</p>
                <p className='w-1/2 py-2 text-xs  text-[#606279]'>{data.vendorType}</p>
              </div>
                             
                             <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Phone</p>
                <p className='w-1/2 py-2 text-xs  text-[#606279]'>{data.phoneWithCC}</p>
              </div> 
              <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Email</p>
                <p className='w-1/2 py-2 text-xs  text-[#606279]'>{data.email}</p>
              </div>
                              <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Website</p>
                <p className='w-1/2 py-2 text-xs  text-[#606279]'>{data.website}</p>
              </div> 

              <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>TaxId</p>
                <p className='w-1/2 py-2 text-xs  text-[#606279]'>{data.taxId}</p>
              </div>
              
              <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Contact Person </p>
                <p className='w-1/2 py-2 text-xs  text-[#606279]'>{data?.contactPerson}</p>
              </div> 
                
                              <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Designation</p>
                <p className='w-1/2 py-2 text-xs  text-[#606279]'>{data.designation}</p>
              </div>



              <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Contact Person Email</p>
                <p className='w-1/2 py-2 text-xs  text-[#606279]'>{data.contactPersonEmail}</p>
              </div>
                              <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Contact Person Mobile</p>
                <p className='w-1/2 py-2 text-xs  text-[#606279]'>{data?.cPMobileWithCC}</p>
              </div> 
              <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Performance Ratings</p>
                <p className='w-1/2 py-2 text-xs  text-[#606279]'>{data.performanceRating}</p>
              </div>
     
                             
                           
                           
                             
        </div>
    </div>
    <div className='border-r border-[1px] border-slate-200/60 border-solid'/>


            <div className='w-full  flex gap-3 '>
            <div className='w-full rounded-[20px]  bg-white py-4  flex  flex-col  '>
            
              <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Payment Terms</p>
                <p className='w-1/2 py-2 text-xs  text-[#606279]'>{data.paymentTerms}</p>
              </div>
              <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Delivery Terms</p>
                <p className='w-1/2 py-2 text-xs  text-[#606279]'>{data.deliverTerms}</p>
              </div>

              <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Street Address Line 1</p>
                <p className='w-1/2 py-2 text-xs  text-[#606279]'>{data?.address1||""}</p>
              </div>
              <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Street Address Line 1</p>
                <p className='w-1/2 py-2 text-xs  text-[#606279]'>{data?.address2||""}</p>
              </div> 
              <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>City</p>
                <p className='w-1/2 py-2 text-xs  text-[#606279]'>{data.city}</p>
              </div>

              <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>State / Province</p>
                <p className='w-1/2 py-2 text-xs  text-[#606279]'>{data.state}</p>
              </div> 

  
              
                              <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Postal / Zip Code</p>
                <p className='w-1/2 py-2 text-xs  text-[#606279]'>{data.postalCode}</p>
              </div> 
              <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Country</p>
                <p className='w-1/2 py-2 text-xs  text-[#606279]'>{data.country}</p>
              </div>  
              {/* <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Status</p>
                <p className='w-1/2 py-2 text-xs  text-[#606279]'>{data.vendorStatus}</p>
              </div> */}
              <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Comments</p>
                <p className='w-1/2 py-2 text-xs  text-[#606279]'>{data.comments}</p>
              </div>
          </div>
        </div>
      </div>
      <p className='w-full  pb-2 pt-4 px-4 text-sm font-medium text-[#111537]'>Products , Services & Emails</p>
      <div className="border-t border-[1px] border-slate-200/60 border-solid w-full"></div>

           <div className="flex flex-col md:flex-row justify-start gap-2">
           <div className="w-full flex flex-col ">
              {/* <div className="flex justify-start">
              <SupplierNuInput
                type="text"
                label="Products"
                placeholder="seprate products with ','"
                formik={formik}
                name="products"
                width="w-11/12"
              />
              <button
                onClick={handleAddProducts}
                  type="button"
                 className="mt-10 w-10 h-10 text-center cursor-pointer bg-[#048178] border border-solid border-white  text-white font-semibold rounded-md "
                   >
                +
               </button>
               </div> */}
              <p className='w-full  pb-2 pt-4 px-4 text-xs font-medium text-[#111537]'>Products</p>
              <div className="w-11/12 h-32 ml-4 rounded-md p-1 flex flex-col border border-solid overflow-y-scroll">
                {productList.map((word, index) => (
                  <span key={index} className="m-1  flex justify-between items-center text-xs bg-[#B3EBD7] rounded text-main-title py-2 px-2 hover:bg-baseColor-secondary hover:bg-opacity-50">
                    {word}
                    {/* <span
                onClick={() => {
                  handleDeleteProduct(index);
                }}
                className=" text-blue-900 cursor-pointer"
              >
                x
              </span> */}
                  </span>
                ))}
              </div>
            </div>
            <div className="w-full flex flex-col ">
              {/* <div className="flex justify-start">
              <SupplierNuInput
                type="text"
                label="Products"
                placeholder="seprate products with ','"
                formik={formik}
                name="products"
                width="w-11/12"
              />
              <button
                onClick={handleAddProducts}
                  type="button"
                 className="mt-10 w-10 h-10 text-center cursor-pointer bg-[#048178] border border-solid border-white  text-white font-semibold rounded-md "
                   >
                +
               </button>
               </div> */}
              <p className='w-full  pb-2 pt-4 px-4 text-xs font-medium text-[#111537]'>Services</p>
              <div className="w-11/12 h-32 ml-4 rounded-md p-1 flex flex-col border border-solid overflow-y-scroll">
                {servicesList.map((word, index) => (
                  <span key={index} className="m-1  flex justify-between items-center text-xs bg-[#B3EBD7] rounded text-main-title py-2 px-2 hover:bg-baseColor-secondary hover:bg-opacity-50">
                    {word}
                    {/* <span
                onClick={() => {
                  handleDeleteProduct(index);
                }}
                className=" text-blue-900 cursor-pointer"
              >
                x
              </span> */}
                  </span>
                ))}
              </div>
            </div>
            <div className="w-full flex flex-col ">
              {/* <div className="flex justify-start">
              <SupplierNuInput
                type="text"
                label="Products"
                placeholder="seprate products with ','"
                formik={formik}
                name="products"
                width="w-11/12"
              />
              <button
                onClick={handleAddProducts}
                  type="button"
                 className="mt-10 w-10 h-10 text-center cursor-pointer bg-[#048178] border border-solid border-white  text-white font-semibold rounded-md "
                   >
                +
               </button>
               </div> */}
              <p className='w-full  pb-2 pt-4 px-4 text-xs font-medium text-[#111537]'>Emails</p>
              <div className="w-11/12 h-32 ml-4 rounded-md p-1 flex flex-col border border-solid overflow-y-scroll">
                {emailsList.map((word, index) => (
                  <span key={index} className="m-1  flex justify-between items-center text-xs bg-[#B3EBD7] rounded text-main-title py-2 px-2 hover:bg-baseColor-secondary hover:bg-opacity-50">
                    {word}
                    {/* <span
                onClick={() => {
                  handleDeleteProduct(index);
                }}
                className=" text-blue-900 cursor-pointer"
              >
                x
              </span> */}
                  </span>
                ))}
              </div>
            </div>
            </div>
    </div>
  </div>
)
};
export default ViewSuppliers