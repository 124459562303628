import React, { useState } from 'react';
import { TextMediumdS, TextMediumSM, TextMediumXS } from '../../../../../Components/Text/MediumText';
import UseGetDashBoardData from '../../../../../Components/Hooks/useDashboardData';
import UseGetDashBoardWithParams from '../../../../../Components/Hooks/useDashboardDataParams';
import capitalizeFirstLetter from '../../../../../Components/Utils/Funcs';

const SalesMan = () => {
  const currentMonthIndex = new Date().getMonth() + 1; // Get current month as a number (1-12)
  const [params, setParams] = useState({
    month: currentMonthIndex,
  });
  const [selectedMonth, setSelectedMonth] = useState(currentMonthIndex);

  const { data, loading } = UseGetDashBoardWithParams(`/new-dashboard/sales/ypr-actioned`, params);

  const handleMonthChange = (e) => {
    const newMonth = parseInt(e.target.value); // Get selected month as a number
    setParams({ ...params, month: newMonth });
    setSelectedMonth(newMonth);
  };

  const months = [
    { name: 'January', value: 1 },
    { name: 'February', value: 2 },
    { name: 'March', value: 3 },
    { name: 'April', value: 4 },
    { name: 'May', value: 5 },
    { name: 'June', value: 6 },
    { name: 'July', value: 7 },
    { name: 'August', value: 8 },
    { name: 'September', value: 9 },
    { name: 'October', value: 10 },
    { name: 'November', value: 11 },
    { name: 'December', value: 12 },
  ];

  return (
    <div className="col-span-2 bg-white h-[45vh] p-4 pb-10 rounded-lg shadow-lg overflow-hidden mb-4">
      <div className="w-full px-1 py-[2px] rounded-md flex justify-between">
        <TextMediumSM content="YPR Actioned" color="text-[#4F4768]" />
        <div className="text-center text-xs text-[#304463] flex space-x-2 mb-1">
          <select
            value={selectedMonth}
            onChange={handleMonthChange}
            className="border rounded-md p-1 text-xs text-[#ACACAC] font-normal"
          >
            {months.map((month) => (
              <option key={month.value} value={month.value}>
                {month.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="border-t border-[1px] border-slate-200/60 border-solid w-full"></div>

      {loading ? (
        <div className="flex justify-center items-center h-full">
          <div className="animate-pulse space-y-4 w-full mb-1">
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
          </div>
        </div>
      ) : (
        <div className="w-full h-[96%] overflow-y-scroll pb-4">
          <table className="w-full">
            <thead className="sticky top-0 bg-white shadow-sm">
              <tr>
                <th className="text-left px-2 py-1"><TextMediumXS content="Sales" /></th>
                <th className="text-center px-2 py-1"><TextMediumXS content="Total" /></th>
                <th className="text-center px-2 py-1"><TextMediumXS content="Quotes" /></th>
                <th className="text-center px-2 py-1"><TextMediumXS content="Rem." /></th>
                <th className="text-center px-2 py-1"><TextMediumXS content="Ord. Rev." /></th>
                <th className="text-center px-2 py-1"><TextMediumXS content="Win %" /></th>
              </tr>
            </thead>
            <tbody>
              {data && data.map((item, index) => (
                <tr className="odd:bg-white even:bg-slate-50" key={index}>
                  <td className="px-2 py-2 text-left truncate"><TextMediumdS content={capitalizeFirstLetter(item?.name || "N/A")} /></td>
                  <td className="px-2 py-2 text-center">
                    <TextMediumdS content={item?.total ? item.total : "0"} />
                  </td>
                  <td className="px-2 py-2 text-center">
                    <TextMediumdS content={item?.submited ? item.submited : "0"} />
                  </td>
                  <td className="px-2 py-2 text-center">
                    <TextMediumdS content={item?.remaining ? item.remaining : "0"} />
                  </td>
                  <td className="px-2 py-2 text-center">
                    <TextMediumdS content={item?.received ? item.received : "0"} />
                  </td>
                  <td className="px-2 py-2 text-center">
  <TextMediumdS content={item?.Wining ? `${parseFloat(item.Wining).toFixed(2)}%` : "0.00%"} />
</td>


                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default SalesMan;
