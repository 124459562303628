import React, { useState, useEffect } from 'react';
import { TextMediumSM } from '../../../../../Components/Text/MediumText';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import UseGetDashBoardWithParams from '../../../../../Components/Hooks/useDashboardDataParams';
import NuAmount from '../../../../../Components/Utils/NuAmount';

function OrdersRecordProDash() {
  const [params, setParams] = useState({ period: 'monthly' });
  const [selectedPeriod, setSelectedPeriod] = useState('monthly');
  const { data, loading } = UseGetDashBoardWithParams(`/dashboard/QuotesSubmittedByProcurementRecords`, params);

  const periods = [
    { value: 'monthly', option: 'Monthly' },
    { value: 'quarterly', option: 'Quarterly' },
    { value: 'halfyearly', option: 'Half Yearly' },
    { value: 'yearly', option: 'Yearly' },
  ];

  const hanChange=(e)=>{
    setParams({
      period:e.target.value
    })
    setSelectedPeriod(e.target.value)
  }

  const dataDummy = (data?.customer_request_salesman_wise || []).map(item => ({
    name: item.procurementMember,
    amount: item.price,
  }));

  const CustomTooltip = ({ active, payload, label}) => {  
    if (active && payload && payload.length) {
      return (
        <div className=" max-w-[350px] bg-white shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] rounded-lg p-[5px]">
          <p className=" text-slate-600 font-semibold text-xs">{label}</p>
          <table className=" w-full border-collapse ">
            {payload &&
            payload.map((p, index) => (
              <tr key={index} className=' text-xs'>
              <td style={{color:p?.fill}} className={` w-[70px] py-[2px]`}>{p?.name || "-"}</td>
              <td>
                : <span style={{color:p?.fill}} className=" font-semibold"> <NuAmount value={p.value||0} /> </span>
              </td>
            </tr>
            ))}
          </table>
        </div>
      );
    }
  
    return null;
  };

  return (
    <div className="col-span-2 bg-white h-[45vh] w-full p-4  rounded-lg drop-shadow-lg mb-4">
   <div className="flex justify-between items-center mb-1">
      <p className="text-xs 2xl:text-sm text-[#4F4768] font-medium">Order Records</p>
          <div className="flex items-center">
            <select
              value={selectedPeriod}
              onChange={hanChange}
              className="border rounded-md p-1 text-xs text-[#ACACAC]  font-normal"
            >
              {periods.map((period) => (
                <option key={period.value} value={period.value}>
                {period.option}
              </option>
              ))}
            </select>
          </div>
        </div>
        <div className="border-t border-[1px] border-slate-200/60 border-solid w-full "></div>

      {loading ? (
        <div className="flex justify-center items-center h-full">
          <div className="animate-pulse space-y-4 w-full">
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
          </div>
        </div>
      ) : (
        <ResponsiveContainer width="100%" height='90%'>
          <BarChart
            layout="vertical"
            data={dataDummy}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            barSize={15}
          >
            <CartesianGrid strokeDasharray="1 1" />
            <XAxis type="number"  tick={{ fontSize: 12 }}/>
            <YAxis type="category" dataKey="name" tick={{ fontSize: 12 }} />
            <Tooltip content={<CustomTooltip/>}  cursor={{fill:"none"}}/>
            <Legend />
            <Bar dataKey="amount" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
}

export default OrdersRecordProDash;
