import React from 'react'
import CustomerSalesDashboard from './Components/CustomerSales'
import RequestSales from './Components/RequestSales'
import QuoteSubmmitted from './Components/QuoteSubmmitted'
import SalesOrderSales from './Components/SalesOrderSales'
import TopCustomersOrderDashboard from './Components/TopCustomersDashboard'
import TopValueDashboard from './Components/TopSuppliersDashboard'
import MarketingSales from './Components/MarketingSales'
import RequestFromCustomer from './Components/RequestFromCustomer'
import Industry from './Components/Industry'
import Product from './Components/Product'
import Sales from './Components/Sales'
import Approval from './Components/Approval'
import MonthWiseSales from './Components/MonthWiseSales'
import SalesMan from './Components/SalesMan'


function Salesindex() {
  return (
    <div className="w-[100%] h-[calc(100vh-90px)] py-2 ">
      <div className="grid grid-cols-3 grid-rows-2 gap-4 w-full max-h-[44vh] 2xl:max-h-[33vh] mb-4">
    <div > <CustomerSalesDashboard /></div>
    <div className=""> <RequestSales /></div>
    <div className="row-span-2"> <Approval /></div>
    <div className=""> <QuoteSubmmitted /> </div>
    <div className="" > <SalesOrderSales /></div>
</div>
<div className="w-full grid grid-cols-4 gap-4 ">
    <RequestFromCustomer/>
    <SalesMan/>

    
      </div>
      <div className="w-full grid grid-cols-4 gap-4">
    <MonthWiseSales/>
    <Sales/>
    </div>
<div className="w-full grid grid-cols-4 gap-4">
    <TopCustomersOrderDashboard/>
    <TopValueDashboard/>
    </div>
    <div className="w-full grid grid-cols-4 gap-4">
    <Industry/>
    <Product/>
    </div>
    
  
</div>
  )
}

export default Salesindex