import React from 'react';
import UseGetDashBoardData from '../../../../../Components/Hooks/useDashboardData';
import { Pie, PieChart, Cell, Tooltip, ResponsiveContainer, Label } from 'recharts';

function PrFromCustomer() {
  const { data, loading } = UseGetDashBoardData(`/dashboard/pr-from-customer-approvals`);
  const COLORS = ['#FF6347','#32CD32','#048178'];

  const chartData = [
    { name: 'New', value: data?.pendding || 0 },

    { name: 'Verified', value: data?.verified || 0 },
  ];

  // const total = chartData.reduce((acc, item) => acc + item.value, 0);
  const CustomTooltip = ({ active, payload, }) => {
    
    if (active && payload && payload.length) {
      return (
        <div className=" max-w-[250px] bg-white shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px] rounded-lg p-[5px]">
          <p className=" text-xs font-medium">{`${payload[0].name} : ${payload[0].value}`}</p>
        </div>
      );
    }
  
    return null;
  };

  let SumValue = chartData.reduce((sum, value)=>{
    return sum + value?.value;
  },0)


  
  

  return (
    <div className="w-full md:w-[50%]  px-2">
      <div className="flex items-center">
        <div className="pl-1 py-1">
          <p className="text-[#4F4768] text-xs 2xl:text-sm font-normal">YPR from Customer</p>
        </div>
      </div>
      {loading ? (
       <LoadSaleApproval/>
      ) : (
        <>
          <div className="grid grid-rows-2 h-[90%]  justify-center items-center">
            <div className="w-full flex justify-center items-center">
              {
                SumValue > 0 ? (
                  <ResponsiveContainer  width={100} height={90}>
                  <PieChart>
                    <Pie
                      data={chartData}
                      cx="50%"
                      cy="50%"
                      innerRadius={25}
                      outerRadius={40}
                      fill="#8884d8"
                      dataKey="value"
                      paddingAngle={0}
                    >
                      {chartData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                      <Label
                        value={`${data?.total}`}
                        position="center"
                        className="text-center"
                        style={{ fontSize: '12px', fontWeight: 'bold', }}
                      />
                    </Pie>
                    <Tooltip content={<CustomTooltip />} />
                  </PieChart>
                </ResponsiveContainer>
                ):(
                  <ResponsiveContainer  width={100} height={90}>
                  <PieChart>
                    <Pie
                      data={[{
                        name:"No Data",
                        value:100
                      }]}
                      cx="50%"
                      cy="50%"
                      innerRadius={25}
                      outerRadius={40}
                      fill="#E8E8E8"
                      dataKey="value"
                      paddingAngle={0}
                    >
                      <Label
                        value={0}
                        position="center"
                        className="text-center"
                        style={{ fontSize: '12px', fontWeight: 'bold', }}
                      />
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
                )
              }
            </div>
            <div className="w-full h-full flex flex-col ">
              {chartData.map((item, index) => (
                <div key={index} className=" w-full flex items-center text-xs mb-1">
                  <table className=' w-full border-collapse '>
                    <tr>
                      <td className='w-[10px]'>
                      <span
                    className="inline-block w-2 h-2 rounded-full mr-2"
                    style={{ backgroundColor: COLORS[index % COLORS.length] }}
                  ></span>
                      </td>
                      <td className=' w-[47px]'>
                      {item.name}
                      </td>
                      <td>: <span className=' font-semibold'>{item.value}</span></td>
                    </tr>
                  </table>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      {/* <p className="text-sm font-semibold mt-1 pb-4">Total: {total}</p> */}
    </div>
  );
}

export default PrFromCustomer;




export function LoadSaleApproval(){
  return (
    <div className="flex animate-pulse flex-col items-center h-full">
      <div class="flex items-center justify-center w-20 h-20  bg-gray-200 rounded-full mb-2.5 ">
        <div class="flex items-center justify-center w-12 h-12  bg-white rounded-full "></div>
      </div>
          <div className=" space-y-3 w-full">
            <div className="h-3 bg-slate-200 rounded"></div>
            <div className="h-3 bg-slate-200 rounded"></div>
            <div className="h-3 bg-slate-200 rounded"></div>
          </div>
        </div>
  )
}
