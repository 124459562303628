import React, { useEffect, useMemo, useState } from 'react';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuDataTime, NuDate, NuInput, NuSelect, NuTextArea, NuTime } from '../../../../Components/Utils/Input';
import FormikSelectTwo from '../../../../Components/Utils/Select';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { Link, useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import useAddFormData from '../../../../Components/Hooks/useAddFormData';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import Delete from '../../../../Components/Icons/Delete';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import axiosInstance from '../../../../Services/axiosCommon';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import DropDownAddMetaData from '../../../../Components/Utils/selectAddMetaData';
import { TextMediumBase } from '../../../../Components/Text/MediumText';
import Select from 'react-select';
import EditIcon from '../../../../Components/Icons/Edit';
import NuAmount from '../../../../Components/Utils/NuAmount';
import { useCurrency } from '../../../../Context/CurrencyContext';

function QuoteCreate() {
  const { currencySymbol } = useCurrency();
  const { value: Availablity, getData: refAvailablity } = useFetchMataData(`/dropdown/dropdown-details/`, 'Availablity_CustomerQuotes');
  // const { value: DeliveryTerm, getData: refDeliveryTerm } = useFetchMataData(`/dropdown/dropdown-details/`, 'DeliveryTerm_CustomerQuotes');
  // const { value: PaymentTerms, getData: refPaymentTerms } = useFetchMataData(`/dropdown/dropdown-details/`, 'PaymentTerms_CustomerQuotes');
  const { value: PaymentMethod, getData: refPaymentMethod } = useFetchMataData(`/dropdown/dropdown-details/`, 'PaymentMethod_CustomerQuotes');
  // const { value: Validity, getData: refValidity } = useFetchMataData(`/dropdown/dropdown-details/`, 'Validity_CustomerQuotes');
  const { value: leadTime, getData: refLeadTime } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "LeadTime_Supplierqoutes"
  );  const { value: DeliveryTerms, getData: refDeliveryTerms } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "DeliveryTerms_Supplierqoutes"
  );
  const { value: PaymentTerms, getData: refPaymentTerms } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "PaymentTerms_Supplierqoutes"
  );
  const { value: Validity, getData: refValidity } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "Validity_Supplierquotes"
  );
  // const [loading, setLoading] = useState(false);

  // const newLead = leadTime.map((i)=>i.value)
  const { ViewId } = useParams();
  const location = useLocation();
  // const prId = location.state?.data?.prId || null;
  const { prId } = useOutletContext();
  const navigate = useNavigate();

  const { data: Items } = UseDropDown('/items/drop-down');
  const { data: customers } = UseDropDown('/customers/drop-down');

  const [isEditing, setIsEditing] = useState(false);

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };
  const [image, setImage] = useState({ preview: "", raw: "" });
  const { Oncancel, addFormData, contextHolder, isLoading } = useAddFormData();
  const [items, setItems] = useState([]);
  const [newItemData, setNewItemData] = useState({
    supQuotProductId: "",
    quantity: "",
    price: "",
    comments: ""
  });

  const [createNew, setCreateNew] = useState({
    date: "",
    deliverTerms: "",
    paymentTerms: "",
    paymentMethod: "",
    totalPrice: 0.00,
    prId: prId,
    ref: "",
    // ref: "",
    availability: "",
    validity: "",
    specialNotes: "",
    leadTime: "",
    leadTime2: "",
    preparedBy: "",
    reviewedBy: "",
    approvedBy: "",
    remarks: "",
    comments: "",
    products: "",
    discount: ""
  });

  const handleAdd = () => {
    const newItem = {
      supQuotProductId: formik.values.supQuotProductId,
      comments: formik.values.comments,
      price: formik.values.price,
      leadTime: formik.values.leadTime2,
      quantity: formik.values.quantity,
      leadTime: formik.values.leadTime // Add leadTime to the new item
    };
    setItems([...items, newItem]);
    setNewItemData({
      supQuotProductId: "",
      comments: "",
      price: "",
      leadTime: "",
      quantity: ""
    });
    formik.values.supQuotProductId = '';
    formik.values.quantity = '';
    formik.values.leadTime2 = '';
    formik.values.price = '';
    formik.values.comments = '';
    // formik.values.leadTime = ''; // Reset leadTime
  };

  const [deletedItem,setDeletedItem] = useState([]);
  const [deletedItemDropDown,setDeletedItemDropDown] = useState([]);
  const handleDeleteItem = (indexToDelete) => {
    const updatedItems = items.filter((_, index) => index !== indexToDelete);
    setItems(updatedItems);
    const deletedItems = items.filter((_, index) => index == indexToDelete);
    setDeletedItem((prevDeletedItems) => [...prevDeletedItems, ...deletedItems]);
    const removedItemPrice = parseFloat(prlist[indexToDelete].price || 0);
    const removedItemQuantity = parseFloat(prlist[indexToDelete].quantity || 0);
    const newTotalPrice = formik.values.totalPrice - (removedItemPrice * removedItemQuantity);

    formik.setFieldValue('totalPrice', newTotalPrice);
    console.log("Updated totalPrice:", newTotalPrice);
  };
  useEffect(() => {
    let tempData = [];
    deletedItem.forEach((item) => {
      let tempObject = { value: item.productId, label: item.productName };
      tempData.push(tempObject);
    });
    setDeletedItemDropDown(tempData);
  }, [deletedItem]);
    console.log("dele",deletedItem);

    const handleAddCQDel = () => {
      const selectedProductId = formik.values.productId;
    
      // Find the selected product's details from the deletedItemDropDown (or deletedItem array)
      const selectedProduct = deletedItem.find(
        (item) => item.productId === selectedProductId
      );
    
      if (selectedProduct) {
        // Add the selected product to prList
        setItems((prevPrList) => [...prevPrList, selectedProduct]);
    
        // Optionally, remove the selected product from the dropdown after adding it to prList
        setDeletedItem((prevDeletedItems) =>
          prevDeletedItems.filter((item) => item.productId !== selectedProductId)
        );
      }
    };
  const[error,setError] = useState('')
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      // setLoading(true); 
      let newItems = items.map((i)=> ({...i,leadTime:i?.leadTime?.value||""}))
      if (newItems.length === 0) {
        setError('Product table is empty');
        return;
      }
      const valueWithItems = {
        ...value,
        // prId: 21,
        products: newItems,
        
      };
      addFormData('/customer-quotations/new-quotation', valueWithItems, resetForm,true,"/sales/quotes");
      // setLoading(false)
    }
  });
  useEffect(() => {
    setCreateNew((prevCreateNew) => ({
      ...prevCreateNew,
      date: new Date().toISOString().slice(0, 10), 
      
    }));
  }, []);

  const { data: PR } = UseDropDown('/PR/drop-down');
  const { data: user } = UseDropDown('/user/drop-down');
  const [subQuot, setSubQuot] = useState([]);

  // const listSubQuotes = useMemo(() => {
  //   axiosInstance
  //     .get(`/supplier-quotations/quot-products/drop-down/${formik?.values?.prId}`)
  //     .then((res) => {
  //       let list = res.data;
  //       let array = [];
  //       list && list.forEach((i) => {
  //         let num = { value: i?.id, label: i?.productName };
  //         array.push(num);
  //       });
  //       res.data && setSubQuot(array);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching data:', error);
  //     });
  // }, [formik?.values?.prId]);

  const [prlist, setPrlist] = useState([]);

  const getPr = (prId) => {
    if (prId !== "") {
      formik.values.prId=prId
      axiosInstance
        .get(`/supplier-quotations/selected-products/drop-down/${prId}`)
        .then(res => {
          let data = res?.data?.data?.products;

          let tPrice = 0.00;
          for (let i = 0; i < data?.length; i++) {
            tPrice = parseFloat(tPrice) + (parseFloat(data[i].price) * parseFloat(data[i].quantity));
          }
          formik.setFieldValue('totalPrice', tPrice);

          setPrlist(data);
          setItems(data);

          formik.setFieldValue('paymentTerms', res?.data?.data?.customerId || '');
          formik.setFieldValue('deliveryTerms', res?.data?.data?.deliveryTerms || '');
        })
        .catch(err => {
          console.log(err, "err");
        });
    }
  };

  const getCusByPr = (prId) => {
    if (prId !== "") {
      axiosInstance
        .get(`/PR/customer-name/${prId}`)
        .then(res => {

          formik.setFieldValue('customerName', res?.data?.customerName || '');
          formik.setFieldValue('paymentTerms', res?.data?.paymentTerm || '');
        })
        .catch(err => {
          console.log(err, "err");
        });
    }
  };

  useEffect(() => {
    const totalPrice = prlist?.reduce((total, item) => {
      return total + (parseFloat(item.price) * parseFloat(item.quantity || 0));
    }, 0);

    formik.setFieldValue('totalPrice', totalPrice);
  }, [prlist]);

  useEffect(() => {
    if (prId) {
      getPr(prId);
      getCusByPr(prId);
    }
  }, [prId]);

  const handlePriceChange = (e, index) => {
    const updatedItems = [...prlist];
    updatedItems[index].price = e.target.value;
    setPrlist(updatedItems);
  };

  const handleQuantityChange = (index, newQuantity) => {
    const updatedItems = [...prlist];
    updatedItems[index].quantity = newQuantity;
    setPrlist(updatedItems);
  };

  const handleIncrement = (index) => {
    handleQuantityChange(index, prlist[index].quantity + 1);
  };

  const handleDecrement = (index) => {
    handleQuantityChange(index, Math.max(1, prlist[index].quantity - 1));
  };

  const handleInputChange = (event, index) => {
    const value = Math.max(Number(event.target.value));
    handleQuantityChange(index, value);
  };

  const handleLeadTimeChange = (value, index) => {
    const updatedItems = [...prlist];
    updatedItems[index].leadTime = value;
    setPrlist(updatedItems);
  };

  useEffect(() => {
    if (items && items.length > 0) {
      setError('');
    }
  }, [items]);

  const handleNoteChange = (e, index) => {
    const updatedItem = [...prlist];
    updatedItem[index].notes = e.target.value;
    setPrlist(updatedItem);
  };

  return (
    <div className="w-full h-full py-2 px-4">
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-260px)] bg-white rounded-md overflow-hidden ">
          <div className='w-full h-full py-4 px-3 flex justify-start content-start flex-wrap overflow-y-auto'>
            {formik.values.prId&&
            <NuInput
              disabled={true}
              type="text"
              label="Customer"
              placeholder="customer"
              formik={formik}
              name="customerName"
              width="w-full md:w-1/2 lg:w-1/3"
            />}
            <NuDate
              label="Quote Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="date"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            {/* <DropDownAddMetaData
              label="Lead Time"
              placeholder="Choose"
              Options={leadTime}
              formik={formik}
              name="leadTime"
              width="w-full md:w-1/2 lg:w-1/3"
              getFunc={refleadTime}
              displayProperty={"Lead Time"}
              propertyName={"leadTime_customerQuotes"}
            /> */}
            <DropDownAddMetaData
              label="Product Availability"
              placeholder="Choose"
              Options={Availablity}
              formik={formik}
              name="availability"
              width="w-full md:w-1/2 lg:w-1/3"
              getFunc={refAvailablity}
              displayProperty={"Product Availability"}
              propertyName={"Availablity_CustomerQuotes"}
            />
            {/* <DropDownAddMetaData
              label="Quotation Validity"
              placeholder="Choose"
              Options={Validity}
              formik={formik}
              name="validity"
              width="w-full md:w-1/2 lg:w-1/3"
              getFunc={refValidity}
              displayProperty={"Quotation Validity"}
              propertyName={"Validity_CustomerQuotes"}
            /> */}
              <DropDownAddMetaData
              label="Quotation Validity"
              placeholder="Choose"
              Options={Validity}
              formik={formik}
              name="validity"
              width="w-full md:w-1/2 lg:w-1/3"
              getFunc={refValidity}
              displayProperty={"Quotation Validity"}
              propertyName={"Validity_Supplierquotes"}
            />
            {/* <DropDownAddMetaData
              label="Delivery Terms"
              placeholder="Choose"
              Options={DeliveryTerm}
              formik={formik}
              name="deliverTerms"
              width="w-full md:w-1/2 lg:w-1/3"
              getFunc={refDeliveryTerm}
              displayProperty={"Deliver Terms"}
              propertyName={"DeliveryTerm_CustomerQuotes"}
            /> */}
            <DropDownAddMetaData
              label="Delivery Terms"
              placeholder="Choose"
              Options={DeliveryTerms}
              formik={formik}
              name="deliverTerms"
              width="w-full md:w-1/2 lg:w-1/3"
              getFunc={refDeliveryTerms}
              displayProperty={"Delivery Terms"}
              propertyName={"DeliveryTerms_Supplierqoutes"}
            />

            <DropDownAddMetaData
              label="Payment Terms"
              placeholder="Choose"
              Options={PaymentTerms}
              formik={formik}
              name="paymentTerms"
              width="w-full md:w-1/2 lg:w-1/3"
              getFunc={refPaymentTerms}
              displayProperty={"Payment Terms"}
              propertyName={"PaymentTerms_Supplierqoutes"}
            />
            {/* <DropDownAddMetaData
              label="Payment Method"
              placeholder="Choose"
              Options={PaymentMethod}
              formik={formik}
              name="paymentMethod"
              width="w-full md:w-1/2 lg:w-1/3"
              getFunc={refPaymentMethod}
              displayProperty={"Payment Method"}
              propertyName={"PaymentMethod_CustomerQuotes"}
            /> */}
            {/* <NuInput
              type="number"
              label="Discount"
              placeholder="Discount"
              formik={formik}
              name="discount"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            <NuTextArea
              label="Special Notes"
              placeholder="Special Notes"
              formik={formik}
              name="specialNotes"
              // new_width="w-full md:w-2/3"

              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuTextArea
              label="Comments"
              placeholder="comments"
              formik={formik}
              name="comments"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div>
            {deletedItemDropDown.length > 0 ?
              <div className='w-full flex justify-start gap-2'>
                 <FormikSelectTwo
                   type="text"
                   label="Product"
                   placeholder="Product"
                   Options={deletedItemDropDown}
                   formik={formik}
                   name="productId"
                   width="w-full md:w-1/2 lg:w-[67%]"
                 />
                 <button
                onClick={handleAddCQDel}
                  type="button"
                 className=" p-1 mt-[44px] m-1 text-center h-8 cursor-pointer bg-[#048178] border border-solid border-white  text-xs font-medium  text-white  rounded-lg "
                   >
                Add
               </button>
              </div>
              :
              null
            }
            <div className='w-full bg-white overflow-y-scroll'>
            {error && <p className="text-xs font-semibold text-red-500">{error}</p>}
              <div className='flex py-2 items-center'><p className='text-xs pr-1 text-red-500'>Note :</p><p className='text-[10px] text-slate-600'>CLick the edit icon near "Action" to edit quantity, lead time and price.</p></div>
              <div className='w-full py-3 bg-[#048178] flex justify-between rounded-lg px-6'>
              <p className="w-[5%]  text-left  text-white text-xs font-medium">S.N. </p>

                <p className="w-[30%] text-left text-white text-xs font-medium">
                  Product
                </p>
             
                <p className="w-[5%] text-center text-white text-xs font-medium">
                  Unit
                </p>
                <p className="w-[15%] text-center text-white text-xs font-medium">
                  Qty
                </p>
                <p className="w-[10%] text-center text-white text-xs font-medium">
                  Lead Time
                </p>
                <p className="w-[15%] text-center text-white text-xs font-medium">
                  Price
                </p>
                <p className="w-[15%] text-center text-white text-xs font-medium">
                  Total
                </p>
                <p className="w-[5%] text-center text-white text-xs font-medium">
                  Action
                </p>
                <button type="button" onClick={toggleEdit} className="text-center text-white text-xs font-medium">
                  <EditIcon color='white' width={"12"} height={"12"}/>
                </button>
              </div>
              {items?.length>0?
              <div className="h-[calc(100vh-300px)] overflow-y-scroll">

              {items.map((List, index) => {
                return (
                  <div key={index} className="w-full py-2 flex justify-between px-6 odd:bg-white even:bg-slate-100">
                    <p className="w-[5%]  text-left  text-[#606279] text-xs font-light py-1">
                                    {index + 1}
                                </p>
                      <div className="w-[30%]">

                    <p className=" text-left text-[#606279] text-xs font-light">
                      {List?.productName}
                    </p>
                    
                    <p className="flex">
         <p className="text-[10px] text-[#606279] font-thin mt-1"> </p>
         {isEditing  ? (
 
  <input
                          type="text"
                          className="w-[65%] py-1 text-left px-1 text-[#606279] border border-gray-100 text-[10px] font-thin "
                          name="notes"
                          placeholder="Enter here..."
                          value={List.notes || ""}
                          onChange={(e) => handleNoteChange(e, index)}
                        />
                      
  ):(
    <>
    {List?.notes && (
    <div className="relative group w-[40%]">
    <p className="text-left text-[#9e9fa7] text-[10px] font-thin truncate break-words">
      Notes : {List?.notes}
    </p>
    <div className="absolute hidden group-hover:block bg-gray-200 text-[#111537] text-[10px] font-light rounded-md px-2 py-1 w-max max-w-xs z-10">
      {List?.notes}
    </div>
  </div>  )}
  </>
)}
    </p>
    </div>
                    <p className="w-[5%] text-center text-[#606279] text-xs font-light">
                      {List?.unit}
                    </p>
                    <div className="w-[15%] text-center text-[#606279] text-xs font-light flex items-center justify-center space-x-2">
                      {isEditing ? (
                        <>
                          <button
                            type="button"
                            className="border rounded-full p-1"
                            onClick={() => handleDecrement(index)}
                          >
                            -
                          </button>
                          <input
                            type="number"
                            className="w-16 text-center border text-[#606279] text-xs font-light"
                            name="quantity"
                            value={List.quantity}
                            onChange={(e) => handleInputChange(e, index)}
                          />
                          <button
                            type="button"
                            className="border rounded-full p-1"
                            onClick={() => handleIncrement(index)}
                          >
                            +
                          </button>
                        </>
                      ) : (
                        <p>{List.quantity}</p>
                      )}
                    </div>
                    <div className="w-[10%] text-center text-[#606279] text-xs font-light">
                        <Select
                          value={List.leadTime}
                          options={isEditing ? leadTime : []}
                          className="w-full"
                          isDisabled={!isEditing}
                          onChange={(value) => handleLeadTimeChange(value, index)}
                        />
                    </div>
                    <div className="w-[15%] text-center text-[#606279] text-xs font-light">
                      {isEditing ? (
                        <input
                          type="number"
                          className="w-full py-1 text-center border text-[#606279] text-xs font-light"
                          name="price"
                          placeholder="Enter the price"
                          value={List.price || ''}
                          onChange={(e) => handlePriceChange(e, index)}
                        />
                      ) : (
                        <p>{List.price}</p>
                      )}
                    </div>
                    <p className=" w-[15%] text-center text-[#606279] text-xs font-light">
                  {(List.price * List.quantity ).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} 
                  
                  </p>
                    <div className='w-[5%] text-center'>
                      <button
                        disabled={isEditing ? false : true}
                        onClick={() => handleDeleteItem(index)}
                        type="button"
                        className={`text-red-500 ${isEditing && "cursor-pointer"} ml-4 border-none`}
                      >
                        <Delete
                          color='red'
                          height={4}
                        />
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>:<div className="w-full text-center text-[#93949f]">No Items here</div>}

            </div>
            <div className=" w-full my-6  flex justify-end items-center gap-4">
              <TextMediumBase content={"Total Price : "} />
              <p className="text-sm"> {currencySymbol} </p>
              <p className="w-64 h-8 border rounded-md text-xs pl-2 py-2">
                <NuAmount defaultCurrency={false} value={formik?.values?.totalPrice || 0} />
              </p>
              {/* <NuInput
                label=""
                type="number"
                formik={formik}
                placeholder="Total Price"
                name="totalPrice"
                width="w-full md:w-1/2 lg:w-1/3"
                disabled={true}
              /> */}
            </div>
            <div className="w-full  mx-auto my-4 mt-8 ">
              <div className="flex justify-end gap-4">
                <CancelButton handleClick={() => { Oncancel(-1) }} />
                <SubmitButton isLoading={isLoading}/>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default QuoteCreate;
