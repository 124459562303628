import React from "react";
import {
  CustomersDashboard,
  PurchaseOrdersfromCustomers,
  PurchaseOrderstoSuppliers,
  PurchaseRequestDashborad,
  QuotestoCustomersDashboard,
  SuppliersDashboard,
} from "./Components";
import Approvals from "./Components/Approval";
import AlertDashborad from "./Components/AlertDashborad";
import SalesOrderSales from "./Sales/Components/SalesOrderSales";

function Dashboard() {
  return (
    <div className="w-[100%] h-[calc(100vh-90px)] py-2  ">
      <div className="grid grid-cols-3 grid-rows-2 gap-4 w-full max-h-[44vh] 2xl:max-h-[33vh] mb-4">
        <div>
          <CustomersDashboard />
        </div>
        <div className="">
          <SuppliersDashboard />
        </div>
        <div className="row-span-2">
          <AlertDashborad />
        </div>
        <div className="">
          <PurchaseOrderstoSuppliers />
        </div>
        <div className="">
          <SalesOrderSales />
        </div>
      </div>
      <div className="w-full grid grid-cols-4 gap-4 ">
        <Approvals />
      </div>
      <div className="w-full grid grid-cols-3 gap-4 ">
        <PurchaseRequestDashborad />
        <QuotestoCustomersDashboard />
        <PurchaseOrdersfromCustomers />
      </div>
    </div>
  );
}

export default Dashboard;
