import { useFormik } from 'formik';
import React from 'react'
import axiosInstance from '../../../Services/axiosCommon';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useToast } from '../../../Components/Others/ToastServices';
import * as yup from 'yup';
import { CancelButton, SubmitButton } from '../../../Components/Utils/Buttons';
import { TextMediumXL } from '../../../Components/Text/MediumText';
import { useEffect } from 'react';
import UseEditData from '../../../Components/Hooks/useEditData';

function NotificationProfile() {
    const { userId } = useParams();
     const {Oncancel,editData,isLoading} = UseEditData();
    const navigate = useNavigate();
    const toast = useToast();
    const [data,setData] = useState([]);
    function getData() {
      axiosInstance.get(`/alert-settings/user-preference`).then(res=>{
        setData(res?.data?.data)
      }).catch(err=>{
        console.log(err);
      })
    }
    // const notiValue =[
    //     // {
    //     //   name: "Catalogues",
    //     // },
    //     {
    //       name: "Items",
    //     },
    //     {
    //       name: "Appointments",
    //     },
    //     {
    //       name: "Leads",
    //     },
    //     {
    //       name: "Purchase Requests",
    //     },
    //     {
    //       name: "RFQ",
    //     },
    //     {
    //       name: "Supplier Quotes",
    //     },
    //     {
    //       name: "Quote Comparison",
    //     },
    //     {
    //       name: "Purchase Order",
    //     },
    //     {
    //       name: "Contracts",
    //     },
    //     {
    //       name: "Suppliers",
    //     },
    //     {
    //       name: "Customers",
    //     },
    //     {
    //       name: "Invoices Incoming",
    //     },
    //     {
    //       name: "Invoices Outgoing",
    //     },
    //     {
    //       name: "Promotions",
    //     },
    //     {
    //       name: "Sales Orders",
    //     },
    //     {
    //       name: "Quotes",
    //     },
    //     {
    //       name: "Payables",
    //     },
    //     {
    //       name: "Receivables",
    //     },
    //     {
    //       name: "Refunds",
    //     },
    //     {
    //       name: "My Tasks",
    //     },
    //     {
    //       name: "All Tasks",
    //     },
    //     {
    //       name: "Assets",
    //     },
    //     {
    //       name: "Inventory",
    //     },
    //     {
    //       name: "GRN",
    //     },
    //     {
    //       name: "Delivery Note",
    //     },
    //     {
    //       name: "Returns",
    //     },
    //     {
    //       name: "Packing List",
    //     },
    //     {
    //       name: "Requests",
    //     },
    //     {
    //       name: "Agents",
    //     },
    //     {
    //       name: "Shipment Tracking",
    //     },
    //     {
    //       name: "Vehicle",
    //     },
    //     {
    //       name: "Equipment",
    //     },
    //     {
    //       name: "Chat",
    //     },
    //     // {
    //     //   name: "Team",
    //     // },
    //   ]
    // const [createNew,setCreateNew] = useState({
        
    //   });
      const formik = useFormik({
        enableReinitialize: true,
        initialValues: "",
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (value,{resetForm}) => {
          let noti={
            data:data
          }
          editData(`/alert-settings/edit-preference/by-user`,noti,resetForm,`/profile`)
          getData()
        }
      })
    // const formik = useFormik({
    //     enableReinitialize: true,
    //     initialValues: createNew,
    //     validationSchema:yup.object({

    //     }),
    //     onSubmit: async (values, { resetForm }) => {
    //       axiosInstance.put(`/auth/reset-password`, values)
    //         .then((res) => {
    //           if (res.data.status) {
    //             resetForm();
    //             toast.open({
    //               type: "success",
    //               message: "Password reset successfully",
    //               description: res.data?.msg || "Password has been updated.",
    //             });
    //             navigate(-1);
    //           }
    //         })
    //         .catch((err) => {
    //           toast.open({
    //             type: "error",
    //             message: "Password reset failed",
    //             description: err.response?.data?.msg || "Network Error",
    //           });
    //         });
    //     },
    //   });
      // function handleChange(e,value){
      //   console.log("e",e,value)
      // }
      function handleAlertChange(event, name, id) {
        const updatedData = data.map((item) =>
          item.actionName === name && item.id ===id ? { ...item, alertEnabled: event.target.checked } : item
        );
        setData(updatedData);
        console.log("Updated Data:", updatedData);
      }
      useEffect(()=>{
        getData()
      },[]) 
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
     <div className="animate-scale-in w-[90%] max-h-[90vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
     <form onSubmit={formik.handleSubmit}>
          <div>
          <TextMediumXL content="Email & Notification" otherStyles="mb-2" align="start" />
          <table className=' w-full h-full overflow-y-auto mt-2 '>
       <thead>
       <tr className=''>
          <th className=' w-[33.3%] text-left text-sm font-medium py-2 px-4'>Module Name</th>
          <th className=' w-[33.3%] text-center text-sm font-medium py-2 px-4'>Alert</th>
          {/* <th className=' w-[33.3%] text-center text-sm font-medium py-2 px-4'>Push Notification</th> */}
        </tr>
       </thead>
       <tbody>
        {
          data && data.map((value, index) => {
            return (
              <tr className='even:bg-white odd:bg-slate-50 ' key={index}>
              <td className=' w-[33.3%] py-2 text-left text-sm px-4'>{value?.actionName}</td>
              <td className=" w-[33.3%] py-2 text-center text-sm px-4">
                  <label className="inline-flex items-center cursor-pointer overflow-hidden">
                    <input type="checkbox" 
                      checked={value?.alertEnabled == true ? true : false}
                      onChange={(e) => handleAlertChange(e, value?.actionName,value?.id)}
                     className="sr-only peer" hidden />
                    <div className="relative w-9 h-5 bg-gray-200  rounded-full peer dark:bg-gray-300 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] peer-checked:after:bg-white after:bg-textColor-main after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all  peer-checked:bg-textColor-main"></div>
                  </label>
                </td>
              {/* <td className=' w-[33.3%] py-2 text-center text-sm px-4'>
              <input type='checkbox' onChange={(e)=>handleChange(e,value?.actionName,value?.actionId)} />
              </td> */}
            </tr>
            )
          })
        }
       </tbody>
      </table>
          <div className="w-full flex justify-end items-center gap-2 mt-2">
              <CancelButton handleClick={() => navigate(-1)} />
              <SubmitButton />
            </div>
          </div>
     </form>
     </div>
    </div>
  )
}

export default NotificationProfile;