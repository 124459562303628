
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { FormikSelect, NuInput } from './Input';
import { CancelButton, SubmitButton } from './Buttons';
import axiosInstance from '../../Services/axiosCommon';
import { useToast } from '../Others/ToastServices';

const EmailModal = ({ onClose, vendorId, idd,fetcher,url,emailType }) => {
  console.log("emailType",emailType);
  const [dropdownEmails, setDropdownEmails] = useState([]);
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [newCcEmail, setNewCcEmail] = useState('');
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      to: "",
      cc: [],
    },
    validationSchema: yup.object({}),
    onSubmit: async (values, { resetForm }) => {
      setLoading(true); 
    let newValue = {...values,to: [values['to']]}
      axiosInstance.post(`${url}send-email/${idd}`, newValue)
        .then((res) => {
          if (res.data?.status === true) {
            toast.open({
              type: 'success',
              message: 'Success',
              description: res.data?.msg || 'Email sent successfully.',
            });
            resetForm();
            setDropdownEmails([...dropdownEmails]); 
            fetcher();
            onClose()
          } else {
            toast.open({
              type: res.data?.status === false ? 'error' : 'warning',
              message: res.data?.status === false ? 'Error' : 'Warning',
              description: res.data.error || res.data?.msg || 'Network error.',
            });
          }
        })
        .catch((err) => {
          console.error(err);
          toast.open({
            type: 'error',
            message: 'Error',
            description: 'Network error.',
          });
        }).finally(() => {
          setLoading(false); 
        });
    },
  });

  const getData = (vendorId) => {
    axiosInstance.get(`/vendors/${vendorId}/emails`).then((res) => {
      if (res.data?.data) {
        setDropdownEmails(res.data.data.cc || []); 
        res.data.data.to && formik.setFieldValue('to', res.data.data.to);
        formik.setFieldValue('cc', []);
      }
    }).catch(() => {
      toast.open({
        type: 'error',
        message: 'Error',
        description: 'Failed to fetch data.',
      });
    });
  };

  useEffect(() => {
    if (vendorId) {
      getData(vendorId);
    }
  }, [vendorId]);

  const handleAddNewCcEmail = () => {
    if (newCcEmail && !dropdownEmails.includes(newCcEmail)) {
      setDropdownEmails([...dropdownEmails, newCcEmail]);
      setShowAddPopup(false);
      setNewCcEmail('');
    }
  };

  const handleSelectCcEmail = (e) => {
    const email = e.value;
    if (email && !formik.values.cc.includes(email)) {
      const updatedCcEmails = [...formik.values.cc, email];
      formik.setFieldValue('cc', updatedCcEmails);
      setDropdownEmails(dropdownEmails.filter((e) => e !== email));
    }
  };

  const AddEmail = (isEmailType) => {
    if (isEmailType && newCcEmail) {
      formik.setFieldValue('cc', [...formik.values.cc, newCcEmail]);
      setNewCcEmail(''); 
    }
  };
  

  const handleRemoveCcEmail = (email) => {
    const updatedCcEmails = formik.values.cc.filter((e) => e !== email);
    formik.setFieldValue('cc', updatedCcEmails);
    setDropdownEmails([...dropdownEmails, email]);
  };

  return (
    <div className="animate-scale-in w-full max-h-[80vh] bg-white overflow-y-scroll p-2 rounded-lg">
      <h2 className="text-sm text-center font-semibold">Email</h2>

      <form onSubmit={formik.handleSubmit}>
        <div className="mb-4 flex items-center">
          <label className="text-sm font-medium text-gray-700 mr-2">To:</label>
          <NuInput
            type="email"
            placeholder="Email"
            formik={formik}
            name="to"
            value={formik.values.to}
            width="w-full"
          />
        </div>

        <div className="mb-4 flex items-center">
          <label className="text-sm font-medium text-gray-700 mr-2">CC:</label>
          {emailType === true ? (
            <div className="flex w-full items-center">
              <NuInput
                name="cc"
                type="email"
                value={newCcEmail}
                onChange={(e) => setNewCcEmail(e.target.value)}
                placeholder="email"
                width="w-full"
              />
              <button
                onClick={() => AddEmail(true)}
                type="button"
                className="ml-2 px-2 mt-2 py-1 bg-baseColor-primary text-white rounded"
              >
                +
              </button>
            </div>
          ) : (
            <div className="flex w-full items-center">
              <FormikSelect
                name="cc"
                className="text-xs font-medium border w-full h-10 px-3 mt-2 rounded-[10px] outline-none"
                onChange={handleSelectCcEmail}
                value=""
                placeholder="Select"
                width="w-full"
                Options={dropdownEmails.map((email) => ({
                  value: email,
                  label: email,
                }))}
              />
              <button
                onClick={() => setShowAddPopup(true)}
                type="button"
                className="ml-2 px-2 mt-2 py-1 bg-baseColor-primary text-white rounded"
              >
                +
              </button>
            </div>
          )}
        </div>
        {formik.values.cc.length > 0 && (
          <div className="w-full bg-gray-200 border h-[40%] border-gray-300  rounded-lg p-2 overflow-y-scroll">
            {formik.values.cc.map((email, index) => (
              <div
                key={index}
                className="flex items-center border bg-white border-gray-300 m-1 rounded-lg p-1  px-2 justify-between text-xs"
              >
                <p>{email}</p>
                <button
                  type="button"
                  onClick={() => handleRemoveCcEmail(email)}
                  className="text-red-500 hover:text-red-700"
                >
                  x
                </button>
              </div>
            ))}
          </div>
        )}
        {showAddPopup && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white w-[35%] p-4 rounded">
              <h3 className="mb-2 text-sm">Add CC Email</h3>
              <NuInput
                type="email"
                placeholder="Email"
                value={newCcEmail}
                onChange={(e) => setNewCcEmail(e.target.value)}
              />
              <div className="flex justify-end mt-2">
                <button
                  onClick={() => setShowAddPopup(false)}
                  className="px-5 py-[9px] cursor-pointer text-xs text-textColor-main bg-[#E6F2F2] hover:bg-gray-300 rounded-md"
                >
                  Cancel
                </button>
                <button
                  onClick={handleAddNewCcEmail}
                  className="ml-2 px-5 py-[9px] cursor-pointer text-xs text-white bg-baseColor-primary rounded-md"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="flex justify-end gap-2 mt-4">
          <CancelButton handleClick={onClose} />
          <SubmitButton name="Send" type="submit" isLoading={loading} />
        </div>
      </form>
    </div>
  );
};

export default EmailModal;

