import { TextMediumSM } from "../../../../../Components/Text/MediumText"
import ApprovalStatusDN from "./ApprovalStatusDN";
import DeliveryStatus from "./DeliveryStatus";
import DeliveryVehicle from "./DeliveryVechicleType";
import IdType from "./IdType";



const DeliveryNotes = () =>{
    return(
        <>
        <TextMediumSM content="Delivery Notes" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <DeliveryStatus/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <DeliveryVehicle/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <IdType/>
         </div>
         {/* <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <ApprovalStatusDN/>
         </div> */}
         </div>
         </>
    )
}

export default DeliveryNotes;