import React, { useEffect, useState } from 'react'
import UseGetDashBoardData from '../../../../../Components/Hooks/useDashboardData';
import axiosInstance from '../../../../../Services/axiosCommon';

const Vehicle = () => {
    // const { data, loading } = UseGetDashBoardData(`/new-dashboard/vehicles-and-equipments`);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
  
    async function getData() {
      setLoading(true);
      try {
        await axiosInstance.get(`/new-dashboard/vehicles-and-equipments`).then((res) => {
          setLoading(false);
          setData(res?.data || []);
        });
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  
    useEffect(() => {
      getData();
    }, []);
  
  
    return (
      <div className=" bg-white p-4 rounded-lg drop-shadow-lg h-[16vh] w-full ">
   
      <div className="flex  scroll-px-36 mt-2 gap-2">
      <div className="bg-baseColor-teritary rounded-full p-2 2xl:p-3 h-[18%]  ml-2">
         
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#048178" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 18.75a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 0 1-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 0 0-3.213-9.193 2.056 2.056 0 0 0-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 0 0-10.026 0 1.106 1.106 0 0 0-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12" />
</svg>
      </div>
          <div className="pl-4 h-[95%] flex flex-col">
          <p className='text-[#4F4768] text-xs 2xl:text-sm font-normal '> Vehicle</p>
          <p className='text-[#333333] text-[18px] font-semibold'>
          {data?.vehicles }
</p>   
 
           
            </div>
            
  
            </div>
            </div>
    );
  }

export default Vehicle