import React from 'react';
import companyLogo from "../../../../assets/images/companyLogo.png";
import companyName from "../../../../assets/images/companyName.png";

function DesignTwo({ data }) {
  return (
    <div className="p-4 text-xs font-normal font-helvetica w-full h-[50%] border overflow-y-scroll rounded-md">
      {/* Header Section */}
      <div className="flex justify-between items-center p-5 border-b-2 border-blue-700">
        <img src={companyName} alt="Company Name Logo" className="h-24" />
        <img src={companyLogo} alt="Company Logo" className="h-24 w-1/4 opacity-80" />
      </div>

      
      <div className="flex justify-between p-5 border-b border-gray-300">
        <div>Your Ref.: </div>
        <div className="text-center font-bold text-xl text-blue-700 uppercase">Title</div>
        <div className="text-right">Date: </div>
      </div>

    
      <div className="flex justify-between my-5 p-5 border gap-4 border-gray-300">
        <div className="w-1/3 text-center">
          <h3 className=" mb-2 border-b-2 border-blue-700 text-blue-700 text-sm">Name</h3>
         
        </div>
        <div className="w-1/3 text-center">
          <h3 className=" mb-2 border-b-2 border-blue-700 text-blue-700 text-sm">Reference</h3>
          
        </div>
        <div className="w-1/3 text-center">
          <h3 className=" mb-2 border-b-2 border-blue-700 text-blue-700 text-sm">Category</h3>
          
        </div>
      </div>

     
      <div className="relative border-t border-black border-b ">
        <table className="min-w-full border-collapse">
          <thead>
            <tr>
              <th className="border-b border-black px-2 py-2 text-left font-bold">S/N</th>
              <th className="border-b border-black px-2 py-2 text-left font-bold">Product</th>
              <th className="border-b border-black px-2 py-2 text-left font-bold">Unit</th>
              <th className="border-b border-black px-2 py-2 text-left font-bold">QTY</th>
              <th className="border-b border-black px-2 py-2 text-left font-bold">Price</th>
              <th className="border-b border-black px-2 py-2 text-left font-bold">Total</th>
            </tr>
          </thead>
          <tbody>
           
                <tr  className="border-b border-black">
                  <td className="border-b border-black px-2 py-2 text-left font-bold"></td>
                  <td className="border-b border-black px-2 py-2 text-left font-bold break-words"></td>
                  <td className="border-b border-black px-2 py-2 text-left font-bold"></td>
                  <td className="border-b border-black px-2 py-2 text-left font-bold"></td>
                  <td className="border-b border-black px-2 py-2 text-left font-bold"></td>
                  <td className="border-b border-black px-2 py-2 text-left font-bold"></td>
                </tr>
           
          </tbody>
        </table>
      </div>

      {/* Total Section */}
      <div className="mt-5">
        <table className="w-full border-collapse">
          <tfoot>
            <tr>
              <td colSpan="5" className="text-right font-semibold">TOTAL : </td>
            </tr>
            <tr>
              <td colSpan="5" className="text-right font-semibold">NET TOTAL : </td>
            </tr>
          </tfoot>
        </table>
      </div>

      {/* Terms & Conditions */}
      <div className="flex justify-between items-start my-5">
        <div>
          <h4 className="font-semibold">Terms & Conditions</h4>
          <div className="flex">
            <p className="font-medium text-gray-800">Price:</p>
             </div>
          <div className="flex">
            <p className="font-medium text-gray-800">Delivery Period:</p>
             </div>
          <div className="flex">
            <p className="font-medium text-gray-800">Delivery:</p>
              </div>
              <div className="flex">
            <p className="font-medium text-gray-800">Payment:</p>
              </div>
        </div>
      </div>

      {/* Note Section */}
      <div className="flex justify-between items-center">
        <div>
          <p>Note:</p>
          {/* <p>{data.notesFromCus || 'N/A'}</p> */}
        </div>
      </div>
    </div>
  );
}

export default DesignTwo;
