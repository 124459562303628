import { useFormik } from "formik";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import { BackButton, CancelButton, SubmitButton } from "../../../../Components/Utils/Buttons";
import { useState } from "react";
import * as yup from 'yup';
import { NuDate, NuInput, NuMobileNumber, NuUpload } from "../../../../Components/Utils/Input";
import { useLocation } from "react-router-dom";
import useEditFormData from "../../../../Components/Hooks/useEditFormData";
import CustomImgUpload from "../../../../Components/Utils/CustomImgUpload";
import { currencySymbol } from "../../../../assets/CurrencySymbols";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import { CountryMIN } from "../../../../assets/CountryMini";


const EditCompanyInfo = () =>{
    const [image, setImage] = useState({ preview: "", raw: "" });
    const location = useLocation();
    const data = location?.state?.data;
    const from = location?.state?.from;
    const { Oncancel, editFormData, contextHolder, isLoading } = useEditFormData();
    console.log("cxz",data);
  const [createNew, setCreateNew] = useState({
    taxId: data?.taxId,
    companyName: data?.companyName,
    mobile: data?.mobile?.number,
    countryCode: data?.mobile?.countryCode,
    email: data?.email,
    website: data?.website,
    establishedOn: data?.establishedOn,
    currency: data?.currency,
    registrationNo: data?.registrationNo,
    businessIndustry: data?.businessIndustry,
    businessDesc: data?.businessDesc,
    phone: data?.phone,
    city: data?.address?.city,
    streetName: data?.address?.streetName,
    district: data?.address?.district,
    state: data?.address?.state,
    companyLogo: data?.compLogo?.file,
    country: data?.address?.country,
    postalCode: data?.address?.postalCode != "None"?data?.address?.postalCode:"",
    socialMediaLinks: "",
    delivery_postal_code:data?.delivery_postal_code !='None'?data?.delivery_postal_code:"",
    delivery_country: data?.delivery_country,
    delivery_state: data?.delivery_state,
    delivery_city: data?.delivery_city,
    deliveryAddress2: data?.deliveryAddress2,
    deliveryAddress1: data?.deliveryAddress1,
    billing_postal_code:data?.billing_postal_code !=='None'?data?.billing_postal_code:"",
    billing_country: data?.billing_country,
    billing_state: data?.billing_state,
    billing_city: data?.billing_city,
    billingAddress2: data?.billingAddress2,
    billingAddress1: data?.billingAddress1,
  })
  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  const handleDeleteImg = () => {
    setImage({
      preview: "",
      raw: "",
    });
    setCreateNew(prevState => ({ ...createNew, companyLogo: "" }))
    formik.values.companyLogo = ''
  }
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: createNew,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
          // email: yup.string().required('Email is required!'),
        }),
        onSubmit: async (value, { resetForm }) => {
            let formData = new FormData();
            formData.append('json_data', JSON.stringify(value));
            formData.append('companyLogo', image.raw);
      
            editFormData(`/company-info/edit-company-details/1`, formData, resetForm, from);
        },
      });

      const [useAddress,setAddress] = useState(false);
      function handleAddress(e){
        setAddress(e.target.checked);
        if(e.target.checked){
          formik.values.deliveryAddress1 = formik.values.billingAddress1
          formik.values.deliveryAddress2 = formik.values.billingAddress2
          formik.values.delivery_country = formik.values.billing_country
          formik.values.delivery_city = formik.values.billing_city
          formik.values.delivery_state = formik.values.billing_state
          formik.values.delivery_postal_code = formik.values.billing_postal_code
        }else{
          formik.values.deliveryAddress1 = ""
          formik.values.deliveryAddress2 = ""
          formik.values.delivery_country = ""
          formik.values.delivery_city = ""
          formik.values.delivery_state =  ""
          formik.values.delivery_postal_code = ""
        }
        console.log(formik.values,"hhu");
        
      }

    return(
        <div className='w-full h-full px-4 py-2'>
        <div className='w-full flex justify-between items-center'>
        <Breadcrumb
          items={[
            { 
                name: "Company Info",
                link: "/settings"
            },
            { name: "Edit" },
          ]} />
        <BackButton/>
          </div>
          <div className="w-full h-[calc(100vh-140px)] overflow-y-auto">
        <form onSubmit={formik.handleSubmit}>
          <div className="w-full mx-auto bg-white rounded-md pb-4 ">
            <div className=" w-full h-full flex justify-start content-start flex-wrap   mb-4">
         <CustomImgUpload
            borderStyle="dashed"
            handleChange={handleChangeImg}
            label={'Company Logo'}
            preview={image.preview || createNew.companyLogo || ""}
            removeFunction={handleDeleteImg}
          />
            <div className="w-full">
                <p className="py-2 px-4 text-xs 2xl:text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                  Company Info :
                </p>
              </div>
            <NuInput
              type="text"
              label="Company Name"
              placeholder="Company Name"
              formik={formik}
              name="companyName"
              width="w-full md:w-1/2 lg:w-1/3"
            />
              <NuInput
              type="email"
              label="Email"
              placeholder="Email"
              formik={formik}
              name="email"
              width="w-full md:w-1/2 lg:w-1/3"
            />
              <NuInput
              type="number"
              label="Phone"
              placeholder="Phone"
              formik={formik}
              name="phone"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuMobileNumber
              nunCode="countryCode"
              label="Mobile"
              placeholder="mobile"
              formik={formik}
              name="mobile"
               width="w-full md:w-1/2 lg:w-1/3"
            />
          
            <NuInput
              type="text"
              label="Website"
              placeholder="Website"
              formik={formik}
              name="website"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <NuDate
              label="Established On"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="establishedOn"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             </div>
          </div>
          <div className="w-full mx-auto  bg-white rounded-md  mt-4 ">
            <div className=" w-full h-full flex justify-start content-start flex-wrap   mb-4">
         
             <div className="w-full">
                <p className="py-2 px-4 text-xs 2xl:text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                  Address :
                </p>
              </div>
                 <NuInput
              type="text"
              label="Country"
              placeholder="Country"
              formik={formik}
              name="country"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="text"
              label="State / Province "
              placeholder="State / Province"
              formik={formik}
              name="state"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="text"
              label="District"
              placeholder="District"
              formik={formik}
              name="district"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="text"
              label="City"
              placeholder="City"
              formik={formik}
              name="city"
              width="w-full md:w-1/2 lg:w-1/3"
            />
      <NuInput
              type="text"
              label="Street "
              placeholder="Street "
              formik={formik}
              name="streetName"
                width="w-full md:w-1/2 lg:w-1/3"
            />
 <NuInput
              type="number"
              label="Zip / Postal Code"
              placeholder="Zip / Postal Code"
              formik={formik}
              name="postalCode"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             </div>
          </div>
          
          <div className="w-full mx-auto  bg-white rounded-md  mt-4 ">
            <div className=" w-full h-full flex justify-start content-start flex-wrap   mb-4">
         
           <div className="w-full">
                <p className="py-2 px-4 text-xs 2xl:text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                Billing Address1 :
                </p>
              </div>
              <NuInput
                type="text"
                label="Street Address Line 1"
                placeholder="Street Address Line 1"
                formik={formik}
                name="billingAddress1"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="text"
                label="Street Address Line 2"
                placeholder="Street Address Line 2"
                formik={formik}
                name="billingAddress2"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="text"
                label="City"
                placeholder="City"
                formik={formik}
                name={"billing_city"}
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="text"
                label="State / Province"
                placeholder="State / Province"
                formik={formik}
                name="billing_state"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="number"
                label="Postal / Zip Code"
                placeholder="Postal / Zip Code"
                formik={formik}
                name="billing_postal_code"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <FormikSelectTwo
                type="text"
                label="Country"
                placeholder="Country"
                Options={CountryMIN}
                formik={formik}
                name="billing_country"
                width="w-full md:w-1/2 lg:w-1/3"
                FixPosition={false}
                PortalTarget={false}
              />
              </div>
              <div className=" px-4 pb-2 flex gap-1">
                  <input type="checkbox" checked={useAddress} onChange={handleAddress} />
                  <label className=" text-xs">Use Billing Address as Delivery Address</label>
                </div>
              </div>
              <div className="w-full mx-auto  bg-white rounded-md  mt-4 ">
            <div className=" w-full h-full flex justify-start content-start flex-wrap   mb-4">
         
           <div className="w-full">
                <p className="py-2 px-4 text-xs 2xl:text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                Delivery Address1 :
                </p>
              </div>
              <NuInput
                type="text"
                label="Street Address Line 1"
                placeholder="Street Address Line 1"
                formik={formik}
                name="deliveryAddress1"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="text"
                label="Street Address Line 2"
                placeholder="Street Address Line 2"
                formik={formik}
                name="deliveryAddress2"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="text"
                label="City"
                placeholder="City"
                formik={formik}
                name="delivery_city"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="text"
                label="State / Province"
                placeholder="State / Province"
                formik={formik}
                name="delivery_state"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="number"
                label="Postal / Zip Code"
                placeholder="Postal / Zip Code"
                formik={formik}
                name="delivery_postal_code"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <FormikSelectTwo
                type="text"
                label="Country"
                placeholder="Country"
                Options={CountryMIN}
                formik={formik}
                name="delivery_country"
                width="w-full md:w-1/2 lg:w-1/3"
                FixPosition={false}
                PortalTarget={false}
              />
              </div>
              
              </div>
          <div className="w-full mx-auto  bg-white rounded-md  mt-4 ">
            <div className=" w-full h-full flex justify-start content-start flex-wrap   mb-4">
         
           <div className="w-full">
                <p className="py-2 px-4 text-xs 2xl:text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                 Business :
                </p>
              </div>   
                <NuInput
              type="text"
              label="Business Industry"
              placeholder="Business Industry"
              formik={formik}
              name="businessIndustry"
              width="w-full md:w-1/2 lg:w-1/3"
            />
           
           
            <NuInput
              type="number"
              label="Registration No."
              placeholder="Registration No"
              formik={formik}
              name="registrationNo"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="number"
              label="Tax Id"
              placeholder="Tax Id"
              formik={formik}
              name="taxId"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <FormikSelectTwo
                  label="Currency"
                  placeholder="Choose"
                  Options={currencySymbol}
                  formik={formik}
                  name="currency"
                  width="w-full md:w-1/2 lg:w-1/3"
                />
            <NuInput
              type="text"
              label="Business Desc"
              placeholder="Business Desc"
              formik={formik}
              name="businessDesc"
              width="w-full md:w-1/2 lg:w-1/3"
            />
          
           
           
           
           
            {/* <NuUpload
              label="Attachment"
              width="w-full md:w-1/2 lg:w-1/3"
              onChange={handleChangeImg}
            /> */}
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton
                  handleClick={() => {
                    Oncancel(-1);
                  }}
                />
                <SubmitButton />
              </div>
            </div>
          </div>
          </div>
          </form>
          </div>
         </div>
    )
}

export default EditCompanyInfo;