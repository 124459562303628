import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import App from "./App";
import Dashboard from "./Views/Web/Dashboard";
import Auth from "./Views/Main/MainAuth";
import Login from "./Views/Web/Auth/login";
import PageNotFound from "./Components/Utils/PageNotFound";
import PrivateRoute from "./Services/PrivateRouter";
import {
  DashboardRoutes,
  MaintenanceRoutes,
  MarketingRoutes,
  PaymentRoutes,
  ProcurementRoutes,
  ProductRoutes,
  ReportRoutes,
  SalesRoutes,
  SettingsRoutes,
  ShippingRoutes,
  StoreRoutes,
  TaskRoutes,
} from "./Routes";
import MainChat from "./Views/Web/chat/chatMain";
import ChatProfile from "./Views/Web/chat/chatProfile";
import ChatIndex from "./Views/Web/chat";
import ChangePassword from "./Views/Web/Auth/login/changePassword";
import AnotherLogin from "./Views/Web/Auth/login/AnotherIndex";
import DashboardLayout from "./Layouts/MainPage/Dashboard";
import UserProfile from "./Views/Web/Profile/userProfile";
import EditUserprofile from "./Views/Web/Profile/EditUserprofile";
import MainProfile from "./Views/Web/Profile/MainProfile";
import ChangeUserPassword from "./Views/Web/Profile/ChangeUserPassword";
import NotificationProfile from "./Views/Web/Profile/NotificationProfile";
// import ChatIndexScreen from "./Views/Web/chat/ChatIndexScreen";
// import ChatProfileScreen from "./Views/Web/chat/ChatProfileScreen";

function Mainroutes() {
  const location = useLocation();
  const navigate = useNavigate()

  useEffect(() => {
    if (
      location.pathname === "/" ||
      location.pathname === "" ||
      location.pathname === "/main"
    ) {
      window.location.replace("/dashboard");
    }
    if (location.pathname === "/auth") {
      window.location.replace("/auth/login");
    }
    if(location.pathname === "/chat"){
      navigate("/chat/view")
    }
  }, [location]);



  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <App />
          </PrivateRoute>
        }
      >
        {DashboardRoutes()}
        {ProductRoutes()}
        {ShippingRoutes()}
        {SalesRoutes()}
        {ProcurementRoutes()}
        {StoreRoutes()}
        {MarketingRoutes()}
        {PaymentRoutes()}
        {MaintenanceRoutes()}
        {TaskRoutes()}
        {ReportRoutes()}
        <Route path="chat" element={<MainChat />}>
          <Route path="view" element={<ChatIndex />} >
           <Route path="chatProfile/:id" element={<ChatProfile />} />
          </Route>
        </Route>
        {/* <Route path="chat-screen" element={<MainChat/>} >
          <Route path="" element={<ChatIndexScreen/>} >
             <Route path="chat-profile/:chatProfileId" element={<ChatProfileScreen/>}/>
          </Route>
        </Route> */}
         <Route path="/profile" element={<MainProfile  />}>
          <Route index element={<UserProfile />} />
          <Route path="editProfile/:profileid" element={<EditUserprofile />} />
          <Route path="PasswordProfile/:Passwordid" element={<ChangeUserPassword />} />
          <Route path="notificationProfile/:userId" element={<NotificationProfile />} />
        </Route>
      </Route>
      <Route path="/auth" element={<Auth />}>
        <Route path="login" element={<AnotherLogin />} />
        <Route path="forget-password" element={<ChangePassword />} />
      </Route>
      {SettingsRoutes()}
      <Route path="*" element={<PageNotFound />} />
      <Route path="page-not-found" element={<PageNotFound backbutton={false} />} />
    </Routes>
  );
}

export default Mainroutes;
