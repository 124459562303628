import React, { useState } from 'react';
// import { Document, Page, pdfjs } from 'react-pdf';

// Specify worker source
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PDFViewer = ({ pdfFile }) => {


  return (
    <div className=' w-full h-full flex items-end'>
    {pdfFile ? (
      <iframe
        src={pdfFile} // PDF URL or Blob URL
        style={{ width: '100%', height: '96%' }}
        frameBorder="0"
      />
    ) : (
      <p>No PDF file specified.</p>
    )}
  </div>
  );
};

export default PDFViewer;
