import React, { useState } from 'react'
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { Link ,useLocation} from 'react-router-dom';
import { useFormik } from 'formik';
import useEditFormData from '../../../../Components/Hooks/useEditFormData';
import * as yup from 'yup';
import { NuDataTime, NuInput, NuTextArea,NuDate } from "../../../../Components/Utils/Input";
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import FormikSelectTwo from '../../../../Components/Utils/Select';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import DropDownAddMetaData from '../../../../Components/Utils/selectAddMetaData';

function EditVehicle() {
  const {value:VehicleName} = useFetchMataData(`/dropdown/dropdown-details/`,'VehicleName')
  const {value:VehicleType} = useFetchMataData(`/dropdown/dropdown-details/`,'VehicleType')
  const {value:VehicleModel} = useFetchMataData(`/dropdown/dropdown-details/`,'VehicleModel')
  const {value:VehicleBrand} = useFetchMataData(`/dropdown/dropdown-details/`,'VehicleBrand')
  const {value:VehicleYearOfManufacture} = useFetchMataData(`/dropdown/dropdown-details/`,'VehicleYearOfManufacture')
  const {value:VehicleCondition} = useFetchMataData(`/dropdown/dropdown-details/`,'VehicleCondition')
  const {value:VehicleStatus,getData:refVehicleStatus} = useFetchMataData(`/dropdown/dropdown-details/`,'VehicleStatus')
  const {value:FuelType} = useFetchMataData(`/dropdown/dropdown-details/`,'Vehicle_FuelType')

    const [image, setImage] = useState({ preview: "", raw: "" });
    const location = useLocation();
    const data = location.state.data;
    const from = location.state.from;
    console.log(location.state,'state');
    // const [division, setDivision] = useState([]);
    const { Oncancel, editFormData,contextHolder,isLoading } = useEditFormData();
    const { data:users} = UseDropDown("/user/drop-down");
    // const [loading, setLoading] = useState(false);

    const [createNew, setCreateNew] = useState({
      companyId: 1,
      vehicleType: data?.vehicleType,
      name: data?.name,
      brand: data?.brand,
      model: data?.model,
      fuelType: data?.fuelType,
      purchasedOn: data?.purchasedOn,
      condition: data?.condition,
      plateNo: data?.plateNo,
      chasisNo:data?.chasisNo,
      assignedTo:data?.assignedTo,
      comments:data?.comments,
      status:data?.status,
      yearOfMan:data?.yearOfMan,
      insDate:data?.moreDetails?.insDate,
      insExpDate:data?.moreDetails?.insExpDate,
      insCompany:data?.moreDetails?.insCompany,
      fahasDate:data?.moreDetails?.fahasDate,
      fahasExpDate:data?.moreDetails?.fahasExpDate,
      istimaraExpDate:data?.moreDetails?.istimaraExpDate,

    })
    

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: createNew,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
            // email: yup.string().required('Email is required!'),
        }),
        onSubmit: async (value, { resetForm }) => {
          // setLoading(true); 

            editFormData(`/vehicles/edit-vehicle/${data.vehicleId}`, value, resetForm, from);
            // setLoading(false)
        }
    })
  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between mb-4">
   
    <Breadcrumb
          items={[
            {
              name: "Vehicle",
              link: "/maintenance/vehicle"

            },
            {
              name: "Edit Vehicle"
            }
          ]}
        />
    <BackButton/>
    </div> 
    <div className="w-full h-[calc(100vh-140px)] overflow-y-auto">
      <form onSubmit={formik.handleSubmit}>
      <div className="w-full mx-auto bg-white rounded-md pb-4">
          <div className=" w-full h-full flex justify-start content-start flex-wrap   mb-4">
          
                   <div className="w-full">
  <p className='pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4'>
  Vehicle Details :
  </p>
</div>
          <FormikSelectTwo
              label="Vehicle Type"
              placeholder="Choose"
              formik={formik}
              Options={VehicleType}
              name="vehicleType"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <FormikSelectTwo
              label="Name"
              placeholder="Choose"
              formik={formik}
              Options={VehicleName}
              name="name"
              width="w-full md:w-1/2 lg:w-1/3"
            />
          
             <FormikSelectTwo
              label="Brand"
              placeholder="Choose"
              formik={formik}
              Options={VehicleBrand}
              name="brand"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <FormikSelectTwo
              label="Model"
              placeholder="Choose"
              formik={formik}
              Options={VehicleModel}
              name="model"
              width="w-full md:w-1/2 lg:w-1/3"
            />
          
             <FormikSelectTwo
              label="Year Of Manufacturing"
              placeholder="Choose"
              formik={formik}
              Options={VehicleYearOfManufacture}
              name="yearOfMan"
              width="w-full md:w-1/2 lg:w-1/3"
            />
           
           <FormikSelectTwo
              label="Fuel Type"
              placeholder="Fuel Type"
              formik={formik}
              Options={FuelType}
              name="fuelType"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDate
              label="Purchased On"
              placeholder="Purchased On"
              formik={formik}
              name="purchasedOn"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <FormikSelectTwo
              label="Condition"
              placeholder="Choose"
              formik={formik}
              Options={VehicleCondition}
              name="condition"
              width="w-full md:w-1/2 lg:w-1/3"
            />
           
            <NuInput
              type="text"
              label="Plate No"
              placeholder="Plate No"
              formik={formik}
              name="plateNo"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="text"
              label="Chasis No"
              placeholder="Chasis No"
              formik={formik}
              name="chasisNo"
              width="w-full md:w-1/2 lg:w-1/3"
            />

            <FormikSelectTwo
              type="text"
              label="Assigned To"
              placeholder="Assigned To"
              Options={users}
              formik={formik}
              name="assignedTo"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <DropDownAddMetaData
                label="Status"
                placeholder="Choose"
                Options={VehicleStatus}
                formik={formik}
                name="status"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refVehicleStatus}
                displayProperty={"Status"}
                propertyName={"VehicleStatus"}
              />
            {/* <FormikSelectTwo
              label="status"
              placeholder="status"
              formik={formik}
              Options={VehicleStatus}
              name="status"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
          </div>
           </div>
           <div className="w-full mx-auto  bg-white rounded-md  pb-4 mt-4 ">
          <div className=" w-full h-full flex justify-start content-start flex-wrap   mb-4">
       
          <div className="w-full">
  <p className='pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4'>
  Insurance Details
  </p></div>
      <NuDate
              label="Insurance Date"
              placeholder="Insurance Date"
              formik={formik}
              name="insDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <NuDate
              label="Insurance Expiry Date"
              placeholder="insurance Expiry Date"
              formik={formik}
              name="insExpDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <NuInput
              type="text"
              label="Insurance Company"
              placeholder="Insurance Company"
              formik={formik}
              name="insCompany"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             </div>
           </div>
           <div className="w-full mx-auto pb-4 bg-white rounded-md  mt-4 ">
          <div className=" w-full h-full flex justify-start content-start flex-wrap   mb-4">
       
          <div className="w-full">
  <p className='pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4'>
  Fahas Details
  </p></div>
             <NuDate
              label="Fahas Date"
              placeholder="Fahas Date"
              formik={formik}
              name="fahasDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <NuDate
              label="Fahas Expiry Date"
              placeholder="Fahas Expiry Date"
              formik={formik}
              name="fahasExpDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <NuDate
              label="Istimara Expiry Date"
              placeholder="Istimara Expiry Date"
              formik={formik}
              name="istimaraExpDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuTextArea
              label="Comments"
              placeholder="comments"
              formik={formik}
              name="comments"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={()=>{Oncancel(-1)}} />
                <SubmitButton isLoading={isLoading}/>
              </div>
            </div>
          </div>
        </div>
      </form>
      </div>
 </div>
  )
}

export default EditVehicle