import { useFormik } from "formik";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import * as yup from "yup";
import useAddFormData from "../../../../Components/Hooks/useAddFormData";
import { useEffect, useState } from "react";
import Input, { NuInput, NuSelect, NuTextArea, NuUpload } from "../../../../Components/Utils/Input";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import axiosInstance from "../../../../Services/axiosCommon";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import UseDropDown from "../../../../Components/Hooks/useDropDown";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import DropDownAddMetaData from "../../../../Components/Utils/selectAddMetaData";
import UseDropDownNew from "../../../../Components/Hooks/useDDNew";

const CreateRequest = () => {
  const {value:RequestFor ,getData:refRequestFor} = useFetchMataData(`/dropdown/dropdown-details/`,'RequestFor')
  const {value:PurposeOfRequest ,getData:refPurposeOfRequest} = useFetchMataData(`/dropdown/dropdown-details/`,'PurposeOfRequest')
  // const { value:Unit, getData:refUnit } = useFetchMataData(`/dropdown/dropdown-details/`,'Unit_item')
  // const {value:RequestStatus} = useFetchMataData(`/dropdown/dropdown-details/`,'RequestStatus')
  // const [loading, setLoading] = useState(false);

  const { Oncancel, addFormData, isLoading } = useAddFormData();
  const [createNew, setCreateNew] = useState({
    requestFor: "",
    purpose: "",
    // isReturnable: "",
    isReturnable: false,
    item: "",
    comments: "",
    quantity: "",
    unit: "",
    issuedBy:"",
   
    
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      item: yup.string().required('Product is required!'),
      quantity: yup.string().required('Quantity is required!'),

    }),
    onSubmit: async (value, { resetForm }) => {
    //  setLoading(true)
      addFormData("/requests/new-request", value, resetForm);
      // setLoading(false)
    },
  });

  const { data: user } = UseDropDown('/user/drop-down');
  const { data: items , setData} = UseDropDownNew('/items/drop-down');

  useEffect(() => {
    if (formik.values.item) {
      const selectedProduct = items.find((i) => i.value === formik.values.item);
      if (selectedProduct) {
        formik.setFieldValue('unit', selectedProduct.unit);
      }
    }
  }, [formik.values.item, items]);
  
  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
      <Breadcrumb
         items={
          [
            { name: "Store",
            link: "/store/requests" },
          {
            name: "Request",
            link: "/store/requests"
          },
          {
            name: "Create Request"
          }]
         }
        />
        <BackButton />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden mt-4">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
                    <FormikSelectTwo
                      label="Product"
                      placeholder="Choose"
                      Options={items}
                      formik={formik}
                      name="item"
                      width="w-full md:w-1/2 lg:w-1/3"
                      isRequired={true}
                    />
                     {/* <DropDownAddMetaData
                label="Unit"
                placeholder="Choose"
                Options={Unit}
                formik={formik}
                name="unit"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refUnit}
                displayProperty={"Unit"}
                propertyName={"Unit_item"}
              /> */}
                    <NuInput
                      type="number"
                      label="Quantity"
                      placeholder="Quantity"
                      formik={formik}
                      name="quantity"
                      isRequired={true}
                      width="w-full md:w-1/2 lg:w-1/3"
                    />
          <DropDownAddMetaData
                label="Request For"
                placeholder="Choose"
                Options={RequestFor}
                formik={formik}
                name="requestFor"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refRequestFor}
                displayProperty={"Request For"}
                propertyName={"RequestFor"}
              /> 
          
             <DropDownAddMetaData
                label="Purpose Of Request"
                placeholder="Choose"
                Options={PurposeOfRequest}
                formik={formik}
                name="purpose"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refPurposeOfRequest}
                displayProperty={"Purpose Of Request"}
                propertyName={"PurposeOfRequest"}
              />
                 <FormikSelectTwo
                      label="Is Returnable"
                      placeholder="Choose"
                      Options={[
                        { value: true , label: "Yes"},
                        { value: false , label: "No"},
                      ]}
                      formik={formik}
                      name="isReturnable"
                      width="w-full md:w-1/2 lg:w-1/3"
                    />
                   <FormikSelectTwo
                      label="Issued By"
                      placeholder="Choose"
                      Options={user}
                      formik={formik}
                      name="issueedBy"
                      width="w-full md:w-1/2 lg:w-1/3"
                    />                   
                    <NuTextArea
                      label="Comments"
                      placeholder="comments"
                      formik={formik}
                      name="comments"
                      width="w-full md:w-1/2 lg:w-1/3"
                    />
             
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={()=>{Oncancel(-1)}} />
                <SubmitButton isLoading={isLoading}/>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateRequest;
