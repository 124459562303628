import { useFormik } from "formik";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import * as yup from "yup";
import useAddFormData from "../../../../Components/Hooks/useAddFormData";
import { useEffect, useState } from "react";
import Input, {
  NuDate,
  NuInput,
  NuSelect,
  NuTextArea,
  NuUpload,
} from "../../../../Components/Utils/Input";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import axiosInstance from "../../../../Services/axiosCommon";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import { useParams } from "react-router-dom";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import DropDownAddMetaData from "../../../../Components/Utils/selectAddMetaData";

const AddPayableAmount = () =>{
  const {value:PaymentMethod ,getData:refPaymentMethod} = useFetchMataData(`/dropdown/dropdown-details/`,'PaymentMethod_Payable')

  const currentDate = new Date().toISOString().split('T')[0];
  const { amountPayableId } = useParams();
  console.log("ddd",amountPayableId);
  const { Oncancel, addFormData, isLoading } = useAddFormData();
  const [createNew, setCreateNew] = useState({
    paidAmount: "",
    paymentMethod: "Online",
    paidOn: currentDate || "",
    comments: "",
  });
  // const [loading, setLoading] = useState(false);


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      // setLoading(true)
      addFormData(`/payable/pay-amount/${amountPayableId}`, value, resetForm);
      // setLoading(true)
    },
  });

    return(
        <div className="w-full h-full px-4 py-2 bg-[#F1F2FB]">
      <div className="flex justify-between">
      <Breadcrumb
         items={
          [
            { name: "Payment",
            link: "/payments/payables" },  
          {
            name: "Payable",
            link: "/payments/payables"
          },
          {
            name: "View Payables",
            // link: `/payments/payables/edit/${amountPayableId}`
          },
          {
            name: "Add Payable Amount"
          },
        ]
         }
        />
        <BackButton />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-150px)] bg-white rounded-lg overflow-hidden mt-4">
          <div className=" w-full h-full flex py-2 justify-start content-start flex-wrap  overflow-y-auto">
            <NuInput
              type="number"
              label="Paid Amount"
              placeholder="Paid Amount"
              formik={formik}
              name="paidAmount"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <DropDownAddMetaData
              label="Payment Method"
              placeholder="Choose"
              Options={PaymentMethod}
              formik={formik}
              name="paymentMethod"
              width="w-full md:w-1/2 lg:w-1/3"
              getFunc={refPaymentMethod}
              displayProperty={"Payment Method"}
              propertyName={"PaymentMethod_Payable"}
            />
             {/* <FormikSelectTwo
              label="Payment Method"
              placeholder="Choose"
              Options={PaymentMethod}
              // defValue={"online"}
              formik={formik}
              name="paymentMethod"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            <NuDate
              label="Paid On"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="paidOn"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuTextArea
              label="Comments"
              placeholder="comments"
              formik={formik}
              name="comments"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <div className="w-full my-4 mt-8 ">
              <div className="flex justify-end gap-4">
               <CancelButton
                  handleClick={() => {
                    Oncancel(-1);
                  }}
                />
                <SubmitButton isLoading={isLoading}/>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    )
}

export default AddPayableAmount;