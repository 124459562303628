import React, { useEffect, useState } from 'react';
import { FaCheckCircle, FaClipboardCheck, FaEye, FaHourglassHalf } from 'react-icons/fa';
import axiosInstance from '../../../../Services/axiosCommon';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import { NoData } from './PrFromCustomer';

// Define colors for the statuses
const COLORS = ["#FFBB28", "#6A5ACD", "#8884d8", "#048178"]; // Colors for Pending, Reviewed, Verified, Approved

function PurchaseOrderToSupplier() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  async function getData() {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/dashboard/PO-pending`);
      setData(response?.data || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  // Prepare chart data
  const chartData = [
    { name: 'Pending', value: data?.pending || 0 },
    { name: 'Reviewed', value: data?.reviewed || 0 },
    { name: 'Verified', value: data?.verified || 0 },
    { name: 'Approved', value: data?.approved || 0 },
  ];

  const total = (data?.total || 0).toLocaleString();

  return (
    <div className="flex-1 flex-wrap p-2  rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)]  overflow-y-scroll my-1">
      <div className="flex items-center justify-between mb-1">
        <p className="text-[#4F4768] text-xs 2xl:text-sm font-semibold">Purchase Order</p>
        {/* <p className="text-[#333333] text-sm float-end font-semibold">{total}</p> */}
      </div>

      {/* Donut chart */}
      <div className="relative">
      {
          total > 0 ? (
            <ResponsiveContainer width="100%" height={90}>
            <PieChart>
              <Pie
                data={chartData}
                cx="50%"
                cy="50%" // Center the pie chart
                innerRadius={27}
                outerRadius={32}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
              >
                {chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip contentStyle={{ fontSize: '12px', padding: '1px' }} />
            </PieChart>
          </ResponsiveContainer>
          )
          :
          (
            <NoData/>
          )
        }

        
        <div className="absolute inset-0 flex items-center justify-center text-[10px] font-semibold text-[#333333]">
          {total||0}
        </div>
       
      </div>

      <div className="flex gap-4 mt-2 justify-between">
        <div className="flex items-center text-[11px]">
          {/* <FaHourglassHalf className="text-yellow-500  mr-1 text-[8px]" />
          <span>Pending: {(data?.pending || 0).toLocaleString()}</span> */}
                    <p className="text-yellow-500">Pending<span className="text-[#4F4768]"> : {(data?.pending || 0).toLocaleString()}</span></p>

        </div>

        <div className="flex items-center text-[11px]">
          {/* <FaEye className="text-indigo-500  mr-1 text-[9px]" />
          <span>Reviewed: {(data?.reviewed || 0).toLocaleString()}</span> */}
                    <p className="text-indigo-500">Reviewed<span className="text-[#4F4768]"> : {(data?.reviewed || 0).toLocaleString()}</span></p>

        </div>
      </div>
      
      <div className="flex gap-4  mt-2 justify-between">
        <div className="flex items-center text-[11px]">
          {/* <FaClipboardCheck className="text-purple-500 mr-1 text-[8px]" />
          <span>Verified: {(data?.verified || 0).toLocaleString()}</span> */}
                    <p className="text-purple-500">Verified<span className="text-[#4F4768]"> : {(data?.verified || 0).toLocaleString()}</span></p>

        </div>

        <div className="flex items-center text-[11px]">
          {/* <FaCheckCircle className="text-green-500 mr-1 text-[8px]" />
          <span>Approved: {(data?.approved || 0).toLocaleString()}</span> */}
                    <p className="text-green-500">Approved<span className="text-[#4F4768]"> : {(data?.approved || 0).toLocaleString()}</span></p>

        </div>
      </div>
    </div>
  );
}

export default PurchaseOrderToSupplier;
