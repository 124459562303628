import React, { useState } from "react";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";
import useEditFormData from "../../../../Components/Hooks/useEditFormData";
import * as yup from "yup";
import {
  NuInput,
  NuMobileNumber,
  NuTextArea,
  NuUpload,
  SupplierNuInput,
} from "../../../../Components/Utils/Input";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import useFetchMetaStar from "../../../../Components/Hooks/useFetchMetaStar";
import { CountryMIN } from "../../../../assets/CountryMini";
import UseFetchCurrency from "../../../../Components/Hooks/useFetchCurrencyData";
import DropDownAddMetaData from "../../../../Components/Utils/selectAddMetaData";

function EditSuppliers() {
  const { value: desigination } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "desigination_Supplier"
  );
  const { value: vendorRating } = useFetchMetaStar(
    `/dropdown/dropdown-details/`,
    "vendorRating_supplier"
  );
  const { value: vendorstatus, getData: refStatus } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "status_supplier"
  );
  const { value: vendorType } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "SupplierType"
  );
  const { value: supplierCat } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "SupplierCategory"
  );
  const { value: currency } = UseFetchCurrency("/currency/drop-down");
  const [loading, setLoading] = useState(false);
  const { value: DeliveryTerms, getData: refDeliveryTerms } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "DeliveryTerms_Supplierqoutes"
  );
  const { value: PaymentTerms, getData: refPaymentTerms } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "PaymentTerms_Supplierqoutes"
  );
  const [image, setImage] = useState({ preview: "", raw: "" });
  const location = useLocation();
  const data = location.state.data;
  const from = location.state.from;
  console.log(location.state, "state");
  const initialProducts = data?.products
    ? data.products.split(", ").map((p) => p.trim())
    : [];
  const [productList, setProductList] = useState(initialProducts);
  const productsString = productList.join(", ");
  const initialServices = data?.services
    ? data.services.split(", ").map((p) => p.trim())
    : [];
  const [servicesList, setServicesList] = useState(initialServices);
  const servicesString = servicesList.join(", ");
  const initialEmails = data?.emails
    ? data.emails.split(", ").map((p) => p.trim())
    : [];
  const [emailsList, setEmailsList] = useState(initialEmails);
  const emailsString = emailsList.join(", ");
  const { Oncancel, editFormData, contextHolder, isLoading } =
    useEditFormData();
  const [createNew, setCreateNew] = useState({
    contactPerson: data?.contactPerson,
    companyName: data?.companyName,
    email: data?.email,
    phone: data?.phone,
    countryCode: data?.phoneCC,
    website: data?.website,
    designation: data?.designation,
    buildingNo: data?.buildingNo,
    contactPersonEmail: data?.contactPersonEmail,
    contactPersonMobile: data?.cPMobile,
    taxId: data?.taxId,
    performanceRating: data?.performanceRating,
    comments: data?.comments,
    streetName: data?.streetName,
    country: data?.country,
    city: data?.city,
    state: data?.state,
    district: data?.district,
    postalCode: data?.postalCode,
    // cpDesigation:data?.cpDesigation,
    cpCountryCode: data?.cPMobileCC,
    vendorStatus: data?.vendorStatus,
    products: "",
    services: "",
    address1: data.address1,
    address2: data.address2,
    category: data.category,
    vendorType: data.vendorType,
    currencyId: data.currencyId,
    emails: "",
    paymentTerms: data.paymentTerms,
    deliverTerms: data.deliverTerms,
  });
  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      phone: yup
        .string()
        .matches(/^\d+$/, "Phone number must contain only digits")
        .min(9, "Phone number must be at least 9 digits")
        .max(10, "Phone number must be at most 10 digits")
        .required("Phone number is required"),
      contactPersonMobile: yup
        .string()
        .matches(/^\d+$/, "Phone number must contain only digits")
        .min(9, "Phone number must be at least 9 digits")
        .max(10, "Phone number must be at most 10 digits")
        .required("Contact Phone number is required"),
      email: yup.string().required("Email is required!"),
      companyName: yup.string().required("Company Name is required!"),
      website: yup.string().required("Website is required!"),
      contactPerson: yup.string().required("Contact Person is required!"),
    }),
    onSubmit: async (value, { resetForm }) => {
      // setLoading(true);
      let formData = new FormData();
      const valueWithProducts = {
        ...value,
        products: productsString,
        services: servicesString,
        emails: emailsString,
      };
      formData.append("vendorDetails", JSON.stringify(valueWithProducts));
      formData.append("vendorImage", image.raw);

      editFormData(
        `/vendors/edit-vendor/${data.vendorId}`,
        formData,
        resetForm,
        from
      );
      // setLoading(false);
    },
  });
  const handleAddProducts = () => {
    const newProducts = formik.values.products
      .split(",")
      .map((p) => p.trim())
      .filter(Boolean);
    setProductList((prevList) => {
      const allProducts = [...prevList, ...newProducts];
      return Array.from(new Set(allProducts)); // Ensure unique products
    });
    formik.setFieldValue("products", "");
  };

  const handleDeleteProduct = (index) => {
    setProductList((prevList) => prevList.filter((_, i) => i !== index));
  };

  const handleAddServices = () => {
    const newServices = formik.values.services
      .split(",")
      .map((p) => p.trim())
      .filter(Boolean);
    setServicesList((prevList) => {
      const allServices = [...prevList, ...newServices];
      return Array.from(new Set(allServices)); // Ensure unique products
    });
    formik.setFieldValue("services", "");
  };

  const handleDeleteServices = (index) => {
    setServicesList((prevList) => prevList.filter((_, i) => i !== index));
  };

  const handleAddEmails = () => {
    const newEmails = formik.values.emails
      .split(",")
      .map((p) => p.trim())
      .filter(Boolean);
    setEmailsList((prevList) => {
      const allEmails = [...prevList, ...newEmails];
      return Array.from(new Set(allEmails)); // Ensure unique products
    });
    formik.setFieldValue("emails", "");
  };

  const handleDeleteEmails = (index) => {
    setEmailsList((prevList) => prevList.filter((_, i) => i !== index));
  };

  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between mb-4">
        <Breadcrumb
          items={[
            {
              name: "Suppliers",
              link: "/procurement/suppliers",
            },
            {
              name: "Edit Suppliers",
            },
          ]}
        />
        <BackButton />
      </div>
      <div className="w-full h-[calc(100vh-140px)] overflow-y-auto">
        <form onSubmit={formik.handleSubmit}>
          <div className="w-full mx-auto pb-4 bg-white rounded-md  ">
            <div className=" w-full h-full flex justify-start content-start flex-wrap   mb-4">
              <div className="w-full">
                <p className="py-2 px-4 text-xs 2xl:text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                  Supplier Details
                </p>
              </div>
              <NuInput
                type="text"
                label="Company Name"
                placeholder="company Name"
                formik={formik}
                name="companyName"
                isRequired={true}
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <FormikSelectTwo
                type="text"
                label="Category"
                placeholder="Choose"
                Options={supplierCat}
                formik={formik}
                name="category"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <FormikSelectTwo
                type="text"
                label="Type"
                placeholder="Choose"
                Options={vendorType}
                formik={formik}
                name="vendorType"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuMobileNumber
                nunCode="countryCode"
                label="Phone"
                placeholder="Phone"
                formik={formik}
                name="phone"
                isRequired={true}
                width="w-full md:w-1/2 lg:w-1/3"
              />
              <NuInput
                type="email"
                label="Email"
                placeholder="Email"
                formik={formik}
                name="email"
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
              />

              <NuInput
                type="text"
                label="website"
                placeholder="website"
                formik={formik}
                name="website"
                isRequired={true}
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="number"
                label="Tax Id"
                placeholder="Tax Id"
                formik={formik}
                name="taxId"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <FormikSelectTwo
                Options={currency}
                label="Currency"
                placeholder="Currency"
                formik={formik}
                name="currencyId"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <FormikSelectTwo
                type="text"
                label="Payment Terms"
                placeholder="Payment Terms"
                Options={PaymentTerms}
                formik={formik}
                name="paymentTerms"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <FormikSelectTwo
                type="text"
                label="Delivery Terms"
                placeholder="Delivery Terms"
                Options={DeliveryTerms}
                formik={formik}
                name="deliverTerms"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <FormikSelectTwo
                Options={vendorRating}
                label="Performance Ratings"
                placeholder="Performance Ratings"
                formik={formik}
                name="performanceRating"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuUpload
                label="Vendor Image"
                width="w-full md:w-1/2 lg:w-1/3"
                onChange={handleChangeImg}
              />

              <DropDownAddMetaData
                label="Status"
                placeholder="Choose"
                Options={vendorstatus}
                formik={formik}
                name="status"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refStatus}
                displayProperty={"Status"}
                propertyName={"status_supplier"}
              />

              <div className="w-full">
                <p className="py-2 px-4 text-xs 2xl:text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                  Contact Person Details :
                </p>
              </div>

              <NuInput
                type="text"
                label="Name"
                placeholder="Name"
                formik={formik}
                name="contactPerson"
                isRequired={true}
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <FormikSelectTwo
                type="text"
                label="Designation"
                placeholder="Choose"
                Options={desigination}
                formik={formik}
                name="designation"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuMobileNumber
                nunCode="cpCountryCode"
                label="Contact Person Mobile"
                placeholder="Contact Person Mobile"
                formik={formik}
                isRequired={true}
                name="contactPersonMobile"
                width="w-full md:w-1/2 lg:w-1/3"
              />
              <NuInput
                type="email"
                label="Contact Person Email"
                placeholder="Contact Person Email"
                formik={formik}
                name="contactPersonEmail"
                width="w-full md:w-1/2 lg:w-1/3"
              />
            </div>
          </div>
          <div className="w-full mx-auto pb-4 bg-white rounded-md  mt-4">
            <div className=" w-full h-full flex justify-start content-start flex-wrap   mb-4">
              <div className="w-full">
                <p className="py-2 px-4 text-xs 2xl:text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                  Address :
                </p>
              </div>

              <NuInput
                type="text"
                label="Street Address Line 1"
                placeholder="Street Address Line 1"
                formik={formik}
                name="address1"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="text"
                label="Street Address Line 2"
                placeholder="Street Address Line 2"
                formik={formik}
                name="address2"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="text"
                label="City"
                placeholder="City"
                formik={formik}
                name="city"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="text"
                label="State / Province"
                placeholder="State / Province"
                formik={formik}
                name="state"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="number"
                label="Postal / Zip Code"
                placeholder="Postal / Zip Code"
                formik={formik}
                name="postalCode"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <FormikSelectTwo
                type="text"
                label="Country"
                placeholder="Country"
                Options={CountryMIN}
                formik={formik}
                name="country"
                width="w-full md:w-1/2 lg:w-1/3"
                FixPosition={false}
                PortalTarget={false}
              />

              <NuTextArea
                label="Comments"
                placeholder="comments"
                formik={formik}
                name="comments"
                width="w-full md:w-1/2 "
                new_width="lg:w-2/3"
              />
            </div>
          </div>
          <div className="w-full mx-auto h-auto bg-white rounded-md overflow-hidden mt-4">
            <div className=" w-full h-full flex justify-start content-start flex-wrap  overflow-y-auto mb-4">
              <div className="w-full">
                <p className="py-2 px-4 text-xs 2xl:text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                  Products & Services :
                </p>
              </div>

              <div className="flex justify-start gap-2">
                <div className="w-full flex flex-col">
                  <div className="flex justify-start">
                    <SupplierNuInput
                      type="text"
                      label="Products"
                      placeholder="seprate products with ','"
                      formik={formik}
                      name="products"
                      width="11/12"
                    />
                    <button
                      onClick={handleAddProducts}
                      type="button"
                      className="mt-10 w-10 h-10 text-center cursor-pointer bg-[#048178] border border-solid border-white  text-white font-semibold rounded-lg "
                    >
                      +
                    </button>
                  </div>
                  <div className="w-11/12 h-28 ml-[8%] bg-[#f5f3ff]  border-gray-300 rounded-md p-1 flex flex-col border border-solid overflow-y-scroll">
                    {productList.map((word, index) => (
                      <span
                        key={index}
                        className="m-1  flex justify-between items-center text-xs bg-white border border-gray-300 rounded text-main-title py-2 px-2 hover:bg-[#B3EBD7] hover:bg-opacity-50"
                        >
                        {word}
                        <span
                          onClick={() => {
                            handleDeleteProduct(index);
                          }}
                          className=" text-blue-900 cursor-pointer"
                        >
                          x
                        </span>
                      </span>
                    ))}
                  </div>
                </div>
                <div className="w-full flex flex-col">
                  <div className="flex justify-start">
                    <SupplierNuInput
                      type="text"
                      label="Services"
                      placeholder="seprate services with ','"
                      formik={formik}
                      name="services"
                      width="w-11/12"
                    />
                    <button
                      onClick={handleAddServices}
                      type="button"
                      className="mt-10 w-10 h-10 text-center cursor-pointer bg-[#048178] border border-solid border-white  text-white font-semibold rounded-lg "
                    >
                      +
                    </button>
                  </div>
                  <div className="w-11/12 h-28 ml-[8%] bg-[#f5f3ff]  border-gray-300 rounded-md p-1 flex flex-col border border-solid overflow-y-scroll">
                  {servicesList.map((word, index) => (
                      <span
                        key={index}
                        className="m-1  flex justify-between items-center text-xs bg-white border border-gray-300 rounded text-main-title py-2 px-2 hover:bg-[#B3EBD7] hover:bg-opacity-50"
                        >
                        {word}
                        <span
                          onClick={() => {
                            handleDeleteServices(index);
                          }}
                          className=" text-blue-900 cursor-pointer"
                        >
                          x
                        </span>
                      </span>
                    ))}
                  </div>
                </div>
              </div>

              <div className="w-full">
                <p className="py-2 px-4 text-xs 2xl:text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                  Alternate Emails :
                </p>
              </div>
              <div className="flex justify-start gap-2">
                <div className="w-full flex flex-col ">
                  <div className="flex justify-start">
                    <SupplierNuInput
                      type="email"
                      label="Emails"
                      placeholder="seprate emails with ','"
                      formik={formik}
                      name="emails"
                      width="w-11/12"
                    />
                    <button
                      onClick={handleAddEmails}
                      type="button"
                      className="mt-10 w-10 h-10 text-center cursor-pointer bg-[#048178] border border-solid border-white  text-white font-semibold rounded-md "
                    >
                      +
                    </button>
                  </div>
                  <div className="w-11/12 h-28 ml-[8%] bg-[#f5f3ff]  border-gray-300 rounded-md p-1 flex flex-col border border-solid overflow-y-scroll">
                    {emailsList.map((word, index) => (
                      <span
                        key={index}
                        className="m-1  flex justify-between items-center text-xs bg-white border border-gray-300 rounded text-main-title py-2 px-2 hover:bg-[#B3EBD7] hover:bg-opacity-50"
                        >
                        {word}
                        <span
                          onClick={() => {
                            handleDeleteEmails(index);
                          }}
                          className=" text-blue-900 cursor-pointer"
                        >
                          x
                        </span>
                      </span>
                    ))}
                  </div>
                </div>
              </div>

              <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
                <div className="md:flex justify-end gap-4">
                  <CancelButton
                    handleClick={() => {
                      Oncancel(-1);
                    }}
                  />
                  <SubmitButton isLoading={isLoading} />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditSuppliers;
