import React from "react";
import UseGetDashBoardData from "../../../../Components/Hooks/useDashboardData";

function SuppliersDashboard() {
  const { data, loading } = UseGetDashBoardData(`/dashboard/supplierStats`);
  const percentage = parseFloat(data?.new_suppliers_count) || 0;
  const isNegative = percentage < 0;

  return (
    <div className="bg-white p-4  rounded-lg drop-shadow-lg max-h-[25vh] w-full">
      <div className="flex">
        <div className="bg-baseColor-teritary rounded-full p-2 2xl:p-3  h-[18%]  mt-4 ml-2">
          <svg
            width="22"
            height="22"
            xmlns="http://www.w3.org/2000/svg"
            fill="#048178"
            viewBox="0 0 640 512"
          >
            <path d="M256 48c0-26.5 21.5-48 48-48H592c26.5 0 48 21.5 48 48V464c0 26.5-21.5 48-48 48H381.3c1.8-5 2.7-10.4 2.7-16V253.3c18.6-6.6 32-24.4 32-45.3V176c0-26.5-21.5-48-48-48H256V48zM571.3 347.3c6.2-6.2 6.2-16.4 0-22.6l-64-64c-6.2-6.2-16.4-6.2-22.6 0l-64 64c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L480 310.6V432c0 8.8 7.2 16 16 16s16-7.2 16-16V310.6l36.7 36.7c6.2 6.2 16.4 6.2 22.6 0zM0 176c0-8.8 7.2-16 16-16H368c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H16c-8.8 0-16-7.2-16-16V176zm352 80V480c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32V256H352zM144 320c-8.8 0-16 7.2-16 16s7.2 16 16 16h96c8.8 0 16-7.2 16-16s-7.2-16-16-16H144z" />
          </svg>
        </div>
        <div className="pl-8 h-[96%] flex flex-col gap-1">
          <p className="text-[#4F4768] text-xs 2xl:text-sm font-semibold ">
            {" "}
            Supplier
          </p>
          <p className="text-[#333333] text-[22px]  font-semibold ">
            {data?.total_suppliers_count || 0}
          </p>
          <div className="flex flex-wrap">
            <p className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="18"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d={`M10 ${isNegative ? "17L10 5" : "5L10 17"}`}
                  stroke={isNegative ? "#FF0000" : "#048178"}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d={`M${
                    isNegative
                      ? "15.8337 10.0001L10.0003 15.8334L4.16699 10.0001"
                      : "4.16699 10.0001L10.0003 4.16675L15.8337 10.0001"
                  }`}
                  stroke={isNegative ? "#FF0000" : "#048178"}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p
                className={`text-${
                  isNegative ? "red-500" : "[#048178]"
                } text-[10px] 2xl:text-xs `}
              >
                {percentage}
                <span className="pl-[2px] text-[10px] text-[#4F4768]">
                  this month
                </span>
              </p>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuppliersDashboard;
