import React from 'react'
import { TextMediumdS, TextMediumSM, TextMediumXS } from '../../../../../Components/Text/MediumText';
import UseGetDashBoardData from '../../../../../Components/Hooks/useDashboardData';

function TopProductsByValueProDash() {
  const { data, loading } = UseGetDashBoardData('/dashboard/top-products-by-sales-value');
  const dataDummy = data?.top_products_by_sales_value?.map(product => ({
    product: product.product_name,
    price: product.price
  })) || [];

  return (
    <div className="col-span-2 bg-white h-[40vh] w-full p-4 rounded-lg drop-shadow-lg mb-4">
     <TextMediumSM content={"Top Products by Value"} color='text-[#4F4768]' />
        {loading ? (
        <div className="flex justify-center items-center h-full">
          <div className="animate-pulse space-y-4 w-full">
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
          </div>
        </div>
      ) : (
        <div className="w-full h-[89%] overflow-y-scroll ">
        <table className="w-full">
          <thead className="sticky top-0 bg-white shadow-sm">
            <tr>
              <th className="text-left px-2 py-1"><TextMediumSM content="Product" /></th>
              <th className="text-left px-2 py-1"><TextMediumSM content="Value" /></th>
            </tr>
          </thead>
          <tbody>
          {dataDummy.map((value, index) => {
  const [currency, numericValue] = (value.price || "N/A").split(" ");
  const formattedValue = numericValue
    ? Number(numericValue).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    : "N/A";

  return (
    <tr className="odd:bg-white even:bg-slate-50" key={index}>
      <td className="px-2 py-2"><TextMediumdS content={value.product || "N/A"} /></td>
      <td className="px-2 py-2">
        <TextMediumdS 
          content={`${currency || ""} ${formattedValue}`} 
        />
      </td>
    </tr>
  );
})}

          </tbody>
        </table>
      </div>
     
      )}
    </div>
  )
}

export default TopProductsByValueProDash;