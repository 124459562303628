import React, { useEffect } from "react";
import { Route, useLocation, useNavigate } from "react-router-dom";
import MainSales from "../Views/Web/Sales/MainSales";
import MainCustomers from "../Views/Web/Sales/Customers/MainCustomers";
import CustomersIndex from "../Views/Web/Sales/Customers";
import CreateCustomers from "../Views/Web/Sales/Customers/CreateCustomers";
import EditCustomers from "../Views/Web/Sales/Customers/EditCustomers";
import ViewCustomers from "../Views/Web/Sales/Customers/ViewCustomers";
import MainInvoicesIncoming from "../Views/Web/Sales/InvoicesIncoming/MainInvoicesIncoming";
import InvoiceIncomingIndex from "../Views/Web/Sales/InvoicesIncoming/Index";
import CreateInvoiceIncoming from "../Views/Web/Sales/InvoicesIncoming/CreateInvoiceIncoming";
import EditInvoiceIncoming from "../Views/Web/Sales/InvoicesIncoming/EditInvoiceIncoming ";
import ViewInvoiceIncoming from "../Views/Web/Sales/InvoicesIncoming/ViewInvoiceIncoming ";
import MainInvoicesOutgoing from "../Views/Web/Sales/InvoicesOutgoing/MainInvoicesOutgoing";
import InvoiceOutgoingIndex from "../Views/Web/Sales/InvoicesOutgoing/Index";
import CreateInvoiceOutgoing from "../Views/Web/Sales/InvoicesOutgoing/CreateInvoiceOutgoing";
import EditInvoiceOutgoing from "../Views/Web/Sales/InvoicesOutgoing/EditInvoiceOutgoing ";
import ViewInvoiceOutgoing from "../Views/Web/Sales/InvoicesOutgoing/ViewInvoiceOutgoing ";
import MainPromotions from "../Views/Web/Sales/Promotions/MainPromotions";
import PromotionIndex from "../Views/Web/Sales/Promotions/Index";
import MainCustomerOrder from "../Views/Web/Procurement/CustomerOrder/MainCustomerOrder";
import CustomerOrderIndex from "../Views/Web/Procurement/CustomerOrder";
import CreateCustomerOrder from "../Views/Web/Procurement/CustomerOrder/CreateCustomerOrder";
import EditCustomerOrder from "../Views/Web/Procurement/CustomerOrder/EditCustomerOrder";
import ViewCustomerOrder from "../Views/Web/Procurement/CustomerOrder/ViewCustomerOrder";
import CreateCustomerQuotes from "../Views/Web/Sales/CustomerQuotation/CreateCustomerQuoatation";
import CustomerQuotationIndex from "../Views/Web/Sales/CustomerQuotation/index";
import CustomerQuotationMain from "../Views/Web/Sales/CustomerQuotation/MainCustomerQuotation";
import ViewCustomerQuotes from "../Views/Web/Sales/CustomerQuotation/ViewCustomerQuotation";
import EditCustomerQuotes from "../Views/Web/Sales/CustomerQuotation/EditCustomerQuotation";
import EditSalesOrders from "../Views/Web/Sales/SalesOrders/EditSalesOrders";
import ViewSalesOrders from "../Views/Web/Sales/SalesOrders/ViewSalesOrders";
import CreateSalesOrders from "../Views/Web/Sales/SalesOrders/CreateSalesOrders";
import SalesOrdersIndex from "../Views/Web/Sales/SalesOrders";
import MainSalesOrders from "../Views/Web/Sales/SalesOrders/MainSalesOrders";
import { Wrapper } from "../Components/Utils/roles";
import ViewPromotion from "../Views/Web/Sales/Promotions/ViewPromotion";
// import ViewCqRevision from "../Views/Web/Sales/CustomerQuotation/ViewCqRevision";
import ViewCqCreate from "../Views/Web/Sales/CustomerQuotation/ViewCqCreate";
import ViewQuote from "../Views/Web/Sales/CustomerQuotation/ViewQuote";
import QuoteCreate from "../Views/Web/Sales/CustomerQuotation/QuotesCreate";
// import ViewCQIdRevision from "../Views/Web/Sales/CustomerQuotation/ViewCQIdRevision";
import QuoteEdit from "../Views/Web/Sales/CustomerQuotation/QuoteEdit";
import CQEdit from "../Views/Web/Sales/CustomerQuotation/CQEdit";
const SalesRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/sales" || location.pathname === "/sales/") {
      navigate("/page-not-found");
    }
  }, [location]);
  return (
    <Route path="sales" element={<MainSales />}>
      <Route path="customers" element={<MainCustomers />}>
        <Route
          index
          element={<Wrapper accessKey={"customers_view"} Component={CustomersIndex} />}
        />
        <Route
          path="create"
          element={<Wrapper accessKey={"customers_create"} Component={CreateCustomers} />}
        />
        <Route
          path="edit/:customersEditId"
          element={<Wrapper accessKey={"customers_edit"} Component={EditCustomers} />}
        />
        <Route
          path="view/:customersViewId"
          element={<Wrapper accessKey={"customers_view"} Component={ViewCustomers} />}
        />
      </Route>
      <Route path="invoice_incoming" element={<MainInvoicesIncoming />}>
        <Route
          index
          element={
            <Wrapper accessKey={"invoice_in_view"} Component={InvoiceIncomingIndex} />
          }
        />
        <Route
          path="create"
          element={
            <Wrapper accessKey={"invoice_in_create"} Component={CreateInvoiceIncoming} />
          }
        />
        <Route
          path="edit/:incomingEditId"
          element={<Wrapper accessKey={"invoice_in_edit"} Component={EditInvoiceIncoming} />}
        />
        <Route
          path="view/:incomingViewId"
          element={<Wrapper accessKey={"invoice_in_view"} Component={ViewInvoiceIncoming} />}
        />
      </Route>
      <Route path="quotes" element={<CustomerQuotationMain />}>
        <Route
          index
          element={
            <Wrapper accessKey={"customer_quote_view"} Component={CustomerQuotationIndex} />
          }
        />
        <Route
          path="create"
          element={
            <Wrapper accessKey={"customer_quote_create"} Component={CreateCustomerQuotes} />
          }
        >
          <Route index element={<Wrapper accessKey={"customer_quote_create"}  Component={QuoteCreate} />} />
          <Route path='viewqoutes' element={<Wrapper accessKey={"customer_quote_create"}  Component={ViewQuote} />} />

</Route>
        <Route
          path="edit/:quotesEditId"
          element={<Wrapper accessKey={"customer_quote_edit"} Component={EditCustomerQuotes} />}
        >
            <Route index element={<Wrapper accessKey={"customer_quote_edit"}  Component={CQEdit} />} />
          <Route path='viewqoute' element={<Wrapper accessKey={"customer_quote_edit"}  Component={ViewQuote} />} />


          </Route>
        <Route
          path="view/:quotesViewId"
          element={<Wrapper accessKey={"customer_quote_view"} Component={ViewCustomerQuotes} />}
        />
         <Route path='viewcqcreate/:viewcreatecqId' element={<Wrapper accessKey={"customer_quote_view"}  Component={ViewCqCreate} />} />
          {/* <Route path='viewcqrevision/:viewrevisioncqId' element={<Wrapper accessKey={"customer_quote_view"}  Component={ViewCqRevision} />} />
          <Route path='viewCQIdRevision/:ViewCQIdRevisionId' element={<Wrapper accessKey={"customer_quote_view"}  Component={ViewCQIdRevision} />} /> */}
          
      </Route>
      <Route path="customer-order" element={<MainCustomerOrder />}>
        <Route
          index
          element={<Wrapper accessKey={"customer_order_view"} Component={CustomerOrderIndex} />}
        />
        <Route
          path="create"
          element={<Wrapper accessKey={"customer_order_create"} Component={CreateCustomerOrder} />}
        />
        <Route
          path="edit/:coEditId"
          element={<Wrapper accessKey={"customer_order_edit"} Component={EditCustomerOrder} />}
        />
        <Route
          path="view/:coViewId"
          element={<Wrapper accessKey={"customer_order_view"} Component={ViewCustomerOrder} />}
        />
      </Route>
      <Route path="invoice_outgoing" element={<MainInvoicesOutgoing />}>
        <Route
          index
          element={
            <Wrapper accessKey={"invoice_out_view"} Component={InvoiceOutgoingIndex} />
          }
        />
        <Route
          path="create"
          element={
            <Wrapper accessKey={"invoice_out_create"} Component={CreateInvoiceOutgoing} />
          }
        />
        <Route
          path="edit/:outgoingEditId"
          element={<Wrapper accessKey={"invoice_out_edit"} Component={EditInvoiceOutgoing} />}
        />
        <Route
          path="view/:outgoingViewId"
          element={<Wrapper accessKey={"invoice_out_view"} Component={ViewInvoiceOutgoing} />}
        />
      </Route>
      <Route path="sales-orders" element={<MainSalesOrders />}>
        <Route
          index
          element={<Wrapper accessKey={"sales_order_view"} Component={SalesOrdersIndex} />}
        />
        <Route
          path="create"
          element={<Wrapper accessKey={"sales_order_create"} Component={CreateSalesOrders} />}
        />
        <Route
          path="edit/:salesOrdersEditId"
          element={<Wrapper accessKey={"sales_order_edit"} Component={EditSalesOrders} />}
        />
        <Route
          path="view/:salesOrdersViewId"
          element={<Wrapper accessKey={"sales_order_view"} Component={ViewSalesOrders} />}
        />
      </Route>
      <Route path="promotions" element={<MainPromotions />}>
        <Route
          index
          element={<Wrapper accessKey={"promotions_view"} Component={PromotionIndex} />}
        />
         <Route
          path="view/:promotionId"
          element={<Wrapper accessKey={"promotions_view"} Component={ViewPromotion} />}
        />
      </Route>
    </Route>
  );
};

export default SalesRoutes;