import { Link } from "react-router-dom";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import { BackButton, CreateButton } from "../../../../Components/Utils/Buttons";
import axiosInstance from "../../../../Services/axiosCommon";
import { useEffect, useState } from "react";
import { TextMediumSM } from "../../../../Components/Text/MediumText";
import EditIcon from "../../../../Components/Icons/Edit";
import DeleteIcon from "../../../../Components/Icons/Delete";
import ModalContainer from "../../../../Components/Others/ModalContainer";
import { useToast } from "../../../../Components/Others/ToastServices";

const MainRolesSettings = () => {
  const [roles, setRoles] = useState([]);
  const toast = useToast();
  const [deleteId,setDeleteId] = useState()
  const [roleName,setRoleName] = useState("")
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  function GetRole() {
    axiosInstance.get(`/role/`).then((res) => {
      console.log(res.data.data);
      let role = res.data.data;
      res.data.data && setRoles(role);
    });
  }

  const deleteQuote = (id) =>{
    setDeleteLoading(true)
       axiosInstance.delete(`/role/${id}`)
                  .then(res => {
                      if (res?.data.status === true) {
                        setDeleteLoading(false)
                          toast.open({
                              type:"success",
                              message:"Success",
                              description: res.data?.msg || "Deleted Successfully."
                            })
                            setIsModalDeleteOpen(false);
                            GetRole();
                      }
                      else if(res.data?.status === false && res.data?.error){
                        setDeleteLoading(false)
                          toast.open({
                            type:"error",
                            message:"Error",
                            description: res.data.error || "Network error."
                          })
                        } 
                      else {
                        setDeleteLoading(false)
                          toast.open({
                              type:"warning",
                              message:"Warning",
                              description: res.data?.msg || "Warning."
                            })
                      }
      
                  }).catch(
                      error => {
                          console.log("error",error);
                        setDeleteLoading(false)
                          toast.open({
                              type:"error",
                              message:"Error",
                              description:  "Network error."
                            })
                      }
                  );
  }

  useEffect(() => {
    GetRole();
  }, []);

  return (
    <div className="w-full h-full px-4 py-2">
      <div className="w-full flex justify-between items-center">
        <Breadcrumb
          items={[
            {
              name: "Settings",
            },
            {
              name: "Roles",
            },
          ]}
        />
        <div className=" flex gap-2 items-center">
          <Link to="/settings/role/create">
            <CreateButton label="Role" />
          </Link>
          <BackButton />
        </div>
      </div>
      <div className="w-full h-[calc(100vh-145px)] bg-white mt-3 mx-auto px-4 py-2 rounded-md overflow-y-scroll">
        <div className=" w-full  grid gap-4 grid-cols-4 2xl:grid-cols-5">
          {roles &&
            roles.map((role, indx) => {
              return (
                <div
                  className=" w-full bg-slate-100 rounded-lg p-3 flex justify-between items-center"
                  key={indx}
                >
                  <TextMediumSM content={role?.roleName || ""} />

                  <div className=" flex gap-2 items-center">
                    <Link to={`/settings/role/edit/${role?.roleId}`}>
                      <EditIcon color="#999999" />
                    </Link>
                    {role?.isDefault === true ?
                    null
                    :
                    <button onClick={()=>{setDeleteId(role?.roleId); setIsModalDeleteOpen(true); setRoleName(role?.roleName)}}>
                      <DeleteIcon />
                    </button>
                    }
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <ModalContainer
                visiable={isModalDeleteOpen}
                title="Warning"
                content={`Are you sure, ${roleName} will removed from Role!`}
                handleClick={() => deleteQuote(deleteId)}
                closeModal={() => setIsModalDeleteOpen(false)}
                isLoading={deleteLoading}
            />
    </div>
  );
};

export default MainRolesSettings;
