import React, { useState } from "react";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";
import useEditFormData from "../../../../Components/Hooks/useEditFormData";
import * as yup from "yup";
import {
  NuInput,
  NuMobileNumber,
  NuTextArea,
  NuUpload,
} from "../../../../Components/Utils/Input";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import useFetchMetaStar from "../../../../Components/Hooks/useFetchMetaStar";
import { CountryMIN } from "../../../../assets/CountryMini";

function EditCustomers() {
  const { value: Industry } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "Industry_Customer"
  );
  const { value: Desigination } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "Desigination_Customer"
  );
  const { value: customerRating } = useFetchMetaStar(
    `/dropdown/dropdown-details/`,
    "customerRating_Customer"
  );
  const { value: customerType } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "customerType_Customer"
  );
  const { value: Category } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "category_Customer"
  );
  const [image, setImage] = useState({ preview: "", raw: "" });
  const location = useLocation();
  const data = location.state.data;
  const from = location.state.from;

  const { Oncancel, editFormData, contextHolder, isLoading } =
    useEditFormData();
  const [createNew, setCreateNew] = useState({
    customerType: data?.customerType,
    companyName: data?.companyName,
    email: data?.email,
    phone: data?.phone,
    countryCode: data?.phoneCC,
    website: data?.website,
    industry: data?.industry,
    billingAddress: data?.billingAddress,
    shippingAddress: data?.shippingAddress,
    crNum: data?.crNum,
    vatNum: data?.vatNum,
    ratings: data?.ratings,
    paymentTerm: data?.paymentTerm,
    creditLimit: data?.creditLimit,
    comments: data?.comments,
    cpName: data?.cpName,
    cpEmail: data?.cpEmail,
    designation: data?.designation,
    cpCountryCode: data?.cpPhoneCC,
    cpPhone: data?.cpPhone,
    billingAddress1: data?.billingAddress1 || "",
    billingAddress2: data?.billingAddress2 || "",
    billing_city: data?.billing_city || "",
    billing_country: data?.billing_country || "",
    billing_postal_code: data?.billing_postal_code || "",
    billing_state: data?.billing_state || "",
    shippingAddress1: data?.shippingAddress1 || "",
    shippingAddress2: data?.shippingAddress2 || "",
    shipping_country: data?.shipping_country || "",
    shipping_city: data?.shipping_city || "",
    shipping_state: data?.shipping_state || "",
    shipping_postal_code: data?.shipping_postal_code || "",
    category: data?.category || "",
  });
  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      phone: yup
        .string()
        .matches(/^\d+$/, "Phone number must contain only digits")
        .min(9, "Phone number must be at least 9 digits")
        .max(10, "Phone number must be at most 10 digits")
        .required("Phone number is required"),
      email: yup.string().required("Email is required!"),
      companyName: yup.string().required("Company Name is required!"),
      cpName: yup.string().required("Contact Person Name is required!"),
      category: yup.string().required("Category is required!"),

      cpPhone: yup
        .string()
        .matches(/^\d+$/, "Phone number must contain only digits")
        .min(9, "Phone number must be at least 9 digits")
        .max(10, "Phone number must be at most 10 digits")
        .required("Phone number is required"),
    }),
    onSubmit: async (value, { resetForm }) => {
      // setLoading(true);
      let formData = new FormData();
      formData.append("json_data", JSON.stringify(value));
      formData.append("customerImage", image.raw);

      editFormData(
        `/customers/edit-customer/${data.customerId}`,
        formData,
        resetForm,
        from
      );
      // setLoading(false)
    },
  });

  const [useAddress, setAddress] = useState(false);
  function handleAddress(e) {
    setAddress(e.target.checked);
    if (e.target.checked) {
      formik.values.shippingAddress1 = formik.values.billingAddress1;
      formik.values.shippingAddress2 = formik.values.billingAddress2;
      formik.values.shipping_country = formik.values.billing_country;
      formik.values.shipping_city = formik.values.billing_city;
      formik.values.shipping_state = formik.values.billing_state;
      formik.values.shipping_postal_code = formik.values.billing_postal_code;
    } else {
      formik.values.shippingAddress1 = "";
      formik.values.shippingAddress2 = "";
      formik.values.shipping_country = "";
      formik.values.shipping_city = "";
      formik.values.shipping_state = "";
      formik.values.shipping_postal_code = "";
    }
  }

  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between mb-4">
        <Breadcrumb
          items={[
            {
              name: "Customers",
              link: "/sales/customers",
            },

            { name: "Edit" },
          ]}
        />
        <BackButton />
      </div>
      <div className="w-full h-[calc(100vh-140px)] overflow-y-auto">
        <form onSubmit={formik.handleSubmit}>
          <div className="w-full mx-auto  bg-white rounded-md pb-4 ">
            <div className=" w-full h-full flex justify-start content-start flex-wrap   mb-4">
              <div className="w-full">
                <p className="pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                  Customer Details
                </p>
              </div>
              <NuInput
                type="text"
                label="Company Name"
                placeholder="Company Name"
                formik={formik}
                isRequired={true}
                name="companyName"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <FormikSelectTwo
                type="text"
                label="Industry"
                placeholder="Choose"
                Options={Industry}
                formik={formik}
                name="industry"
                width="w-full md:w-1/2 lg:w-1/3"
                FixPosition={false}
                PortalTarget={false}
              />

              <FormikSelectTwo
                type="text"
                label="Customer Type"
                placeholder="Customer Type"
                Options={customerType}
                formik={formik}
                name="customerType"
                width="w-full md:w-1/2 lg:w-1/3"
                FixPosition={false}
                PortalTarget={false}
              />

              <NuMobileNumber
                nunCode="countryCode"
                label="Phone"
                placeholder="Phone"
                isRequired={true}
                formik={formik}
                name="phone"
                width="w-full md:w-1/2 lg:w-1/3"
              />
              <NuInput
                type="email"
                label="Email"
                placeholder="Email"
                formik={formik}
                name="email"
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
              />

              <NuInput
                type="text"
                label="website"
                placeholder="website"
                formik={formik}
                name="website"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="number"
                label="CRNum"
                placeholder="CRNum"
                formik={formik}
                name="crNum"
                width="w-full md:w-1/2 lg:w-1/3"
              />
              <NuInput
                type="number"
                label="Vat Num"
                placeholder="Vat Num"
                formik={formik}
                name="vatNum"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="text"
                label="Payment Term"
                placeholder="payment Term"
                formik={formik}
                name="paymentTerm"
                width="w-full md:w-1/2 lg:w-1/3"
              />
              <NuInput
                type="number"
                label="Credit Limit"
                placeholder="Credit Limit"
                formik={formik}
                name="creditLimit"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <FormikSelectTwo
                type="text"
                label="Category"
                placeholder="Choose"
                Options={Category}
                formik={formik}
                name="category"
                isRequired={true}
                width="w-full md:w-1/2 lg:w-1/3"
                FixPosition={false}
                PortalTarget={false}
              />

              <FormikSelectTwo
                label="Ratings"
                placeholder="Ratings"
                Options={customerRating}
                formik={formik}
                name="ratings"
                width="w-full md:w-1/2 lg:w-1/3"
                FixPosition={false}
                PortalTarget={false}
              />

              <NuUpload
                label="Attachment"
                width="w-full md:w-1/2 lg:w-1/3"
                onChange={handleChangeImg}
              />

              <div className="w-full">
                <p className="py-2 px-4 text-xs 2xl:text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                  Contact Person Details :
                </p>
              </div>

              <NuInput
                type="text"
                label="Name"
                placeholder="cpName"
                formik={formik}
                name="cpName"
                isRequired={true}
                width="w-full md:w-1/2 lg:w-1/3"
              />
              <FormikSelectTwo
                type="text"
                label="Desigation"
                placeholder="Choose"
                Options={Desigination}
                formik={formik}
                name="designation"
                width="w-full md:w-1/2 lg:w-1/3"
                FixPosition={false}
                PortalTarget={false}
              />
              <NuMobileNumber
                nunCode="cpCountryCode"
                label="Phone"
                placeholder="Phone"
                formik={formik}
                name="cpPhone"
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
              />
              <NuInput
                type="email"
                label="Contact Person Email"
                placeholder="cpEmail"
                formik={formik}
                name="cpEmail"
                width="w-full md:w-1/2 lg:w-1/3"
              />
            </div>
          </div>

          <div className="w-full mx-auto  bg-white rounded-md  mt-4 ">
            <div className=" w-full h-full flex justify-start content-start flex-wrap   mb-4">
              <div className="w-full">
                <p className="pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                  Billing Address :
                </p>
              </div>

              <NuInput
                type="text"
                label="Street Address Line 1"
                placeholder="Street Address Line 1"
                formik={formik}
                name="billingAddress1"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="text"
                label="Street Address Line 2"
                placeholder="Street Address Line 2"
                formik={formik}
                name="billingAddress2"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="text"
                label="City"
                placeholder="City"
                formik={formik}
                name="billing_city"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="text"
                label="State / Province"
                placeholder="State / Province"
                formik={formik}
                name="billing_state"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="number"
                label="Postal / Zip Code"
                placeholder="Postal / Zip Code"
                formik={formik}
                name="billing_postal_code"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <FormikSelectTwo
                type="text"
                label="Country"
                placeholder="Country"
                Options={CountryMIN}
                formik={formik}
                name="billing_country"
                width="w-full md:w-1/2 lg:w-1/3"
                FixPosition={false}
                PortalTarget={false}
              />
            </div>
            <div className=" px-4 pb-2 flex gap-1">
              <input
                type="checkbox"
                checked={useAddress}
                onChange={handleAddress}
              />
              <label className=" text-xs">
                Use Billing Address as Shipping Address
              </label>
            </div>
          </div>

          <div className="w-full mx-auto  bg-white rounded-md  mt-4 ">
            <div className=" w-full h-full flex justify-start content-start flex-wrap   mb-4">
              <div className="w-full">
                <p className="pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                  Shipping Address :
                </p>
              </div>

              <NuInput
                type="text"
                label="Street Address Line 1"
                placeholder="Street Address Line 1"
                formik={formik}
                name="shippingAddress1"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="text"
                label="Street Address Line 2"
                placeholder="Street Address Line 2"
                formik={formik}
                name="shippingAddress2"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="text"
                label="City"
                placeholder="City"
                formik={formik}
                name="shipping_city"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="text"
                label="State / Province"
                placeholder="State / Province"
                formik={formik}
                name="shipping_state"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="number"
                label="Postal / Zip Code"
                placeholder="Postal / Zip Code"
                formik={formik}
                name="shipping_postal_code"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <FormikSelectTwo
                type="text"
                label="Country"
                placeholder="Country"
                Options={CountryMIN}
                formik={formik}
                name="shipping_country"
                width="w-full md:w-1/2 lg:w-1/3"
                FixPosition={false}
                PortalTarget={false}
              />

              <NuTextArea
                label="Comments"
                placeholder="comments"
                formik={formik}
                name="comments"
                width="w-full md:w-1/2 "
                new_width="lg:w-2/3"
              />

              <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
                <div className="md:flex justify-end gap-4">
                  <CancelButton
                    handleClick={() => {
                      Oncancel(-1);
                    }}
                  />
                  <SubmitButton isLoading={isLoading} />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditCustomers;
